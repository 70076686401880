import Login from "./pages/login/Login";
import Signup from "./pages/signup/Signup";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Home from "./pages/home/Home";
import Profile from "./pages/profile/Profile";
import { observer } from "mobx-react-lite";
import UserDetail from "./pages/user_detail/UserDetail";
import styled from "styled-components";
import { useEffect, useState } from "react";
import Appbar from "./components/appbar/Appbar";
import SmsCampaign from "./pages/campaign_forms/SmsCampaign";
import WhatsappCampaign from "./pages/campaign_forms/WhatsappCampaign";
import ImportHistory from "./pages/import_history/ImportHistory";
import TargetPage from "./pages/target_page/TargetPage";
import CreateTarget from "./pages/create_target/CreateTarget";
import TargetDetails from "./pages/target_page/TargetDetails";
import Dashboard from "./pages/dashboard/Dashboard";
import NotificationPage from "./pages/notification_page/NotificationPage";
import AdminSettingPage from "./pages/admin_setting/AdminSettingPage";
import MemberSetting from "./pages/member_setting/MemberSetting";
import ProjectSetting from "./pages/project_setting/ProjectSetting";
import ProjectDetails from "./pages/project_details/ProjectDetails";
import TeamDetails from "./pages/team_details/TeamDetails";
import ReportsPage from "./pages/reports/ReportsPage";
import ReportDetailsPage from "./pages/reports/ReportDetailsPage";
import CampaignSection from "./pages/marketing/CampaignSection";
import SegmentSection from "./pages/marketing/SegmentSection";
import JobsPage from "./pages/jobs_page/JobsPage";
import SupportSection from "./pages/support_section/SupportSection";
import BillingSection from "./pages/billing_section/BillingSection";
import WebsiteTemplates from "./pages/website_templates/WebsiteTemplates";
import WebsiteTemplatePreview from "./pages/website_templates/WebsiteTemplatePreview";
import DigitalMedia from "./pages/digital_media/DigitalMedia";
import CheckInPage from "./pages/checkin_page/CheckInPage";
import UserCheckinPage from "./pages/checkin_page/UserCheckinPage";
import AdminUserCheckinPage from "./pages/checkin_page/AdminUserCheckinPage";
import rootStore from "./stores/RootStore";
import NoAccessPage from "./pages/no_access_page/NoAccessPage";
import FilterPage from "./pages/filter_page/FilterPage";
import { styled as muiStyled } from "@mui/system";
import { CircularProgress, Box } from "@material-ui/core";
import RawLeadsPage from "./pages/raw_leads_page/RawLeadsPage";
import AdminProjectSetting from "./pages/project_administration/AdminProjectSetting";
import AdminProjectMembers from "./pages/project_administration/AdminProjectMembers";
import AdminProjectTeams from "./pages/project_administration/AdminProjectTeams";
import AdminProjectSources from "./pages/project_administration/AdminProjectSources";
import AdminProjectStatuses from "./pages/project_administration/AdminProjectStatuses";
import AdminProjectStages from "./pages/project_administration/AdminProjectStages";
import AdminProjectStatusToStage from "./pages/project_administration/AdminProjectStatusToStage";
import AdminProjectColumns from "./pages/project_administration/AdminProjectColumns";
import AdminProjectJobs from "./pages/project_administration/AdminProjectJobs";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import ImportDetailsPage from "./pages/import_history/ImportDetailsPage";
import TeckInfoCallActionButton from "./components/teckinfo_components/TeckInfoCallActionButton";
import IpConfigPage from "./pages/ip_config_page/IpConfigPage";
import NetworkRestrictionPage from "./pages/network_restriction_page/NetworkRestrictionPage";
import SetupInterceptors from "./SetupInterceptors";
import QuickStartDialog from "./components/quick_start_dialog/QuickStartDialog";
import BirthdayPopup from "./components/birthday_popup_dialog/BirthdayPopup";
import { consoleLogger, isCurrentLatLongValid } from "./Functions";
import CampaignStatsPage from "./pages/campaign_stats_page/CampaignStatsPage";
import FormBuilderPage from "./pages/form_builder_page/FormBuilderPage";
import CustomFormPage from "./pages/custom_form_page/CustomFormPage";
import WebFormsListingPage from "./pages/web_forms_listing_page/WebFormsListingPage";
import WebformStatsPage from "./pages/webform_stats_page/WebformStatsPage";
import AdminProjectSmartViews from "./pages/project_administration/AdminProjectSmartViews";
import OfflineCampaignStatsPage from "./pages/campaign_stats_page/OfflineCampaignStatsPage";
import ProductsPage from "./pages/products_page/ProductsPage";
import LeadsPage from "./pages/leads_page/LeadsPage";
import ContactsPage from "./pages/contacts_page/ContactsPage";
import DealsPage from "./pages/deals_page/DealsPage";
import QuotesPage from "./pages/quotes_page/QuotesPage";
import ContactDetailsPage from "./pages/contacts_page/ContactDetailsPage";
import WebpageBuilderPage from "./pages/webpage_builder_page/WebpageBuilderPage";
import OrganizationsPage from "./pages/organizations_page/OrganizationsPage";
import OrganizationDetailsPage from "./pages/organizations_page/OrganizationDetailsPage";
import AppbarV2 from "./components/appbar/AppbarV2";
import AdminProjectViewGroups from "./pages/project_administration/AdminProjectViewGroups";
import CustomersPage from "./pages/customers_page/CustomersPage";
import CustomerDetailsPage from "./pages/customers_page/CustomerDetailsPage";
import AdminProjectPredefinedDeals from "./pages/project_administration/AdminProjectPredefinedDeals";
import FilesPage from "./pages/files_page/FilesPage";
import FilesDetailsPage from "./pages/files_page/FilesDetailsPage";
import AdminProjectColumnGroups from "./pages/project_administration/AdminProjectColumnGroups";
import BannerComponent from "./components/banner_component/BannerComponent";
import CreateLandingPage from "./pages/create_landing_page/CreateLandingPage";
import UpdateLandingPage from "./pages/create_landing_page/UpdateLandingPage";
import QualityPage from "./pages/project_administration/prioritization/QualityPage";
import PreviewQuotePage from "./pages/preview_quote_page/PreviewQuotePage";
import TermsAndConditionsDialog from "./components/terms_and_conditions_dialog/TermsAndConditionsDialog";
import AdminProjectQuotations from "./pages/project_administration/AdminProjectQuotations";
import UserLoginHistoryPage from "./pages/user_login_history_page/UserLoginHistoryPage";
import IntegrationsPage from "./pages/integrations/IntegrationsPage";
import AdminProjectWorkDayTemplates from "./pages/project_administration/AdminProjectWorkDayTemplates";
import BranchLocationsPage from "./pages/branch_locations_page/BranchLocationsPage";
import AttendanceSettingsPage from "./pages/attendance_settings_page/AttendanceSettingsPage";
import AttendanceHistoryPage from "./pages/attendance_history_page/AttendanceHistoryPage";
import AttendanceDetailsPage from "./pages/attendance_details_page/AttendanceDetailsPage";
import TasksPage from "./pages/tasks_page/TasksPage";
import TaskTypesPage from "./pages/tasks_page/TaskTypesPage";
import AdminProjectLeadForm from "./pages/project_administration/AdminProjectLeadForm";
import BrandingPage from "./pages/branding/BrandingPage";
import LoadingAnimationComponent from "./components/loading_component/LoadingAnimationComponent";
import AdminPersonalWhatsappTemplates from "./pages/whatsapp_templates/admin/AdminPersonalWhatsappTemplates";
import AdminBusinessWhatsappTemplates from "./pages/whatsapp_templates/admin/AdminBusinessWhatsappTemplates";
import ProjectPersonalWhatsappTemplates from "./pages/whatsapp_templates/project_admin/ProjectPersonalWhatsappTemplates";
import ProjectBusinessWhatsappTemplates from "./pages/whatsapp_templates/project_admin/ProjectBusinessWhatsappTemplates";
import InvoicesPage from "./pages/invoices_page/InvoicesPage";
import NoAccessForLocationPage from "./pages/no_access_page/NoAccessForLocationPage";
import SecurityPage from "./pages/security_page/SecurityPage";
const Wrapper = muiStyled(Box)`
  display: flex;
`;
const Right = muiStyled(Box)`
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
  pointer-events: ${(e) => (e.open ? "none" : "unset")};
`;
const ProgressWrapper = muiStyled(Box)(({ theme }) => ({
  width: "80%",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: "auto",
}));
const SpinnerWrapper = muiStyled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  paddingTop: "10px",
}));

//below functional component added to use routing inside interceptor

function NavigateFunctionComponent() {
  let navigate = useNavigate();
  const [ran, setRan] = useState(false);

  {
    /* only run setup once */
  }
  if (!ran) {
    SetupInterceptors(navigate);
    setRan(true);
  }
  return <></>;
}

function App() {
  const { authStore, userStore } = rootStore;
  const [data, setData] = useState();
  const [isCollapse, setIsCollapse] = useState(false);
  const [showBar, setShowBar] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [openMainFilter, setOpenMainFilter] = useState(false);
  const [row, setRow] = useState();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState();
  const [type, setType] = useState(false);
  const [page, setPage] = useState(0);
  const [isNotFilterPage, setIsNotFilterPage] = useState(true);
  const [initLoading, setInitLoading] = useState(true);

  //***************firebase related configuration*************** */
  const firebaseConfig = {
    apiKey: "AIzaSyDCZ3NIHu3vUyO_5lGnDFRbn-d_Rw3tJuA",
    authDomain: "leadpixie.firebaseapp.com",
    projectId: "leadpixie",
    storageBucket: "leadpixie.appspot.com",
    messagingSenderId: "735626050890",
    appId: "1:735626050890:web:45c90a2064163d0fe895b3",
    measurementId: "G-8C1SCMYCXB",
  };

  const firebase = initializeApp(firebaseConfig);
  const analytics = getAnalytics(firebase);
  //************************END*************************** */
  function AppBarRoute() {
    const location = useLocation();
    const canShowAppBar = () => {
      if (
        localStorage.getItem("token") !== null &&
        localStorage.getItem("uid") !== null
      ) {
        return (
          location.pathname !== "/filter-page" &&
          location.pathname !== "/web-forms/create" &&
          location.pathname !== "/web-forms/form" &&
          location.pathname !== "/book-demo" &&
          location.pathname !== "/book-demo/" &&
          location.pathname !== "/webpage/create" &&
          location.pathname !== "/landing-page/create" &&
          location.pathname !== "/landing-page/update" &&
          location.pathname !== "/quote/preview"
        );
      }
      return false;
    };
    return canShowAppBar() ? (
      <AppbarV2
        isFiltered={isFiltered}
        open={openMainFilter}
        setOpen={setOpenMainFilter}
        setRow={setRow}
        setCount={setCount}
        setLoading={setLoading}
        setIsFiltered={setIsFiltered}
        setPage={setPage}
        setType={setType}
        row={row}
        type={type}
      />
    ) : null;
  }

  const RenderOwnerBirthdayPopup = () => {
    const location = useLocation();
    useEffect(() => {}, [location]);
    return authStore.canShowLoginPopup("dob") && <BirthdayPopup />;
  };

  const RenderStartDayDialog = () => {
    const location = useLocation();
    useEffect(() => {}, [location]);
    return authStore.canShowStartDayDialog() && <QuickStartDialog />;
  };

  const RenderTermsAndConditionsDialog = () => {
    const location = useLocation();
    useEffect(() => {}, [location]);
    return (
      userStore.canShowTermsAndConditionsDialog() && (
        <TermsAndConditionsDialog />
      )
    );
  };
  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    consoleLogger("init is called");
    if (!window.location.pathname.includes("/quote/preview")) {
      try {
        if (
          localStorage.getItem("token") !== null &&
          localStorage.getItem("uid") !== null
        ) {
          await userStore.fetchUserData();
          rootStore.subscriptionState.init();
          if (userStore.UserData !== null) {
            await authStore.postInit();
            if (authStore.canFetchStartDayApi()) {
              await authStore.fetchUserStartDayApiCall(); //this api fetch function is for startday api (get fetched once in a day), calling it here as we need to determine role which we will get after fetching userdata api
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    //lat, long check if addon is present and check flag is true
    await rootStore.authStore.checkIfLoginLocationValid();

    setInitLoading(false);

    consoleLogger("init completed");
  };

  return (
    <>
      {initLoading ? (
        <ProgressWrapper>
          <SpinnerWrapper>
            <LoadingAnimationComponent size={"large"} />
          </SpinnerWrapper>
        </ProgressWrapper>
      ) : (
        <Router>
          {<NavigateFunctionComponent />}
          <Wrapper>
            <BannerComponent />
            <AppBarRoute />
            {/* {openMainFilter && (
            <MainFilterModal
              open={openMainFilter}
              setOpen={setOpenMainFilter}
              setRow={setRow}
              setCount={setCount}
              setLoading={setLoading}
              setIsFiltered={setIsFiltered}
              setPage={setPage}
              setType={setType}
              row={row}
              type={type}
            />
          )} */}
            {rootStore.authStore.isLocationValid ? (
              <Right open={openMainFilter}>
                <Routes>
                  <Route
                    path="/"
                    element={
                      <LeadsPage
                        setIsCollapse={setIsCollapse}
                        isCollapse={isCollapse}
                        data={data}
                        setShowBar={setIsNotFilterPage}
                        isFiltered={isFiltered}
                        setIsFiltered={setIsFiltered}
                        row={row}
                        setRow={setRow}
                        loading={loading}
                        setLoading={setLoading}
                        count={count}
                        setCount={setCount}
                        page={page}
                        setPage={setPage}
                      />
                    }
                  />
                  <Route path="/signup" element={<Signup />} />
                  <Route
                    path="/web-forms/create"
                    element={<FormBuilderPage />}
                  />
                  <Route path="/web-forms/form" element={<CustomFormPage />} />
                  <Route path="/web-forms" element={<WebFormsListingPage />} />
                  <Route
                    path="/web-forms/stats/:id"
                    element={<WebformStatsPage />}
                  />

                  <Route
                    path="/login"
                    element={
                      <Login
                        setIsCollapse={setIsCollapse}
                        setData={setData}
                        setShowBar={setIsNotFilterPage}
                      />
                    }
                  />
                  <Route
                    path="/profile"
                    element={<Profile setShowBar={setIsNotFilterPage} />}
                  />
                  <Route
                    path="/campaigns"
                    element={
                      <CampaignSection setShowBar={setIsNotFilterPage} />
                    }
                  />
                  <Route
                    path="/campaigns/:id/:campaignType/"
                    element={
                      <CampaignStatsPage setShowBar={setIsNotFilterPage} />
                    }
                  />
                  <Route
                    path="/campaigns/offline/:id/:campaignType/"
                    element={
                      <OfflineCampaignStatsPage
                        setShowBar={setIsNotFilterPage}
                      />
                    }
                  />
                  <Route
                    path="/segments"
                    element={<SegmentSection setShowBar={setIsNotFilterPage} />}
                  />
                  <Route
                    path="/website-templates"
                    element={
                      <WebsiteTemplates setShowBar={setIsNotFilterPage} />
                    }
                  />
                  <Route
                    path="/website-templates/preview"
                    element={
                      <WebsiteTemplatePreview setShowBar={setIsNotFilterPage} />
                    }
                  />
                  <Route
                    path="/digital-media"
                    element={<DigitalMedia setShowBar={setIsNotFilterPage} />}
                  />
                  <Route
                    path="/smscampaign"
                    element={
                      <SmsCampaign
                        setShowBar={setIsNotFilterPage}
                        setOpen={setOpenMainFilter}
                        setType={setType}
                      />
                    }
                  />
                  <Route
                    path="/whatsapp_campaign"
                    element={
                      <WhatsappCampaign
                        setShowBar={setIsNotFilterPage}
                        setOpen={setOpenMainFilter}
                        setType={setType}
                      />
                    }
                  />
                  <Route
                    path="/users/:uid/leads/:lid"
                    element={
                      <UserDetail
                        setIsCollapse={setIsCollapse}
                        isCollapse={isCollapse}
                        setShowBar={setIsNotFilterPage}
                      />
                    }
                  />
                  <Route path="/summary" element={<Dashboard />} />

                  <Route
                    path="/no-access-for-location"
                    element={<NoAccessForLocationPage />}
                  />

                  <Route
                    path="/imports"
                    element={<ImportHistory setShowBar={setIsNotFilterPage} />}
                  />
                  <Route path="/imports/:id" element={<ImportDetailsPage />} />
                  <Route
                    path="/reports"
                    element={<ReportsPage setShowBar={setIsNotFilterPage} />}
                  />
                  <Route
                    path="/reports/details/:id"
                    element={
                      <ReportDetailsPage setShowBar={setIsNotFilterPage} />
                    }
                  />
                  <Route
                    path="/target"
                    element={<TargetPage setShowBar={setIsNotFilterPage} />}
                  />
                  <Route
                    path="/target/:name/:id"
                    element={<TargetDetails setShowBar={setIsNotFilterPage} />}
                  />
                  <Route
                    path="/create-target"
                    element={<CreateTarget setShowBar={setIsNotFilterPage} />}
                  />
                  <Route
                    path="/notification/:type/:title"
                    element={
                      <NotificationPage setShowBar={setIsNotFilterPage} />
                    }
                  />
                  {/* <Route
                path="/dashboard"
                element={<Dashboard setShowBar={setShowBar} />}
              /> */}
                  <Route
                    path="/account-setting"
                    element={
                      <AdminSettingPage setShowBar={setIsNotFilterPage} />
                    }
                  />
                  <Route
                    path="/admin/users"
                    element={<MemberSetting setShowBar={setIsNotFilterPage} />}
                  />
                  <Route
                    path="/admin/users-login-history/:id"
                    element={
                      <UserLoginHistoryPage setShowBar={setIsNotFilterPage} />
                    }
                  />
                  <Route
                    path="/admin/projects"
                    element={<ProjectSetting setShowBar={setIsNotFilterPage} />}
                  />
                  <Route
                    path="/admin/jobs"
                    element={<JobsPage setShowBar={setIsNotFilterPage} />}
                  />
                  <Route
                    path="/admin/billing"
                    element={<BillingSection setShowBar={setIsNotFilterPage} />}
                  />
                  <Route
                    path="/admin/branding"
                    element={<BrandingPage setShowBar={setIsNotFilterPage} />}
                  />
                  <Route
                    path="/admin/security"
                    element={<SecurityPage setShowBar={setIsNotFilterPage} />}
                  />
                  <Route
                    path="/project-detail/:name/:id"
                    element={<ProjectDetails setShowBar={setIsNotFilterPage} />}
                  />
                  <Route
                    path="/team-detail/:name/:id/:pid/:pname"
                    element={<TeamDetails setShowBar={setIsNotFilterPage} />}
                  />
                  <Route
                    path="/support"
                    element={<SupportSection setShowBar={setIsNotFilterPage} />}
                  />
                  <Route
                    path="/checkin"
                    element={<CheckInPage setShowBar={setIsNotFilterPage} />}
                  />
                  <Route
                    path="/checkin/:pid/:id"
                    element={
                      <UserCheckinPage setShowBar={setIsNotFilterPage} />
                    }
                  />
                  <Route
                    path="/admin/checkin/:id"
                    element={
                      <AdminUserCheckinPage setShowBar={setIsNotFilterPage} />
                    }
                  />
                  <Route
                    path="/filter-page"
                    element={
                      <FilterPage
                        setShowBar={setIsNotFilterPage}
                        setIsNotFilterPage={setIsNotFilterPage}
                      />
                    }
                  />
                  <Route path="/admin/raw-leads" element={<RawLeadsPage />} />
                  <Route
                    path="/admin/integrations"
                    element={<IntegrationsPage />}
                  />

                  <Route path="/admin/task-types" element={<TaskTypesPage />} />
                  <Route path="/tasks" element={<TasksPage />} />

                  <Route
                    path="/admin/whatsapp-templates/business"
                    element={<AdminBusinessWhatsappTemplates />}
                  />
                  <Route
                    path="/admin/whatsapp-templates/personal"
                    element={<AdminPersonalWhatsappTemplates />}
                  />

                  <Route
                    path="/project-administration/whatsapp-templates/business"
                    element={<ProjectBusinessWhatsappTemplates />}
                  />
                  <Route
                    path="/project-administration/whatsapp-templates/personal"
                    element={<ProjectPersonalWhatsappTemplates />}
                  />
                  {/* **************************routes for project administration submenus ************************/}
                  <Route
                    path="/project-administration/settings"
                    element={<AdminProjectSetting />}
                  />
                  <Route
                    path="/project-administration/members"
                    element={<AdminProjectMembers />}
                  />
                  <Route
                    path="/project-administration/teams"
                    element={<AdminProjectTeams />}
                  />
                  <Route
                    path="/project-administration/sources"
                    element={<AdminProjectSources />}
                  />
                  <Route
                    path="/project-administration/statuses"
                    element={<AdminProjectStatuses />}
                  />
                  <Route
                    path="/project-administration/stages"
                    element={<AdminProjectStages />}
                  />
                  <Route
                    path="/project-administration/statustostage"
                    element={<AdminProjectStatusToStage />}
                  />
                  <Route
                    path="/project-administration/columns"
                    element={<AdminProjectColumns />}
                  />
                  <Route
                    path="/project-administration/columngroups"
                    element={<AdminProjectColumnGroups />}
                  />
                  <Route
                    path="/project-administration/jobs"
                    element={<AdminProjectJobs />}
                  />
                  <Route
                    path="/project-administration/view-tabs"
                    element={<AdminProjectSmartViews />}
                  />
                  <Route
                    path="/project-administration/view-groups"
                    element={<AdminProjectViewGroups />}
                  />
                  <Route
                    path="/project-administration/predefined-deals"
                    element={<AdminProjectPredefinedDeals />}
                  />
                  <Route
                    path="/project-administration/quotations"
                    element={<AdminProjectQuotations />}
                  />
                  <Route
                    path="/project-administration/workday-templates"
                    element={<AdminProjectWorkDayTemplates />}
                  />
                  <Route
                    path="/project-administration/lead-form"
                    element={<AdminProjectLeadForm />}
                  />
                  {/* ************************************END**************************************** */}

                  <Route path="/admin/ip-config" element={<IpConfigPage />} />
                  <Route
                    path="/admin/branch-locations"
                    element={<BranchLocationsPage />}
                  />
                  <Route
                    path="/admin/attendance-settings"
                    element={<AttendanceSettingsPage />}
                  />
                  <Route
                    path="/admin/attendance-history"
                    element={<AttendanceHistoryPage />}
                  />
                  <Route
                    path="/admin/attendance-history/:id"
                    element={<AttendanceDetailsPage />}
                  />
                  <Route path="/products" element={<ProductsPage />} />
                  <Route path="/contacts" element={<ContactsPage />} />
                  <Route
                    path="/contacts/:id"
                    element={<ContactDetailsPage />}
                  />
                  <Route path="/deals" element={<DealsPage />} />
                  <Route path="/quotes" element={<QuotesPage />} />
                  <Route path="/invoices" element={<InvoicesPage />} />
                  <Route
                    path="/organizations"
                    element={<OrganizationsPage />}
                  />
                  <Route path="/customers" element={<CustomersPage />} />
                  <Route
                    path="/customers/:id"
                    element={<CustomerDetailsPage />}
                  />

                  <Route
                    path="/organizations/:id"
                    element={<OrganizationDetailsPage />}
                  />
                  <Route
                    path="/network-restriction"
                    element={<NetworkRestrictionPage />}
                  />
                  <Route
                    path="/webpage/create"
                    element={<WebpageBuilderPage />}
                  />
                  <Route path="/files" element={<FilesPage />} />
                  <Route path="/files/:id" element={<FilesDetailsPage />} />

                  <Route
                    path="/landing-page/create"
                    element={<CreateLandingPage />}
                  />
                  <Route
                    path="/landing-page/update"
                    element={<UpdateLandingPage />}
                  />

                  <Route
                    path="/project-administration/quality"
                    element={<QualityPage />}
                  />
                  <Route path="/quote/preview" element={<PreviewQuotePage />} />
                </Routes>
              </Right>
            ) : (
              <NoAccessForLocationPage />
            )}
          </Wrapper>
          {rootStore.authStore.isLocationValid && (
            <>
              <RenderStartDayDialog />
              <RenderOwnerBirthdayPopup />
              <RenderTermsAndConditionsDialog />
            </>
          )}
        </Router>
      )}
      {/* <TeckInfoCallActionButton /> */}
    </>
  );
}

export default observer(App);
