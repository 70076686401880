import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  IconButton,
  Tab,
  Tabs,
  withStyles,
} from "@material-ui/core";
import FirstForm from "../create_lead_form/FirstForm";
import SecondForm from "../create_lead_form/SecondForm";
import {
  createContactApi,
  editContactApi,
  searchContactByEmailApi,
  searchContactByPhoneApi,
  submitLeadApi,
} from "../../Api";
import {
  IsoToLocalDate,
  IsoToLocalTime,
  getLocalizedText,
  isDateInRange,
  isDateTimeInRange,
} from "../../Functions";
import { myTheme } from "../../themeUtils";
import rootStore from "../../stores/RootStore";
import { useEffect } from "react";
import {
  AppBar,
  Backdrop,
  CircularProgress,
  Toolbar,
  Typography,
} from "@mui/material";
import analytics from "../../Analytics";
import { Close } from "@material-ui/icons";
import MuiAlert from "@mui/material/Alert";
import { titleCase } from "title-case";
import { LoadingButton } from "@mui/lab";
const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  margin: 0px;
`;
const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  row-gap: 20px;
`;
const Message = styled(Typography)`
  font: normal normal normal 18px Open Sans;
  color: green;
  margin: 0px;
  text-align: center;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  column-gap: 16px;
  justify-content: flex-end;
`;
const Column = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Row = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
`;

const CreateLeadComponent = (props) => {
  const { userStore } = rootStore;
  const [tabIdx, setTabIdx] = useState(0);
  const [mandatoryFields, setMandatoryFields] = useState([]);
  const [filledParentChildMap, setFilledParentChildMap] = useState({});
  const columnList = [...userStore.AllColumnsList];
  const [errorMessage, setErrorMessage] = useState("");
  const [contactDetails, setContactDetails] = useState({});
  const [formData, setFormData] = useState({});
  const [loadingContactData, setLoadingContactData] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);
  const validateMandatoryFields = ({ formData }) => {
    let filled_fields = { ...formData };
    for (let i = 0; i < mandatoryFields.length; i++) {
      let item = mandatoryFields[i];

      if (filled_fields.hasOwnProperty(item["field"])) {
        if (
          filled_fields[item["field"]]["value"] === "" ||
          filled_fields[item["field"]]["value"] === null
        ) {
          setErrorMessage(`Please fill "${item["headerName"]}" field`);
          return false;
        }
      } else {
        setErrorMessage(`Please fill "${item["headerName"]}" field`);
        return false;
      }
    }
    for (let key in filledParentChildMap) {
      let childIDList = filledParentChildMap[key];
      for (let i = 0; i < childIDList.length; i++) {
        let index = columnList.findIndex(
          (column) => column["id"] === childIDList[i]
        );
        if (index !== -1) {
          let item = columnList[index];
          if (item["required"] === true) {
            if (filled_fields.hasOwnProperty(item["field"])) {
              if (
                filled_fields[item["field"]]["value"] === "" ||
                filled_fields[item["field"]]["value"] === null
              ) {
                setErrorMessage(`Please fill "${item["headerName"]}" field`);
                return false;
              }
            } else {
              setErrorMessage(`Please fill "${item["headerName"]}" field`);
              return false;
            }
          }
        }
      }
    }

    return true;
  };

  const inRange = (num, min, max) => num >= min && num <= max;
  const validateFieldsWithRange = ({ formData }) => {
    let filled_fields = { ...formData };

    for (let key in filled_fields) {
      let index = columnList.findIndex((column) => column["field"] === key);
      if (index !== -1) {
        let columnMeta = columnList[index];
        if (
          columnMeta["min"] !== null &&
          columnMeta["max"] !== null &&
          columnMeta["min"] !== "" &&
          columnMeta["max"] !== "" &&
          filled_fields[key]["value"] !== null &&
          filled_fields[key]["value"] !== ""
        ) {
          if (columnMeta["data_type"] === "integer") {
            if (
              !inRange(
                parseFloat(filled_fields[key]["value"]),
                parseFloat(columnMeta["min"]),
                parseFloat(columnMeta["max"])
              )
            ) {
              setErrorMessage(
                `Please ensure the '${columnMeta["headerName"]}' value is between ${columnMeta["min"]} and ${columnMeta["max"]}.`
              );
              return false;
            }
          } else if (columnMeta["data_type"] === "datetime") {
            if (
              !isDateTimeInRange(
                columnMeta["min"],
                columnMeta["max"],
                filled_fields[key]["value"]
              )
            ) {
              setErrorMessage(
                `Please ensure the '${
                  columnMeta["headerName"]
                }' value is between ${IsoToLocalTime(
                  columnMeta["min"]
                )} and ${IsoToLocalTime(columnMeta["max"])}.`
              );
              return false;
            }
          } else if (columnMeta["data_type"] === "date") {
            if (
              !isDateInRange(
                columnMeta["min"],
                columnMeta["max"],
                filled_fields[key]["value"]
              )
            ) {
              setErrorMessage(
                `Please ensure the '${
                  columnMeta["headerName"]
                }' value is between ${IsoToLocalDate(
                  columnMeta["min"]
                )} and ${IsoToLocalDate(columnMeta["max"])}.`
              );
              return false;
            }
          }
        }
      }
    }

    return true;
  };

  const formFieldSeparator = (data) => {
    let normalFields = {};
    let customFields = {};
    for (const key in data) {
      let index = columnList.findIndex((column) => column["field"] === key);
      if (index !== -1) {
        let value = data[key]["value"];
        if (data[key]["type"] === "integer") {
          value = parseInt(data[key]["value"]);
        } else if (data[key]["type"] === "array") {
          let tempList = [];
          data[key]["value"].forEach((item) => {
            tempList.push(item["value"]);
          });
          value = tempList.join(",");
        } else {
          value = data[key]["value"];
          if (columnList[index].hasOwnProperty("set_title_case")) {
            if (columnList[index]["set_title_case"] === true) {
              value = titleCase(data[key]["value"]);
            }
          }
        }
        if (columnList[index]["is_custom_param"]) {
          let newKey = key.replace("custom_params_", "");
          customFields[newKey] = value;
        } else {
          normalFields[key] = value;
        }
      }
    }
    return { ...normalFields, custom_params: customFields };
  };

  const generateContactPayloadData = (data) => {
    let contactFields = {};
    for (const key in data) {
      let value = data[key]["value"];
      if (data[key]["type"] === "integer") {
        value = parseInt(data[key]["value"]);
      } else if (data[key]["type"] === "array") {
        let tempList = [];
        data[key]["value"].forEach((item) => {
          tempList.push(item["value"]);
        });
        value = tempList.join(",");
      } else {
        value = data[key]["value"];
        let index = columnList.findIndex(
          (column) => column["contact_column"] === key
        );
        if (index !== -1) {
          if (columnList[index].hasOwnProperty("set_title_case")) {
            if (columnList[index]["set_title_case"] === true) {
              value = titleCase(data[key]["value"]);
            }
          }
        }
      }
      contactFields[key] = value;
    }

    return contactFields;
  };

  const getContactAndLeadData = (data) => {
    let contactData = {};
    let leadData = {};
    for (const key in data) {
      let index = columnList.findIndex((column) => column["field"] === key);
      if (index !== -1) {
        let columnData = columnList[index];
        if (columnData["contact_column"] !== null) {
          contactData[columnData["contact_column"]] = data[columnData["field"]];
        }
        leadData[columnData["field"]] = data[columnData["field"]];
      }
    }
    return { contactData: contactData, leadData: leadData };
  };

  const canUpdateContact = (generatedContactData, contactDetails) => {
    for (const key in generatedContactData) {
      if (generatedContactData[key] !== contactDetails[key]) {
        return true;
      }
    }
    return false;
  };

  const isPhoneAndEmailFieldDisabled = (fieldName) => {
    return (
      Object.keys(contactDetails).length > 0 &&
      (fieldName.toLowerCase() === "phone" ||
        fieldName.toLowerCase() === "email")
    );
  };

  const handleSubmit = async ({ contactDetails, formData }) => {
    setSubmittingForm(true);
    setErrorMessage("");
    setIsSubmitFail(false);
    if (validateMandatoryFields({ formData: formData })) {
      if (validateFieldsWithRange({ formData: formData })) {
        setIsSubmitFail(false);
        setErrorMessage("");

        //addon lead limit check
        const limitExceeded = await rootStore.subscriptionState.hasReachedMaxLeadsLimitForProject({
          newLeadCount: 1,
          pid: rootStore.authStore.projectId
        });
        if (limitExceeded){
          setErrorMessage(
            "You've reached the maximum number of leads allowed for this project. Please get in touch with support for assistance or to upgrade your plan."
          );
          setIsSubmitFail(true);
          setSubmittingForm(false);
          return;
        }
        let tempContactDetails = contactDetails;
        let tempFormData = formData;
        if (Object.keys(tempContactDetails).length === 0) {
          let contactData = await fetchContactData();
          if (Object.keys(contactData).length > 0) {
            tempContactDetails = contactData;

            let mappedData = mappedContactFields(contactData);
            let resultantFormData = { ...tempFormData, ...mappedData };
            tempFormData = resultantFormData;
            setFormData(resultantFormData);
          }
        }
        let contactAndLeadData = getContactAndLeadData({ ...tempFormData });
        let contactData = generateContactPayloadData(
          contactAndLeadData.contactData
        );
        let leadData = formFieldSeparator(contactAndLeadData.leadData);
        if (Object.keys(tempContactDetails).length > 0) {
          await createLeadWithExistingContact({
            contactData: contactData,
            leadData: leadData,
            contactDetails: tempContactDetails,
          });
        } else {
          await createdLeadWithNewContact({
            contactData: contactData,
            leadData: leadData,
          });
        }
      } else {
        setIsSubmitFail(true);
      }
    } else {
      setIsSubmitFail(true);
    }
    setSubmittingForm(false);
  };

  const CustomTab = withStyles({
    root: {
      backgroundColor: "white",
      color: "#4D4E4F",
      font: "normal normal 600 14px Open Sans",
      textTransform: "capitalize",
    },
    selected: {
      backgroundColor: "#E7EEFA",
      color: "#4079DA",
      font: "normal normal 600 14px Open Sans",
      textTransform: "capitalize",
    },
  })(Tab);

  useEffect(() => {
    setup();
    analytics.triggerEvent(
      4625000028,
      "create_lead_form_load",
      "leads_page",
      "",
      {}
    );
    let mandatory_field_list = [];
    columnList.forEach((item) => {
      if (
        item["required"] === true &&
        item["derived"] === false &&
        item["enable_for_create"] === true &&
        item["child_of"] === null
      ) {
        let tempObj = { headerName: item["headerName"] };
        tempObj["field"] = item["field"];

        mandatory_field_list.push(tempObj);
      }
    });
    setMandatoryFields(mandatory_field_list);
  }, []);

  const setup = async () => {
    rootStore.authStore.checkAndFetchTotalProjectLeadCount(
      rootStore.authStore.projectId
    ); //fetch total leads count in background
  };

  const mappedContactFields = (contactData) => {
    let tempObj = {};

    columnList.forEach((column) => {
      if (column["contact_column"] !== null) {
        if (contactData.hasOwnProperty(column["contact_column"])) {
          let value = contactData[column["contact_column"]];
          if (value !== null) {
            tempObj[column["field"]] = {
              value: value,
              type: column["data_type"],
            };
          }
        }
      }
    });
    return tempObj;
  };

  const handleContactSearch = async (fieldName, fieldValue) => {
    if (fieldName.toLowerCase() === "phone") {
      let response = await searchContactByPhoneApi({
        payload: { [fieldName]: fieldValue },
      });
      if (response.length > 0) {
        setLoadingContactData(true);
        setContactDetails(response[0]);
        let mappedData = mappedContactFields(response[0]);
        setTimeout(() => {
          setFormData({ ...formData, ...mappedData });
          setLoadingContactData(false);
        }, 2000);
      }
    } else if (fieldName.toLowerCase() === "email") {
      let response = await searchContactByEmailApi({
        payload: { [fieldName]: fieldValue },
      });
      if (response.length > 0) {
        setLoadingContactData(true);
        setContactDetails(response[0]);
        let mappedData = mappedContactFields(response[0]);
        setTimeout(() => {
          setFormData({ ...formData, ...mappedData });
          setLoadingContactData(false);
        }, 2000);
      }
    }
  };

  const fetchContactData = async () => {
    let tempContactData = {};
    if (formData.hasOwnProperty("phone")) {
      let response = await searchContactByPhoneApi({
        payload: { phone: formData["phone"]["value"] },
      });
      if (response.length > 0) {
        tempContactData = response[0];
      }
    }
    if (Object.keys(tempContactData).length === 0) {
      if (formData.hasOwnProperty("email")) {
        let response = await searchContactByEmailApi({
          payload: { email: formData["email"]["value"] },
        });
        if (response.length > 0) {
          tempContactData = response[0];
        }
      }
    }
    setContactDetails(tempContactData);
    return tempContactData;
  };

  const handleNextButton = async () => {
    setIsSubmitFail(false);
    if (Object.keys(contactDetails).length === 0) {
      let contactData = await fetchContactData();
      if (Object.keys(contactData).length > 0) {
        setLoadingContactData(true);
        let mappedData = mappedContactFields(contactData);
        let resultantFormData = { ...formData, ...mappedData };
        setTimeout(() => {
          setFormData(resultantFormData);
          setLoadingContactData(false);
          setTabIdx(1);
        }, 2000);
      } else {
        setTabIdx(1);
      }
    } else {
      setTabIdx(1);
    }
  };

  const createLead = async ({ contactID, leadData }) => {
    let payload = {
      contact_id: contactID,
      lead_data: leadData,
    };
    let response = await submitLeadApi({
      payload: payload,
    });

    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      rootStore.authStore.updateTotalProjectLeadCount({ pid: rootStore.authStore.projectId, count: 1 });
      props.setRefresh(!props.refresh);
      setIsSubmitSuccess(true);
    }
  };

  const createdLeadWithNewContact = async ({ contactData, leadData }) => {
    let createContactResponse = await createContactApi({
      payload: contactData,
    });
    if (createContactResponse.hasError()) {
      if (
        createContactResponse.data.hasOwnProperty("code") &&
        createContactResponse.data["code"] === "1029"
      ) {
        await handleDuplicateContact();
      } else {
        setErrorMessage(createContactResponse.errorMessage);
        setIsSubmitFail(true);
      }
    } else {
      await createLead({
        contactID: createContactResponse.data["id"],
        leadData: leadData,
      });
    }
  };

  const createLeadWithExistingContact = async ({
    contactData,
    leadData,
    contactDetails,
  }) => {
    if (canUpdateContact(contactData, contactDetails)) {
      let updateContactResponse = await editContactApi({
        contactID: contactDetails["id"],
        payload: contactData,
      });
      if (updateContactResponse.hasError()) {
        setErrorMessage(updateContactResponse.errorMessage);
        setIsSubmitFail(true);
      } else {
        await createLead({
          contactID: contactDetails["id"],
          leadData: leadData,
        });
      }
    } else {
      await createLead({ contactID: contactDetails["id"], leadData: leadData });
    }
  };

  const handleDuplicateContact = async () => {
    let tempContactData = {};
    if (formData.hasOwnProperty("phone")) {
      let response = await searchContactByPhoneApi({
        payload: { phone: formData["phone"]["value"] },
      });
      if (response.length > 0) {
        tempContactData = response[0];
      }
    }
    if (Object.keys(tempContactData).length === 0) {
      if (formData.hasOwnProperty("email")) {
        let response = await searchContactByEmailApi({
          payload: { email: formData["email"]["value"] },
        });
        if (response.length > 0) {
          tempContactData = response[0];
        }
      }
    }
    if (Object.keys(tempContactData).length > 0) {
      setContactDetails(tempContactData);
      let mappedData = mappedContactFields(tempContactData);
      let resultantData = { ...formData, ...mappedData };
      setFormData(resultantData);
      await handleSubmit({
        contactDetails: tempContactData,
        formData: resultantData,
      });
    } else {
      setErrorMessage("Some error occurred!\nError code:1016");
      setIsSubmitFail(true);
    }
  };

  const RenderCancelOrPreviousButton = () => {
    if (tabIdx === 0) {
      return (
        <Button
          onClick={() => {
            analytics.triggerEvent(
              4625000265,
              "cance_button_tap",
              "create_lead_form",
              "cance_button",
              { order: "primary_info_tab" }
            );
            props.setOpen(false);
          }}
          variant="outlined"
          color="primary"
          style={{ width: "100px", textTransform: "none" }}
        >
          {getLocalizedText("cancel")}
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => {
            analytics.triggerEvent(
              4625000032,
              "previous_button_tap",
              "create_lead_form",
              "previous_button",
              { order: "custom_params_tab" }
            );
            setIsSubmitFail(false);
            setErrorMessage("");
            setTabIdx(0);
          }}
          variant="outlined"
          color="primary"
          style={{ width: "100px", textTransform: "none" }}
        >
          {getLocalizedText("prev")}
        </Button>
      );
    }
  };

  const RenderNextOrSubmitButton = () => {
    if (tabIdx === 0) {
      return (
        <Button
          type="submit"
          onClick={() => {
            analytics.triggerEvent(
              4625000031,
              "next_button_tap",
              "create_lead_form",
              "next_button",
              { order: "primary_info_tab" }
            );
          }}
          variant="contained"
          style={{
            width: "100px",
            ...myTheme.Button.btnBlue,
            textTransform: "none",
          }}
        >
          {getLocalizedText("next")}
        </Button>
      );
    } else {
      if (submittingForm) {
        return (
          <LoadingButton
            loading
            variant="contained"
            color="primary"
            style={{
              textTransform: "none",
              width: "100px",
            }}
          >
            <span> {getLocalizedText("submit")}</span>
          </LoadingButton>
        );
      } else {
        return (
          <Button
            onClick={() => {
              analytics.triggerEvent(
                4625000033,
                "submit_button_tap",
                "create_lead_form",
                "submit_button",
                { order: "custom_params_tab" }
              );
            }}
            type="submit"
            variant="contained"
            style={{
              width: "100px",
              ...myTheme.Button.btnBlue,
              textTransform: "none",
            }}
          >
            {getLocalizedText("submit")}
          </Button>
        );
      }
    }
  };
  return (
    <>
      {!isSubmitSuccess ? (
        <Box
          sx={{ position: "relative", height: "100%" }}
          role="presentation"
          component={"form"}
          onSubmit={(e) => {
            e.preventDefault();
            if (tabIdx === 0) {
              handleNextButton();
            } else {
              handleSubmit({
                contactDetails: contactDetails,
                formData: formData,
              });
            }
          }}
        >
          <AppBar
            component={"nav"}
            position="sticky"
            color="inherit"
            elevation={0}
            sx={{
              top: 0,
              bottom: "auto",
              width: 500,
              right: 0,
              bgcolor: "#f9f9fc",
            }}
          >
            <Toolbar style={{ padding: "16px", paddingBottom: "0px" }}>
              <Column style={{ rowGap: "16px" }}>
                <Row style={{ justifyContent: "space-between" }}>
                  <ModelHeader>
                    {getLocalizedText("create_new_lead", "project")}
                  </ModelHeader>
                  <IconButton
                    style={{ padding: "0px" }}
                    onClick={() => {
                      props.setOpen(false);
                    }}
                  >
                    <Close />
                  </IconButton>
                </Row>
                <Tabs
                  value={tabIdx}
                  textColor="primary"
                  indicatorColor="primary"
                  style={{
                    backgroundColor: "#f9f9fc",
                  }}
                  onChange={() => {}}
                >
                  <CustomTab
                    style={{ borderRadius: "8px 0px 0px 0px" }}
                    label={getLocalizedText("primary_info")}
                  />
                  <CustomTab
                    style={{ borderRadius: "0px 8px 0px 0px" }}
                    label={getLocalizedText("custom_params")}
                  />
                </Tabs>
              </Column>
            </Toolbar>
          </AppBar>

          <>
            <ModelWrapper style={{ marginBottom: "68px" }}>
              {(() => {
                if (tabIdx === 0) {
                  return (
                    <FirstForm
                      value={formData}
                      setValue={setFormData}
                      handleContactSearch={handleContactSearch}
                      isPhoneAndEmailFieldDisabled={
                        isPhoneAndEmailFieldDisabled
                      }
                      filledParentChildMap={filledParentChildMap}
                      setFilledParentChildMap={setFilledParentChildMap}
                      setIsSubmitFail={setIsSubmitFail}
                    />
                  );
                } else if (tabIdx === 1) {
                  return (
                    <SecondForm
                      value={formData}
                      setValue={setFormData}
                      filledParentChildMap={filledParentChildMap}
                      setFilledParentChildMap={setFilledParentChildMap}
                      setIsSubmitFail={setIsSubmitFail}
                    />
                  );
                }
              })()}
            </ModelWrapper>
            <AppBar
              position="fixed"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: 500, bgcolor: "#f9f9fc" }}
            >
              <Toolbar
                style={{ width: 500, padding: "16px", position: "relative" }}
              >
                {isSubmitFail && (
                  <ErrorAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setIsSubmitFail(false);
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {errorMessage}
                  </ErrorAlert>
                )}
                <ButtonWrapper>
                  <RenderCancelOrPreviousButton />
                  <RenderNextOrSubmitButton />
                </ButtonWrapper>
              </Toolbar>
            </AppBar>
          </>
          <Backdrop
            open={loadingContactData}
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
              position: "absolute",
              top: 0,
              left: 0,
            }}
          >
            <CircularProgress
              style={{
                color: "white",
                width: "30px",
                height: "30px",
                marginRight: "16px",
              }}
            />
            <Typography>Fetching data please wait...</Typography>
          </Backdrop>
        </Box>
      ) : (
        <ModelWrapper
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <MessageWrapper>
            <Message>
              {getLocalizedText("lead_created_successfully", "project")}
            </Message>
            <Button
              onClick={() => {
                analytics.triggerEvent(
                  4625000034,
                  "close_button_tap",
                  "create_lead_form",
                  "close_button",
                  {}
                );
                props.setOpen(false);
              }}
              variant="outlined"
              style={{
                ...myTheme.Button.btnLight,
                width: "fit-content",
                textTransform: "none",
              }}
            >
              {getLocalizedText("close")}
            </Button>
          </MessageWrapper>
        </ModelWrapper>
      )}
    </>
  );
};

export default CreateLeadComponent;
