import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, Grid, makeStyles } from "@material-ui/core";
import {
  AppBar,
  Box,
  CircularProgress,
  FormControl,
  OutlinedInput,
  Toolbar,
  Typography,
  IconButton,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { MultiSelect } from "react-multi-select-component";
import { getLocalizedText } from "../../Functions";
import CustomFieldHeader from "../custom_tooltip_headers/CustomFieldHeader";
import MapLocationDialog from "../branch_locations_page_components/MapLocationDialog";
import Select from "react-select";
import {
  getBranchLocationsListApi,
  createAdminSecurityLoginLocationSettings,
} from "../../Api";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 24px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: space-between;
  column-gap: 10px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 24px;
  gap: 20px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for options
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for the entire control container, including the search input
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "no options" message
  }),
  loadingMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "loading" message
  }),
};

const CreatePinToLocationSidepanel = (props) => {
  const classes = useStyles();
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openLocationPicker, setOpenLocationPicker] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [loading, setLoading] = useState(true);
  const rolesList = [
    { label: "Member", value: "member" },
    { label: "Admin", value: "admin" },
  ];
  const [data, setData] = useState({
    roles: props.data.roles ? props.data.roles : [],
    location: props.data.location,
  });

  const getLocations = async () => {
    let response = await getBranchLocationsListApi();
    setLocationList(response);
  };

  const getLocationOptions = () => {
    let tempList = [...locationList];
    return tempList.map((entry) => ({
      label: entry["location_name"],
      value: entry["id"],
    }));
  };

  const handleRolesChange = (e) => {
    setData({
      ...data,
      roles: e,
    });
  };

  const handleSubmit = async (e) => {
    props.setData((prevData) => ({
      ...prevData,
      roles: data.roles,
      location: data.location,
    }));

    const rolesArray = data.roles.map((role) => role.value);
    const payload = {
      roles: rolesArray,
      location_id: data.location.value,
    };
    const response = await createAdminSecurityLoginLocationSettings({
      payload: payload,
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      props.setRefresh(true);
      setIsSubmitSuccess(true);
      props.setOpen(false);
    }
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  const init = async () => {
    await getLocations();
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  return !loading ? (
    <>
      <Box role="presentation">
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            top: 0,
            bottom: "auto",
            width: 500,
            right: 0,
            bgcolor: "#f9f9fc",
          }}
        >
          <Toolbar>
            <ModelHeader>{getLocalizedText("pin_to_location")}</ModelHeader>
          </Toolbar>
        </AppBar>
        {!isSubmitSuccess ? (
          <ModelWrapper component={"form"} onSubmit={handleSubmit}>
            <InputContainer>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputWrapper>
                    <CustomFieldHeader
                      header={getLocalizedText("location")}
                      tooltip={"Choose a branch location."}
                      required={true}
                    />
                    <Select
                      required
                      isDisabled={props.isCreated}
                      options={getLocationOptions()}
                      value={data.location}
                      onChange={(selectedOption) => {
                        setData((prevData) => ({
                          ...prevData,
                          roles: data.roles,
                          location: selectedOption,
                        }));
                      }}
                      closeMenuOnSelect={true}
                      styles={customStyles}
                      isClearable
                    />
                  </InputWrapper>
                </Grid>
                <Grid item xs={12}>
                  <InputWrapper>
                    <CustomFieldHeader
                      header={getLocalizedText("select_roles")}
                      tooltip={"Choose the roles to check for."}
                      required={false}
                    />
                    <Box
                      sx={{
                        "&": {
                          width: "100%",
                          fontSize: "12px",
                          border: "none",
                          alignItems: "center",
                          color: "black",
                        },
                        "& .dropdown-heading": {
                          height: "38px",
                        },
                        "& .options": {
                          maxHeight: "150px",
                        },
                      }}
                    >
                      <MultiSelect
                        disabled={props.isCreated}
                        hasSelectAll={true}
                        options={rolesList}
                        value={data.roles}
                        disableSearch
                        onChange={(e) => {
                          handleRolesChange(e);
                        }}
                        labelledBy="Select Group"
                      />
                    </Box>
                  </InputWrapper>
                </Grid>
              </Grid>
            </InputContainer>

            <AppBar
              position="fixed"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: 500 }}
            >
              <Toolbar style={{ width: 500, position: "relative" }}>
                {isSubmitFail && (
                  <ErrorAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setIsSubmitFail(false);
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {errorMessage}
                  </ErrorAlert>
                )}
                <ButtonWrapper>
                  <Button
                    onClick={handleClose}
                    type="button"
                    variant="contained"
                    color="default"
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={props.isCreated}
                    type="submit"
                    variant="contained"
                    style={{
                      backgroundColor: props.isCreated ? null : "#185DD2",
                      color: "white",
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100%",
                    }}
                  >
                    Submit
                  </Button>
                </ButtonWrapper>
              </Toolbar>
            </AppBar>
          </ModelWrapper>
        ) : (
          <MessageWrapper>
            <Message>Settings updated successfully!</Message>
            <Button
              onClick={handleClose}
              type="button"
              variant="contained"
              color="default"
              style={{
                marginTop: "20px",
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              Close
            </Button>
          </MessageWrapper>
        )}
        {openLocationPicker && (
          <MapLocationDialog
            handleSave={(position) => {
              setData((prevData) => ({
                ...prevData,
                location: position,
              }));
              setOpenLocationPicker(false);
            }}
            open={openLocationPicker}
            setOpen={setOpenLocationPicker}
            locationData={data["location"]}
          />
        )}
      </Box>
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: 500,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default CreatePinToLocationSidepanel;
