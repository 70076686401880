import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import TableComponent from "../../add_quote_sidepanel/table_component/TableComponent";
import { Button, Grid, makeStyles } from "@material-ui/core";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Add, CurrencyRupee, Percent } from "@mui/icons-material";
import { ExpandMore } from "@material-ui/icons";
import { dataRenderer } from "../../../Functions";
import { indianStatesList } from "../../../Db";

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;
const DetailWrapper = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;
const DetailLabel = styled(Typography)`
  font: normal normal 600 12px Open Sans;
  color: #a9a9a9;
  white-space: nowrap;
`;
const CustomizedDatePicker = styled(DatePicker)`
  & .MuiInputBase-input {
    font-size: 12px;
    padding: 7.5px 14px;
  }
`;
const TextLgHighlight = styled(Typography)`
  font: normal normal 600 12px Open Sans;
  margin: 0px;
`;
const Header = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #a9a9a9;
`;

const InvoiceAndProuductInfo = ({
  details,
  isFormDisabled,
  handleChange,
  productAddedList,
  setOpenAddProductModal,
  setSelectedProductData,
  setOpenEditProductModal,
  setOpenViewProductModal,
  handleDeleteProduct,
  amountDetails,
  handleAmountDetails,
  handlePlaceOfSupply,
  placeOfSupply,
  quoteList,
  handleQuote,
  selectedQuoteID,
  invoiceNumber,
  scope,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const accordionDetailsRef = useRef(null);

  const handleAccordion = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  useEffect(() => {
    setTimeout(() => {
      if (expanded && accordionDetailsRef.current) {
        accordionDetailsRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }, 300);
  }, [expanded]);
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputWrapper
            style={{ flexDirection: "row", gap: "8px", alignItems: "center" }}
          >
            <Label>Invoice Number :</Label>
            <Label style={{ fontSize: "12px", fontWeight: "bold" }}>
              {dataRenderer(invoiceNumber)}
            </Label>
          </InputWrapper>
        </Grid>
        <Grid item xs={12}>
          <InputWrapper>
            <Label>Template*</Label>
            <FormControl sx={{ width: "100%" }}>
              <Select
                disabled={scope === "edit"}
                displayEmpty
                required
                onChange={(event) => handleQuote(event.target.value)}
                name="selected_predefined_deal"
                disableUnderline
                variant="outlined"
                value={selectedQuoteID}
                style={{
                  width: "100%",
                  height: "30px",
                  fontSize: "12px",
                  borderRadius: "0px",
                }}
              >
                {quoteList.map((item, i) => {
                  return (
                    <MenuItem
                      value={item.id}
                      key={i}
                      style={{ fontSize: "12px" }}
                    >
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </InputWrapper>
        </Grid>
        <Grid item xs={12}>
          <InputWrapper>
            <Label>Subject*</Label>
            <OutlinedInput
              disabled={isFormDisabled}
              style={{ height: "30px" }}
              inputProps={{
                min: 0,
                "aria-label": "weight",
                style: {
                  fontSize: "12px",
                },
              }}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              name="title"
              required
              value={details["title"]}
              placeholder="Enter value"
            />
          </InputWrapper>
        </Grid>

        <Grid item xs={6}>
          {" "}
          <InputWrapper>
            <Label>Invoice Date</Label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CustomizedDatePicker
                disabled={isFormDisabled}
                onChange={(event) => {
                  handleChange("invoice_date", event);
                }}
                value={details["invoice_date"]}
                inputFormat="YYYY-MM-DD"
                renderInput={(params) => (
                  <TextField sx={{ width: "100%" }} size="small" {...params} />
                )}
              />
            </LocalizationProvider>
          </InputWrapper>
        </Grid>
        <Grid item xs={6}>
          {" "}
          <InputWrapper>
            <Label>Expiry Date</Label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CustomizedDatePicker
                disabled={isFormDisabled || details["invoice_date"] === null}
                onChange={(event) => {
                  handleChange("expiry_date", event);
                }}
                minDate={details["invoice_date"]}
                value={details["expiry_date"]}
                inputFormat="YYYY-MM-DD"
                renderInput={(params) => (
                  <TextField sx={{ width: "100%" }} size="small" {...params} />
                )}
              />
            </LocalizationProvider>
          </InputWrapper>
        </Grid>
        <Grid item xs={12}>
          <InputWrapper>
            <Label>Place of Supply*</Label>
            <FormControl
              sx={{ width: "100%" }}
              classes={{
                root: classes.quantityRoot,
              }}
            >
              <Select
                classes={{
                  icon: classes.icon,
                }}
                disabled={isFormDisabled}
                displayEmpty
                onChange={(event) => {
                  handlePlaceOfSupply(event.target.value);
                }}
                name="place_of_supply"
                required
                value={placeOfSupply}
                disableUnderline
                variant="outlined"
                style={{
                  width: "100%",
                  height: "30px",
                  fontSize: "12px",
                  borderRadius: "4px",
                }}
              >
                {indianStatesList.map((item, i) => {
                  return (
                    <MenuItem value={item} key={i} style={{ fontSize: "12px" }}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </InputWrapper>
        </Grid>

        <Grid item xs={12}>
          <InputWrapper>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Header>Products or Services</Header>

              <Button
                disabled={isFormDisabled}
                variant="text"
                style={{ textTransform: "none", fontSize: "12px" }}
                color="primary"
                onClick={() => {
                  setOpenAddProductModal(true);
                }}
              >
                <Add
                  style={{ marginRight: "6px", width: "18px", height: "18px" }}
                />
                <TextLgHighlight>Add new</TextLgHighlight>
              </Button>
            </Box>
            <TableComponent
              tableData={productAddedList}
              setOpen={setOpenAddProductModal}
              setSelectedProductData={setSelectedProductData}
              setOpenEditProductModal={setOpenEditProductModal}
              setOpenViewProductModal={setOpenViewProductModal}
              handleDeleteProduct={handleDeleteProduct}
              isFormDisabled={isFormDisabled}
            />
          </InputWrapper>
        </Grid>
        <Grid item xs={12}>
          <Accordion expanded={expanded} onChange={handleAccordion}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <DetailWrapper style={{ justifyContent: "flex-start" }}>
                <Header>Grand Total :</Header>
                <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                  <CurrencyRupee
                    style={{ width: "16px", height: "16px", color: "#4d4e4f" }}
                  />
                  <Header style={{ color: "#4d4e4f" }}>
                    {amountDetails["grand_total"] !== ""
                      ? amountDetails["grand_total"]
                      : "-"}
                  </Header>
                </Box>
              </DetailWrapper>
            </AccordionSummary>
            <AccordionDetails ref={accordionDetailsRef}>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item xs={11}>
                  <DetailWrapper>
                    <DetailLabel>Sub Total :</DetailLabel>
                    <OutlinedInput
                      disabled={isFormDisabled}
                      style={{ height: "30px", width: "218px" }}
                      readOnly
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          padding: "0px 12px",
                          fontSize: "12px",
                        },
                      }}
                      startAdornment={
                        <InputAdornment
                          position="end"
                          style={{ marginLeft: "0px" }}
                        >
                          <Label style={{ fontSize: "12px", color: "#A9A9A9" }}>
                            INR
                          </Label>
                        </InputAdornment>
                      }
                      type="text"
                      name="sub_total"
                      onChange={(event) => handleAmountDetails(event)}
                      value={
                        amountDetails["sub_total"] !== ""
                          ? amountDetails["sub_total"]
                          : "-"
                      }
                      placeholder="Enter value"
                    />
                  </DetailWrapper>
                </Grid>
                <Grid item xs={11}>
                  <DetailWrapper>
                    <DetailLabel>Additional Discount :</DetailLabel>
                    <OutlinedInput
                      disabled={isFormDisabled}
                      style={{ height: "30px", width: "218px" }}
                      inputProps={{
                        min: 0,
                        step: "any",
                        "aria-label": "weight",
                        style: {
                          padding: "0px 12px",
                          fontSize: "12px",
                        },
                      }}
                      startAdornment={
                        <InputAdornment
                          position="end"
                          style={{ marginLeft: "0px" }}
                        >
                          <Label style={{ fontSize: "12px", color: "#A9A9A9" }}>
                            INR
                          </Label>
                        </InputAdornment>
                      }
                      type="number"
                      name="additional_discount"
                      onChange={(event) => handleAmountDetails(event)}
                      value={amountDetails["additional_discount"]}
                      placeholder="Enter value"
                    />
                  </DetailWrapper>
                </Grid>
                <Grid item xs={11}>
                  <DetailWrapper>
                    <DetailLabel>Additional Tax :</DetailLabel>
                    <OutlinedInput
                      disabled={isFormDisabled}
                      style={{ height: "30px", width: "218px" }}
                      inputProps={{
                        min: 0,
                        step: "any",
                        "aria-label": "weight",
                        style: {
                          padding: "0px 12px",
                          fontSize: "12px",
                        },
                      }}
                      startAdornment={
                        <InputAdornment
                          position="end"
                          style={{ marginLeft: "0px" }}
                        >
                          <Label style={{ fontSize: "12px", color: "#A9A9A9" }}>
                            INR
                          </Label>
                        </InputAdornment>
                      }
                      type="number"
                      name="additional_tax"
                      onChange={(event) => handleAmountDetails(event)}
                      value={amountDetails["additional_tax"]}
                      placeholder="Enter value"
                    />
                  </DetailWrapper>
                </Grid>
                <Grid item xs={11}>
                  <DetailWrapper>
                    <DetailLabel>Adjustments :</DetailLabel>
                    <OutlinedInput
                      disabled={isFormDisabled}
                      style={{ height: "30px", width: "218px" }}
                      inputProps={{
                        step: "any",
                        "aria-label": "weight",
                        style: {
                          padding: "0px 12px",
                          fontSize: "12px",
                        },
                      }}
                      startAdornment={
                        <InputAdornment
                          position="end"
                          style={{ marginLeft: "0px" }}
                        >
                          <Label style={{ fontSize: "12px", color: "#A9A9A9" }}>
                            INR
                          </Label>
                        </InputAdornment>
                      }
                      type="number"
                      name="adjustments"
                      onChange={(event) => handleAmountDetails(event)}
                      value={amountDetails["adjustments"]}
                      placeholder="Enter value"
                    />
                  </DetailWrapper>
                </Grid>
                <Grid item xs={11}>
                  <DetailWrapper>
                    <DetailLabel>Grand Total :</DetailLabel>
                    <OutlinedInput
                      disabled={isFormDisabled}
                      style={{ height: "30px", width: "218px" }}
                      readOnly
                      inputProps={{
                        min: 0,
                        "aria-label": "weight",
                        style: {
                          padding: "0px 12px",
                          fontSize: "12px",
                        },
                      }}
                      startAdornment={
                        <InputAdornment
                          position="end"
                          style={{ marginLeft: "0px" }}
                        >
                          <Label style={{ fontSize: "12px", color: "#A9A9A9" }}>
                            INR
                          </Label>
                        </InputAdornment>
                      }
                      type="text"
                      name="grand_total"
                      onChange={(event) => handleAmountDetails(event)}
                      value={
                        amountDetails["grand_total"] !== ""
                          ? amountDetails["grand_total"]
                          : "-"
                      }
                      placeholder="Enter value"
                    />
                  </DetailWrapper>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InvoiceAndProuductInfo;
