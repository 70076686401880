import { makeObservable, observable, action, toJS } from "mobx";
import { makePersistable } from "mobx-persist-store";
import {
  FormattedColumns,
  FormattedColumnsFilters,
  FormattedReportColumns,
} from "../ColumnUtils";
import {
  bulkAssignLimit,
  callLogsLimit,
  leadListingPageSize,
  pageSize,
  severityOrder,
  sizes,
} from "../Config";
import { birthdayPopupShowIntervals } from "../Db";
import {
  checkFeaturePermission,
  consoleLogger,
  getIsoString,
  hasAccessToLeadsPaginationV2,
  getDaysDifference,
  getTimeDifferenceInHours,
  isCurrentLatLongValid,
} from "../Functions";
import PhoneCallState from "../state/phoneCallState";
import {
  getAllColsApi,
  getBannerListApi,
  getColumnGroupsApi,
  getColumnPermissionsApi,
  getColumnGroupsPermissionsApi,
  getContactColumnGroupsApi,
  getContactColumnsListApi,
  getContactDetailsTabsApi,
  getCustomerColumnGroupsApi,
  getCustomerColumnsListApi,
  getCustomerDetailsTabsApi,
  getCustomerPurchaseCategoryListApi,
  getCustomerStagesListApi,
  getLeadCallsApi,
  getLeadFormDetailsApi,
  getOrganizationColumnsListApi,
  getProductColumnsListApi,
  getProfileDataApi,
  getProjectApi,
  getRoleApi,
  getSourcesListApi,
  getStageStatusesApi,
  getStagesApi,
  getStatusApi,
  getTabsApi,
  getTeamApi,
  getViewGroupsApi,
  getWhatsAppTemplateListApi,
  startDayApi,
  getLeadsItemCountApi,
  getLeadsApi,
  getLeadsApiV3,
  getTasksListApi,
  getAppConfigsApi,
  getProjectMemberListApi,
  getTotalLeadCountApi,
  getSecurityLoginLocationSettings,
} from "../Api";
import externalDialerNotificationService from "../services/externalDialerNotificationService";
import { digitalLenderState } from "../utils/enum";
import moment from "moment";
import NotificationState from "../state/notificationState";
import HotLeadState from "../state/hotLeadState";
import SubscriptionState from "../state/subscriptionState";
import FilterPageState from "../state/filterPageState";
import AttendancePageState from "../state/attendancePageState";
import QuotesPageState from "../state/quotesPageState";
import InvoicesPageState from "../state/invoicesPageState";
class RootStore {
  constructor() {
    this.userStore = new UserStore(this);
    this.filterStore = new FilterStore(this);
    this.authStore = new AuthStore(this);
    this.phoneCallState = new PhoneCallState(this);
    this.leadStore = new LeadStore(this);
    this.productStore = new ProductStore(this);
    this.contactStore = new ContactStore(this);
    this.customerStore = new CustomerStore(this);
    this.organizationStore = new OrganizationStore(this);
    this.notificationState = new NotificationState(this);
    this.hotLeadState = new HotLeadState(this);
    this.subscriptionState = new SubscriptionState(this);
    this.filterPageState = new FilterPageState(this);
    this.attendancePageState = new AttendancePageState(this);
    this.quotesPageState = new QuotesPageState(this);
    this.invoicesPageState = new InvoicesPageState(this);
  }
}

class AuthStore {
  Exist = null;
  projectId = null;
  accountId = null;
  projectList = [];
  currentProject = null;
  disabled_features = null;
  disabled_sub_features = null;
  isInWorkTime = true;
  teamId = null;
  stageList = [];
  statusList = [];
  stageStatusList = [];
  sourcesList = [];
  projectMemberList = [];
  totalProjectLeadCount = {};
  leadForms = {};
  Ul = [
    {
      phone: "",
      otp: null,
      account: "",
    },
  ];
  projectDetails = [];
  getStartedDate = null;
  lastActiveTime = "";
  leadDetailStatus = {
    in_lead_details_page: false,
    lead_id: "",
  };

  currentLeadInfo = {
    call_logs: {
      item_count: null,
      items: null,
      start_index: 0,
    },
  };
  whatsAppTemplateList = [];
  enableLoginLocationCheck = false;
  isLocationValid = true;
  rolesForLoginLocationCheck = [];
  loginLocationCoordinates = "";

  searchQuery = null; //to store the search query entered in the searchbar, to use in search api call
  constructor() {
    makeObservable(this, {
      Ul: observable,
      teamId: observable,
      projectId: observable,
      accountId: observable,
      projectList: observable,
      currentProject: observable,
      isInWorkTime: observable,
      projectDetails: observable,
      Exist: observable,
      disabled_features: observable,
      disabled_sub_features: observable,
      stageList: observable,
      statusList: observable,
      getStartedDate: observable,
      lastActiveTime: observable,
      leadDetailStatus: observable,
      searchQuery: observable,
      currentLeadInfo: observable,
      stageStatusList: observable,
      sourcesList: observable,
      projectMemberList: observable,
      totalProjectLeadCount: observable,
      leadForms: observable,
      whatsAppTemplateList: observable,
      loginLocationCoordinates: observable,
      isLocationValid: observable,
      userLogin: action,
      getOtp: action,
      setExist: action,
      setProjectId: action,
      setAccountId: action,
      setTeamId: action,
      resetUserAuth: action,
      updateDisabledFeature: action,
      updateCurrentProject: action,
      updateIsInWorkTime: action,
      postInit: action,
      fetchProjects: action,
      fetchTeams: action,
      fetchTotalProjectLeadCount: action,
      checkAndFetchTotalProjectLeadCount: action,
      updateTotalProjectLeadCount: action,
      getTotalProjectLeadCount: action,
      fetchColumnsMeta: action,
      fetchColumnGroups: action,
      fetchColumnPermissions: action,
      fetchColumnGroupPermissions: action,
      fetchProjectRole: action,
      fetchStages: action,
      fetchStatuses: action,
      fetchSources: action,
      fetchLeadForms: action,
      getCurrentProjectRoleDetails: action,
      getCurrentProjectRole: action,
      getCallingMode: action,
      getDigitalLenders: action,
      hasDigitalLender: action,
      getDigitalLenderMandatoryFields: action,
      getDigitalLenderOptionalFields: action,
      updateGetStartedDate: action,
      canShowStartDayDialog: action,
      updateLastActiveTime: action,
      fetchUserStartDayApiCall: action,
      updateLeadDetailStatus: action,
      updateSearchQuery: action,
      canRedirectToDahboard: action,
      fetchLeadCallLogs: action,
      getLeadCallDetails: action,
      addCallLog: action,
      resetLeadCallDetails: action,
      fetchWhatsAppTemplateList: action,
      getLoginLocationCoordinates: action,
      shouldCheckForLoginLocation: action,
      getLeadCallLogsPaginationCounterText: action,
      fetchProjectMembers: action,
      checkIfLoginLocationValid: action,
    });
    makePersistable(this, {
      name: AuthStore,
      storage: window.localStorage,
      expireIn: 86400000,
      removeOnExpiration: true,
      stringify: true,
      debugMode: false,
      properties: [
        "Ul",
        "projectId",
        "currentProject",
        "isInWorkTime",
        "getStartedDate",
        "lastActiveTime",
        "totalProjectLeadCount",
      ],
    });
  }

  fetchWhatsAppTemplateList = async () => {
    let response = await getWhatsAppTemplateListApi();
    this.whatsAppTemplateList = response;
  };

  shouldCheckForLoginLocation = () => {
    return this.enableLoginLocationCheck;
  };

  getLoginLocationCoordinates = () => {
    return this.loginLocationCoordinates;
  };

  fetchLoginLocationSettings = async () => {
    let response = await getSecurityLoginLocationSettings();
    if (Object.keys(response).length > 0) {
      if(response.hasOwnProperty("location") && response["location"] !== null){
        if(response["location"].hasOwnProperty("latlong") && response["location"]["latlong"] !== null){
          this.loginLocationCoordinates = response["location"]["latlong"];
        }     
      }
      if(response.hasOwnProperty("roles")){
        this.rolesForLoginLocationCheck = response["roles"] !== null ? response["roles"] : [];
      }
      if(response.hasOwnProperty("enabled")){
        this.enableLoginLocationCheck = response["enabled"] !== null ? response["enabled"] : false;
      }
    } else {
      this.enableLoginLocationCheck = false;
      this.rolesForLoginLocationCheck = [];
      this.loginLocationCoordinates = "";
    }
  };

  checkIfLoginLocationValid = async () => {
    if (
      rootStore.subscriptionState.isFeatureAvailableWithAddOns(
        "Advanced Security"
      )
    ) {
      const account_role =
        rootStore.userStore.UserData !== null
          ? rootStore.userStore.UserData["role"]
          : "";
      consoleLogger("account role:: ", account_role);

      //check if current lat, long is within allowed range - for roles other than owner account role
      if (account_role !== "owner") {
        await this.fetchLoginLocationSettings();
        if (
          this.enableLoginLocationCheck &&
          this.rolesForLoginLocationCheck.includes(account_role)
        ) {
          const isLoginLocationValid = await isCurrentLatLongValid();
          this.isLocationValid = isLoginLocationValid;
        } else {
          this.isLocationValid = true;
        }
      } else {
        this.isLocationValid =  true;
      }
    } else {
      this.isLocationValid =  true;
    }
  }

  resetLeadCallDetails = () => {
    this.currentLeadInfo = {
      call_logs: {
        item_count: null,
        items: null,
        start_index: 0,
      },
    };
  };

  getLeadCallLogsPaginationCounterText = () => {
    let total =
      this.currentLeadInfo.call_logs.item_count !== null &&
      this.currentLeadInfo.call_logs.item_count !== 0
        ? this.currentLeadInfo.call_logs.item_count
        : 0;
    let min =
      total !== 0
        ? this.currentLeadInfo.call_logs.start_index * callLogsLimit + 1
        : 0;
    let max =
      (this.currentLeadInfo.call_logs.start_index + 1) * callLogsLimit > total
        ? total
        : (this.currentLeadInfo.call_logs.start_index + 1) * callLogsLimit;
    return `${min}-${max} of ${total}`;
  };

  fetchLeadCallLogs = async ({ leadID, offset }) => {
    let response = await getLeadCallsApi({
      lid: leadID,
      page: offset,
      page_size: callLogsLimit,
    });
    if (Object.keys(response).length > 0) {
      this.currentLeadInfo["call_logs"]["items"] = response.items;
      this.currentLeadInfo["call_logs"]["start_index"] = offset;
      this.currentLeadInfo["call_logs"]["item_count"] = response.item_count;
    }
  };

  addCallLog = (details) => {
    let itemCount = this.currentLeadInfo.call_logs.item_count;
    itemCount += 1;
    this.currentLeadInfo.call_logs.item_count = itemCount;
    if (this.currentLeadInfo.call_logs.start_index === 0) {
      let items = [...this.currentLeadInfo.call_logs.items];
      items.unshift(details);
      this.currentLeadInfo.call_logs.items = items;
    }
  };

  getLeadCallDetails = async ({ leadID, offset }) => {
    let tempObj = { items: [], item_count: 0 };
    if (
      this.currentLeadInfo.call_logs.items !== null &&
      this.currentLeadInfo.call_logs.item_count !== null
    ) {
      if (offset !== this.currentLeadInfo.call_logs.start_index) {
        await this.fetchLeadCallLogs({ leadID: leadID, offset: offset });
        tempObj["items"] = this.currentLeadInfo.call_logs.items;
        tempObj["item_count"] = this.currentLeadInfo.call_logs.item_count;
      } else {
        tempObj["items"] = this.currentLeadInfo.call_logs.items;
        tempObj["item_count"] = this.currentLeadInfo.call_logs.item_count;
      }
    } else {
      await this.fetchLeadCallLogs({ leadID: leadID, offset: offset });
      tempObj["items"] = this.currentLeadInfo.call_logs.items;
      tempObj["item_count"] = this.currentLeadInfo.call_logs.item_count;
    }
    return tempObj;
  };

  canRedirectToDahboard = () => {
    let account_role =
      rootStore.userStore.UserData !== null
        ? rootStore.userStore.UserData["role"]
        : "";
    let project_role = this.getCurrentProjectRole();
    return (
      account_role === "owner" ||
      account_role === "admin" ||
      project_role === "manager"
    );
  };

  updateSearchQuery = (value) => {
    this.searchQuery = value;
  };

  updateLeadDetailStatus = (lead_id = "") => {
    this.leadDetailStatus["lead_id"] = lead_id;
    this.leadDetailStatus["in_lead_details_page"] =
      lead_id !== "" ? true : false;
  };

  updateGetStartedDate = () => {
    let current_date = moment().toISOString();
    this.getStartedDate = current_date;
  };

  updateLastActiveTime = () => {
    const currentTime = moment().toISOString();
    this.lastActiveTime = currentTime;
  };

  canShowLoginPopup = (type) => {
    if (
      localStorage.getItem("token") !== null &&
      localStorage.getItem("uid") !== null
    ) {
      let account_role =
        rootStore.userStore.UserData !== null
          ? rootStore.userStore.UserData["role"]
          : "";

      if (account_role === "owner") {
        if (type === "dob") {
          if (rootStore.userStore.UserData !== null) {
            const dob = rootStore.userStore.UserData.dob ?? null;
            //if user dob is not there
            if (dob === null || dob === "") {
              consoleLogger("dob popup ::: no dob for user");
              const lastShownTime = rootStore.userStore.popupShownTimes["dob"];
              if (lastShownTime !== null) {
                //when last shown time is there, check whether to show popup again or not based on intervals
                consoleLogger(
                  "last shown time in store ::",
                  rootStore.userStore.popupShownTimes["dob"]
                );

                consoleLogger(
                  "checking with interval :: check val: ",
                  birthdayPopupShowIntervals[
                    rootStore.userStore.currentPopupIntervalIndex["dob"]
                  ] + " days"
                );

                const dayDifference = getDaysDifference(lastShownTime);
                let shouldShowPopup = false;
                if (
                  dayDifference !== -1 &&
                  dayDifference !== null &&
                  dayDifference !== undefined
                ) {
                  shouldShowPopup =
                    dayDifference >=
                    birthdayPopupShowIntervals[
                      rootStore.userStore.currentPopupIntervalIndex["dob"]
                    ];
                }

                consoleLogger("should show popup", shouldShowPopup);

                if (shouldShowPopup) {
                  //update last shown time and interval index if showing popup now
                  rootStore.userStore.updatePopupShownTime("dob");
                  rootStore.userStore.updatePopupIntervalIndex("dob");

                  consoleLogger(
                    "new shown time in set ::",
                    rootStore.userStore.popupShownTimes["dob"]
                  );
                  consoleLogger(
                    "next popup in",
                    birthdayPopupShowIntervals[
                      rootStore.userStore.currentPopupIntervalIndex["dob"]
                    ] + " days"
                  );
                }

                return shouldShowPopup;
              } else {
                // if last shown time is not there, set current time as last shown and show popup
                consoleLogger("dob value", "null");

                rootStore.userStore.updatePopupShownTime("dob");
                rootStore.userStore.updatePopupIntervalIndex("dob");

                consoleLogger(
                  "new last shown time set",
                  rootStore.userStore.popupShownTimes["dob"]
                );
                return true;
              }
            } else {
              consoleLogger("dob popup ::: dob for user present");
              return false;
            }
          } else {
            return false;
          }
        }
      } else {
        return false;
      }

      return false;
    }
    return false;
  };

  canShowStartDayDialog = () => {
    if (
      localStorage.getItem("token") !== null &&
      localStorage.getItem("uid") !== null
    ) {
      if (this.getCurrentProjectRole().toLowerCase() === "member") {
        if (this.getStartedDate !== null) {
          return !moment(this.getStartedDate).isSame(moment(), "day");
        }
        return true;
      }
    }
    return false;
  };

  canFetchStartDayApi = () => {
    if (this.getCurrentProjectRole().toLowerCase() !== "member") {
      if (this.getStartedDate !== null) {
        return !moment(this.getStartedDate).isSame(moment(), "day");
      }
      return true;
    }
    return false;
  };

  fetchUserStartDayApiCall = async () => {
    let response_flag = await startDayApi();
    if (response_flag) {
      this.updateGetStartedDate();
      this.updateLastActiveTime();
    }
  };

  updateIsInWorkTime = (value) => {
    this.isInWorkTime = value;
  };
  setAccountId = (id) => {
    this.accountId = id;
  };
  updateCurrentProject = (value) => {
    this.currentProject = value;
  };

  getFeatureAndSubFeature = () => {
    let disabledFeatures = null;
    let disabledSubFeature = null;
    if (this.currentProject !== null) {
      disabledFeatures = this.currentProject.hasOwnProperty("disabled_features")
        ? this.currentProject["disabled_features"]
        : null;
      disabledSubFeature = this.currentProject.hasOwnProperty(
        "disabled_sub_features"
      )
        ? this.currentProject["disabled_sub_features"]
        : null;
    }
    return {
      disabled_features: disabledFeatures,
      disabled_sub_features: disabledSubFeature,
    };
  };

  updateDisabledFeature = () => {
    let feature_list = this.getFeatureAndSubFeature();
    if (
      typeof feature_list.disabled_features === "string" &&
      typeof feature_list.disabled_sub_features === "string"
    ) {
      feature_list.disabled_features = JSON.parse(
        feature_list.disabled_features
      );
      feature_list.disabled_sub_features = JSON.parse(
        feature_list.disabled_sub_features
      );
    }

    this.disabled_features = feature_list.disabled_features;
    this.disabled_sub_features = feature_list.disabled_sub_features;
  };
  userLogin = ({ phone, account }) => {
    this.Ul[0].phone = phone;
    this.Ul[0].account = account;
  };
  resetUserAuth = () => {
    this.projectId = null;
    this.projectList = [];
    this.teamId = null;
    this.accountId = null;
    this.isInWorkTime = true;
    this.currentProject = null;
    this.columnGroups = [];
    this.AllColumnsList = [];
    this.stageList = [];
    this.statusList = [];
    this.projectMemberList = [];
    this.getStartedDate = null;
    this.lastActiveTime = "";
    this.leadDetailStatus["lead_id"] = "";
    this.leadDetailStatus["in_lead_details_page"] = false;
    this.searchQuery = null;
    this.totalProjectLeadCount = {};
    rootStore.userStore.resetStore();
    rootStore.contactStore.resetStore();
    rootStore.productStore.resetStore();
    rootStore.customerStore.resetStore();
    rootStore.organizationStore.resetStore();
    rootStore.phoneCallState.reset();
    rootStore.leadStore.resetTabInfo();
    rootStore.leadStore.resetLeadTabFilter();
    rootStore.notificationState.resetStore();
    rootStore.hotLeadState.resetStore();
    rootStore.subscriptionState.resetStore();
    this.leadForms = {};
    this.whatsAppTemplateList = [];
    this.enableLoginLocationCheck = false;
    this.isLocationValid = true;
    this.rolesForLoginLocationCheck = [];
    this.loginLocationCoordinates = "";
    rootStore.filterPageState.reset();
    rootStore.filterPageState.resetPaginationAndLeadListing();
    rootStore.attendancePageState.resetStore();
    rootStore.quotesPageState.resetStore();
    rootStore.invoicesPageState.resetStore();
  };
  getOtp = (value) => {
    this.Ul[0].otp = value;
  };
  setExist = (value) => {
    this.Exist = value;
  };
  setProjectId = (value) => {
    this.projectId = value;
  };
  setProjectList = (value) => {
    this.projectList = value;
  };

  setTeamId = (val) => {
    this.teamId = val;
  };

  //*******************data initialization after login success********************* */

  canSetAllTeamsByDefault = () => {
    let account_role =
      rootStore.userStore.UserData !== null
        ? rootStore.userStore.UserData["role"]
        : "";
    let project_role = this.getCurrentProjectRole();
    if (
      account_role === "owner" ||
      account_role === "admin" ||
      project_role === "manager"
    ) {
      if (
        this.currentProject !== null &&
        this.currentProject.hasOwnProperty("teams")
      ) {
        return this.currentProject["teams"].length > 1;
      }
    }
    return false;
  };

  postInit = async () => {
    if (
      localStorage.getItem("token") !== null &&
      localStorage.getItem("uid") !== null
    ) {
      rootStore.userStore.fetchBannerList();
      await rootStore.userStore.fetchAppConfigList();
      await this.fetchProjects();
      consoleLogger(`Project list :::${JSON.stringify(this.projectList)}`);
      if (this.projectList.length > 0) {
        for (const project of this.projectList) {
          let role_response = await this.fetchProjectRole(project["id"]);
          let teams_response = await this.fetchTeams(project["id"]);
          project["role"] = role_response;
          project["teams"] = teams_response;
        }
        if (this.projectId === null) {
          let index = this.projectList.findIndex(
            (project) => project["is_default"] === true
          );
          let projectIndex = index !== -1 ? index : 0;
          this.projectId = this.projectList[projectIndex].id;
          this.currentProject = this.projectList[projectIndex];

          if (this.canSetAllTeamsByDefault()) {
            this.teamId = "All";
          } else {
            this.teamId = this.projectList[projectIndex]["teams"][0]["id"];
          }
        } else {
          const index = this.projectList.findIndex(
            (obj) => obj.id === this.projectId
          );
          if (index === -1) {
            let defaultProjectIndex = this.projectList.findIndex(
              (project) => project["is_default"] === true
            );
            let projectIndex =
              defaultProjectIndex !== -1 ? defaultProjectIndex : 0;
            this.projectId = this.projectList[projectIndex].id;
            this.currentProject = this.projectList[projectIndex];
            if (this.canSetAllTeamsByDefault()) {
              this.teamId = "All";
            } else {
              this.teamId = this.projectList[projectIndex]["teams"][0]["id"];
            }
          } else {
            this.currentProject = this.projectList[index];
            if (this.canSetAllTeamsByDefault()) {
              this.teamId = "All";
            } else {
              this.teamId = this.projectList[index]["teams"][0]["id"];
            }
          }
        }
        this.updateDisabledFeature();
        this.fetchTotalProjectLeadCount(this.projectId);
        await this.fetchColumnsMeta(this.projectId);
        await this.fetchColumnGroups(this.projectId);
        await this.fetchColumnPermissions(this.projectId);
        await this.fetchColumnGroupPermissions(this.projectId);
        this.fetchStages();
        this.fetchStageStatuses();
        this.fetchStatuses();
        this.fetchSources();
        this.fetchProjectMembers();
        this.fetchLeadForms();
        this.fetchWhatsAppTemplateList();

        await rootStore.leadStore.setupTabs();
        rootStore.notificationState.init();
        rootStore.hotLeadState.init();
      }
      await rootStore.phoneCallState.init();
      rootStore.contactStore.init();
      rootStore.customerStore.init();
      externalDialerNotificationService.init();
    }
  };

  resetOnProjectChange = () => {
    rootStore.userStore.resetCurrentFilter();
    rootStore.userStore.resetRawLeadFilters();
    rootStore.userStore.updateFilterDrawerState(true);
    rootStore.userStore.updateCurrentLoadedFilter({});
    rootStore.leadStore.resetTabInfo();
    rootStore.leadStore.resetLeadTabFilter();
    rootStore.filterPageState.reset();
    rootStore.filterPageState.resetPaginationAndLeadListing();
  };

  updateOnProjectChange = async (id) => {
    this.resetOnProjectChange();
    let index = this.projectList.findIndex((obj) => obj.id === id);
    if (index !== -1) {
      this.projectId = id;
      this.currentProject = this.projectList[index];
      if (this.canSetAllTeamsByDefault()) {
        this.teamId = "All";
      } else {
        this.teamId = this.projectList[index]["teams"][0]["id"];
      }
      this.updateDisabledFeature();
      this.fetchTotalProjectLeadCount(this.projectId);
      rootStore.phoneCallState.registerCallService();
      externalDialerNotificationService.close();
      externalDialerNotificationService.init();
    }

    await this.fetchColumnsMeta(id);
    await this.fetchColumnGroups(id);
    await this.fetchColumnPermissions(id);
    await this.fetchColumnGroupPermissions(id);
    this.fetchStages();
    this.fetchStageStatuses();
    this.fetchStatuses();
    this.fetchSources();
    this.fetchProjectMembers();
    this.fetchLeadForms();
    this.fetchWhatsAppTemplateList();

    await rootStore.leadStore.setupTabs();
    rootStore.notificationState.fetchNotificationsOnProjectChange();
    rootStore.hotLeadState.fetchHotLeadOnProjectChange();
  };

  fetchProjects = async () => {
    let response = await getProjectApi();
    this.projectList = response;
  };

  fetchStages = async () => {
    let response = await getStagesApi();
    this.stageList = response;
  };
  fetchSources = async () => {
    let response = await getSourcesListApi();
    this.sourcesList = response;
  };

  fetchProjectMembers = async () => {
    let response = await getProjectMemberListApi();
    this.projectMemberList = response;
  };

  fetchLeadForms = async () => {
    let response = await getLeadFormDetailsApi();
    this.leadForms = response;
  };

  fetchStageStatuses = async () => {
    let response = await getStageStatusesApi();
    this.stageStatusList = response;
  };

  fetchStatuses = async () => {
    let response = await getStatusApi();
    this.statusList = response;
  };

  fetchTeams = async (project_id) => {
    try {
      let response = await getTeamApi(project_id);
      if (response.length > 1) {
        response.push({ id: "All", name: "All Teams" });
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  fetchTotalProjectLeadCount = async (pid) => {
    let response = await getTotalLeadCountApi(pid);
    if (!this.totalProjectLeadCount.hasOwnProperty(pid)) {
      this.totalProjectLeadCount[pid] = {};
    }
    this.totalProjectLeadCount[pid] = {
      count: response.hasOwnProperty("count") ? response["count"] : 0,
      last_updated: moment().toISOString(),
    };
    consoleLogger(
      "totalLeads obj updated:: ",
      JSON.stringify(this.totalProjectLeadCount)
    );
  };

  checkAndFetchTotalProjectLeadCount = async (pid) => {
    consoleLogger(
      "init check and fetch:: ",
      JSON.stringify(this.totalProjectLeadCount)
    );

    if (this.totalProjectLeadCount.hasOwnProperty(pid) === false) {
      await this.fetchTotalProjectLeadCount(pid);
    } else {
      const currentTime = moment();
      if (
        getTimeDifferenceInHours(
          currentTime,
          this.totalProjectLeadCount[pid]["last_updated"]
        ) > 1
      ) {
        await this.fetchTotalProjectLeadCount(pid);
      }
    }
  };

  updateTotalProjectLeadCount = ({ pid, count }) => {
    this.totalProjectLeadCount[pid]["count"] =
      this.totalProjectLeadCount[pid]["count"] + count;
  };

  getTotalProjectLeadCount = (pid) => {
    return this.totalProjectLeadCount.hasOwnProperty(pid)
      ? this.totalProjectLeadCount[pid]["count"]
      : 0;
  };

  fetchColumnsMeta = async (project_id) => {
    try {
      let response = await getAllColsApi(project_id);
      rootStore.userStore.updateAllColumnsList(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  fetchColumnGroups = async (project_id) => {
    try {
      let response = await getColumnGroupsApi(project_id);
      rootStore.userStore.updateColumnGroups(response);
    } catch (error) {
      console.log(error);
    }
  };
  fetchColumnPermissions = async (project_id) => {
    let data = {
      role_name: this.getCurrentProjectRole(),
    };
    let response = await getColumnPermissionsApi({
      pid: project_id,
      data: data,
    });
    rootStore.userStore.updateColumnPermissions(response);
  };
  fetchColumnGroupPermissions = async (project_id) => {
    let data = {
      role_name: this.getCurrentProjectRole(),
    };
    let response = await getColumnGroupsPermissionsApi({
      pid: project_id,
      data: data,
    });
    rootStore.userStore.updateColumnGroupPermissions(response);
  };

  fetchProjectRole = async (project_id) => {
    return await getRoleApi(project_id);
  };

  getCurrentProjectRoleDetails = () => {
    if (this.currentProject !== null) {
      if (this.currentProject.hasOwnProperty("role")) {
        let role = this.currentProject["role"];
        if (Object.keys(role).length > 0) {
          return role;
        }
      }
    }
    return {};
  };

  getCurrentProjectRole = () => {
    let roleDetails = this.getCurrentProjectRoleDetails();
    if (roleDetails.hasOwnProperty("role")) {
      return roleDetails["role"];
    } else {
      return "";
    }
  };

  getCallingMode = () => {
    let roleDetails = this.getCurrentProjectRoleDetails();
    let callingMode = roleDetails.hasOwnProperty("calling_mode")
      ? roleDetails["calling_mode"]
      : null;
    if (callingMode !== null) {
      return callingMode;
    } else {
      callingMode = this.currentProject.hasOwnProperty("calling_mode")
        ? this.currentProject["calling_mode"]
        : null;

      if (callingMode !== null) {
        return callingMode;
      }
    }
    return "Manual";
  };

  getDigitalLenders = () => {
    let lenders = this.currentProject.hasOwnProperty("digital_lenders")
      ? this.currentProject["digital_lenders"]
      : [];
    return lenders;
  };

  hasDigitalLender = (value) => {
    let lenders = this.getDigitalLenders();
    let index = lenders.findIndex((element) => element["lender"] === value);
    return index === -1 ? false : true;
  };
  getDigitalLenderMandatoryFields = (value) => {
    let lenders = this.getDigitalLenders();
    let index = lenders.findIndex((element) => element["lender"] === value);
    if (index === -1) {
      return [];
    } else {
      return lenders[index]["mandatory_fields"];
    }
  };
  getDigitalLenderOptionalFields = (value) => {
    let lenders = this.getDigitalLenders();
    let index = lenders.findIndex((element) => element["lender"] === value);
    if (index === -1) {
      return [];
    } else {
      return lenders[index]["optional_fields"];
    }
  };

  //**********************************END******************************************** */
}
class UserStore {
  Basic = [{ name: "", email: "", phone: "", role: "" }];
  Info = [{ state: "", district: "", school: "", branch: "" }];
  Exp = "";
  TabId = "";
  Paging = {};
  PageSize = {};
  filterStagesList = [];
  filterStatusList = [];
  RawColumnHeaders = [];
  ColumnHeaders = [];
  FilterColumnHeaders = [];
  ReportColumnHeaders = [];
  searchColumns = [];
  start_date = null;
  end_date = null;
  globalFilters = {};
  reportFilters = {};
  tabFilters = {};
  sortingTabs = {};
  segmentsList = [];
  ColorsList = {};
  TargetColorList = {};
  teamsTarget = {};
  blackList = {};
  activeList = {};
  reportColumns = [];
  allColumns = [];
  filters_tab = {
    phone: "",
    lead_owner: "",
    city: "",
    state: "",
    custom_param_active_score_min: "",
    custom_param_active_score_max: "",
    lead_created_at_from: "",
    lead_created_at_end: "",
    last_contacted_on: "",
    next_followup_on: "",
  };
  viewList = null;
  view = "";
  Subject = [];
  Grade = [];
  UserData = null;
  AllColumnsList = [];
  columnGroups = [];
  columnPermissions = [];
  columnGroupPermissions = [];
  currentFilterViewId = null;
  currentFilterPayload = {};
  currentFilterPage = 0;
  currentFilterPageSize = pageSize;
  currentFilterRenderList = {};
  inUseFiltersList = [];
  currentPopupIntervalIndex = {
    dob: 0,
  };
  popupShownTimes = {
    dob: null,
  };
  tasksPageTaskList = [];
  customColumnsCount = 0;

  isFilterDrawerOpen = true;
  //***************raw lead filter varaible */
  rawLeadFilters = {};

  //***************Create Lead in Money View related variables****************** */

  projectMemberMapping = null; //***this variable is used for admin projects members mapping ,getting used in add project member */
  moneyViewStepperState = {
    currentStep: -1,
    step: {
      0: {
        status: digitalLenderState.idle,
      },
      1: {
        status: digitalLenderState.idle,
      },
      2: {
        status: digitalLenderState.idle,
      },
    },
  };

  savedFilterTemplateList = {};
  currentLoadedFilter = {};

  tabCounterList = {};

  tabInfo = {};
  dashboardMode = "grid";
  widgetData = {};
  bannerData = {};
  lastViewedTermsAndConditionsDate = null;

  //**********column mapping state variables****** */
  webFormToProjectColumnMapping = {};
  projectToProjectColumnMapping = {};
  //*******************end********************** */

  seenNoteIds = [];
  appConfigList = [];
  leadListingPageSizeOptions = [];
  leadsBulkAssignLimit = 0;
  defaultLeadListPageSize = 0;
  constructor() {
    makeObservable(this, {
      Basic: observable,
      sortingTabs: observable,
      activeList: observable,
      reportColumns: observable,
      ColumnHeaders: observable,
      reportFilters: observable,
      RawColumnHeaders: observable,
      FilterColumnHeaders: observable,
      viewList: observable,
      view: observable,
      allColumns: observable,
      teamsTarget: observable,
      ReportColumnHeaders: observable,
      segmentsList: observable,
      filters_tab: observable,
      ColorsList: observable,
      TargetColorList: observable,
      filterStagesList: observable,
      filterStatusList: observable,
      searchColumns: observable,
      globalFilters: observable,
      tabFilters: observable,
      start_date: observable,
      end_date: observable,
      Paging: observable,
      PageSize: observable,
      TabId: observable,
      Info: observable,
      Exp: observable,
      UserData: observable,
      Subject: observable,
      Grade: observable,
      blackList: observable,
      AllColumnsList: observable,
      columnGroups: observable,
      columnPermissions: observable,
      popupShownTimes: observable,
      currentPopupIntervalIndex: observable,
      tasksPageTaskList: observable,
      columnGroupPermissions: observable,
      customColumnsCount: observable,
      //money view create lead related variables
      moneyViewStepperState: observable,

      //filterv2 related variables
      currentFilterViewId: observable,
      currentFilterPayload: observable,
      currentFilterPage: observable,
      currentFilterPageSize: observable,
      currentFilterRenderList: observable,
      inUseFiltersList: observable,
      rawLeadFilters: observable,
      isFilterDrawerOpen: observable,
      ///end////////////////
      savedFilterTemplateList: observable,
      currentLoadedFilter: observable,
      tabCounterList: observable,
      projectMemberMapping: observable,
      dashboardMode: observable,
      widgetData: observable,
      bannerData: observable,
      lastViewedTermsAndConditionsDate: observable,
      webFormToProjectColumnMapping: observable,
      projectToProjectColumnMapping: observable,
      seenNoteIds: observable,
      appConfigList: observable,
      leadListingPageSizeOptions: observable,
      leadsBulkAssignLimit: observable,
      defaultLeadListPageSize: observable,
      createUser: action,
      userInfo: action,
      userProfession: action,
      getData: action,
      setTabFilters: action,
      setGlobalFilters: action,
      setTabId: action,
      updateDefaultSelectColumns: action,
      formatRawColumnHeaders: action,
      formatFilterColumnHeaders: action,
      formatReportColumnHeaders: action,
      updateFilterStatusList: action,
      updateFilterStagesList: action,
      updatePaging: action,
      updatePageSize: action,
      setStartDate: action,
      setEndDate: action,
      setDateRange: action,
      setSearchColumns: action,
      setSegmentsList: action,
      updateColorsList: action,
      updateTargetColorList: action,
      updateSortingTabs: action,
      updateTeamsTarget: action,
      resetTeamsTarget: action,
      queryGlobalFilters: action,
      queryTabFilters: action,
      updateBlackList: action,
      resetBlackList: action,
      setReportFilters: action,
      resetReportFilters: action,
      setReportColumns: action,
      setAllColumns: action,
      setActiveList: action,
      resetActiveList: action,
      updateViewList: action,
      updateView: action,
      updateAllColumnsList: action,
      updateColumnGroups: action,
      updateColumnPermissions: action,
      updateColumnGroupPermissions: action,
      //filterv2 related actions

      updateCurrentFilterPayload: action,
      updateCurrentFilterPageSize: action,
      updateCurrentFilterViewId: action,
      updateCurrentFilterPage: action,
      updateCurrentFilterRenderList: action,
      resetCurrentFilter: action,
      updateInUseFilterList: action,

      updateMoneyViewCurrentStep: action,
      updateMoneyViewStepsStatus: action,
      resetCreateLeadStepper: action,
      updatePopupShownTime: action,
      updatePopupIntervalIndex: action,
      updateTasksPageTaskList: action,
      hasAnyGroupCopyable: action,
      updateRawLeadFilters: action,
      resetRawLeadFilters: action,
      fetchUserData: action,
      updateFilterDrawerState: action,
      updateSavedFilterTemplateList: action,
      resetSavedFilterTemplateList: action,
      updateCurrentLoadedFilter: action,
      updateTabCounterList: action,
      resetTabCounterList: action,
      resetTabInfo: action,
      initProjectMemberMapping: action,
      updateProjectMemberMapping: action,
      resetProjectMemberMapping: action,
      resetStore: action,
      updateDashboardMode: action,
      updateWidgetData: action,
      fetchBannerList: action,
      updateBannerVisibility: action,
      setCurrentCustomColumnsNumber: action,
      incrementCustomColumnsNumber: action,
      decrementCustomColumnsNumber: action,
      updateLastViewedTermsAndConditionsDate: action,
      canShowTermsAndConditionsDialog: action,
      hasAcceptedTermsAndConditions: action,
      isColumnVisible: action,
      isColumnEditable: action,
      isColumnGroupEditable: action,
      isColumnGroupCopyable: action,
      isColumnGroupVisible: action,
      updateWebFormToProjectColumnMapping: action,
      updateProjectToProjectColumnMapping: action,
      updateSeenNoteIDs: action,
      fetchAppConfigList: action,
    });
    makePersistable(this, {
      name: UserStore,
      storage: window.localStorage,
      expireIn: 86400000,
      removeOnExpiration: true,
      stringify: true,
      debugMode: false,
      properties: [
        "Basic",
        "Info",
        "Exp",
        "Subject",
        "start_date",
        "end_date",
        "Grade",
        "TabId",
        "ColumnHeaders",
        "searchColumns",
        "ReportColumnHeaders",
        "blackList",
        "savedFilterTemplateList",
        "lastViewedTermsAndConditionsDate",
        "widgetData",
        "webFormToProjectColumnMapping",
        "projectToProjectColumnMapping",
        "popupShownTimes",
        "currentPopupIntervalIndex",
        "seenNoteIds",
        "appConfigList",
        "leadListingPageSizeOptions",
        "leadsBulkAssignLimit",
        "defaultLeadListPageSize",
      ],
    });
  }

  setupLeadListingPageSizeOptionsAndPageSize = () => {
    let tempPageSizeOptions = [...sizes]; //sizes is present in config file i.e [25,50,100]
    let index = this.appConfigList.findIndex(
      (config) => config["name"] === "page_size_lead_listing"
    );
    if (index !== -1) {
      if (
        this.appConfigList[index]["value"] !== null &&
        this.appConfigList[index]["value"] !== ""
      ) {
        tempPageSizeOptions = this.appConfigList[index]["value"]
          .split(",")
          .map((value) => parseInt(value.trim(), 10));
      }
    }
    let defaultPageSizeIndex = tempPageSizeOptions.findIndex(
      (pageSize) => parseInt(pageSize) === leadListingPageSize
    );
    if (defaultPageSizeIndex !== -1) {
      this.defaultLeadListPageSize = leadListingPageSize;
    } else {
      this.defaultLeadListPageSize =
        tempPageSizeOptions.length > 0
          ? tempPageSizeOptions[0]
          : leadListingPageSize; //by default having local page size variable value
    }
    this.leadListingPageSizeOptions = tempPageSizeOptions;
  };

  setupLeadsBulkAssignLimit = () => {
    let tempAssignLimit = bulkAssignLimit;
    let index = this.appConfigList.findIndex(
      (config) => config["name"] === "assign_batch_size"
    );
    if (index !== -1) {
      if (
        this.appConfigList[index]["value"] !== null &&
        this.appConfigList[index]["value"] !== ""
      ) {
        tempAssignLimit = parseInt(this.appConfigList[index]["value"]);
      }
    }

    this.leadsBulkAssignLimit = tempAssignLimit;
  };

  fetchAppConfigList = async () => {
    let response = await getAppConfigsApi();
    this.appConfigList = response;
    this.setupLeadListingPageSizeOptionsAndPageSize();
    this.setupLeadsBulkAssignLimit();
  };

  updateSeenNoteIDs = ({ noteID }) => {
    if (!this.seenNoteIds.includes(noteID)) {
      this.seenNoteIds.push(noteID);
    }
  };

  updateWebFormToProjectColumnMapping = ({
    destProjectID,
    webformID,
    mapping,
  }) => {
    let combinedWebformAndDestProjectID = webformID + destProjectID;
    this.webFormToProjectColumnMapping[combinedWebformAndDestProjectID] =
      mapping;
  };

  updateProjectToProjectColumnMapping = ({ destProjectID, mapping }) => {
    let combinedSrcAndDestProjectID =
      rootStore.authStore.projectId + destProjectID;
    this.projectToProjectColumnMapping[combinedSrcAndDestProjectID] = mapping;
  };

  updateWidgetData = ({ id, data }) => {
    let currentTime = moment().seconds(0).milliseconds(0).toISOString();
    this.widgetData[id] = { data: data, last_updated: currentTime };
  };

  updateBannerVisibility = ({ flag }) => {
    this.bannerData["visible"] = flag;
  };

  setCurrentCustomColumnsNumber = (value) => {
    this.customColumnsCount = value;
  };

  incrementCustomColumnsNumber = () => {
    this.customColumnsCount += 1;
  };

  decrementCustomColumnsNumber = () => {
    this.customColumnsCount -= 1;
  };

  fetchBannerList = async () => {
    if (checkFeaturePermission("banners")) {
      let response = await getBannerListApi();
      if (response.length > 0) {
        response.sort((a, b) => {
          if (a.is_system && !b.is_system) {
            return -1;
          } else if (!a.is_system && b.is_system) {
            return 1;
          } else {
            const severityA = severityOrder[a.severity] || 0;
            const severityB = severityOrder[b.severity] || 0;

            if (severityA > severityB) {
              return -1;
            } else if (severityA < severityB) {
              return 1;
            } else {
              return a.priority - b.priority;
            }
          }
        });
        let tempData = response[0];
        tempData["visible"] = true;
        this.bannerData = tempData;
      }
    }
  };

  updateLastViewedTermsAndConditionsDate = () => {
    let current_date = moment().toISOString();
    this.lastViewedTermsAndConditionsDate = current_date;
  };

  hasAcceptedTermsAndConditions = () => {
    if (this.UserData !== null) {
      if (this.UserData.hasOwnProperty("account")) {
        let account_info = this.UserData["account"];
        return account_info["tos_accepted"];
      }
    }
    return false;
  };

  canShowTermsAndConditionsDialog = () => {
    if (
      localStorage.getItem("token") !== null &&
      localStorage.getItem("uid") !== null
    ) {
      if (checkFeaturePermission("terms_and_conditions")) {
        if (!this.hasAcceptedTermsAndConditions()) {
          if (this.lastViewedTermsAndConditionsDate !== null) {
            return !moment(this.lastViewedTermsAndConditionsDate).isSame(
              moment(),
              "day"
            );
          }
          return true;
        }
      }
    }
    return false;
  };

  resetStore = () => {
    this.resetCurrentFilter();
    this.resetRawLeadFilters();
    this.updateFilterDrawerState(true);
    this.getData(null);
    this.resetSavedFilterTemplateList();
    this.resetTabCounterList();
    this.resetProjectMemberMapping();
    this.dashboardMode = "grid";
    this.widgetData = {};
    this.bannerData = {};
    this.lastViewedTermsAndConditionsDate = null;
    this.widgetData = {};
    this.webFormToProjectColumnMapping = {};
    this.projectToProjectColumnMapping = {};
    this.currentPopupIntervalIndex = {
      dob: 0,
    };
    this.popupShownTimes = {
      dob: null,
    };
    this.seenNoteIds = [];
    this.tasksPageTaskList = [];
    this.appConfigList = [];
    this.leadListingPageSizeOptions = [];
    this.defaultLeadListPageSize = 0;
  };

  updateDashboardMode = (mode) => {
    this.dashboardMode = mode;
  };

  initProjectMemberMapping = (list) => {
    this.projectMemberMapping = list;
  };
  updateProjectMemberMapping = ({ projectID, list }) => {
    this.projectMemberMapping[projectID] = list;
  };

  resetProjectMemberMapping = () => {
    this.projectMemberMapping = null;
  };

  resetTabInfo = () => {
    this.tabInfo = {};
  };

  updateTabCounterList = ({ tabID, count }) => {
    this.tabCounterList[tabID] = count;
    if (this.tabInfo.hasOwnProperty(tabID)) {
      this.tabInfo[tabID]["count"] = count;
    } else {
      this.tabInfo[tabID] = this.getDefaulttabInfo("count", count);
    }
  };
  resetTabCounterList = () => {
    this.tabCounterList = {};
  };
  updateSavedFilterTemplateList = ({ fitlerName, viewId, filters }) => {
    let projectId = rootStore.authStore.projectId;
    if (this.savedFilterTemplateList.hasOwnProperty(projectId)) {
      this.savedFilterTemplateList[projectId][fitlerName] = {
        name: fitlerName,
        viewId: viewId,
        filters: filters,
      };
    } else {
      this.savedFilterTemplateList[projectId] = {
        [fitlerName]: { name: fitlerName, viewId: viewId, filters: filters },
      };
    }
  };
  updateCurrentLoadedFilter = (value) => {
    this.currentLoadedFilter = value;
  };

  resetSavedFilterTemplateList = () => {
    this.savedFilterTemplateList = {};
    this.currentLoadedFilter = {};
  };
  updateFilterDrawerState = (flag) => {
    this.isFilterDrawerOpen = flag;
  };

  fetchUserData = async () => {
    try {
      let response = await getProfileDataApi();
      this.UserData = response.data;
      rootStore.authStore.setAccountId(response.data.account.id);
    } catch (error) {
      this.UserData = null;
      console.log(error);
    }
  };

  updateRawLeadFilters = (filters) => {
    this.rawLeadFilters = filters;
  };

  resetRawLeadFilters = () => {
    this.rawLeadFilters = {};
  };

  hasAnyGroupCopyable = () => {
    return this.columnGroups.some((obj) => obj.copyable === true);
  };

  updateMoneyViewCurrentStep = (step) => {
    this.moneyViewStepperState["currentStep"] = step;
  };
  updateMoneyViewStepsStatus = (step, state) => {
    consoleLogger("rootstore stepper state:::", state, "step::::", step);
    this.moneyViewStepperState["step"][step]["status"] = state;
  };
  resetCreateLeadStepper = () => {
    this.moneyViewStepperState = {
      currentStep: -1,
      step: {
        0: {
          status: digitalLenderState.idle,
        },
        1: {
          status: digitalLenderState.idle,
        },
        2: {
          status: digitalLenderState.idle,
        },
      },
    };
  };

  // owner account popup shown time states
  updatePopupShownTime = (type) => {
    if (this.popupShownTimes.hasOwnProperty(type)) {
      const now = moment();
      this.popupShownTimes[type] = now.toISOString();
    }
  };
  updatePopupIntervalIndex = (type) => {
    if (this.currentPopupIntervalIndex.hasOwnProperty(type)) {
      if (type === "dob") {
        //increment until final index is reached. once at the end -> always use the final interval value
        if (
          this.currentPopupIntervalIndex["dob"] <
          birthdayPopupShowIntervals.length - 1
        ) {
          this.currentPopupIntervalIndex[type] += 1;
        }
      }
    }
  };

  // tasks page list state
  updateTasksPageTaskList = async () => {
    const startOfDay = moment().startOf("day").format("YYYY-MM-DD HH:mm:ss");
    const endOfDay = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");

    const range = {
      start: moment(startOfDay).toISOString(),
      end: moment(endOfDay).toISOString(),
    };

    let queryParams = {
      start_date: range["start"],
      end_date: range["end"],
    };
    let response = await getTasksListApi({
      queryParams,
    });
    this.tasksPageTaskList = response;
  };

  //filterv2 related fucntions////////
  resetCurrentFilter = () => {
    this.inUseFiltersList = [];
    this.currentFilterPage = 0;
    this.currentFilterPageSize = pageSize;
    this.currentFilterPayload = {};
    this.currentFilterRenderList = {};
    this.currentFilterViewId = null;
  };
  updateInUseFilterList = (list) => {
    this.inUseFiltersList = list;
  };
  updateCurrentFilterViewId = (id) => {
    consoleLogger("current view id is", id);
    this.currentFilterViewId = id;
  };
  updateCurrentFilterRenderList = (value) => {
    this.currentFilterRenderList = value;
  };
  updateCurrentFilterPayload = (value) => {
    this.currentFilterPayload = value;
  };
  updateCurrentFilterPage = (page) => {
    this.currentFilterPage = page;
  };
  updateCurrentFilterPageSize = (size) => {
    this.currentFilterPageSize = size;
  };
  //////////end/////////////////
  updateAllColumnsList = (list) => {
    this.AllColumnsList = list;
  };
  updateColumnGroups = (list) => {
    this.columnGroups = list;
  };
  updateColumnPermissions = (list) => {
    this.columnPermissions = list;
  };
  updateColumnGroupPermissions = (list) => {
    this.columnGroupPermissions = list;
  };
  isColumnVisible = (columnMeta) => {
    if (columnMeta["is_visible"]) {
      let permIndex = this.columnPermissions.findIndex(
        (perm) => perm["column_meta"]["id"] === columnMeta.id
      );
      if (permIndex !== -1) {
        return this.columnPermissions[permIndex]["is_visible"];
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  isColumnEditable = (columnMeta) => {
    if (columnMeta["editable"]) {
      let columnPermissions = this.columnPermissions;
      let permIndex = columnPermissions.findIndex(
        (perm) => perm["column_meta"]["id"] === columnMeta.id
      );
      if (permIndex !== -1) {
        return columnPermissions[permIndex]["is_editable"];
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  isColumnGroupEditable = (groupName) => {
    const index = this.columnGroups.findIndex(
      (element) => element["group_name"] === groupName
    );

    if (index !== -1) {
      const columnGroupMeta = this.columnGroups[index];

      if (columnGroupMeta["editable"]) {
        let permIndex = this.columnGroupPermissions.findIndex(
          (perm) => perm["column_group_id"] === columnGroupMeta.id
        );
        if (permIndex !== -1) {
          return this.columnGroupPermissions[permIndex]["editable"];
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
    return false;
  };
  isColumnGroupCopyable = (groupName) => {
    const index = this.columnGroups.findIndex(
      (element) => element["group_name"] === groupName
    );

    if (index !== -1) {
      const columnGroupMeta = this.columnGroups[index];

      if (columnGroupMeta["copyable"]) {
        let permIndex = this.columnGroupPermissions.findIndex(
          (perm) => perm["column_group_id"] === columnGroupMeta.id
        );
        if (permIndex !== -1) {
          return this.columnGroupPermissions[permIndex]["copyable"];
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
    return false;
  };
  isColumnGroupVisible = (groupName) => {
    const index = this.columnGroups.findIndex(
      (element) => element["group_name"] === groupName
    );

    if (index !== -1) {
      const columnGroupMeta = this.columnGroups[index];
      if (columnGroupMeta["visible"]) {
        let permIndex = this.columnGroupPermissions.findIndex(
          (perm) => perm["column_group_id"] === columnGroupMeta.id
        );
        if (permIndex !== -1) {
          return this.columnGroupPermissions[permIndex]["visible"];
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
    return false;
  };

  updateViewList = ({ view, viewList }) => {
    this.viewList = viewList;
    this.view = view;
  };
  updateView = (view) => {
    this.view = view;
    consoleLogger("changing view in store", this.view);
  };
  updateBlackList = (val) => {
    this.blackList[val] = true;
  };
  resetBlackList = () => {
    this.blackList = {};
  };
  setActiveList = (id) => {
    this.activeList[id] = true;
  };
  resetActiveList = () => {
    this.activeList = {};
  };

  updateTeamsTarget = (val) => {
    this.teamsTarget = val;
  };
  resetTeamsTarget = () => {
    this.teamsTarget = { 1: 0, 2: 0 };
  };
  updateSortingTabs = ({ id, val, direction, flag }) => {
    this.sortingTabs[id] = {
      field_name: val,
      direction: direction,
      flag: flag,
    };
  };
  setReportColumns = (val) => {
    this.reportColumns = val;
  };
  updateColorsList = ({ key, color }) => {
    this.ColorsList[key] = color;
  };
  updateTargetColorList = ({ key, color }) => {
    this.TargetColorList[key] = color;
  };
  setSearchColumns = (val) => {
    this.searchColumns = val;
  };
  queryGlobalFilters = (val) => {
    this.globalFilters = val;
  };
  setAllColumns = (val) => {
    this.allColumns = val;
  };
  queryTabFilters = ({ id, val }) => {
    this.tabFilters[id] = val;
  };
  setTabFilters = ({ id, filters, dateRange, numRange, selectedVal }) => {
    let newFilters = {};
    let newDateRange = {};
    if (Object.keys(filters).length !== 0) {
      Object.keys(filters).map((key) => {
        if (
          filters[key]["type"] === "date" ||
          filters[key]["type"] === "datetime"
        ) {
          let newDate = getIsoString(filters[key]["value"]);
          newFilters[key] = newDate;
        } else {
          newFilters[key] = filters[key]["value"];
        }
      });
    }
    if (Object.keys(dateRange).length !== 0) {
      Object.keys(dateRange).map((key) => {
        let d1 = getIsoString(dateRange[key]["start"]);
        let d2 = getIsoString(dateRange[key]["end"]);
        newDateRange[key] = {
          start: d1,
          end: d2,
        };
      });
    }

    let filtersData = {
      ...newFilters,
      ...newDateRange,
      ...numRange,
      ...selectedVal,
    };
    let custom_params = {};
    Object.keys(filtersData).map((key) => {
      let index = this.AllColumnsList.findIndex(
        (column) => column["field"] === key
      );
      if (index !== -1) {
        if (this.AllColumnsList[index]["is_custom_param"]) {
          let keyName = key.replace("custom_params_", "");
          let newValue = filtersData[key];
          delete filtersData[key];
          custom_params[keyName] = newValue;
        }
      }
    });
    filtersData["custom_params"] = custom_params;
    this.tabFilters[id] = filtersData;
  };
  resetTabFilters = (val) => {
    if (this.tabFilters.hasOwnProperty(val)) {
      this.tabFilters[val] = null;
    }
  };
  setGlobalFilters = ({ filters, dateRange, numRange, selectedVal }) => {
    let newFilters = {};
    let newDateRange = {};
    if (Object.keys(filters).length !== 0) {
      Object.keys(filters).map((key) => {
        if (
          filters[key]["type"] === "date" ||
          filters[key]["type"] === "datetime"
        ) {
          let newDate = getIsoString(filters[key]["value"]);
          newFilters[key] = newDate;
        } else {
          newFilters[key] = filters[key]["value"];
        }
      });
    }
    if (Object.keys(dateRange).length !== 0) {
      Object.keys(dateRange).map((key) => {
        let d1 = getIsoString(dateRange[key]["start"]);
        let d2 = getIsoString(dateRange[key]["end"]);
        newDateRange[key] = {
          start: d1,
          end: d2,
        };
      });
    }
    let filtersData = {
      ...newFilters,
      ...newDateRange,
      ...numRange,
      ...selectedVal,
    };
    let custom_params = {};
    Object.keys(filtersData).map((key) => {
      let index = this.AllColumnsList.findIndex(
        (column) => column["field"] === key
      );
      if (index !== -1) {
        if (this.AllColumnsList[index]["is_custom_param"]) {
          let keyName = key.replace("custom_params_", "");
          let newValue = filtersData[key];
          delete filtersData[key];
          custom_params[keyName] = newValue;
        }
      }
    });
    filtersData["custom_params"] = custom_params;
    this.globalFilters = filtersData;
  };
  setReportFilters = ({ filters, dateRange, numRange, selectedVal }) => {
    let newFilters = {};
    let newDateRange = {};
    if (Object.keys(filters).length !== 0) {
      Object.keys(filters).map((key) => {
        if (
          filters[key]["type"] === "date" ||
          filters[key]["type"] === "datetime"
        ) {
          let newDate = getIsoString(filters[key]["value"]);
          newFilters[key] = newDate;
        } else {
          newFilters[key] = filters[key]["value"];
        }
      });
    }
    if (Object.keys(dateRange).length !== 0) {
      Object.keys(dateRange).map((key) => {
        let d1 = getIsoString(dateRange[key]["start"]);
        let d2 = getIsoString(dateRange[key]["end"]);
        newDateRange[key] = {
          start: d1,
          end: d2,
        };
      });
    }
    let filtersData = {
      ...newFilters,
      ...newDateRange,
      ...numRange,
      ...selectedVal,
    };
    let custom_params = {};
    Object.keys(filtersData).map((key) => {
      let index = this.AllColumnsList.findIndex(
        (column) => column["field"] === key
      );
      if (index !== -1) {
        if (this.AllColumnsList[index]["is_custom_param"]) {
          let keyName = key.replace("custom_params_", "");
          let newValue = filtersData[key];
          delete filtersData[key];
          custom_params[keyName] = newValue;
        }
      }
    });
    if (Object.keys(custom_params).length > 0) {
      filtersData["custom_params"] = custom_params;
    }
    this.reportFilters = filtersData;
  };
  resetReportFilters = () => {
    this.reportFilters = {};
  };
  setSegmentsList = ({
    segmentName,
    filters,
    dateRange,
    numRange,
    selectedVal,
  }) => {
    let filtersData = { ...filters, ...dateRange, ...numRange, ...selectedVal };
    let custom_params = {};
    Object.keys(filtersData).map((key) => {
      let index = this.AllColumnsList.findIndex(
        (column) => column["field"] === key
      );
      if (index !== -1) {
        if (this.AllColumnsList[index]["is_custom_param"]) {
          let keyName = key.replace("custom_params_", "");
          let newValue = filtersData[key];
          delete filtersData[key];
          custom_params[keyName] = newValue;
        }
      }
    });
    filtersData["custom_params"] = custom_params;
    let finalObj = { name: segmentName, value: filtersData };
    this.segmentsList.push(finalObj);
  };
  setDateRange = (val) => {
    consoleLogger("in root store date range:", val);
    this.filters_tab.lead_created_at_from = val.lead_created_at_from;
    this.filters_tab.lead_created_at_end = val.lead_created_at_end;
  };

  getDefaulttabInfo = (key, value) => {
    let defaultStruture = {
      page: 0,
      page_size: pageSize,
      columns: [],
      count: 0,
    };
    defaultStruture[key] = value;
    return defaultStruture;
  };
  updatePaging = ({ id, page }) => {
    if (this.tabInfo.hasOwnProperty(id)) {
      this.tabInfo[id]["page"] = page;
    } else {
      this.tabInfo[id] = this.getDefaulttabInfo("page", page);
    }
  };
  updatePageSize = ({ id, size }) => {
    if (this.tabInfo.hasOwnProperty(id)) {
      this.tabInfo[id]["page_size"] = size;
    } else {
      this.tabInfo[id] = this.getDefaulttabInfo("page_size", size);
    }
  };
  updateFilterStagesList = (val) => {
    let list = [];
    if (val.length) {
      val.map((e) => {
        list.push(e.value);
      });
      this.filterStagesList = list;
    } else {
      this.filterStagesList = list;
    }
  };
  updateFilterStatusList = (val) => {
    let list = [];
    if (val.length) {
      val.map((e) => {
        list.push(e.value);
      });
      this.filterStatusList = list;
    } else {
      this.filterStatusList = list;
    }
  };
  setStartDate = (val) => {
    this.start_date = val;
  };
  setEndDate = (val) => {
    this.end_date = val;
  };
  formatFilterColumnHeaders = (val) => {
    this.FilterColumnHeaders = FormattedColumnsFilters(val);
  };
  updateDefaultSelectColumns = (val) => {
    this.ColumnHeaders = val;
  };
  formatRawColumnHeaders = (val) => {
    this.ColumnHeaders = FormattedColumns(val);
  };
  formatReportColumnHeaders = (val) => {
    this.ReportColumnHeaders = FormattedReportColumns(val);
  };
  setTabId = (val) => {
    this.TabId = val;
  };
  getData = (response) => {
    this.UserData = response;
  };
  createUser = ({ name, email, phone, role }) => {
    this.Basic[0].name = name;
    this.Basic[0].email = email;
    this.Basic[0].phone = phone;
    this.Basic[0].role = role;
  };

  userInfo = ({ state, district, school, branch }) => {
    this.Info[0].state = state;
    this.Info[0].district = district;
    this.Info[0].school = school;
    this.Info[0].branch = branch;
  };

  userProfession = ({ subject, grade, experience }) => {
    this.Subject = Array.from(subject);
    this.Grade = Array.from(grade);
    this.Exp = experience;
  };
}
class FilterStore {
  Filters = [];
  SheetFilter = [];
  SheetData = [];
  ContentData = [];
  TabValue = 0;
  CallTabValue = 0;
  detailTabValue = "Basic Info";
  page = 0;
  constructor() {
    makeObservable(this, {
      Filters: observable,
      TabValue: observable,
      CallTabValue: observable,
      SheetFilter: observable,
      SheetData: observable,
      ContentData: observable,
      detailTabValue: observable,
      page: observable,
      removeContent: action,
      removeAllContent: action,
      addFilter: action,
      removeFilter: action,
      removeAllFilter: action,
      addSheetFilter: action,
      updateTabValue: action,
      updateCallTabValue: action,
      setPage: action,
      updateDetailTabValue: action,
    });
    makePersistable(this, {
      name: FilterStore,
      storage: window.localStorage,
      expireIn: 86400000,
      removeOnExpiration: true,
      stringify: true,
      debugMode: false,
      properties: ["Filters", "SheetFilter", "SheetData", "ContentData"],
    });
  }
  setPage = (val) => {
    this.page = val;
  };
  updateTabValue = (val) => {
    this.TabValue = val;
  };
  updateCallTabValue = (val) => {
    this.CallTabValue = val;
  };
  updateDetailTabValue = (val) => {
    this.detailTabValue = val;
  };

  addFilter = (arr) => {
    let temp = [];
    arr.map((e) => {
      if (this.Filters.length > 0) {
        if (
          this.Filters.every((item) => item.type !== e.type) &&
          e.isChecked === true
        ) {
          temp.push(e);
        }
      } else {
        if (e.isChecked === true) {
          temp.push(e);
        }
      }
      return temp;
    });
    this.Filters.push(...temp);
    temp = [];
  };
  removeFilter = (str) => {
    this.Filters = this.Filters.filter((e) => {
      return e.type !== str;
    });
  };
  removeAllFilter = (str) => {
    this.Filters = this.Filters.filter((item) => item.name !== str);
    consoleLogger("removed successful");
  };
  addSheetFilter = (item) => {
    if (!this.SheetFilter.includes(item)) {
      this.SheetFilter.push(item);
    }
  };
  removeSheetFilter = (item) => {
    this.SheetFilter = this.SheetFilter.filter((e) => e !== item);
  };

  removeContent = (str) => {
    if (this.Filters.length === 0) {
      this.ContentData = [];
      return this.ContentData;
    }
    this.ContentData.map((e) => {
      this.Filters.map((itr) => {
        if (
          (e.category === str ||
            e.subject === str ||
            e.grade === str ||
            e.level === str) &&
          e.category !== itr.type &&
          e.subject !== itr.type &&
          e.grade !== itr.type &&
          e.level !== itr.type
        ) {
          this.ContentData = this.ContentData.filter((item) => item !== e);
        }
        return this.ContentData;
      });
      return this.ContentData;
    });
  };
  removeAllContent = (e) => {
    this.ContentData.map((e1) => {
      e.subheaders.map((e2) => {
        this.Filters.map((itr) => {
          if (
            e1.dataType.includes(e2.type) &&
            !e1.dataType.includes(itr.type)
          ) {
            this.ContentData = this.ContentData.filter((e3) => e3 !== e1);
          }
          return this.ContentData;
        });
        return this.ContentData;
      });
      return this.ContentData;
    });
  };
}

//**********************Lead Store**************** */
class LeadStore {
  tabID = "";
  tabInfo = {};
  tabList = [];
  tabColumnSortingList = {};

  //variables to manage tabs grouping
  _tabGroups = [];
  _tabsDetails = [];
  _selectedGroupName = null;
  groupsToTabs = {};
  ///

  leadScopeFilter = {};

  leadsForCurrentTab = [];
  constructor() {
    makeObservable(this, {
      tabInfo: observable,
      tabID: observable,
      tabList: observable,
      tabColumnSortingList: observable,
      _tabGroups: observable,
      _tabsDetails: observable,
      _selectedGroupName: observable,
      groupsToTabs: observable,
      leadScopeFilter: observable,
      leadsForCurrentTab: observable,
      updateTabID: action,
      resetTabInfo: action,
      updateCurrentTabPage: action,
      updateCurrentTabPageSize: action,
      updateCurrentTabItemCount: action,
      setupTabs: action,
      updateTabList: action,
      updateCurrentTabColumns: action,
      updateTabColumnsWithDefaultColumns: action,
      getSelectedTabDetails: action,
      updateTabColumnSortingList: action,
      getTabsForSelectedGroup: action,
      updateSelectedGroupName: action,
      updateLeadTabFilter: action,
      resetLeadTabFilter: action,
      fetchLeadsForTab: action,
      generateQueryPayload: action,
      hasNextLead: action,
      hasPrevLead: action,
      getPrevLeadID: action,
      getNextLeadID: action,
      updateColumnsWidthMapping: action,
      fetchLeadsWithCount: action,
      fetchLeadsAndCountSeperate: action,
      fetchLeadsItemCount: action,
      getLeadPaginationCounterData: action,
      isLeadSortingByColumnApplied: action,
      getColumnSortingDirection: action,
    });

    makePersistable(this, {
      name: FilterStore,
      storage: window.localStorage,
      expireIn: 86400000,
      removeOnExpiration: true,
      stringify: true,
      debugMode: false,
      properties: ["_selectedGroupName", "leadScopeFilter"],
    });
  }

  resetLeadTabFilter = () => {
    this.leadScopeFilter = {};
  };

  updateLeadTabFilter = (filterData) => {
    this.leadScopeFilter = filterData;
  };

  //--------------------functions related to leads page sorting-------------------------//
  isLeadSortingByColumnApplied = ({ columnMeta }) => {
    if (this.tabColumnSortingList.hasOwnProperty(this.tabID)) {
      return (
        this.tabColumnSortingList[this.tabID]["field_name"] ===
        columnMeta["field"]
      );
    }
    return false;
  };

  getColumnSortingDirection = () => {
    if (this.tabColumnSortingList.hasOwnProperty(this.tabID)) {
      return this.tabColumnSortingList[this.tabID]["direction"];
    }
    return "desc";
  };
  //------------------------------end-----------------------------------------------------//

  //*****************************Lead Navigation related helper functions************ */
  getPrevLeadID = async (leadID) => {
    const tabInfo = this.tabInfo[this.tabID];
    const index = this.leadsForCurrentTab.findIndex(
      (element) => element.id === leadID
    );

    if (index === 0) {
      let currentPage = tabInfo.page;

      if (currentPage === 0) {
        return "";
      }
      currentPage -= 1;
      this.updateCurrentTabPage({ tabID: this.tabID, page: currentPage });
      await this.fetchLeadsForTab();
      if (this.leadsForCurrentTab.length > 0) {
        return this.leadsForCurrentTab[this.leadsForCurrentTab.length - 1].id;
      } else {
        return "";
      }
    } else {
      return this.leadsForCurrentTab[index - 1].id;
    }
  };

  getNextLeadID = async (leadID) => {
    const tabInfo = this.tabInfo[this.tabID];
    const index = this.leadsForCurrentTab.findIndex(
      (element) => element.id === leadID
    );

    if (index === this.leadsForCurrentTab.length - 1) {
      let currentPage = tabInfo.page;
      let limit = tabInfo.page_size;
      let numberOfPages = Math.ceil(tabInfo.count / limit);
      if (currentPage >= numberOfPages - 1) {
        return "";
      }
      currentPage += 1;

      this.updateCurrentTabPage({ tabID: this.tabID, page: currentPage });
      await this.fetchLeadsForTab();
      if (this.leadsForCurrentTab.length > 0) {
        return this.leadsForCurrentTab[0].id;
      } else {
        return "";
      }
    } else {
      return this.leadsForCurrentTab[index + 1].id;
    }
  };

  hasNextLead = (leadID) => {
    const tabInfo = this.tabInfo[this.tabID];
    let currentPage = tabInfo.page;
    let limit = tabInfo.page_size;
    let numberOfPages = Math.ceil(tabInfo.count / limit);
    let index = this.leadsForCurrentTab.findIndex(
      (element) => element.id === leadID
    );
    if (index === this.leadsForCurrentTab.length - 1) {
      return currentPage >= numberOfPages - 1;
    } else {
      return false;
    }
  };
  hasPrevLead = (leadID) => {
    const tabInfo = this.tabInfo[this.tabID];
    let currentPage = tabInfo.page;

    let index = this.leadsForCurrentTab.findIndex(
      (element) => element.id === leadID
    );
    if (index === 0) {
      return currentPage === 0;
    }
    return false;
  };

  //**********************************END****************************************** */

  //****************fetch and store lead listing data related function************* */

  generateQueryPayload = () => {
    const columnsList = [...rootStore.userStore.AllColumnsList];
    if (Object.keys(this.leadScopeFilter).length > 0) {
      let tabFilterData = this.leadScopeFilter;
      let filterData = tabFilterData["filter"];
      let value = null;
      Object.keys(filterData).map((key) => {
        if (
          filterData[key]["filter_type"] === "normal" ||
          filterData[key]["filter_type"] === ""
        ) {
          if (
            filterData[key]["dataType"] === "date" ||
            filterData[key]["dataType"] === "datetime"
          ) {
            value = getIsoString(filterData[key]["value"]);
          } else {
            value = filterData[key]["value"];
          }
        } else if (filterData[key]["filter_type"] === "range") {
          if (
            filterData[key]["dataType"] === "date" ||
            filterData[key]["dataType"] === "datetime"
          ) {
            let d1 = getIsoString(filterData[key]["value"]["start"]);
            let d2 = getIsoString(filterData[key]["value"]["end"]);
            value = {
              start: d1,
              end: d2,
            };
          } else if (filterData[key]["dataType"] === "integer") {
            let num1 = filterData[key]["value"]["start"];
            let num2 = filterData[key]["value"]["end"];
            value = {
              start: num1,
              end: num2,
            };
          }
        } else if (filterData[key]["filter_type"] === "list") {
          let tempList = [];
          filterData[key]["value"].forEach((obj) => {
            tempList.push(obj["value"]);
          });
          value = tempList;
        }
      });
      const fieldName = Object.keys(filterData)[0];

      let index = columnsList.findIndex(
        (column) => column["field"] === fieldName
      );
      if (index !== -1) {
        if (columnsList[index]["is_custom_param"]) {
          let newKey = fieldName.replace("custom_params_", "");
          return {
            custom_params: {
              [newKey]: {
                value: value,
                op: tabFilterData["operator"]["value"],
              },
            },
          };
        } else {
          return {
            [fieldName]: {
              value: value,
              op: tabFilterData["operator"]["value"],
            },
          };
        }
      }
    }
    return null;
  };

  getLeadPaginationCounterData = () => {
    let pageLimit = this.tabInfo[this.tabID]["page_size"];
    let count = this.tabInfo[this.tabID]["count"];
    let page = this.tabInfo[this.tabID]["page"];
    let min = count !== null && count > 0 ? page * pageLimit + 1 : 0;

    // Calculate the actual item count for the current page
    let currentPageCount =
      count !== null && count > 0
        ? Math.min(pageLimit, count - page * pageLimit)
        : 0;
    let max = currentPageCount > 0 ? min + currentPageCount - 1 : 0;

    return {
      min: min,
      max: max,
      count: count,
      page_addon: this.tabInfo[this.tabID]["page_addon"],
    };
  };
  fetchLeadsItemCount = async ({ payload }) => {
    let response = await getLeadsItemCountApi({ payload: payload });
    if (Object.keys(response).length > 0) {
      this.updateCurrentTabItemCount({
        tabID: this.tabID,
        count: response.count,
      });
    } else {
      this.updateCurrentTabItemCount({
        tabID: this.tabID,
        count: null,
      });
    }
    this.updateExtraPageAddonState({ flag: false });
  };

  fetchLeadsWithCount = async ({ payload }) => {
    let response = await getLeadsApi({
      page_size: this.tabInfo[this.tabID]["page_size"],
      page: this.tabInfo[this.tabID]["page"],
      payload: payload,
    });
    if (Object.keys(response).length > 0) {
      this.leadsForCurrentTab = response.items;
      this.updateCurrentTabItemCount({
        tabID: this.tabID,
        count: response.item_count,
      });
    } else {
      this.leadsForCurrentTab = [];
      this.updateCurrentTabItemCount({
        tabID: this.tabID,
        count: 0,
      });
    }
  };

  fetchLeadsAndCountSeperate = async ({ payload }) => {
    let response = await getLeadsApiV3({
      page_size: this.tabInfo[this.tabID]["page_size"],
      page: this.tabInfo[this.tabID]["page"],
      payload: payload,
    });

    if (response.length > this.tabInfo[this.tabID]["page_size"]) {
      this.updateExtraPageAddonState({ flag: true });
      this.leadsForCurrentTab = response.slice(
        0,
        this.tabInfo[this.tabID]["page_size"]
      );
    } else {
      this.updateExtraPageAddonState({ flag: false });
      this.leadsForCurrentTab = response;
    }
    this.fetchLeadsItemCount({ payload: payload });
  };

  fetchLeadsForTab = async () => {
    this.updateCurrentTabItemCount({
      tabID: this.tabID,
      count: null,
    });
    this.updateExtraPageAddonState({ flag: false });
    const sorting = toJS(this.tabColumnSortingList).hasOwnProperty(this.tabID)
      ? toJS(this.tabColumnSortingList)[this.tabID]
      : null;
    const selectedColumns = this.tabInfo[this.tabID]["columns"];
    const columnIdList = [];
    selectedColumns.forEach((column) => {
      if (!columnIdList.includes(column["id"])) {
        columnIdList.push(column["id"]);
      }
    });
    let payload = {
      tab_id: this.tabID,
      query: this.generateQueryPayload(),
      sort_on: sorting,
      listing_columns: columnIdList,
    };
    if (hasAccessToLeadsPaginationV2()) {
      await this.fetchLeadsAndCountSeperate({ payload: payload });
    } else {
      await this.fetchLeadsWithCount({ payload: payload });
    }
  };

  //************************************end************************************* */

  //****************tab grouping related functions***************************//
  setupTabs = async () => {
    if (checkFeaturePermission("leads")) {
      await this.fetchTabGroups();
      await this.fetchTabs();
      this.setupGroupsToTabs();

      let _tempTabGroups = [];

      this._tabGroups.forEach((group) => {
        let tabsForGroup = this.groupsToTabs[group["name"]] ?? [];
        if (tabsForGroup.length > 0) {
          _tempTabGroups.push(group);
        }
      });
      this._tabGroups = _tempTabGroups;
      if (this._tabGroups.length > 0) {
        let index = this._tabGroups.findIndex(
          (element) => element["name"] === this._selectedGroupName
        );

        if (index === -1) {
          let index = this._tabGroups.findIndex(
            (element) => element["default_group"] === true
          );
          if (index === -1) {
            let group = this._tabGroups[0];
            this._selectedGroupName = group["name"];
          } else {
            let group = this._tabGroups[index];
            this._selectedGroupName = group["name"];
          }
        }

        for (let i = 0; i < this._tabsDetails.length; i++) {
          this.tabInfo[this._tabsDetails[i]["tab"]["id"]] = {
            page: 0,
            page_size: rootStore.userStore.defaultLeadListPageSize,
            count: null,
            columns: [],
            columns_width_mapping: {},
            page_addon: false,
          };
        }
        this.updateTabID(this.groupsToTabs[this._selectedGroupName][0]);
      }
    }
  };

  updateColumnsWidthMapping = ({ columnID, width }) => {
    this.tabInfo[this.tabID]["columns_width_mapping"][columnID] = width;
  };

  fetchTabGroups = async () => {
    let response = await getViewGroupsApi({
      projectID: rootStore.authStore.projectId,
    });
    if (response.length > 0) {
      this._tabGroups = response;
      this._tabGroups.push({
        id: "others",
        default_group: false,
        name: "Others",
      });
    } else {
      this._tabGroups.push({
        id: "others",
        default_group: true,
        name: "Others",
      });
      this._selectedGroupName = "Others";
    }
  };
  fetchTabs = async () => {
    try {
      let response = await getTabsApi(rootStore.authStore.projectId);
      if (response.data.length > 0) {
        this._tabsDetails = response.data;
      }
    } catch (error) {
      console.log(error);
    }
  };
  setupGroupsToTabs = async () => {
    let uniqueGroups = [
      ...new Set(this._tabGroups.map((item) => item["name"])),
    ];
    let result = {};
    uniqueGroups.forEach((group) => {
      if (!result.hasOwnProperty(group)) {
        result[group] = [];
      }
    });
    this._tabsDetails.forEach((tab) => {
      let groups = tab["tab"]["viewgroups"] ?? [];
      if (groups.length === 0) {
        result["Others"].push(tab["tab"]["id"]);
      } else {
        groups.forEach((group) => {
          if (!uniqueGroups.includes(group)) {
            result["Others"].push(tab["tab"]["id"]);
          } else {
            result[group].push(tab["tab"]["id"]);
          }
        });
      }
    });
    this.groupsToTabs = result;
  };
  getTabsForSelectedGroup = () => {
    let tabIDs = this.groupsToTabs[this._selectedGroupName] ?? [];
    let tabs = [];
    tabIDs.forEach((id) => {
      tabs.push(
        this._tabsDetails.find((element) => element["tab"]["id"] === id)
      );
    });
    return tabs;
  };

  updateSelectedGroupName = (groupName) => {
    this._selectedGroupName = groupName;
  };

  //**************************END********************* */

  updateTabColumnSortingList = ({ columnMeta }) => {
    const currentDirection = this.tabColumnSortingList.hasOwnProperty(
      this.tabID
    )
      ? this.tabColumnSortingList[this.tabID]["direction"]
      : "asc"; //if sorting is for the first time then assuming current direction as ascending order
    const sortingDirection = currentDirection === "desc" ? "asc" : "desc";
    const flag = sortingDirection === "desc"; // flag is true if the new direction is "desc"

    this.tabColumnSortingList[this.tabID] = {
      field_name: columnMeta["field"],
      direction: sortingDirection,
      flag: flag,
    };
  };

  updateTabID = (id) => {
    this.tabID = id;
  };
  updateTabList = (list) => {
    this.tabList = list;
  };
  resetTabInfo = () => {
    this.tabID = "";
    this.tabList = [];
    this.tabColumnSortingList = {};
    this._tabGroups = [];
    this._tabsDetails = [];
    this._selectedGroupName = null;
    this.tabInfo = {};
  };

  updateCurrentTabPage = ({ tabID, page }) => {
    this.tabInfo[tabID]["page"] = page;
  };

  updateCurrentTabPageSize = ({ tabID, size }) => {
    this.tabInfo[tabID]["page_size"] = size;
  };

  updateCurrentTabItemCount = ({ tabID, count }) => {
    this.tabInfo[tabID]["count"] = count;
  };
  updateCurrentTabColumns = ({ tabID, columnsList }) => {
    this.tabInfo[tabID]["columns"] = columnsList;
  };
  updateExtraPageAddonState = ({ flag }) => {
    this.tabInfo[this.tabID]["page_addon"] = flag;
  };
  updateTabColumnsWithDefaultColumns = () => {
    let index = this._tabsDetails.findIndex(
      (tab) => tab["tab"]["id"] === this.tabID
    );
    if (index !== -1) {
      let fieldList = [];
      if (this._tabsDetails[index]["selected_columns"].length > 0) {
        this._tabsDetails[index]["selected_columns"].forEach((column) => {
          let index = rootStore.userStore.AllColumnsList.findIndex(
            (columnData) => columnData["field"] === column["field"]
          );
          if (index !== -1) {
            fieldList.push(rootStore.userStore.AllColumnsList[index]);
          }
        });
      } else {
        this._tabsDetails[index]["tab"]["selected_columns"].forEach(
          (column) => {
            let index = rootStore.userStore.AllColumnsList.findIndex(
              (columnData) => columnData["field"] === column["field"]
            );
            if (index !== -1) {
              fieldList.push(rootStore.userStore.AllColumnsList[index]);
            }
          }
        );
      }
      this.updateCurrentTabColumns({
        tabID: this.tabID,
        columnsList: fieldList,
      });
    }
  };

  getSelectedTabDetails = () => {
    let index = this._tabsDetails.findIndex(
      (tab) => tab["tab"]["id"] === this.tabID
    );
    return this._tabsDetails[index];
  };
}
//***************************END****************** */

//**********************Product Store**************** */
class ProductStore {
  columnsList = [];
  listingInfo = {
    page_number: 0,
    page_size: pageSize,
    count: 0,
  };

  constructor() {
    makeObservable(this, {
      columnsList: observable,
      listingInfo: observable,
      fetchColumns: action,
      udpatePageNumber: action,
      updatePageSize: action,
      updateCount: action,
    });

    makePersistable(this, {
      name: ProductStore,
      storage: window.localStorage,
      expireIn: 86400000,
      removeOnExpiration: true,
      stringify: true,
      debugMode: false,
      properties: [],
    });
  }

  resetStore = () => {
    this.columnsList = [];
    this.listingInfo = {
      page_number: 0,
      page_size: pageSize,
      count: 0,
    };
  };

  fetchColumns = async () => {
    if (this.columnsList.length === 0) {
      let response = await getProductColumnsListApi();
      this.columnsList = response;
    }
  };
  udpatePageNumber = (pageNumber) => {
    this.listingInfo["page_number"] = pageNumber;
  };
  updatePageSize = (pageSize) => {
    this.listingInfo["page_size"] = pageSize;
  };
  updateCount = (count) => {
    this.listingInfo["count"] = count;
  };
}
//***************************END****************** */

//**********************Contact Store**************** */
class ContactStore {
  columnsList = [];
  listingInfo = {
    page_number: 0,
    page_size: pageSize,
    count: 0,
  };
  columnGroups = [];
  detailTabs = [];
  detailsCurrentTabID = null;
  constructor() {
    makeObservable(this, {
      columnsList: observable,
      listingInfo: observable,
      columnGroups: observable,
      detailsCurrentTabID: observable,
      detailTabs: observable,
      fetchColumns: action,
      udpatePageNumber: action,
      updatePageSize: action,
      updateCount: action,
      fetchColumnGroups: action,
      updateDetailsCurrentTabID: action,
      fetchDetailTabs: action,
      init: action,
    });

    makePersistable(this, {
      name: ContactStore,
      storage: window.localStorage,
      expireIn: 86400000,
      removeOnExpiration: true,
      stringify: true,
      debugMode: false,
      properties: [],
    });
  }

  init = async () => {
    await this.fetchColumns();
    await this.fetchColumnGroups();
    await this.fetchDetailTabs();
  };

  updateDetailsCurrentTabID = (tabID) => {
    this.detailsCurrentTabID = tabID;
  };

  fetchColumnGroups = async () => {
    if (this.columnGroups.length === 0) {
      let response = await getContactColumnGroupsApi();
      this.columnGroups = response;
    }
  };

  fetchDetailTabs = async () => {
    let response = await getContactDetailsTabsApi();
    this.detailTabs = response;
  };

  resetStore = () => {
    this.columnsList = [];
    this.listingInfo = {
      page_number: 0,
      page_size: pageSize,
      count: 0,
    };
    this.columnGroups = [];
    this.detailTabs = [];
    this.detailsCurrentTabID = null;
  };

  fetchColumns = async () => {
    let response = await getContactColumnsListApi();
    this.columnsList = response;
  };
  udpatePageNumber = (pageNumber) => {
    this.listingInfo["page_number"] = pageNumber;
  };
  updatePageSize = (pageSize) => {
    this.listingInfo["page_size"] = pageSize;
  };
  updateCount = (count) => {
    this.listingInfo["count"] = count;
  };
}
//***************************END****************** */
//**********************Customer Store**************** */
class CustomerStore {
  columnsList = [];
  listingInfo = {
    page_number: 0,
    page_size: pageSize,
    count: 0,
    scope: "all",
  };
  columnGroups = [];
  detailTabs = [];
  detailsCurrentTabID = null;
  stagesList = [];
  purchaseCategoryList = [];
  constructor() {
    makeObservable(this, {
      columnsList: observable,
      listingInfo: observable,
      columnGroups: observable,
      detailsCurrentTabID: observable,
      detailTabs: observable,
      stagesList: observable,
      purchaseCategoryList: observable,
      fetchColumns: action,
      udpatePageNumber: action,
      updatePageSize: action,
      updateCount: action,
      fetchColumnGroups: action,
      updateDetailsCurrentTabID: action,
      fetchDetailTabs: action,
      init: action,
      updateScope: action,
      isGroupEditable: action,
      fetchStagesList: action,
      fetchPurchaseCategoryList: action,
    });

    makePersistable(this, {
      name: CustomerStore,
      storage: window.localStorage,
      expireIn: 86400000,
      removeOnExpiration: true,
      stringify: true,
      debugMode: false,
      properties: [],
    });
  }

  isGroupEditable = (groupName) => {
    let index = this.columnGroups.findIndex(
      (element) => element["name"] === groupName
    );
    if (index !== -1) {
      return this.columnGroups[index]["editable"];
    }
    return false;
  };

  init = async () => {
    await this.fetchColumns();
    await this.fetchColumnGroups();
    await this.fetchDetailTabs();
    await this.fetchStagesList();
    await this.fetchPurchaseCategoryList();
  };

  fetchStagesList = async () => {
    let response = await getCustomerStagesListApi();
    this.stagesList = response;
  };
  fetchPurchaseCategoryList = async () => {
    let response = await getCustomerPurchaseCategoryListApi();
    this.purchaseCategoryList = response;
  };

  updateScope = (scope) => {
    this.listingInfo["scope"] = scope;
  };

  updateDetailsCurrentTabID = (tabID) => {
    this.detailsCurrentTabID = tabID;
  };

  fetchColumnGroups = async () => {
    if (this.columnGroups.length === 0) {
      let response = await getCustomerColumnGroupsApi();
      this.columnGroups = response;
    }
  };

  fetchDetailTabs = async () => {
    let response = await getCustomerDetailsTabsApi();
    this.detailTabs = response;
  };

  resetStore = () => {
    this.columnsList = [];
    this.listingInfo = {
      page_number: 0,
      page_size: pageSize,
      count: 0,
      scope: "all",
    };
    this.columnGroups = [];
    this.detailTabs = [];
    this.detailsCurrentTabID = null;
    this.stagesList = [];
    this.purchaseCategoryList = [];
  };

  fetchColumns = async () => {
    let response = await getCustomerColumnsListApi();
    this.columnsList = response;
  };
  udpatePageNumber = (pageNumber) => {
    this.listingInfo["page_number"] = pageNumber;
  };
  updatePageSize = (pageSize) => {
    this.listingInfo["page_size"] = pageSize;
  };
  updateCount = (count) => {
    this.listingInfo["count"] = count;
  };
}
//***************************END****************** */

//**********************Organization Store**************** */
class OrganizationStore {
  columnsList = [];
  listingInfo = {
    page_number: 0,
    page_size: pageSize,
    count: 0,
  };

  constructor() {
    makeObservable(this, {
      columnsList: observable,
      listingInfo: observable,
      fetchColumns: action,
      udpatePageNumber: action,
      updatePageSize: action,
      updateCount: action,
    });

    makePersistable(this, {
      name: OrganizationStore,
      storage: window.localStorage,
      expireIn: 86400000,
      removeOnExpiration: true,
      stringify: true,
      debugMode: false,
      properties: [],
    });
  }

  resetStore = () => {
    this.columnsList = [];
    this.listingInfo = {
      page_number: 0,
      page_size: pageSize,
      count: 0,
    };
  };

  fetchColumns = async () => {
    if (this.columnsList.length === 0) {
      let response = await getOrganizationColumnsListApi();
      this.columnsList = response;
    }
  };
  udpatePageNumber = (pageNumber) => {
    this.listingInfo["page_number"] = pageNumber;
  };
  updatePageSize = (pageSize) => {
    this.listingInfo["page_size"] = pageSize;
  };
  updateCount = (count) => {
    this.listingInfo["count"] = count;
  };
}
//***************************END****************** */

const rootStore = new RootStore();
export default rootStore;
