import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import rootStore from "../../stores/RootStore";
import LeadsAssignModal from "../lead_assign_modal/LeadsAssignModal";
import { observer } from "mobx-react-lite";
import {
  changeLeadstatusApi,
  deleteMultipleLeadsApi,
  getFilterColsApiV2,
  markUnmarkMultipleLeadAsFreshLeadApi,
  resetNextFollowupForLeadsApi,
  searchLeadsByQueryApi,
} from "../../Api";
import {
  Backdrop,
  Box,
  CircularProgress,
  Tooltip,
  styled,
} from "@mui/material";
import SuccessMessageDialog from "../alert_dialogue/SuccessMessageDialog";
import ErrorMessageDialog from "../alert_dialogue/ErrorMessageDialog";
import {
  getAvailableStatusListByStage,
  isAdditionalStatusDetailsRequired,
  isLastContactedToday,
  isOpenCallLogContextMenuDisabled,
  isSetLeadColorContextMenuDisabled,
  validateDisabledSubFeature,
} from "../../Functions";
import CustomConfirmationDialog from "../alert_dialogue/CustomConfirmationDialog";
import {
  FormattedColumnsFilters,
  leadListingIconsColumn,
} from "../../ColumnUtils";
import MultiLeadUpdateStageModal from "../update_stage_modal/MultiLeadUpdateStageModal";
import MultiLeadUpdateSourceModal from "../update_source_modal/MultiLeadUpdateSourceModal";
import MultiLeadUpdateStatusModal from "../update_status_modal/MultiLeadUpdateStatusModal";
import DeleteLeadConfirmationDialog from "../alert_dialogue/DeleteLeadConfirmationDialog";
import CustomMessageDialog from "../alert_dialogue/CustomMessageDialog";
import PriorityIndividualLeadDialog from "../priority_lead_dialog/PriorityIndividualLeadDialog";
import MoveProjectLeadModal from "../move_leads_to_project/MoveProjectLeadModal";
import DialogWithCommentField from "../alert_dialogue/DialogWithCommentField";
import SetMultiLeadColorDialog from "../update_lead_color_dialog/SetMultiLeadColorDialog";
import SetLeadColorDialog from "../update_lead_color_dialog/SetLeadColorDialog";
import CallLogModal from "../call_log_modal/CallLogModal";
import LeadContextMenu from "../lead_context_menu/LeadContextMenu";
import TableSkeleton from "./components/TableSkeleton";
import DropdownColumnWidget from "./components/DropdownColumnWidget";
import UpdateStatusModal from "../update_status_modal/UpdateStatusModal";
import ExportMultiLeadDialog from "../export_multi_lead_dialog/ExportMultiLeadDialog";
import AddLeadRemarksDialog from "../add_lead_remarks_dialog/AddLeadRemarksDialog";
import { AgGridReact } from "ag-grid-react";
import LeadListingPaginationComponentV1 from "../pagination/LeadListingPaginationComponentV1";
import { leadListingRowBuffer } from "../../Config";
import MultiLeadUpdateFieldModal from "../update_field_modal/MultiLeadUpdateFieldModal";
const Container = styled(Box)`
  height: fit-content;
  background-color: white;
  width: 100%;
  /* padding: 12px; */
  @media (max-width: 700px) {
    padding: 0px;
  }
`;
const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  min-height: 400px;
  flex-direction: column;
  margin: auto;
`;
const PaginationWrapper = styled(Box)`
  padding: 0px 20px;
`;

const FilteredTab = (props) => {
  let navigate = useNavigate();

  const [refresh, setRefresh] = useState(false);
  let uid = localStorage.getItem("uid");
  const [selectedLeadIDs, setSelectedLeadIDs] = useState([]);
  const { authStore } = rootStore;
  let pid = authStore.projectId;
  const columnsList = [...rootStore.userStore.AllColumnsList];
  // let columns = userStore.FilterColumnHeaders;
  const [size, setSize] = useState(rootStore.userStore.defaultLeadListPageSize);
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  // delete leads related state hooks
  // const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const stagesList = [...rootStore.authStore.stageList];
  const statusList = [...rootStore.authStore.statusList];
  const [showDeleteProtectedAlert, setShowDeleteProtectedAlert] =
    useState(false);
  const [openCustomCommentDialog, setOpenCustomCommentDialog] = useState(false);

  //********************state variable related to lead context menu******** */
  const [leadContextAnchor, setLeadContextAnchor] = useState(null);
  const [selectedLeadData, setSelectedLeadData] = useState({});
  const [
    openIndividualSetLeadColorDialog,
    setOpenIndividualSetLeadColorDialog,
  ] = useState(false);
  const [openCallLogDialog, setOpenCallLogDialog] = useState(false);
  //***************************END******************************* */
  const [openUpdateStatusDialog, setOpenUpdateStatusDialog] = useState(false);
  const [selectedLeadStatus, setSelectedLeadStatus] = useState("");
  const [staleDataIDList, setStaleDataIDList] = useState([]);
  const [hoveredRowID, setHoveredRowID] = useState("");

  const [openAddRemarksDialog, setOpenAddRemarksDialog] = useState(false);
  const [columns, setColumns] = useState([]);

  const fetchFilterColumns = async () => {
    try {
      let response = await getFilterColsApiV2();

      let tempColumnsList = [];
      response.data.forEach((entry) => {
        let index = columnsList.findIndex(
          (column) => column["field"] === entry["field"]
        );
        if (index !== -1) {
          let columnMeta = columnsList[index];
          if (rootStore.userStore.isColumnVisible(columnMeta)) {
            tempColumnsList.push(columnMeta);
          }
        }
      });
      return tempColumnsList;
    } catch (error) {
      return [];
    }
  };

  const init = async () => {
    window.scrollTo(0, 0);
    let fetchColumnResponse = await fetchFilterColumns();
    let formattedColumn = FormattedColumnsFilters({
      columnMetaList: fetchColumnResponse,
      scope: "search_page",
    });
    setColumns(formattedColumn);
    await getFilteredLeads({
      page_no: 0,
      page_size: rootStore.userStore.defaultLeadListPageSize,
    });
  };
  useEffect(() => {
    init();
  }, [authStore.searchQuery]);
  useEffect(() => {
    if (refresh === true) {
      init();
      setRefresh(false);
    }
  }, [refresh]);

  const getFilteredLeads = async ({ page_no, page_size }) => {
    props.setAssignState(0);
    setSelectedLeadIDs([]);
    setRow([]);
    setLoading(true);
    let response = await searchLeadsByQueryApi({ page_no, page_size });
    if (Object.keys(response).length > 0) {
      setRow(response.items);
      setCount(response.item_count);
    }
    setStaleDataIDList([]);
    setLoading(false);
  };
  const handlePageSize = async (e) => {
    setSize(e.target.value);
    setPage(0);
    await getFilteredLeads({ page_no: 0, page_size: e.target.value });
  };
  const handlePagination = async (e, page) => {
    setPage(page - 1);
    await getFilteredLeads({ page_no: page - 1, page_size: size });
  };

  const handleMarkLeadAsFreshLead = async () => {
    props.setOpenConfirmMarkLeadDialog(false);
    setShowBackDrop(true);
    let payload = { lead_ids: selectedLeadIDs, status: true };
    let response = await markUnmarkMultipleLeadAsFreshLeadApi({
      payload: payload,
    });
    if (response.hasError()) {
      setShowBackDrop(false);
      setOpenErrorDialog(true);
    } else {
      setShowBackDrop(false);
      setSuccessMessage("Leads successfully marked as fresh!");
      setOpenSuccessDialog(true);
      setRefresh(true);
    }
  };

  const handleResetFollowupForLeads = async (comments) => {
    props.setOpenConfirmResetFollowupDialog(false);
    setShowBackDrop(true);
    let payload = { lead_ids: selectedLeadIDs, comments: comments };
    let response = await resetNextFollowupForLeadsApi({
      payload: payload,
    });
    if (response.hasError()) {
      setShowBackDrop(false);
      setOpenErrorDialog(true);
    } else {
      setShowBackDrop(false);
      setSuccessMessage("Next Follow-up reset successfully!");
      setOpenSuccessDialog(true);
      setRefresh(true);
    }
  };

  //*******************delete leads related functions************* */

  const isStageDeleteProtected = ({ leadData }) => {
    let index = stagesList.findIndex(
      (stage) => stage["stage"] === leadData["lead_stage"]
    );
    if (index !== -1) {
      return stagesList[index]["delete_protected"];
    }
    return false;
  };
  const isStatusDeleteProtected = ({ leadData }) => {
    let index = statusList.findIndex(
      (status) => status["status"] === leadData["lead_status"]
    );
    if (index !== -1) {
      return statusList[index]["delete_protected"];
    }
    return false;
  };

  const isDeleteProtected = ({ leadData }) => {
    return (
      isStageDeleteProtected({ leadData: leadData }) ||
      isStatusDeleteProtected({ leadData: leadData })
    );
  };

  const isAnyLeadDeleteProtected = () => {
    for (let i = 0; i < selectedLeadIDs.length; i++) {
      let index = row.findIndex((lead) => lead["id"] === selectedLeadIDs[i]);
      let leadData = row[index];
      if (isDeleteProtected({ leadData: leadData })) {
        return true;
      }
    }
    return false;
  };

  const handleDeleteLead = async () => {
    props.setOpenDeleteLead(false);
    setShowBackDrop(true);

    if (isAnyLeadDeleteProtected()) {
      setShowBackDrop(false);
      setShowDeleteProtectedAlert(true);
    } else {
      let filteredLeadIds = [];

      for (let i = 0; i < selectedLeadIDs.length; i++) {
        let index = row.findIndex((lead) => lead["id"] === selectedLeadIDs[i]);
        let leadData = row[index];
        if (leadData["is_customer"] !== null) {
          if (leadData["is_customer"] === false) {
            filteredLeadIds.push(selectedLeadIDs[i]);
          }
        } else {
          // including leads with null is_customer value
          filteredLeadIds.push(selectedLeadIDs[i]);
        }
      }

      let response = await deleteMultipleLeadsApi({
        idList: filteredLeadIds,
      });
      if (response.hasError()) {
        setShowBackDrop(false);
        setOpenErrorDialog(true);
      } else {
        setShowBackDrop(false);
        setSuccessMessage("Leads deleted successfully!");
        setOpenSuccessDialog(true);
        setRefresh(true);
      }
    }
  };
  const sortIdsBasedOnListingOrder = () => {
    // Create a map to store the index of each object's ID in the list of objects
    const idIndexMap = new Map(row.map((obj, index) => [obj.id, index]));
    let ids = [...selectedLeadIDs];
    // Sort the list of IDs based on their index in the list of objects
    return ids.sort((a, b) => idIndexMap.get(a) - idIndexMap.get(b));
  };

  //****************************END********************************** */

  const handleQuickUpdate = async ({ rowID, status }) => {
    let payload = { status: status, sub_status: "None" };
    let response = await changeLeadstatusApi({
      data: payload,
      id: rowID,
    });
    if (!response.hasError()) {
      reFreshLeadListingLocally({ leadData: response.data });
    }
  };

  const reFreshLeadListingLocally = ({ leadData }) => {
    let tempList = [...row];
    let index = tempList.findIndex((entry) => entry["id"] === leadData["id"]);
    if (index !== -1) {
      tempList[index] = leadData;
    }
    setRow(tempList);
    let tempIDList = [...staleDataIDList];
    if (!staleDataIDList.includes(leadData["id"])) {
      tempIDList.push(leadData["id"]);
    }
    setStaleDataIDList(tempIDList);
  };

  const StatusFieldRenderer = ({ rowData }) => {
    if (hoveredRowID === rowData.id) {
      const availableStatuses = getAvailableStatusListByStage({
        currentStage: rowData["lead_stage"],
        stageList: stagesList,
        statusList: statusList,
      });

      let index = availableStatuses.findIndex(
        (status) => status["status"] === rowData["lead_status"]
      );
      if (index === -1) {
        let statusIndexInAllStatus = statusList.findIndex(
          (status) => status["status"] === rowData["lead_status"]
        );
        if (statusIndexInAllStatus !== -1) {
          availableStatuses.unshift(statusList[statusIndexInAllStatus]);
        }
      }
      return (
        <DropdownColumnWidget
          scope={"status"}
          onChange={(value) => {
            let additionalDetailsRequired = isAdditionalStatusDetailsRequired({
              status: value,
              leadData: rowData,
            });

            if (additionalDetailsRequired === true) {
              setSelectedLeadStatus(value);
              setSelectedLeadData(rowData);
              setOpenUpdateStatusDialog(true);
            } else {
              handleQuickUpdate({ rowID: rowData.id, status: value });
            }
          }}
          options={availableStatuses}
          value={rowData["lead_status"]}
          labelKey={"status"}
          valueKey={"status"}
        />
      );
    } else {
      let value = rowData["lead_status"];
      let color_value = "";
      let index = statusList.findIndex((status) => status.status === value);
      if (index !== -1) {
        color_value = statusList[index]["color"];
      }
      return (
        <Tooltip title={value}>
          <span
            className="csutable-cell-trucate"
            style={{
              color: color_value !== null ? color_value : "",
            }}
          >
            {value}
          </span>
        </Tooltip>
      );
    }
  };

  const getColumnListForDatatable = () => {
    if (validateDisabledSubFeature("quick_lead_update_in_listing")) {
      let index = columns.findIndex(
        (column) => column["field"] === "lead_status"
      );
      if (index !== -1) {
        let tempObj = {
          ...columns[index],
          cellRenderer: (params) => {
            return (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
                onMouseEnter={() => {
                  setHoveredRowID(params.data.id);
                }}
                onMouseLeave={() => {
                  setHoveredRowID("");
                }}
              >
                <StatusFieldRenderer rowData={params.data} />
              </Box>
            );
          },
        };
        columns[index] = tempObj;
      }
    }
    let len = columns.length;
    if (len > 0) {
      columns[len - 1]["minWidth"] = columns[len - 1]["width"];
      columns[len - 1]["flex"] = 1;
    }
    if (selectedLeadIDs.length > 0) {
      leadListingIconsColumn[0][
        "headerName"
      ] = `${selectedLeadIDs.length} Selected`;
    } else {
      leadListingIconsColumn[0]["headerName"] = "";
    }
    return leadListingIconsColumn.concat(columns);
  };

  const defaultColDef = useMemo(() => ({
    resizable: true,
    suppressMovable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
    preventDefaultOnContextMenu: validateDisabledSubFeature(
      "context_menu_in_lead_listing"
    ),
  };

  const handleCellContextMenu = (params) => {
    if (validateDisabledSubFeature("context_menu_in_lead_listing")) {
      params.event.preventDefault();
      setSelectedLeadData(params.data);
      setLeadContextAnchor({
        mouseX: params.event.clientX - 2,
        mouseY: params.event.clientY - 4,
      });
    }
  };
  const handleSelectionChange = (event) => {
    const selectedRows = event.api.getSelectedRows();
    const selectedIds = selectedRows.map((row) => row.id);
    props.setAssignState(selectedIds.length);
    setSelectedLeadIDs(selectedIds);
  };

  const getRowStyle = (params) => {
    return {
      cursor: "pointer",
      backgroundColor: isLastContactedToday(params.data["last_contacted_on"])
        ? "#E6EDF7"
        : "unset",
      opacity: staleDataIDList.includes(params.data.id) ? "0.2" : "1",
    };
  };

  const onCellClicked = (params) => {
    if (params.colDef.field === "icons") {
      params.event.stopPropagation();
      params.event.preventDefault();
    }
  };

  return (
    <>
      <Container>
        <Wrapper>
          {!loading ? (
            <>
              <Box
                sx={{
                  "& .ag-root-wrapper-body": {
                    height: "calc(100vh - 300px)",
                  },
                  "& .ag-checkbox-input-wrapper input": {
                    cursor: "pointer",
                  },
                }}
              >
                <div className="ag-theme-alpine" style={{ width: "100%" }}>
                  <AgGridReact
                    getRowStyle={getRowStyle}
                    gridOptions={gridOptions}
                    rowData={row}
                    defaultColDef={defaultColDef}
                    columnDefs={getColumnListForDatatable()}
                    animateRows={true}
                    suppressCellFocus
                    pagination={false}
                    paginationPageSize={size}
                    onRowClicked={(row) => {
                      if (row.event.defaultPrevented) {
                        return null;
                      }

                      authStore.updateLastActiveTime();
                      navigate(`/users/${uid}/leads/${row.data.id}`, {
                        state: {
                          lead_status: null,
                          scope: "search_page",
                        },
                      });
                    }}
                    onCellContextMenu={handleCellContextMenu}
                    rowSelection="multiple"
                    onSelectionChanged={handleSelectionChange}
                    rowBuffer={leadListingRowBuffer}
                    suppressColumnVirtualisation={true}
                    onCellClicked={onCellClicked}
                  />
                </div>
              </Box>
              <PaginationWrapper>
                <LeadListingPaginationComponentV1
                  pageNumber={page}
                  row={row}
                  pageSize={size}
                  count={count}
                  handlePageSize={handlePageSize}
                  handlePage={handlePagination}
                  pageSizeOptionList={
                    rootStore.userStore.leadListingPageSizeOptions
                  }
                />
              </PaginationWrapper>
            </>
          ) : (
            <TableSkeleton height={"calc(100vh - 249px)"} />
          )}
          {props.open && (
            <LeadsAssignModal
              open={props.open}
              setOpen={props.setOpen}
              row={props.member}
              leads={sortIdsBasedOnListingOrder()}
              pid={pid}
              setRefresh={setRefresh}
              refresh={refresh}
              totalLeadCount={count}
              pageSize={size}
              getAllLeadIDsToAssign={() => {}}
              enableAssignAllLead={false}
            />
          )}
        </Wrapper>
      </Container>

      {openSuccessDialog && (
        <SuccessMessageDialog
          open={openSuccessDialog}
          setOpen={setOpenSuccessDialog}
          message={successMessage}
        />
      )}
      {openErrorDialog && (
        <ErrorMessageDialog
          open={openErrorDialog}
          setOpen={setOpenErrorDialog}
        />
      )}
      {props.openConfirmMarkLeadDialog && (
        <CustomConfirmationDialog
          message={"Are you sure you want to mark the leads as fresh?"}
          open={props.openConfirmMarkLeadDialog}
          setOpen={props.setOpenConfirmMarkLeadDialog}
          handleConfirm={() => {
            handleMarkLeadAsFreshLead();
          }}
        />
      )}

      {props.openConfirmResetFollowupDialog && (
        <CustomConfirmationDialog
          message={
            "Are you sure you want to reset Next Follow-up for the leads?"
          }
          open={props.openConfirmResetFollowupDialog}
          setOpen={props.setOpenConfirmResetFollowupDialog}
          handleConfirm={() => {
            props.setOpenConfirmResetFollowupDialog(false);
            setOpenCustomCommentDialog(true);
          }}
        />
      )}
      {props.openPriorityLeadDialog && (
        <PriorityIndividualLeadDialog
          open={props.openPriorityLeadDialog}
          setOpen={props.setOpenPriorityLeadDialog}
          selectedLeadIdsData={selectedLeadIDs}
          setRefresh={setRefresh}
        />
      )}
      {props.openDeleteLead && (
        <DeleteLeadConfirmationDialog
          open={props.openDeleteLead}
          setOpen={props.setOpenDeleteLead}
          handleConfirm={() => {
            handleDeleteLead();
          }}
        />
      )}
      {showDeleteProtectedAlert && (
        <CustomMessageDialog
          open={showDeleteProtectedAlert}
          setOpen={setShowDeleteProtectedAlert}
          message={
            "Leads are delete-protected. Unprotect or remove them to proceed with deletion."
          }
        />
      )}

      {props.openUpdateStageModal && (
        <MultiLeadUpdateStageModal
          open={props.openUpdateStageModal}
          setOpen={props.setOpenUpdateStageModal}
          selectedLeadIdsData={selectedLeadIDs}
          setRefresh={setRefresh}
          scope={"search_page"}
        />
      )}
      {props.openUpdateSourceModal && (
        <MultiLeadUpdateSourceModal
          open={props.openUpdateSourceModal}
          setOpen={props.setOpenUpdateSourceModal}
          selectedLeadIdsData={selectedLeadIDs}
          setRefresh={setRefresh}
          scope={"search_page"}
        />
      )}
      {props.openUpdateFieldModal && (
        <MultiLeadUpdateFieldModal
          open={props.openUpdateFieldModal}
          setOpen={props.setOpenUpdateFieldModal}
          selectedLeadIdsData={selectedLeadIDs}
          setRefresh={setRefresh}
          scope={"search_page"}
        />
      )}
      {props.openUpdateStatusModal && (
        <MultiLeadUpdateStatusModal
          open={props.openUpdateStatusModal}
          setOpen={props.setOpenUpdateStatusModal}
          selectedLeadIdsData={selectedLeadIDs}
          setRefresh={setRefresh}
          scope={"search_page"}
        />
      )}
      {props.openMoveLead && (
        <MoveProjectLeadModal
          setOpen={props.setOpenMoveLead}
          open={props.openMoveLead}
          leads={selectedLeadIDs}
          pid={pid}
        />
      )}
      <Backdrop
        open={showBackDrop}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress style={{ color: "white" }} />
      </Backdrop>
      {openCustomCommentDialog && (
        <DialogWithCommentField
          open={openCustomCommentDialog}
          setOpen={setOpenCustomCommentDialog}
          handleSubmitButton={(value) => {
            handleResetFollowupForLeads(value);
            setOpenCustomCommentDialog(false);
          }}
        />
      )}

      {props.openSetLeadColorDialog && (
        <SetMultiLeadColorDialog
          leadIDs={selectedLeadIDs}
          open={props.openSetLeadColorDialog}
          setOpen={props.setOpenSetLeadColorDialog}
          setRefresh={setRefresh}
        />
      )}

      {/* lead context menu related components */}
      {leadContextAnchor !== null && (
        <LeadContextMenu
          anchor={leadContextAnchor}
          setAnchor={setLeadContextAnchor}
          handleClose={() => {
            setLeadContextAnchor(null);
          }}
          handleMenuClick={(value) => {
            if (value === "set_lead_color") {
              setOpenIndividualSetLeadColorDialog(true);
              setLeadContextAnchor(null);
            } else if (value === "add_call_log") {
              setOpenCallLogDialog(true);
              setLeadContextAnchor(null);
            } else if (value === "add_remarks") {
              setOpenAddRemarksDialog(true);
              setLeadContextAnchor(null);
            }
          }}
          isOpenCallLogDisabled={() =>
            isOpenCallLogContextMenuDisabled({ leadData: selectedLeadData })
          }
          isSetLeadColorContextMenuDisabled={() =>
            isSetLeadColorContextMenuDisabled({ leadData: selectedLeadData })
          }
        />
      )}

      {openIndividualSetLeadColorDialog && (
        <SetLeadColorDialog
          open={openIndividualSetLeadColorDialog}
          setOpen={setOpenIndividualSetLeadColorDialog}
          setRefresh={setRefresh}
          leadID={selectedLeadData["id"]}
        />
      )}

      {openCallLogDialog && (
        <CallLogModal
          scope={"lead_listing"}
          open={openCallLogDialog}
          setOpen={setOpenCallLogDialog}
          lid={selectedLeadData["id"]}
          uid={localStorage.getItem("uid")}
          setRefreshCallLogAfterAdd={() => {}}
          setRefreshLeadDetails={setRefresh}
          leadData={selectedLeadData}
        />
      )}

      {openUpdateStatusDialog && (
        <UpdateStatusModal
          selectedLeadStatus={selectedLeadStatus}
          scope={"lead_listing_page"}
          id={selectedLeadData["id"]}
          open={openUpdateStatusDialog}
          setOpen={setOpenUpdateStatusDialog}
          data={selectedLeadData}
          setRefreshLeadDetails={() => {}}
          handleRefreshLeadListing={(leadData) => {
            reFreshLeadListingLocally({ leadData: leadData });
          }}
        />
      )}

      {props.openExportLeadDialog && (
        <ExportMultiLeadDialog
          leadIDs={selectedLeadIDs}
          open={props.openExportLeadDialog}
          setOpen={props.setOpenExportLeadDialog}
        />
      )}

      {openAddRemarksDialog && (
        <AddLeadRemarksDialog
          open={openAddRemarksDialog}
          setOpen={setOpenAddRemarksDialog}
          leadData={selectedLeadData}
          handleRefreshLeadListing={(leadData) => {
            reFreshLeadListingLocally({ leadData: leadData });
          }}
        />
      )}

      {/* end */}
    </>
  );
};

export default observer(FilteredTab);
