import {
  Box,
  Button,
  FormControl,
  Grid,
  makeStyles,
  Modal,
  Select,
  AppBar,
  Toolbar,
  Typography,
  Divider,
} from "@material-ui/core";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import rootStore from "../../stores/RootStore";
import {
  IsoToLocalDate,
  IsoToLocalTime,
  getLocalizedText,
  isDateInRange,
  isDateTimeInRange,
} from "../../Functions";
import { titleCase } from "title-case";
import { MenuItem } from "@mui/material";
import { myTheme } from "../../themeUtils";
import { updateMultipleLeadFieldApi } from "../../Api";
import MultiSelectField from "../custom_field_components/MultiSelectField";
import NumberField from "../custom_field_components/NumberField";
import DateField from "../custom_field_components/DateField";
import DateTimeField from "../custom_field_components/DateTimeField";
import SearchableCustomSelectField from "../select_dropdown/SearchableCustomSelectField";
import analytics from "../../Analytics";
import MuiAlert from "@mui/material/Alert";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "12px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const ModelContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "0px",
  bottom: "0px",
  left: "0px",
  right: "0px",
  margin: "auto",
  height: "fit-content",
  width: "fit-content",
  border: "none",
  outline: "none",
  backgroundColor: "white",
  boxShadow: "0px 3px 20px #185dd21f",
  borderRadius: "8px",
  border: "1px solid #c5d7f1",
  overflow: "hidden",
}));

const HeaderWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "200px",
  [theme.breakpoints.down("sm")]: {
    width: "280px",
  },
}));

const Header = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 18px Open Sans",
  color: "#4d4e4f",
}));

const SubHeader = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 14px Open Sans",
  color: "#6f6f6f",
  marginTop: "2px",
}));

const Form = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "16px",
  width: "420px",
}));

const Label = styled("span")(({ theme }) => ({
  font: "normal normal 600 10px Open Sans",
  color: "#4d4e4f",
  marginBottom: "6px",
}));

const ButtonWrapper = styled("div")(({ theme }) => ({
  marginTop: "30px",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
}));

const Input = styled("input")(({ theme }) => ({
  width: "100%",
  height: "38px",
  padding: "2px 6px",
  outline: "none",
  border: "1px solid #e0deca",
  borderRadius: "4px",
  font: "normal normal 600 14px Open Sans",
  color: "#4d4e4f",
  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: "0",
  },
  "&[type='number']": {
    MozAppearance: "textfield",
  },
  "&::placeholder": {
    font: "normal normal normal 14px Open Sans",
    color: "#b5b5b5",
  },
  [theme.breakpoints.down("sm")]: {
    width: "280px",
  },
}));

const TextArea = styled("textarea")(({ theme }) => ({
  width: "100%",
  height: "60px",
  padding: "4px 12px",
  outline: "none",
  border: "1px solid #e0deca",
  color: "#4d4e4f",
  borderRadius: "4px",
  resize: "none",
  font: "normal normal 600 14px Open Sans",
  "&::placeholder": {
    font: "normal normal normal 14px Open Sans",
    color: "#b5b5b5",
  },
}));

const SelectColumn = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    width: "280px",
  },
}));

const InputWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const MultiLeadUpdateFieldModal = (props) => {
  const classes = useStyles();
  const [details, setDetails] = useState({});
  const [selectedFieldItem, setSelectedFieldItem] = useState({});
  const [filteredColumnsList, setFilteredColumnsList] = useState([]);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [loading, setLoading] = useState(true);
  const [btnText, setBtnText] = useState("Update");
  const columnsList = [...rootStore.userStore.AllColumnsList];
  const [errorMessage, setErrorMessage] = useState("");

  const init = async () => {
    let tempColumns = [];
    for (let entry of columnsList) {
      if (
        rootStore.userStore.isColumnVisible(entry) &&
        rootStore.userStore.isColumnEditable(entry) &&
        (entry["contact_column"] === null || entry["contact_column"] === "") &&
        entry["show_in_edit_form"] &&
        entry["derived"] === false &&
        entry["child_of"] === null &&
        entry["parent"] === false
      ) {
        tempColumns.push(entry);
      }
    }
    tempColumns.sort((a, b) =>
      a["headerName"].toLowerCase().localeCompare(b["headerName"].toLowerCase())
    );
    setFilteredColumnsList(tempColumns);
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  const updateField = async () => {
    if (validateFieldsWithRange()) {
      setLoading(true);
      setBtnText("Loading...");
      const key = selectedFieldItem["field"];
      let newValue = {};
      let newCustomValue = {};

      //setting custom and regular values based on data type of field
      if (details[key]["type"] === "integer") {
        let value = parseInt(details[key]["value"]);
        if (selectedFieldItem["is_custom_param"]) {
          let new_key_name = key.replace("custom_params_", "");
          newCustomValue[new_key_name] = value;
        } else {
          newValue[key] = value;
        }
      } else if (details[key]["type"] === "array") {
        let tempList = [];
        details[key]["value"].forEach((item) => {
          tempList.push(item["value"]);
        });
        if (selectedFieldItem["is_custom_param"]) {
          let new_key_name = key.replace("custom_params_", "");
          newCustomValue[new_key_name] = tempList.join(","); //comma separated string for array type
        } else {
          newValue[key] = tempList.join(",");
        }
      } else {
        let value = details[key]["value"];
        if (selectedFieldItem.hasOwnProperty("set_title_case")) {
          if (selectedFieldItem["set_title_case"] === true) {
            value = titleCase(value);
          }
        }
        if (selectedFieldItem["is_custom_param"]) {
          let new_key_name = key.replace("custom_params_", "");
          newCustomValue[new_key_name] = value;
        } else {
          newValue[key] = value;
        }
      }

      let payload = {};

      //if it is a custom param, sending it in "custom_param" key in payload
      if (selectedFieldItem["is_custom_param"]) {
        payload["custom_params"] = { ...newCustomValue }; // // example final payload: {custom_params: {json_field: "test1"}}
        //else sending it in top level (along with lead_id)
      } else {
        payload = { ...newValue }; // example: {utm_source: "t1"}
      }

      // always sending the payload inside "updated_fields"
      payload["updated_fields"] = {
        ...payload,
      };

      payload["lead_ids"] = props.selectedLeadIdsData; // sending lead_ids array in top level

      let response = await updateMultipleLeadFieldApi({
        payload: payload,
      });
      if (response.hasError()) {
        console.log(response.errorMessage);
        setBtnText("Update");
        setLoading(false);
      } else {
        setBtnText("Updated");
        setTimeout(() => {
          props.setRefresh(true);
          props.setOpen(false);
          setLoading(false);
        }, [800]);
      }
    } else {
      setIsSubmitFail(true);
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();

    await updateField();
  };
  const handleChange = ({ fieldName, fieldValue, type }) => {
    const updatedValue = {
      [fieldName]: {
        value: fieldValue,
        type: type,
      },
    };

    setDetails(updatedValue);
  };
  const handleClose = () => {
    props.setOpen(false);
  };

  useEffect(() => {
    analytics.triggerEvent(
      4624999885,
      "update_field_form_load",
      "leads_page",
      "",
      { order: props.scope }
    );
  }, []);

  const inRange = (num, min, max) => num >= min && num <= max;
  const validateFieldsWithRange = () => {
    let columnMeta = selectedFieldItem;
    const key = selectedFieldItem.field;
    if (
      columnMeta["min"] !== null &&
      columnMeta["max"] !== null &&
      columnMeta["min"] !== "" &&
      columnMeta["max"] !== "" &&
      details[key]["value"] !== null &&
      details[key]["value"] !== ""
    ) {
      if (columnMeta["data_type"] === "integer") {
        if (
          !inRange(
            parseFloat(details[key]["value"]),
            parseFloat(columnMeta["min"]),
            parseFloat(columnMeta["max"])
          )
        ) {
          setErrorMessage(
            `Please ensure the '${columnMeta["headerName"]}' value is between ${columnMeta["min"]} and ${columnMeta["max"]}.`
          );
          return false;
        }
      } else if (columnMeta["data_type"] === "datetime") {
        if (
          !isDateTimeInRange(
            columnMeta["min"],
            columnMeta["max"],
            details[key]["value"]
          )
        ) {
          setErrorMessage(
            `Please ensure the '${
              columnMeta["headerName"]
            }' value is between ${IsoToLocalTime(
              columnMeta["min"]
            )} and ${IsoToLocalTime(columnMeta["max"])}.`
          );
          return false;
        }
      } else if (columnMeta["data_type"] === "date") {
        if (
          !isDateInRange(
            columnMeta["min"],
            columnMeta["max"],
            details[key]["value"]
          )
        ) {
          setErrorMessage(
            `Please ensure the '${
              columnMeta["headerName"]
            }' value is between ${IsoToLocalDate(
              columnMeta["min"]
            )} and ${IsoToLocalDate(columnMeta["max"])}.`
          );
          return false;
        }
      }
    }

    return true;
  };

  const getParentFieldValue = (parent_field_info) => {
    let index = columnsList.findIndex(
      (column) => column.id === parent_field_info.id
    );
    if (index !== -1) {
      let target_column = columnsList[index];
      if (details.hasOwnProperty(target_column["field"])) {
        return {
          value: details[target_column["field"]],
          field_data: target_column,
        };
      }
    }
    return null;
  };

  const getRegex = (item) => {
    if (item.regex === null || item.regex === "") {
      return null;
    } else {
      return item.regex;
    }
  };

  const RenderLabel = ({ item }) => {
    return (
      <Label style={{ color: "#4d4e4f" }}>
        {item.headerName}
        {item.required && <span style={{ color: "red" }}>*</span>}
      </Label>
    );
  };

  const renderField = ({ item }) => {
    if (item["is_array"] === true) {
      let list = [];
      if (item["filter_input"] !== null && item["filter_input"] !== "") {
        let tempList = item["filter_input"].split(",");
        tempList.forEach((str) => {
          let tempObj = { label: str, value: str };
          list.push(tempObj);
        });
      }

      return (
        <Grid item xs={12}>
          <InputWrapper>
            <MultiSelectField
              handleChange={(selected) => {
                handleChange({
                  fieldName: item.field,
                  fieldValue: selected,
                  type: "array",
                });
              }}
              list={list}
              value={
                details.hasOwnProperty(item.field)
                  ? details[item.field]["value"]
                  : []
              }
            />
          </InputWrapper>
        </Grid>
      );
    } else {
      if (item.filter_input === "" || item.filter_input === null) {
        if (item.is_multiline === false) {
          if (item["data_type"] === "integer") {
            return (
              <Grid item xs={12}>
                <InputWrapper>
                  <NumberField
                    fontSize="14px"
                    value={
                      details.hasOwnProperty(item.field)
                        ? details[item.field]["value"]
                        : ""
                    }
                    name={item.field}
                    placeholder={
                      item.hint !== null && item.hint !== ""
                        ? item.hint
                        : `Enter ${item.headerName}`
                    }
                    pattern={getRegex(item)}
                    required={true}
                    onInvalid={(e) =>
                      e.target.setCustomValidity(
                        `Please enter a valid ${item.headerName}`
                      )
                    }
                    handleChange={(e) => {
                      e.target.setCustomValidity("");
                      handleChange({
                        fieldName: e.target.name,
                        fieldValue: e.target.value,
                        type: item.data_type,
                      });
                    }}
                    handleBlur={() => {}}
                  />
                </InputWrapper>
              </Grid>
            );
          } else if (item["data_type"] === "date") {
            return (
              <Grid item xs={12}>
                <InputWrapper>
                  <DateField
                    value={
                      details.hasOwnProperty(item.field) &&
                      details[item.field]["value"] !== ""
                        ? details[item.field]["value"]
                        : null
                    }
                    onChange={(event) => {
                      handleChange({
                        fieldName: item["field"],
                        fieldValue: event,
                        type: item["data_type"],
                      });
                    }}
                    required={true}
                  />
                </InputWrapper>
              </Grid>
            );
          } else if (item["data_type"] === "datetime") {
            return (
              <Grid item xs={12}>
                <InputWrapper>
                  <DateTimeField
                    value={
                      details.hasOwnProperty(item.field) &&
                      details[item.field]["value"] !== ""
                        ? details[item.field]["value"]
                        : null
                    }
                    onChange={(event) => {
                      handleChange({
                        fieldName: item["field"],
                        fieldValue: event,
                        type: item["data_type"],
                      });
                    }}
                    required={true}
                  />
                </InputWrapper>
              </Grid>
            );
          } else {
            return (
              <Grid item xs={12}>
                <InputWrapper>
                  <Input
                    type={item.data_type === "email" ? "email" : "text"}
                    value={
                      details.hasOwnProperty(item.field)
                        ? details[item.field]["value"]
                        : ""
                    }
                    name={item.field}
                    placeholder={
                      item.hint !== null && item.hint !== ""
                        ? item.hint
                        : `Enter ${item.headerName}`
                    }
                    pattern={getRegex(item)}
                    required={true}
                    onInvalid={(e) =>
                      e.target.setCustomValidity(
                        `Please enter a valid ${item.headerName}`
                      )
                    }
                    onChange={(e) => {
                      e.target.setCustomValidity("");
                      handleChange({
                        fieldName: e.target.name,
                        fieldValue: e.target.value,
                        type: item.data_type,
                      });
                    }}
                  />
                </InputWrapper>
              </Grid>
            );
          }
        } else {
          return (
            <Grid item xs={12}>
              <InputWrapper>
                <TextArea
                  value={
                    details.hasOwnProperty(item.field)
                      ? details[item.field]["value"]
                      : ""
                  }
                  name={item.field}
                  placeholder={
                    item.hint !== null && item.hint !== ""
                      ? item.hint
                      : `Enter ${item.headerName}`
                  }
                  pattern={getRegex(item)}
                  required={true}
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      `Please enter a valid ${item.headerName}`
                    )
                  }
                  onChange={(e) => {
                    e.target.setCustomValidity("");
                    handleChange({
                      fieldName: e.target.name,
                      fieldValue: e.target.value,
                      type: item.data_type,
                    });
                  }}
                />
              </InputWrapper>
            </Grid>
          );
        }
      } else {
        if (item["map_to_parent_selection"]) {
          let parent_field_info = item.child_of;
          let parent_data = getParentFieldValue(parent_field_info); //getting the data of parent field including value
          let parent_value = parent_data !== null ? parent_data["value"] : null;
          let is_required =
            parent_data !== null
              ? parent_data["field_data"]["required"]
              : false;

          return (
            <Grid item xs={12}>
              <InputWrapper>
                <SearchableCustomSelectField
                  columnMeta={item}
                  handleChange={(value) => {
                    handleChange({
                      fieldName: item["field"],
                      fieldValue: value,
                      type: item["data_type"],
                    });
                  }}
                  value={
                    details.hasOwnProperty(item.field) &&
                    details[item.field]["value"] !== null
                      ? details[item.field]["value"]
                      : ""
                  }
                  mapToParentSelection={true}
                  isRequired={is_required}
                  parentValue={parent_value}
                />
              </InputWrapper>
            </Grid>
          );
        } else {
          return (
            <Grid item xs={12}>
              <InputWrapper>
                <SearchableCustomSelectField
                  columnMeta={item}
                  handleChange={(value) => {
                    handleChange({
                      fieldName: item["field"],
                      fieldValue: value,
                      type: item["data_type"],
                    });
                  }}
                  value={
                    details.hasOwnProperty(item.field) &&
                    details[item.field]["value"] !== null
                      ? details[item.field]["value"]
                      : ""
                  }
                  isRequired={true}
                />
              </InputWrapper>
            </Grid>
          );
        }
      }
    }
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer component={"form"} onSubmit={handleUpdate}>
          <AppBar
            component={"nav"}
            elevation={0}
            position="sticky"
            color="inherit"
            sx={{
              top: 0,
              bottom: "auto",
              width: "100%",
              right: 0,
            }}
          >
            <Toolbar
              style={{
                padding: "16px",
              }}
            >
              <HeaderWrapper>
                <Header>{getLocalizedText("update_lead_field")}</Header>
                <SubHeader>
                  {getLocalizedText("select_from_below_dropdown")}
                </SubHeader>
              </HeaderWrapper>
            </Toolbar>
          </AppBar>
          <Divider style={{ margin: "0px 16px" }} />

          <Box>
            <Box>
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <SelectColumn>
                      <Label>{getLocalizedText("select_field")}*</Label>
                      <FormControl
                        sx={{ width: "100%" }}
                        classes={{
                          root: classes.quantityRoot,
                        }}
                      >
                        <Select
                          classes={{
                            icon: classes.icon,
                          }}
                          name="field"
                          value={selectedFieldItem["id"] || ""}
                          displayEmpty
                          disableUnderline
                          variant="outlined"
                          required
                          onChange={(e) => {
                            const id = e.target.value;
                            const index = filteredColumnsList.findIndex(
                              (column) => column.id === id
                            );
                            if (index !== -1) {
                              setSelectedFieldItem(filteredColumnsList[index]);
                            }
                          }}
                          style={{
                            width: "100%",
                            height: "30px",
                            fontSize: "12px",
                            fontWeight: "bold",
                            borderRadius: "0px",
                          }}
                        >
                          {filteredColumnsList.map((column, index) => {
                            return (
                              <MenuItem key={index} value={column["id"]}>
                                {column["headerName"]}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </SelectColumn>
                  </Grid>
                  <Grid item xs={12}>
                    {Object.keys(selectedFieldItem).length > 0 &&
                      renderField({ item: selectedFieldItem })}
                  </Grid>
                  {/* 
                  <Grid item xs={12}>
                    <SelectColumn>
                      <Label>Comments</Label>
                      <OutlinedInput
                        multiline={true}
                        rows={3}
                        inputProps={{
                          min: 0,
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        value={details.comments}
                        name={"comments"}
                        type={"text"}
                        placeholder={"Enter Value"}
                        onChange={handleChange}
                      />
                    </SelectColumn>
                  </Grid> */}
                </Grid>
              </Form>
              <AppBar
                position="static"
                color="inherit"
                elevation={0}
                sx={{ top: "auto", bottom: 0, width: "100%" }}
              >
                <Toolbar
                  style={{
                    padding: "16px",
                    width: 420,
                  }}
                >
                  {isSubmitFail && (
                    <ErrorAlert
                      onClose={(e) => {
                        e.stopPropagation();
                        setIsSubmitFail(false);
                      }}
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      {errorMessage}
                    </ErrorAlert>
                  )}
                  <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item>
                      <Button
                        onClick={() => {
                          props.setOpen(false);
                        }}
                        type="button"
                        variant="contained"
                        color="default"
                        style={{
                          backgroundColor: "#EFEFF4",
                          textTransform: "none",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        {getLocalizedText("cancel")}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        disabled={loading}
                        type="submit"
                        variant="contained"
                        style={{
                          textTransform: "none",
                          fontSize: "12px",
                          fontWeight: "bold",
                          ...myTheme.Button.btnBlue,
                        }}
                      >
                        {btnText}
                      </Button>
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
            </Box>
          </Box>
        </ModelContainer>
      </Modal>
    </>
  );
};

export default MultiLeadUpdateFieldModal;
