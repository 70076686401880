import React, { useEffect } from "react";
import { useState } from "react";
import { Box, Drawer } from "@material-ui/core";
import SettingsIcon from "@mui/icons-material/Settings";
import { styled, Typography, Switch, IconButton } from "@mui/material";
import LoadingAnimationComponent from "../loading_component/LoadingAnimationComponent";
import { getLocalizedText } from "../../Functions";
import CreatePinToLocationSidepanel from "./CreatePinToLocationSidepanel";
import {
  disableAdminSecurityLoginLocation,
  enableAdminSecurityLoginLocation,
  getAdminSecurityLoginLocationSettings,
} from "../../Api";
import { titleCase } from "title-case";

const Container = styled(Box)`
  width: 100%;
  position: relative;
`;
const Wrapper = styled(Box)`
  display: flex;
`;
const SectionWrapper = styled(Box)`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  background-color: #f9f9fc;
  padding: 20px;
`;
const Row = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: "12px";
`;
const SectionsContainer = styled(Box)`
  width: 100%;
  background-color: white;
  border-radius: 10px;
  display: flex;
  height: 100%;
  column-gap: 20px;
  row-gap: 20px;
`;
const DetailsHeader = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #979797;
  text-transform: uppercase;
`;
const Title = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  color: #4d4e4f;
  text-align: left;
`;
const HeaderWrapper = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  column-gap: 20px;
`;
const Description = styled(Typography)`
  font: normal normal 500 14px Open Sans;
  color: #4d4e4f;
  text-align: left;
  word-wrap: break-word;
`;
const PinToLocationRow = (props) => {
  const [data, setData] = useState({
    id: "",
    enabled: false,
    roles: [],
    location: null,
  });
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const response = await getAdminSecurityLoginLocationSettings();
    setData(response);
    setLoading(false);
  };

  useEffect(() => {
    if (refresh) {
      setLoading(true);
      init();
      setRefresh(false);
    }
  }, [refresh]);

  const handlePinToLocationCheck = async (e) => {
    let bool = e.target.checked;
    if (bool) {
      const response = await enableAdminSecurityLoginLocation({
        id: data["id"],
      });
    } else {
      const response = await disableAdminSecurityLoginLocation({
        id: data["id"],
      });
    }
    setData({
      ...data,
      enabled: bool,
    });
  };

  const checkIfCreated = () => {
    return data["id"] !== undefined && data["id"] !== null && data["id"] !== "";
  };

  const getDataForSidepanel = () => {
    let locationData = {};
    if (data.location) {
      locationData = {
        label: data.location["location_name"],
        value: data.location["id"],
      };
    }
    let roles = [];

    let rolesData = [];
    if (data.roles) {
      roles = [...data.roles];
      rolesData = roles.map((entry) => ({
        label: titleCase(entry),
        value: entry,
      }));
    }
    return {
      roles: rolesData,
      location: locationData,
    };
  };

  return !loading ? (
    <>
      <Container>
        <Wrapper>
          <SectionsContainer>
            <SectionWrapper>
              <HeaderWrapper>
                <DetailsHeader>
                  {getLocalizedText(props.rowData.type)}
                </DetailsHeader>
              </HeaderWrapper>
              <Row>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Title>{getLocalizedText(props.rowData.title)}</Title>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Switch
                      disabled={!checkIfCreated()}
                      onClick={(e) => {
                        handlePinToLocationCheck(e);
                      }}
                      checked={data.enabled}
                    />
                    <IconButton
                      color="primary"
                      onClick={() => {
                        props.setOpenSettings(true);
                      }}
                    >
                      <SettingsIcon
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  </Box>
                </Box>
                <Description>
                  {getLocalizedText(props.rowData.desc)}
                </Description>
              </Row>
            </SectionWrapper>
          </SectionsContainer>
        </Wrapper>
      </Container>
      {props.openSettings && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={props.openSettings}
          onClose={() => {
            props.setOpenSettings(false);
          }}
        >
          <CreatePinToLocationSidepanel
            setOpen={props.setOpenSettings}
            open={props.openSettings}
            data={checkIfCreated() ? getDataForSidepanel() : data}
            setData={setData}
            isCreated={checkIfCreated()}
            setRefresh={setRefresh}
            refresh={refresh}
          />
        </Drawer>
      )}
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "118px",
      }}
    >
      <LoadingAnimationComponent size={"small"} />
    </Box>
  );
};

export default PinToLocationRow;
