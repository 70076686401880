import React, { useEffect } from "react";

import { observer } from "mobx-react-lite";
import { Box, Grid, Typography } from "@mui/material";
import { getLocalizedText } from "../../Functions";
import { styled } from "@mui/material/styles";

const InputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 428px;
  gap: 20px;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Input = styled("input")`
  width: 100%;
  height: 30px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;
  border: 1px solid #bfbfbf;
  font: normal normal 600 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
`;

const Info = styled(Typography)`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Detail = styled(Typography)`
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;

const OfflineCampaign = (props) => {
  useEffect(() => {
    props.setCampaignDateRange({ start_date: "", end_date: "" });
  }, []);

  const handleChange = (e) => {
    props.setCampaignDateRange({
      ...props.campaignDateRange,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <InputContainer>
        <Grid container rowSpacing={3} columnSpacing={3}>
          <Grid item xs={6}>
            <InputWrapper>
              <Info>Start Date </Info>
              <Input
                onChange={handleChange}
                name="start_date"
                type="date"
                value={props.campaignDateRange["start_date"]}
                max={props.campaignDateRange["end_date"]}
              />
            </InputWrapper>
          </Grid>
          <Grid item xs={6}>
            <InputWrapper>
              <Info>End Date </Info>
              <Input
                onChange={handleChange}
                name="end_date"
                type="date"
                disabled={props.campaignDateRange["start_date"] === ""}
                value={props.campaignDateRange["end_date"]}
                min={props.campaignDateRange["start_date"]}
              />
            </InputWrapper>
          </Grid>
        </Grid>
      </InputContainer>
    </>
  );
};

export default observer(OfflineCampaign);
