import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";
import { Box, CircularProgress, Drawer, Switch } from "@material-ui/core";
import { Add, ChevronRight } from "@material-ui/icons";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { adminQuationsPageColumnHeaders } from "../../Db";

import rootStore from "../../stores/RootStore";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { pageSize } from "../../Config";
import { styled } from "@mui/material/styles";
import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import {
  activateQuotationTemplate,
  deactivateQuotationTemplate,
  getAdminQuotationsListApi,
} from "../../Api";
import AddAdminQuoteTemplateSidepanel from "../../components/add_admin_quote_template_panel/AddAdminQuoteTemplateSidepanel";
import ViewAdminQuoteTemplateSidepanel from "../../components/add_admin_quote_template_panel/ViewAdminQuoteTemplateSidepanel";
import CustomErrorMessageDialog from "../../components/alert_dialogue/CustomErrorMessageDialog";
import SuccessMessageDialog from "../../components/alert_dialogue/SuccessMessageDialog";
import CloneAdminQuoteTemplateSidepanel from "../../components/add_admin_quote_template_panel/CloneAdminQuoteTemplateSidepanel";

const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 75px);`};
  margin: auto;
  padding-top: 64px;
  width: 95%;
`;

const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: flex-end;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const Row = styled(Box)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 16px 0px;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 208px);`};
  background-color: white;
  padding: 20px;
  position: relative;
`;
const AdminProjectQuotations = () => {
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [openCreateTemplate, setOpenCreateTemplate] = useState(false);
  const [openCloneTemplate, setOpenCloneTemplate] = useState(false);
  const [openViewTemplate, setOpenViewTemplate] = useState(false);
  const [selectedTemplateData, setSelectedTemplateData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const teamID =
    rootStore.authStore.teamId !== null
      ? rootStore.authStore.teamId !== "All"
        ? rootStore.authStore.teamId
        : ""
      : null;
  const projectID = rootStore.authStore.projectId;
  const getQuotationList = async () => {
    setRow([]);
    let response = await getAdminQuotationsListApi({
      projectID: projectID,
      teamID: teamID,
    });
    setRow(response);
  };

  const init = async () => {
    setLoading(true);
    await getQuotationList();
    setLoading(false);
  };

  useEffect(() => {
    if (refresh === true) {
      init();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    init();
    rootStore.authStore.updateLeadDetailStatus();
  }, []);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const handleActiveSwitch = async ({ flag, id }) => {
    let response = {};
    if (flag) {
      response = await deactivateQuotationTemplate({
        projectID: rootStore.authStore.projectId,
        quotationTemplateID: id,
      });
    } else {
      response = await activateQuotationTemplate({
        projectID: rootStore.authStore.projectId,
        quotationTemplateID: id,
      });
    }
    if (response.hasError()) {
      setErrorMessage(response.errorMessage);
      setShowErrorDialog(true);
    } else {
      setSuccessMessage(
        flag
          ? "Template is disabled successfully!"
          : "Template is enabled successfully!"
      );
      setShowSuccessDialog(true);
      setRefresh(true);
    }
  };

  const actionColumns = [
    {
      field: "active",
      headerName: "ACTIVE",
      sortable: false,
      width: 100,
      cellRenderer: (params) => {
        return (
          <>
            <Switch
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleActiveSwitch({
                  flag: params.data.active,
                  id: params.data.id,
                });
              }}
              checked={params.data.active}
            />
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      sortable: false,
      flex: 1,
      minWidth: 100,
      cellRenderer: (params) => {
        return (
          <>
            <Tooltip title="Clone">
              <IconButton
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSelectedTemplateData(params.data);
                  setOpenCloneTemplate(true);
                }}
              >
                <ContentCopyIcon
                  style={{
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                  }}
                />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <>
      <ParentContainer>
        {!loading ? (
          <Container>
            <Row>
              <TopWrapper>
                <Header>Project Administration</Header>
                <ChevronRight style={{ width: "16px", height: "16px" }} />
                <Header>Quotations</Header>
              </TopWrapper>
              <Button
                onClick={() => {
                  setOpenCreateTemplate(true);
                }}
                variant="contained"
                style={{
                  backgroundColor: "#185DD2",
                  color: "white",
                  textTransform: "none",
                  fontSize: "12px",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                }}
              >
                <Add />
                Create Template
              </Button>
            </Row>
            <TableWrapper>
              <div
                className="ag-theme-alpine"
                style={{ width: "100%", height: "100%" }}
              >
                <AgGridReact
                  domLayout="autoHeight"
                  gridOptions={gridOptions}
                  rowData={row}
                  defaultColDef={defaultColDef}
                  columnDefs={adminQuationsPageColumnHeaders.concat(
                    actionColumns
                  )}
                  animateRows={true}
                  suppressCellFocus
                  pagination={false}
                  paginationPageSize={pageSize}
                  onRowClicked={(row) => {
                    if (row.event.defaultPrevented) {
                      return null;
                    }
                    setSelectedTemplateData(row.data);
                    setOpenViewTemplate(true);
                  }}
                />
              </div>
            </TableWrapper>
          </Container>
        ) : (
          <Container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Container>
        )}
      </ParentContainer>
      <Footer />
      {openCreateTemplate && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openCreateTemplate}
          onClose={() => {
            //setOpenCreateTemplate(false);
          }}
        >
          <AddAdminQuoteTemplateSidepanel
            setRefresh={setRefresh}
            setOpen={setOpenCreateTemplate}
            open={openCreateTemplate}
          />
        </Drawer>
      )}
      {openCloneTemplate && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openCloneTemplate}
          onClose={() => {
            //setOpenCloneTemplate(false);
          }}
        >
          <CloneAdminQuoteTemplateSidepanel
            setRefresh={setRefresh}
            setOpen={setOpenCloneTemplate}
            open={openCloneTemplate}
            selectedTemplateData={selectedTemplateData}
          />
        </Drawer>
      )}
      {openViewTemplate && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openViewTemplate}
          onClose={() => {
            setOpenViewTemplate(false);
          }}
        >
          <ViewAdminQuoteTemplateSidepanel
            details={selectedTemplateData}
            handleActiveSwitch={handleActiveSwitch}
            handleClose={() => {
              setOpenViewTemplate(false);
            }}
          />
        </Drawer>
      )}
      {showErrorDialog && (
        <CustomErrorMessageDialog
          open={showErrorDialog}
          setOpen={setShowErrorDialog}
          message={errorMessage}
        />
      )}
      {showSuccessDialog && (
        <SuccessMessageDialog
          open={showSuccessDialog}
          setOpen={setShowSuccessDialog}
          message={successMessage}
        />
      )}
    </>
  );
};

export default observer(AdminProjectQuotations);
