import React, { useEffect } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import Footer from "../../components/landing_page_components/footer/Footer";
import rootStore from "../../stores/RootStore";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  min-height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  // background-color: #f9f9fc;
  justify-content: center;
  margin: 0;
  margin-top: 70px;
  width: 100%;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${`min-height: calc(100vh - 139px);`};
`;
const Text = styled.p`
  margin: 0px;
  font: normal normal 600 22px Open Sans;
  color: #4d4e4f;
`;
const NoAccessForLocationPage = () => {
  let navigate = useNavigate();
  const { authStore } = rootStore;

  useEffect(() => {
    if (authStore.isLocationValid) {
      if (authStore.canRedirectToDahboard()) {
        navigate("/summary");
      } else {
        navigate("/");
      }
    }
  }, []);

  return (
    <>
      <Container>
        <Wrapper>
          <Text>
            Important: App Access is permitted only within the designated area.
            Please move closer to gain access!
          </Text>
        </Wrapper>
        <Footer />
      </Container>
    </>
  );
};

export default observer(NoAccessForLocationPage);
