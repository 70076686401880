import React, { useEffect, useState } from "react";
import {
  Page,
  Document,
  StyleSheet,
  View,
  Text,
  Font,
  Link,
  Image,
} from "@react-pdf/renderer";
import TableHeader from "./TableHeader";
import {
  IsoToLocalDate,
  consoleLogger,
  dataRenderer,
} from "../../../../Functions";
import TableRow from "./TableRow";
import TableNoRows from "./TableNoRows";
import Header from "./Header";
import { observer } from "mobx-react-lite";
import Markdown from "markdown-to-jsx";

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/poppins/v21/pxiEyp8kv8JHgFVrFJA.ttf",
      fontWeight: 400,
      fontStyle: "normal",
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLGT9V1s.ttf",
      fontWeight: 500,
      fontStyle: "normal",
    },
    {
      src: "https://fonts.gstatic.com/s/poppins/v21/pxiByp8kv8JHgFVrLCz7V1s.ttf",
      fontWeight: 700,
      fontStyle: "bold",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    border: "1px solid #D3D3D3",
    padding: "16px",
    paddingBottom: "0px",
    display: "flex",
    justifyContent: "space-between",
  },
  colanText: {
    fontSize: 8,
    fontWeight: "700",
    color: "black",
    fontFamily: "Poppins",
  },

  contentWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  footerWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    marginTop: "16px",
    marginBottom: 5,
  },
  columnWrapper: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    gap: "8px",
  },
  horizontalDivider: {
    height: "1px",
    width: "100%",
    backgroundColor: "#D3D3D3",
  },
  verticalDivider: {
    width: "1px",
    backgroundColor: "#D3D3D3",
    alignSelf: "stretch",
  },
  rowWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: "16px",
  },
  addressWrapper: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "row",
    gap: "2px",
  },
  labelText: {
    fontSize: 8,
    fontWeight: "400",
    color: "black",
    width: "100%",
    fontFamily: "Poppins",
    marginRight: "8px",
  },
  detailText: {
    fontSize: 8,
    fontWeight: "700",
    color: "black",
    width: "60%",
    fontFamily: "Poppins",
  },
  amountRowWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: "8px",
  },
  text1: {
    fontSize: "10px",
    fontWeight: "700",
    color: "black",
    fontFamily: "Poppins",
  },
  footerText: {
    fontSize: 8,
    fontWeight: "500",
    color: "gray",
    fontFamily: "Poppins",
    textAlign: "center",
  },

  amountLabelText: {
    fontSize: 8,
    fontWeight: "400",
    color: "black",
    width: "100px",
    fontFamily: "Poppins",
    textAlign: "right",
  },
  amountDetailText: {
    fontSize: 8,
    fontWeight: "700",
    color: "black",
    fontFamily: "Poppins",
    textAlign: "right",
  },
  titleText: {
    fontSize: "22px",
    fontWeight: "700",
    color: "black",
    fontFamily: "Poppins",
  },

  headerText: {
    fontSize: "10px",
    fontWeight: "700",
    color: "black",
    fontFamily: "Poppins",
  },
  pageContainer: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid #D3D3D3",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  plainTextWrapper: {
    padding: "8px 8px",
  },
  BottomEmptyBox: {
    border: "1px solid #D3D3D3",
    height: "8px",
    borderBottom: "none",
  },
  topEmptyBox: {
    border: "1px solid #D3D3D3",
    height: "8px",
    borderTop: "none",
  },
  //markdown styles
  listItem: {
    fontSize: "12px",
    fontWeight: "400",
    fontFamily: "Poppins",
    marginLeft: 10,
    marginBottom: 5,
  },
  p: {
    fontSize: 8,
    fontWeight: "400",
    color: "black",
    fontFamily: "Poppins",
    marginRight: "8px",
    margin: 10,
  },
  pFooter: {
    fontSize: 8,
    fontWeight: "500",
    color: "gray",
    fontFamily: "Poppins",
    textAlign: "center",
    marginRight: "8px",
    margin: 10,
  },
  strong: {
    fontSize: 8,
    fontWeight: "600",
    color: "black",
    fontFamily: "Poppins",
    marginRight: "8px",
  },
  em: {
    fontFamily: "Helvetica-Oblique",
    fontSize: 8,
    fontStyle: "italic",
  },
  del: {
    fontFamily: "Poppins",
    fontSize: 8,
    textDecoration: "line-through",
  },
  link: {
    fontFamily: "Poppins",
    fontSize: 8,
  },
  hr: {
    borderBottom: "1pt solid black",
    marginBottom: 10,
  },
  list: {
    fontFamily: "Poppins",
    fontSize: 8,
    marginBottom: 10,
  },
  orderedListItem: {
    marginLeft: 10,
    marginBottom: 5,
  },
  h1: {
    fontFamily: "Helvetica-Bold",
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 10,
  },
  h2: {
    fontFamily: "Helvetica-Bold",
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 10,
  },
  h3: {
    fontFamily: "Helvetica-Bold",
    fontSize: 13,
    fontWeight: "bold",
    marginBottom: 10,
  },
  h4: {
    fontFamily: "Helvetica-Bold",
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 10,
  },
  h5: {
    fontFamily: "Helvetica-Bold",
    fontSize: 11,
    fontWeight: "bold",
    marginBottom: 10,
  },
  h6: {
    fontFamily: "Helvetica-Bold",
    fontSize: 10,
    fontWeight: "bold",
    marginBottom: 10,
  },
  blockquote: {
    fontFamily: "Helvetica-Oblique",
    fontSize: 10,
    fontStyle: "italic",
    marginBottom: 10,
  },
  code: {
    fontFamily: "Courier",
    fontSize: 8,
    backgroundColor: "#f0f0f0",
    padding: 5,
    borderRadius: 3,
  },
  pre: {
    fontFamily: "Courier",
    fontSize: 10,
    backgroundColor: "#f0f0f0",
    padding: 10,
    borderRadius: 5,
    marginBottom: 10,
  },
  sub: {
    fontSize: 8,
    verticalAlign: "sub",
  },
  sup: {
    fontSize: 8,
    verticalAlign: "super",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderCollapse: "collapse",
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    width: "100%",
    backgroundColor: "#f0f0f0",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    padding: 8,
  },
  tableCol: {
    width: "100%",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    padding: 8,
  },
  tableCellHeader: {
    fontSize: 10,
    fontWeight: "bold",
  },
  tableCell: {
    fontSize: 8,
  },
  tableCellRightAlign: {
    fontSize: 8,
    textAlign: "right",
  },
  img: {
    width: "420px",
  },
});

//configData - uses data (for invoice schema) if available else uses templateConfig (for template schema)
const PrintInvoiceLayout = ({ data, templateConfig, version }) => {
  const configData = version === 1 ? templateConfig.schema : data.schema; //use schema from template if v1 else use it from data
  // consoleLogger("version", version);
  // consoleLogger(data);
  // consoleLogger(configData);
  //for markdown options
  const components = {
    p: ({ children }) => {
      return <Text style={styles.p}>{children}</Text>;
    },
    a: ({ children, href }) => {
      return (
        <Link style={styles.link} src={href}>
          {children}
        </Link>
      );
    },
    br: () => {
      return (
        <Text>
          <br />
        </Text>
      );
    },
    strong: ({ children }) => {
      return <Text style={styles.strong}>{children}</Text>;
    },
    em: ({ children }) => {
      return <Text style={styles.em}>{children}</Text>;
    },
    del: ({ children }) => {
      return <Text style={styles.del}>{children}</Text>;
    },
    hr: () => {
      return <View style={styles.hr} />;
    },
    ol: ({ children }) => {
      return <View style={styles.list}>{children}</View>;
    },
    ul: ({ children }) => {
      return <View style={styles.list}>{children}</View>;
    },
    li: ({ children }) => {
      return <Text style={styles.listItem}>• {children}</Text>;
    },
    h1: ({ children }) => {
      return <Text style={styles.h1}>{children}</Text>;
    },
    h2: ({ children }) => {
      return <Text style={styles.h2}>{children}</Text>;
    },
    h3: ({ children }) => {
      return <Text style={styles.h3}>{children}</Text>;
    },
    h4: ({ children }) => {
      return <Text style={styles.h4}>{children}</Text>;
    },
    h5: ({ children }) => {
      return <Text style={styles.h5}>{children}</Text>;
    },
    h6: ({ children }) => {
      return <Text style={styles.h6}>{children}</Text>;
    },
    blockquote: ({ children }) => {
      return <Text style={styles.blockquote}>{children}</Text>;
    },
    code: ({ children }) => {
      return <Text style={styles.code}>{children}</Text>;
    },
    pre: ({ children }) => {
      return <View style={styles.pre}>{children}</View>;
    },
    img: ({ src }) => {
      return <Image style={styles.img} src={src} />;
    },
    sub: ({ children }) => {
      return <Text style={styles.sub}>{children}</Text>;
    },
    sup: ({ children }) => {
      return <Text style={styles.sup}>{children}</Text>;
    },
    table: ({ children }) => {
      return <View style={styles.table}>{children}</View>;
    },
    thead: ({ children }) => {
      return <View style={styles.tableRow}>{children}</View>;
    },
    tbody: ({ children }) => {
      return <View>{children}</View>;
    },
    tr: ({ children }) => {
      return <View style={styles.tableRow}>{children}</View>;
    },
    th: ({ children }) => {
      return (
        <View style={styles.tableColHeader}>
          <Text style={styles.tableCellHeader}>{children}</Text>
        </View>
      );
    },
    td: ({ children, align }) => {
      return (
        <View style={styles.tableCol}>
          <Text
            style={
              align === "right" ? styles.tableCellRightAlign : styles.tableCell
            }
          >
            {children}
          </Text>
        </View>
      );
    },
  };
  const footerComponents = {
    p: ({ children }) => {
      return <Text style={styles.pFooter}>{children}</Text>;
    },
    a: ({ children, href }) => {
      return (
        <Link style={styles.link} src={href}>
          {children}
        </Link>
      );
    },
    br: () => {
      return (
        <Text>
          <br />
        </Text>
      );
    },
    strong: ({ children }) => {
      return <Text style={styles.strong}>{children}</Text>;
    },
    em: ({ children }) => {
      return <Text style={styles.em}>{children}</Text>;
    },
    del: ({ children }) => {
      return <Text style={styles.del}>{children}</Text>;
    },
    hr: () => {
      return <View style={styles.hr} />;
    },
    ol: ({ children }) => {
      return <View style={styles.list}>{children}</View>;
    },
    ul: ({ children }) => {
      return <View style={styles.list}>{children}</View>;
    },
    li: ({ children }) => {
      return <Text style={styles.listItem}>• {children}</Text>;
    },
    h1: ({ children }) => {
      return <Text style={styles.h1}>{children}</Text>;
    },
    h2: ({ children }) => {
      return <Text style={styles.h2}>{children}</Text>;
    },
    h3: ({ children }) => {
      return <Text style={styles.h3}>{children}</Text>;
    },
    h4: ({ children }) => {
      return <Text style={styles.h4}>{children}</Text>;
    },
    h5: ({ children }) => {
      return <Text style={styles.h5}>{children}</Text>;
    },
    h6: ({ children }) => {
      return <Text style={styles.h6}>{children}</Text>;
    },
    blockquote: ({ children }) => {
      return <Text style={styles.blockquote}>{children}</Text>;
    },
    code: ({ children }) => {
      return <Text style={styles.code}>{children}</Text>;
    },
    pre: ({ children }) => {
      return <View style={styles.pre}>{children}</View>;
    },
    img: ({ src }) => {
      return <Image style={styles.img} src={src} />;
    },
    sub: ({ children }) => {
      return <Text style={styles.sub}>{children}</Text>;
    },
    sup: ({ children }) => {
      return <Text style={styles.sup}>{children}</Text>;
    },
    table: ({ children }) => {
      return <View style={styles.table}>{children}</View>;
    },
    thead: ({ children }) => {
      return <View style={styles.tableRow}>{children}</View>;
    },
    tbody: ({ children }) => {
      return <View>{children}</View>;
    },
    tr: ({ children }) => {
      return <View style={styles.tableRow}>{children}</View>;
    },
    th: ({ children }) => {
      return (
        <View style={styles.tableColHeader}>
          <Text style={styles.tableCellHeader}>{children}</Text>
        </View>
      );
    },
    td: ({ children, align }) => {
      return (
        <View style={styles.tableCol}>
          <Text
            style={
              align === "right" ? styles.tableCellRightAlign : styles.tableCell
            }
          >
            {children}
          </Text>
        </View>
      );
    },
  };

  const isIGST = () => {
    if (data["schema"].hasOwnProperty("company_details")) {
      if (
        data["schema"]["company_details"].hasOwnProperty("company_state") &&
        data["schema"].hasOwnProperty("place_of_supply")
      ) {
        if (
          data["schema"]["company_details"]["company_state"] ===
          data["schema"]["place_of_supply"]
        ) {
          return false;
        } else {
          return true;
        }
      }
    }
    return false;
  };

  const getValidValue = (value) => {
    if (value !== undefined && value !== null && value !== "") {
      return value;
    }
    return 0;
  };

  const getAmount = (percentage, amount) => {
    let tempPercentage = getValidValue(percentage);

    let tempAmount = getValidValue(amount);
    if (tempPercentage !== "" && tempAmount !== "") {
      return parseFloat((tempAmount * tempPercentage) / 100);
    }
    return 0;
  };

  const getTotalTaxAmount = () => {
    if (data["schema"]["items"].length !== 0) {
      let total = 0;

      data["schema"]["items"].map((item) => {
        const itemAmount = item["rate"] * item["quantity"];
        let taxAmount = (itemAmount * (item["tax"] || 0)) / 100;
        total += taxAmount;
      });
      return total;
    } else {
      return 0;
    }
  };

  const gstDetailsComponent = () => {
    if (configData === null || configData === undefined) {
      return <></>;
    }

    if (configData.hasOwnProperty("show_gst")) {
      const taxAmount = getTotalTaxAmount();
      if (configData.show_gst) {
        if (isIGST()) {
          return (
            <>
              <View style={styles.amountRowWrapper}>
                <Text style={styles.amountLabelText}>IGST (INR)</Text>
                <Text style={styles.amountDetailText}>
                  :{" "}
                  {dataRenderer(taxAmount) != "-"
                    ? dataRenderer(taxAmount).toFixed(2)
                    : "-"}
                </Text>
              </View>
            </>
          );
        } else {
          return (
            <>
              <View style={styles.amountRowWrapper}>
                <Text style={styles.amountLabelText}>CGST (INR)</Text>
                <Text style={styles.amountDetailText}>
                  :{" "}
                  {dataRenderer(taxAmount) / 2 != "-"
                    ? (dataRenderer(taxAmount) / 2).toFixed(2)
                    : "-"}
                </Text>
              </View>
              <View style={styles.amountRowWrapper}>
                <Text style={styles.amountLabelText}>SGST (INR)</Text>
                <Text style={styles.amountDetailText}>
                  :{" "}
                  {dataRenderer(taxAmount) / 2 != "-"
                    ? (dataRenderer(taxAmount) / 2).toFixed(2)
                    : "-"}
                </Text>
              </View>
            </>
          );
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const getInvoiceTitle = () => {
    if (configData === null || configData === undefined) {
      return "Invoice";
    }

    if (version === 2) {
      if (configData === null || configData === undefined) {
        return "Invoice";
      } else {
        if (
          configData["title"] === null ||
          configData["title"] === undefined ||
          configData["title"] === ""
        ) {
          return "Invoice";
        } else {
          return configData["title"];
        }
      }
    } else {
      if (configData === null || configData === undefined) {
        return "Invoice";
      } else {
        if (
          configData["invoice_title"] === null ||
          configData["invoice_title"] === undefined ||
          configData["invoice_title"] === ""
        ) {
          return "Invoice";
        } else {
          return configData["invoice_title"];
        }
      }
    }
  };

  function isMarkdown(input) {
    // Regular expressions for common Markdown elements
    const markdownPatterns = [
      /^#{1,6}\s/, // Headers (e.g., # Header, ## Header, etc.)
      /\*\*[^*]+\*\*/, // Bold (e.g., **bold text**)
      /\*[^*]+\*/, // Italic (e.g., *italic text*)
      /\[[^\]]+\]\([^)]+\)/, // Links (e.g., [link text](url))
      /!\[[^\]]*\]\([^)]+\)/, // Images (e.g., ![alt text](url))
      /^>\s/, // Blockquotes (e.g., > blockquote)
      /^-\s|\*\s|\+\s/, // Unordered lists (e.g., - item, * item, + item)
      /^\d+\.\s/, // Ordered lists (e.g., 1. item, 2. item)
      /`[^`]+`/, // Inline code (e.g., `code`)
      /^```[a-z]*\s[\s\S]*```$/m, // Code blocks (e.g., ```language ... ```)
    ];

    return markdownPatterns.some((pattern) => pattern.test(input));
  }

  const canShowTermsAndConditions = () => {
    if (configData === null || configData === undefined) {
      return false;
    }

    if (configData["additional_details"]["show_terms_and_conditions"]) {
      if (
        configData["terms_and_conditions"] !== "" &&
        configData["terms_and_conditions"] !== null
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  const canShowAdditionalInfo = () => {
    if (configData === null || configData === undefined) {
      return false;
    }

    if (configData["additional_details"]["show_additional_information"]) {
      if (
        configData["additional_information"] !== "" &&
        configData["additional_information"] !== null
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  const canShowExpiryDate = () => {
    if (data["expiry_date"] !== null && data["expiry_date"] !== undefined) {
      if (data["expiry_date"] != "" && data["expiry_date"] != "-") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const canShowBillingInfo = (type) => {
    if (
      data["schema"]["billing_details"][type] !== null &&
      data["schema"]["billing_details"][type] !== undefined
    ) {
      if (
        data["schema"]["billing_details"][type] != "" &&
        data["schema"]["billing_details"][type] != "-"
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const canShowInvoiceNumber = () => {
    if (configData === null || configData === undefined) {
      return false;
    } else {
      if (
        configData["additional_details"] === null ||
        configData["additional_details"] === undefined
      ) {
        return true;
      } else {
        if (
          configData["additional_details"].hasOwnProperty(
            "show_quotation_number"
          )
        ) {
          return configData["additional_details"]["show_quotation_number"];
        } else {
          return true;
        }
      }
    }
  };

  const canShowDiscountColumns = () => {
    if (data["schema"].hasOwnProperty("items")) {
      if (data["schema"]["items"].length !== 0) {
        return data["schema"]["items"].some((item) => item.discount > 0);
      } else {
        return false;
      }
    }
    return false;
  };

  const canShowAdditionalDiscount = () => {
    return data["schema"]["amount_details"]["additional_discount"] != "";
  };

  const canShowAdditionalTax = () => {
    return data["schema"]["amount_details"]["additional_tax"] != "";
  };

  const canShowShippingAddress = () => {
    if (configData === null || configData === undefined) {
      return false;
    } else {
      if (
        configData["billing_details"] === null ||
        configData["billing_details"] === undefined
      ) {
        return true;
      } else {
        if (
          configData["billing_details"].hasOwnProperty("show_shipping_address")
        ) {
          return configData["billing_details"]["show_shipping_address"];
        } else {
          return true;
        }
      }
    }
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.contentWrapper}>
          <View
            fixed
            render={({ pageNumber }) => {
              if (pageNumber !== 1) {
                return <View style={styles.BottomEmptyBox} />;
              }
              return null;
            }}
          />
          <View
            style={{
              ...styles.rowWrapper,
              width: "100%",
              justifyContent:
                data["schema"]["company_details"]["logo_url"] !== ""
                  ? "center"
                  : "left",
            }}
          >
            <Text style={styles.titleText}>{getInvoiceTitle()}</Text>
          </View>
          <Header data={data["schema"]["company_details"]} />
          <View style={styles.pageContainer}>
            <View
              style={{
                ...styles.columnWrapper,
                width: "100%",
                gap: "2px",
                padding: "8px",
              }}
            >
              {canShowInvoiceNumber() && (
                <View style={styles.addressWrapper}>
                  <View
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      width: "30%",
                    }}
                  >
                    <Text style={styles.labelText}>Invoice</Text>
                    <Text style={styles.colanText}>:</Text>
                  </View>
                  <Text style={styles.detailText}>
                    {dataRenderer(data["invoice_number"])}
                  </Text>
                </View>
              )}
              <View style={styles.addressWrapper}>
                <View
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    width: "30%",
                  }}
                >
                  <Text style={styles.labelText}>Subject</Text>
                  <Text style={styles.colanText}>:</Text>
                </View>
                <Text style={styles.detailText}>
                  {dataRenderer(data["schema"]["invoice_title"])}
                </Text>
              </View>
              <View style={styles.addressWrapper}>
                <View
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    width: "30%",
                  }}
                >
                  <Text style={styles.labelText}>Invoice Date</Text>
                  <Text style={styles.colanText}>:</Text>
                </View>

                <Text style={styles.detailText}>
                  {data["invoice_date"] !== null
                    ? IsoToLocalDate(data["invoice_date"])
                    : "="}
                </Text>
              </View>
              {canShowExpiryDate() && (
                <View style={styles.addressWrapper}>
                  <View
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      width: "30%",
                    }}
                  >
                    <Text style={styles.labelText}>Expiry Date</Text>
                    <Text style={styles.colanText}>:</Text>
                  </View>
                  <Text style={styles.detailText}>
                    {data["expiry_date"] !== null
                      ? IsoToLocalDate(data["expiry_date"])
                      : "="}
                  </Text>
                </View>
              )}
            </View>
            <View style={styles.horizontalDivider} />
            <View
              style={{
                ...styles.rowWrapper,
                padding: "0px 8px",
                backgroundColor: "#F9F9FC",
              }}
            >
              <Text style={{ ...styles.headerText, width: "50%" }}>
                Bill To
              </Text>

              {canShowShippingAddress() ? (
                <>
                  <View style={styles.verticalDivider} />{" "}
                  <Text style={{ ...styles.headerText, width: "50%" }}>
                    Ship To
                  </Text>
                </>
              ) : (
                <Text style={{ ...styles.headerText, width: "50%" }}></Text>
              )}
            </View>
            <View style={styles.horizontalDivider} />
            <View
              style={{
                ...styles.rowWrapper,
                padding: "0px 8px",
                alignItems: "flex-start",
              }}
            >
              <View
                style={{
                  ...styles.columnWrapper,
                  width: canShowShippingAddress() ? "50%" : "100%",
                  paddingBottom: "16px",
                }}
              >
                <View
                  style={{
                    ...styles.columnWrapper,
                    gap: "2px",
                    padding: "8px 0px",
                  }}
                >
                  <View style={{ ...styles.addressWrapper, width: "100%" }}>
                    <Text
                      style={{
                        ...styles.detailText,
                        width: "100%",
                        color: "#1976d2",
                      }}
                    >
                      {dataRenderer(
                        data["schema"]["billing_details"]["billing_name"]
                      )}
                    </Text>
                  </View>
                  <View style={{ ...styles.addressWrapper, width: "100%" }}>
                    <Text style={{ ...styles.detailText, width: "100%" }}>
                      {dataRenderer(
                        data["schema"]["billing_details"]["billing_address"]
                      )}
                    </Text>
                  </View>
                  <View style={{ ...styles.addressWrapper, width: "100%" }}>
                    <Text style={{ ...styles.detailText, width: "100%" }}>
                      {dataRenderer(
                        data["schema"]["billing_details"]["billing_city"]
                      )}
                    </Text>
                  </View>
                  <View style={{ ...styles.addressWrapper, width: "100%" }}>
                    <Text style={{ ...styles.detailText, width: "100%" }}>
                      {dataRenderer(
                        data["schema"]["billing_details"]["billing_state"]
                      )}
                    </Text>
                  </View>
                  <View style={{ ...styles.addressWrapper, width: "100%" }}>
                    <Text style={{ ...styles.detailText, width: "100%" }}>
                      {dataRenderer(
                        data["schema"]["billing_details"]["billing_country"]
                      )}
                    </Text>
                  </View>
                  {canShowBillingInfo("billing_zipcode") && (
                    <View style={{ ...styles.addressWrapper, width: "100%" }}>
                      <Text style={{ ...styles.detailText, width: "100%" }}>
                        {dataRenderer(
                          data["schema"]["billing_details"]["billing_zipcode"]
                        )}
                      </Text>
                    </View>
                  )}
                  {canShowBillingInfo("billing_gst") && (
                    <View
                      style={{
                        ...styles.addressWrapper,
                      }}
                    >
                      <Text
                        style={{
                          ...styles.labelText,
                          width: canShowShippingAddress() ? "7%" : "3%",
                        }}
                      >
                        GSTIN
                      </Text>

                      <Text style={{ ...styles.detailText }}>
                        {dataRenderer(
                          data["schema"]["billing_details"]["billing_gst"]
                        )}
                      </Text>
                    </View>
                  )}
                </View>
              </View>
              {canShowShippingAddress() && (
                <View style={styles.verticalDivider} />
              )}
              <View
                style={{
                  ...styles.columnWrapper,
                  width: canShowShippingAddress() ? "50%" : "0%",
                  paddingBottom: "16px",
                }}
              >
                {canShowShippingAddress() ? (
                  <>
                    <View
                      style={{
                        ...styles.columnWrapper,
                        gap: "2px",
                        padding: "8px 0px",
                      }}
                    >
                      <View style={{ ...styles.addressWrapper, width: "100%" }}>
                        <Text
                          style={{
                            ...styles.detailText,
                            width: "100%",
                            color: "#1976d2",
                          }}
                        >
                          {dataRenderer(
                            data["schema"]["billing_details"]["shipping_name"]
                          )}
                        </Text>
                      </View>
                      <View style={{ ...styles.addressWrapper, width: "100%" }}>
                        <Text style={{ ...styles.detailText, width: "100%" }}>
                          {dataRenderer(
                            data["schema"]["billing_details"][
                              "shipping_address"
                            ]
                          )}
                        </Text>
                      </View>
                      <View style={{ ...styles.addressWrapper, width: "100%" }}>
                        <Text style={{ ...styles.detailText, width: "100%" }}>
                          {dataRenderer(
                            data["schema"]["billing_details"]["shipping_city"]
                          )}
                        </Text>
                      </View>
                      <View style={{ ...styles.addressWrapper, width: "100%" }}>
                        <Text style={{ ...styles.detailText, width: "100%" }}>
                          {dataRenderer(
                            data["schema"]["billing_details"]["shipping_state"]
                          )}
                        </Text>
                      </View>
                      <View style={{ ...styles.addressWrapper, width: "100%" }}>
                        <Text style={{ ...styles.detailText, width: "100%" }}>
                          {dataRenderer(
                            data["schema"]["billing_details"][
                              "shipping_country"
                            ]
                          )}
                        </Text>
                      </View>
                      {canShowBillingInfo("shipping_zipcode") && (
                        <View
                          style={{ ...styles.addressWrapper, width: "100%" }}
                        >
                          <Text style={{ ...styles.detailText, width: "100%" }}>
                            {dataRenderer(
                              data["schema"]["billing_details"][
                                "shipping_zipcode"
                              ]
                            )}
                          </Text>
                        </View>
                      )}
                    </View>
                  </>
                ) : (
                  <View
                    style={{
                      ...styles.columnWrapper,
                      gap: "2px",
                      padding: "8px 0px",
                    }}
                  ></View>
                )}
              </View>
            </View>
            <View style={styles.horizontalDivider} />

            <View
              style={{
                ...styles.rowWrapper,
                padding: "0px 8px",
                backgroundColor: "#F9F9FC",
              }}
            >
              <Text style={styles.headerText}>Products or Services</Text>
            </View>
            <TableHeader showDiscountCol={canShowDiscountColumns()} />
            {data["schema"]["items"] !== null &&
            data["schema"]["items"].length === 0 ? (
              <>
                <TableNoRows text={"No Items Added."} />
              </>
            ) : (
              data["schema"]["items"].map((data, index) => {
                return (
                  <TableRow
                    data={data}
                    key={index}
                    index={index}
                    showDiscountCol={canShowDiscountColumns()}
                  />
                );
              })
            )}
            <View
              style={{
                ...styles.columnWrapper,
                gap: "2px",
                padding: "8px 16px",
                marginLeft: "auto",
              }}
            >
              {gstDetailsComponent()}
              <View style={styles.amountRowWrapper}>
                <Text style={styles.amountLabelText}>Sub Total (INR)</Text>
                <Text style={styles.amountDetailText}>
                  :{" "}
                  {dataRenderer(data["schema"]["amount_details"]["sub_total"])}
                </Text>
              </View>
              {canShowAdditionalDiscount() && (
                <View style={styles.amountRowWrapper}>
                  <Text style={styles.amountLabelText}>
                    Additional Discount (INR)
                  </Text>

                  <Text style={styles.amountDetailText}>
                    :{" "}
                    {dataRenderer(
                      data["schema"]["amount_details"]["additional_discount"]
                    )}
                  </Text>
                </View>
              )}
              {canShowAdditionalTax() && (
                <View style={styles.amountRowWrapper}>
                  <Text style={styles.amountLabelText}>
                    Additional Tax (INR)
                  </Text>
                  <Text style={styles.amountDetailText}>
                    :{" "}
                    {dataRenderer(
                      data["schema"]["amount_details"]["additional_tax"]
                    )}
                  </Text>
                </View>
              )}

              <View style={styles.amountRowWrapper}>
                <Text style={styles.amountLabelText}>Adjustments (INR)</Text>
                <Text style={styles.amountDetailText}>
                  :{" "}
                  {dataRenderer(
                    data["schema"]["amount_details"]["adjustments"]
                  )}
                </Text>
              </View>
              <View style={styles.amountRowWrapper}>
                <Text
                  style={{
                    ...styles.amountLabelText,
                    fontWeight: "700",
                    fontSize: 10,
                  }}
                >
                  Grand Total (INR)
                </Text>

                <Text style={{ ...styles.amountDetailText, fontSize: 10 }}>
                  :{" "}
                  {dataRenderer(
                    data["schema"]["amount_details"]["grand_total"]
                  )}
                </Text>
              </View>
            </View>
            <View style={styles.horizontalDivider} />
            {canShowTermsAndConditions() && (
              <>
                <View
                  style={{
                    ...styles.rowWrapper,
                    padding: "0px 8px",
                    backgroundColor: "#F9F9FC",
                  }}
                >
                  <Text style={styles.headerText}>Terms and Conditions</Text>
                </View>
                <View style={styles.horizontalDivider} />

                <View style={styles.plainTextWrapper}>
                  <Text style={{ ...styles.labelText, width: "100%" }}>
                    {dataRenderer(
                      data["schema"]["additional_details"][
                        "terms_and_conditions"
                      ]
                    )}
                  </Text>
                </View>
                <View style={styles.horizontalDivider} />
              </>
            )}
            {canShowAdditionalInfo() && (
              <>
                <View
                  style={{
                    ...styles.rowWrapper,
                    padding: "0px 8px",
                    backgroundColor: "#F9F9FC",
                  }}
                >
                  <Text style={styles.headerText}>Additional Information</Text>
                </View>
                <View style={styles.horizontalDivider} />
                <View>
                  {isMarkdown(
                    data["schema"]["additional_details"][
                      "additional_information"
                    ]
                  ) ? (
                    <Markdown
                      options={{
                        overrides: components,
                      }}
                    >
                      {dataRenderer(
                        data["schema"]["additional_details"][
                          "additional_information"
                        ] + `\n`
                      )}
                    </Markdown>
                  ) : (
                    <View style={styles.plainTextWrapper}>
                      <Text style={{ ...styles.labelText, width: "100%" }}>
                        {dataRenderer(
                          data["schema"]["additional_details"][
                            "additional_information"
                          ]
                        )}
                      </Text>
                    </View>
                  )}
                </View>
                <View style={styles.horizontalDivider} />
              </>
            )}
          </View>
          <View
            fixed
            render={({ pageNumber, totalPages }) => {
              if (pageNumber !== totalPages) {
                return <View style={styles.topEmptyBox} />;
              }
              return null;
            }}
          />
        </View>
        <View fixed style={styles.footerWrapper}>
          {isMarkdown(data["schema"]["company_details"]["footer_text"]) ? (
            <Text style={{ ...styles.footerText }}>
              <Markdown
                options={{
                  overrides: footerComponents,
                }}
              >
                {dataRenderer(
                  data["schema"]["company_details"]["footer_text"] + `\n`
                )}
              </Markdown>
            </Text>
          ) : (
            <Text style={{ ...styles.footerText }}>
              {data["schema"]["company_details"]["footer_text"]}
            </Text>
          )}
        </View>
      </Page>
    </Document>
  );
};
export default observer(PrintInvoiceLayout);
