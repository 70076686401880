import React from "react";
import styled from "styled-components";
import { FormControl, makeStyles, Select } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import { observer } from "mobx-react-lite";

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 428px;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const Label = styled.span`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Info = styled.span`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Detail = styled.span`
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;
const Input = styled.input`
  width: 428px;
  height: 30px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;
  border: 1px solid #bfbfbf;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const TextArea = styled.div`
  width: 428px;
  height: 60px;
  margin-bottom: 20px;
  padding: 4px 12px;
  outline: none;
  border: 1px solid #bfbfbf;
  color: #4d4e4f;
  resize: none;
  font: normal normal 500 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
  @media (max-width: 700px) {
    width: 280px;
  }
`;

const SelectColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const Row = styled.div`
  display: flex;
  align-items: center;
`;

function ampm(time) {
  if (time.value !== "") {
    var hours = time.split(":")[0];
    var minutes = time.split(":")[1];
    var suffix = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    var displayTime = hours + ":" + minutes + " " + suffix;
    return displayTime;
  }
  return;
}
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const variableList = ["name", "email"];
const templateList = ["Template1", "Template2", "Template3"];
const WhatsappCampaign = (props) => {
  const classes = useStyles();

  const handleVariable = (e) => {
    props.setVariables({ ...props.variables, [e.target.name]: e.target.value });
  };

  return (
    <>
      <InputContainer>
        <Row
          style={{
            marginBottom: "20px",
            width: "100%",
          }}
        >
          <InputWrapper>
            <Info>Campaign Type </Info>
            <Detail>{props.campaignType}</Detail>
          </InputWrapper>
          <InputWrapper>
            <Info>Provider </Info>
            <Detail>{props.provider}</Detail>
          </InputWrapper>
        </Row>

        <SelectColumn>
          <Label>Select Whatsapp Template*</Label>
          <FormControl
            sx={{ m: 1, minWidth: 428 }}
            classes={{
              root: classes.quantityRoot,
            }}
          >
            <Select
              classes={{
                icon: classes.icon,
              }}
              name="segment"
              displayEmpty
              disableUnderline
              variant="outlined"
              required
              onChange={(e) => {
                // handleChange(e);
              }}
              style={{
                marginBottom: "20px",
                width: "428px",
                height: "30px",
                fontSize: "12px",
                fontWeight: "bold",
                borderRadius: "0px",
              }}
            >
              {templateList.map((e) => {
                return (
                  <MenuItem id={e} value={e}>
                    {e}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </SelectColumn>
        <InputWrapper>
          <Label>Whatsapp Text</Label>
          <TextArea>{props.smsText}</TextArea>

          {variableList.map((e) => {
            return (
              <InputWrapper
                style={{ flexDirection: "row", alignItems: "center" }}
              >
                <Label style={{ marginRight: "20px" }}>{e}</Label>
                <Input
                  name={e}
                  style={{ width: "100%" }}
                  onChange={handleVariable}
                />
              </InputWrapper>
            );
          })}
        </InputWrapper>
      </InputContainer>
    </>
  );
};

export default observer(WhatsappCampaign);
