import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Typography,
  FormControl,
  makeStyles,
  Select,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import {
  ToggleButton,
  ToggleButtonGroup,
  RadioGroup,
  Radio,
  FormControlLabel,
  Box,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { observer } from "mobx-react-lite";
import { getTemplateListApi } from "../../Api";
import { Grid } from "@mui/material";
import { getLocalizedText } from "../../Functions";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { daysList, monthsList } from "../../Db";

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-height: 300px;
  width: 428px;
  gap: 20px;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Header = styled.span`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
  margin-bottom: 10px;
`;
const RequiredFieldWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const Input = styled.input`
  width: 100%;
  height: 30px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;
  border: 1px solid #bfbfbf;
  font: normal normal 600 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
`;
const CustomizedDatePicker = styled(DatePicker)`
  & .MuiInputBase-input {
    font-size: 12px;
    padding: 7.5px 14px;
  }
`;
const Label = styled.span`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Info = styled.span`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Detail = styled.span`
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;
const SelectColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  @media (max-width: 700px) {
    width: 280px;
  }
`;
const RadioGroupContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;
const EndDateContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

function ampm(time) {
  if (time.value !== "") {
    var hours = time.split(":")[0];
    var minutes = time.split(":")[1];
    var suffix = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    var displayTime = hours + ":" + minutes + " " + suffix;
    return displayTime;
  }
  return;
}
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const FormSubHeader = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #a9a9a9;
  margin-bottom: 10px;
`;
const generateTimeIntervals = (
  start = "00:00",
  end = "23:59",
  interval = 15
) => {
  const times = [];
  const startTime = new Date(`1970-01-01T${start}:00`);
  const endTime = new Date(`1970-01-01T${end}:00`);

  while (startTime <= endTime) {
    times.push(startTime.toTimeString().slice(0, 5)); // HH:mm format
    startTime.setMinutes(startTime.getMinutes() + interval);
  }
  return times;
};

const EmailScheduleComponent = (props) => {
  const classes = useStyles();
  const times = generateTimeIntervals();

  return (
    <>
      <InputContainer>
        {props.selectedSubType === "One-Time" ? (
          <>
            <FormSubHeader>Schedule</FormSubHeader>
            <InputWrapper>
              <Label>Date*</Label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomizedDatePicker
                  onChange={(newValue) => {
                    props.setEmailDateData("date", newValue);
                  }}
                  value={props.emailDateData.date || null}
                  inputFormat="YYYY-MM-DD"
                  renderInput={(params) => (
                    <TextField
                      required
                      sx={{
                        width: "100%",
                        height: "30px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        borderRadius: "0px",
                      }}
                      size="small"
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </InputWrapper>
            <InputWrapper>
              <Label>Time*</Label>
              <FormControl fullWidth>
                <Select
                  required
                  value={props.emailDateData.time || null}
                  onChange={(e) => {
                    props.setEmailDateData("time", e.target.value);
                  }}
                  displayEmpty
                  variant="outlined"
                  style={{
                    width: "100%",
                    height: "30px",
                    fontSize: "12px",
                    fontWeight: "bold",
                    borderRadius: "0px",
                  }}
                >
                  {times.map((time) => (
                    <MenuItem key={time} value={time}>
                      {time}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </InputWrapper>
          </>
        ) : (
          <>
            <FormSubHeader>Schedule</FormSubHeader>
            <InputWrapper>
              <Label>Start Date*</Label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomizedDatePicker
                  onChange={(newValue) => {
                    props.setEmailDateData("date", newValue);
                  }}
                  value={props.emailDateData.date || null}
                  inputFormat="YYYY-MM-DD"
                  renderInput={(params) => (
                    <TextField
                      required
                      sx={{
                        width: "100%",
                        height: "30px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        borderRadius: "0px",
                      }}
                      size="small"
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </InputWrapper>
            <InputWrapper sx={{ flexDirection: "column" }}>
              <Label>Recurring</Label>

              <ToggleButtonGroup
                size="small"
                color="primary"
                value={props.emailDateData.recurring_type || null}
                exclusive
                onChange={(e) => {
                  props.setRecurringType(e.target.value);
                }}
              >
                <ToggleButton value="Daily" sx={{ textTransform: "none" }}>
                  Daily
                </ToggleButton>
                <ToggleButton value="Weekly" sx={{ textTransform: "none" }}>
                  Weekly
                </ToggleButton>
                <ToggleButton value="Monthly" sx={{ textTransform: "none" }}>
                  Monthly
                </ToggleButton>
              </ToggleButtonGroup>
            </InputWrapper>
            <InputWrapper>
              <Label>Every*</Label>
              <Box
                sx={{
                  width: "120px",
                }}
              >
                {props.emailDateData.recurring_type === "Daily" && (
                  <TextField
                    required
                    value={props.emailDateData.every || null}
                    onChange={(e) => {
                      props.setEmailDateData("every", e.target.value);
                    }}
                    variant="outlined"
                    size="small"
                    type="number"
                    inputProps={{
                      min: 1,
                      max: 30,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Day</InputAdornment>
                      ),
                    }}
                    sx={{
                      width: "100%",
                      height: "30px",
                      fontSize: "12px",
                      fontWeight: "bold",
                      borderRadius: "0px",
                    }}
                  />
                )}
                {props.emailDateData.recurring_type === "Weekly" && (
                  <Select
                    required
                    value={props.emailDateData.every || null}
                    onChange={(e) => {
                      props.setEmailDateData("every", e.target.value);
                    }}
                    displayEmpty
                    variant="outlined"
                    style={{
                      width: "100%",
                      height: "30px",
                      fontSize: "12px",
                      fontWeight: "bold",
                      borderRadius: "0px",
                    }}
                  >
                    {daysList.map((day) => (
                      <MenuItem key={day.value} value={day.value}>
                        {day.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                {props.emailDateData.recurring_type === "Monthly" && (
                  <TextField
                    required
                    value={props.emailDateData.every || null}
                    onChange={(e) => {
                      props.setEmailDateData("every", e.target.value);
                    }}
                    variant="outlined"
                    size="small"
                    type="number"
                    inputProps={{
                      min: 1,
                      max: 31,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Day</InputAdornment>
                      ),
                    }}
                    sx={{
                      width: "100%",
                      height: "30px",
                      fontSize: "12px",
                      fontWeight: "bold",
                      borderRadius: "0px",
                    }}
                  />
                )}
              </Box>
            </InputWrapper>
            <InputWrapper>
              <Label>Time*</Label>
              <FormControl fullWidth>
                <Select
                  required
                  value={props.emailDateData.time || null}
                  onChange={(e) => {
                    props.setEmailDateData("time", e.target.value);
                  }}
                  displayEmpty
                  variant="outlined"
                  style={{
                    width: "100%",
                    height: "30px",
                    fontSize: "12px",
                    fontWeight: "bold",
                    borderRadius: "0px",
                  }}
                >
                  {times.map((time) => (
                    <MenuItem key={time} value={time}>
                      {time}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </InputWrapper>
            <InputWrapper>
              <Label>End date</Label>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={props.emailDateData.end_type || null}
                  onChange={(e) => {
                    props.setEmailDateData("end_type", e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="Never End"
                    control={
                      <Radio
                        color={"primary"}
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      />
                    }
                    label="Never End"
                    sx={{
                      "& .MuiFormControlLabel-label": { fontSize: "12px" },
                    }}
                  />
                  <RadioGroupContainer>
                    <FormControlLabel
                      value="Till"
                      control={
                        <Radio
                          color={"primary"}
                          style={{
                            fontSize: "12px",
                            fontWeight: "bold",
                          }}
                        />
                      }
                      label="Till"
                      sx={{
                        "& .MuiFormControlLabel-label": { fontSize: "12px" },
                      }}
                    />
                    {props.emailDateData.end_type === "Till" && (
                      <EndDateContainer>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <CustomizedDatePicker
                            onChange={(newValue) => {
                              props.setEmailDateData("end_date", newValue);
                            }}
                            value={props.emailDateData.end_date || null}
                            inputFormat="YYYY-MM-DD"
                            renderInput={(params) => (
                              <TextField
                                required
                                sx={{
                                  width: "100%",
                                  height: "30px",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  borderRadius: "0px",
                                }}
                                size="small"
                                variant="outlined"
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </EndDateContainer>
                    )}
                  </RadioGroupContainer>
                </RadioGroup>
              </FormControl>
            </InputWrapper>
          </>
        )}
      </InputContainer>
    </>
  );
};

export default observer(EmailScheduleComponent);
