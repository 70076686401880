import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { observer } from "mobx-react-lite";
import { Box, Drawer } from "@material-ui/core";
import { AddBoxOutlined, ChevronRight } from "@material-ui/icons";
import { branchLocationsColumnHeaders } from "../../Db";
import rootStore from "../../stores/RootStore";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { pageSize } from "../../Config";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { getBranchLocationsListApi } from "../../Api";
import CreateBranchLocationComponent from "../../components/branch_locations_page_components/CreateBranchLocationComponent";
import NoAccessPermissionPage from "../no_access_page/NoAccessPermissionPage";
import LoadingAnimationComponent from "../../components/loading_component/LoadingAnimationComponent";
const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 84px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;

const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const Row = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 185px);`};
  background-color: white;
  padding: 20px;
  position: relative;
`;

const AddBtnWrapper = styled(Box)`
  position: absolute;
  right: 24px;
  top: 24px;
  color: #185dd2;
  z-index: 2;
  cursor: pointer;
`;
const BranchLocationsPage = () => {
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [openCreateBranchLocationPanel, setOpenCreateBranchLocationPanel] =
    useState(false);
  const getBranchLocations = async () => {
    setRow([]);
    let response = await getBranchLocationsListApi();
    setRow(response);
  };

  const init = async () => {
    setLoading(true);
    if (
      rootStore.subscriptionState.isFeatureAvailableWithAddOns("Attendance") ||
      rootStore.subscriptionState.isFeatureAvailableWithAddOns(
        "Advanced Security"
      )
    ) {
      await getBranchLocations();
    }
    setLoading(false);
  };
  const refetch = async () => {
    setLoading(true);
    await getBranchLocations();
    setLoading(false);
    setRefresh(false);
  };
  useEffect(() => {
    if (refresh === true) {
      refetch();
    }
  }, [refresh]);

  useEffect(() => {
    init();
    rootStore.authStore.updateLeadDetailStatus();
  }, []);

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  return rootStore.subscriptionState.isFeatureAvailableWithAddOns(
    "Attendance"
  ) ||
    rootStore.subscriptionState.isFeatureAvailableWithAddOns(
      "Advanced Security"
    ) ? (
    <>
      <ParentContainer>
        {!loading ? (
          <Container>
            <Row>
              <TopWrapper>
                <Header>Admin</Header>
                <ChevronRight style={{ width: "16px", height: "16px" }} />
                <Header>Branch Locations</Header>
              </TopWrapper>
            </Row>
            <TableWrapper>
              <AddBtnWrapper
                onClick={() => {
                  setOpenCreateBranchLocationPanel(true);
                }}
              >
                <AddBoxOutlined
                  style={{
                    width: "40px",
                    height: "40px",
                  }}
                />
              </AddBtnWrapper>
              <div className="ag-theme-alpine" style={{ width: "100%" }}>
                <AgGridReact
                  domLayout="autoHeight"
                  gridOptions={gridOptions}
                  rowData={row}
                  defaultColDef={defaultColDef}
                  columnDefs={branchLocationsColumnHeaders}
                  animateRows={true}
                  suppressCellFocus
                  pagination={false}
                  paginationPageSize={pageSize}
                  className="paginated-ag-grid"
                  onRowClicked={(row) => {
                    if (row.event.defaultPrevented) {
                      return null;
                    }
                  }}
                />
              </div>
            </TableWrapper>
          </Container>
        ) : (
          <Container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadingAnimationComponent size={"small"} />
          </Container>
        )}
      </ParentContainer>
      <Footer />
      {openCreateBranchLocationPanel && (
        <Drawer
          disableEnforceFocus
          anchor={"right"}
          open={openCreateBranchLocationPanel}
          onClose={() => {
            setOpenCreateBranchLocationPanel(false);
          }}
        >
          <CreateBranchLocationComponent
            setOpen={setOpenCreateBranchLocationPanel}
            setRefresh={setRefresh}
          />
        </Drawer>
      )}
    </>
  ) : (
    <NoAccessPermissionPage />
  );
};

export default observer(BranchLocationsPage);
