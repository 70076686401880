import React, { useEffect, useState } from "react";
import rootStore from "../../stores/RootStore";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import FilteredTab from "../../components/home_tabs/FilteredTab";
import { Assignment, ChevronRight, Refresh } from "@material-ui/icons";
import FilterModal from "../../components/filter_modal/FilterModal";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Badge,
  Box,
  CircularProgress,
  Drawer,
  Grid,
  Tooltip,
} from "@mui/material";
import { Circle, FilterAlt, PersonAddAlt } from "@mui/icons-material";
import CreateLeadComponent from "../../components/create_lead_modal/CreatLeadComponent";
import Footer from "../../components/landing_page_components/footer/Footer";
import {
  checkFeatureAndSubFeaturePermission,
  checkFeaturePermission,
  consoleLogger,
  getLocalizedText,
  isUnderWorkSchedule,
  validateDisabledSubFeature,
} from "../../Functions";
import AlertDialogue from "../../components/alert_dialogue/AlertDialogue";
import NoAccessPermissionPage from "../no_access_page/NoAccessPermissionPage";
import NoAccessPage from "../no_access_page/NoAccessPage";
import analytics from "../../Analytics";
import { Chip, Typography } from "@mui/material";
import LeadsTab from "../../components/home_tabs/LeadsTab";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import HotLeadBannerComponent from "../../components/banner_component/HotLeadBannerComponent";
import { toJS } from "mobx";
import ViewGroupChipComponent from "../../components/viewgroup_chip_component/ViewGroupChipComponent";
import LoadingAnimationComponent from "../../components/loading_component/LoadingAnimationComponent";

const Container = styled(Box)`
  min-height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9fc;
  justify-content: center;
  margin-top: 64px;
  width: 100%;
`;
const Wrapper = styled(Box)`
  display: flex;
  justify-content: ${(e) => (e.params ? "flex-end" : "space-between")};
  width: 95%;
`;

const EmptyDiv = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${`min-height: calc(100vh - 139px);`};
  font: normal normal Bold 22px Open Sans;
`;

const SpinnerWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
`;

const MenuWrapper = styled(Box)`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
`;

const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
`;
const Text = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const TabLabelWrapper = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;
const TabLabelText = styled(Typography)`
  white-space: nowrap;
  margin: 0px;
  font: normal normal 600 14px Open Sans;
`;
const ActiveCounterText = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: white;
`;
const InActiveCounterText = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #828282;
`;

const LeadsPage = (props) => {
  const { authStore } = rootStore;
  const [open, setOpen] = useState(false);
  const [assignState, setAssignState] = useState(0);
  const [isClicked, setIsClicked] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [openSelectCol, setOpenSelectCol] = useState(false);
  const allColumns = [...rootStore.userStore.AllColumnsList];
  const [isError, setIsError] = useState(false);
  const [refreshLeads, setRefreshLeads] = useState(false);
  const [openConfirmMarkLeadDialog, setOpenConfirmMarkLeadDialog] =
    useState(false);
  let navigate = useNavigate();
  const [openUpdateStageModal, setOpenUpdateStageModal] = useState(false);
  const [openUpdateSourceModal, setOpenUpdateSourceModal] = useState(false);
  const [openUpdateStatusModal, setOpenUpdateStatusModal] = useState(false);
  const [openUpdateFieldModal, setOpenUpdateFieldModal] = useState(false);
  const [openDeleteLead, setOpenDeleteLead] = useState(false);
  const [openPriorityLeadDialog, setOpenPriorityLeadDialog] =
    useState(false);
  const [openMoveLead, setOpenMoveLead] = useState(false);
  const [openConfirmResetFollowupDialog, setOpenConfirmResetFollowupDialog] =
    useState(false);
  const [openSetLeadColorDialog, setOpenSetLeadColorDialog] = useState(false);
  const [openLeadScopeDialog, setOpenLeadScopeDialog] = useState(false);
  const [isTabViewDisabled, setIsTabViewDisabled] = useState(true);

  //************stale data related state variables**************** */
  const [staleDataIDList, setStaleDataIDList] = useState([]);
  const [refreshTabLeadListing, setRefreshTabLeadListing] = useState(false);
  //**********************end*********************************** */

  const [openExportLeadDialog, setOpenExportLeadDialog] = useState(false);

  //menu icon states**************************

  const [anchorEl, setAnchorEl] = React.useState(null);
  const Open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColumns = () => {
    setOpenSelectCol(true);
    handleClose();
  };
  useEffect(() => {
    if (localStorage.getItem("token") === null) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    analytics.triggerEvent(4625000022, "leads_page_load", "leads_page", "", {});
    authStore.updateLeadDetailStatus();
  }, []);

  const canShowMoveToProject = () => {
    if (checkFeaturePermission("move_to_project")) {
      if (toJS(authStore.projectList).length > 1) {
        return true;
      }
    }
    return false;
  };

  const RenderTabLabel = ({ id, name }) => {
    if (Object.keys(rootStore.leadStore.tabInfo).length > 0) {
      let count = rootStore.leadStore.tabInfo[id]["count"];
      if (count !== null) {
        return (
          <>
            <TabLabelWrapper>
              {id === rootStore.leadStore.tabID &&
                staleDataIDList.length > 0 && (
                  <Refresh
                    color="error"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setRefreshTabLeadListing(true);
                    }}
                  />
                )}
              <Chip
                style={{ height: "22px" }}
                color={id === rootStore.leadStore.tabID ? "primary" : "default"}
                label={
                  id === rootStore.leadStore.tabID ? (
                    <ActiveCounterText>{count}</ActiveCounterText>
                  ) : (
                    <InActiveCounterText>{count}</InActiveCounterText>
                  )
                }
              />

              <TabLabelText>{name}</TabLabelText>
            </TabLabelWrapper>
            {id === rootStore.leadStore.tabID &&
              Object.keys(rootStore.leadStore.leadScopeFilter).length > 0 && (
                <Tooltip title={"Lead Scope Set"}>
                  <Badge
                    style={{
                      backgroundColor: "none",
                      padding: "0px",
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                    }}
                    badgeContent={
                      <FilterAlt
                        style={{
                          height: "16px",
                          width: "16px",
                        }}
                        color="primary"
                      />
                    }
                    variant="standard"
                  />
                </Tooltip>
              )}
          </>
        );
      } else {
        return (
          <TabLabelWrapper>
            {id === rootStore.leadStore.tabID && (
              <CircularProgress style={{ width: "20px", height: "20px" }} />
            )}
            <TabLabelText>{name}</TabLabelText>
          </TabLabelWrapper>
        );
      }
    }
  };

  return Object.keys(rootStore.leadStore.tabInfo).length > 0 ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          width: "0",
        },
      }}
    >
      {checkFeaturePermission("leads") ? (
        isUnderWorkSchedule() ? (
          <>
            <Container>
              <Wrapper style={{ margin: "16px 0px" }}>
                <TopWrapper>
                  {!props.isFiltered ? (
                    <Grid container spacing={2} alignItems={"center"}>
                      <Grid item xs={6}>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "flex-end",
                          }}
                        >
                          {rootStore.leadStore._tabGroups.length > 1 ? (
                            <ViewGroupChipComponent />
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "flex-end",
                              }}
                            >
                              <Text>
                                {getLocalizedText("leads", "project")}
                              </Text>
                              <ChevronRight
                                style={{ width: "16px", height: "16px" }}
                              />
                            </Box>
                          )}
                        </Box>
                      </Grid>
                      {rootStore.hotLeadState.hasPermission() && (
                        <Grid item xs={6}>
                          <HotLeadBannerComponent />
                        </Grid>
                      )}
                    </Grid>
                  ) : (
                    <Text style={{ fontSize: "28px" }}>Search Results</Text>
                  )}
                </TopWrapper>
              </Wrapper>
              {authStore.projectId && (
                <Wrapper
                  params={props.isFiltered}
                  style={{ backgroundColor: "white" }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={10}>
                      {!props.isFiltered && (
                        <Tabs
                          sx={{
                            [`& .${tabsClasses.scrollButtons}`]: {
                              "&.Mui-disabled": { opacity: 0.3 },
                            },
                          }}
                          variant="scrollable"
                          value={rootStore.leadStore.tabID}
                          textColor="primary"
                          indicatorColor="primary"
                          onChange={(event, newValue) => {
                            let index =
                              rootStore.leadStore._tabsDetails.findIndex(
                                (tab) => tab["tab"]["id"] === newValue
                              );
                            analytics.triggerEvent(
                              4625000023,
                              "leads_tab_tap",
                              "leads_page",
                              "leads_tab",
                              {
                                order:
                                  rootStore.leadStore._tabsDetails[index][
                                    "tab"
                                  ]["name"],
                              }
                            );
                            rootStore.leadStore.updateTabID(newValue);
                          }}
                        >
                          {rootStore.leadStore
                            .getTabsForSelectedGroup()
                            .map((e, i) => {
                              return (
                                <Tab
                                  disabled={isTabViewDisabled}
                                  sx={{
                                    "&.Mui-selected": {
                                      backgroundColor: "#E7EEFA",
                                      color: "#4079DA",
                                      font: "normal normal 600 14px Open Sans",
                                      textTransform: "capitalize",
                                    },

                                    backgroundColor: "white",
                                    color: "#4D4E4F",
                                    font: "normal normal 600 14px Open Sans",
                                    textTransform: "capitalize",
                                    position: "relative",
                                  }}
                                  label={RenderTabLabel({
                                    id: e["tab"].id,
                                    name: e["tab"].name,
                                  })}
                                  id={e["tab"].id}
                                  value={e["tab"].id}
                                  onClick={(e) => {
                                    consoleLogger(e.target.id);
                                  }}
                                />
                              );
                            })}
                        </Tabs>
                      )}
                    </Grid>
                    <Grid item xs={2}>
                      <MenuWrapper>
                        {checkFeaturePermission("assign_leads") && (
                          <IconButton
                            disabled={assignState < 1}
                            onClick={() => {
                              analytics.triggerEvent(
                                4625000024,
                                "assign_leads_button_tap",
                                "leads_page",
                                "assign_leads_button",
                                {}
                              );
                              authStore.updateLastActiveTime();
                              setOpen(true);
                              handleClose();
                            }}
                          >
                            <Assignment style={{ cursor: "pointer" }} />
                          </IconButton>
                        )}
                        {!props.isFiltered && (
                          <IconButton
                            onClick={() => {
                              analytics.triggerEvent(
                                4625000025,
                                "create_lead_button_tap",
                                "leads_page",
                                "create_lead_button",
                                {}
                              );
                              authStore.updateLastActiveTime();
                              setOpenDrawer(!openDrawer);
                            }}
                          >
                            <PersonAddAlt style={{ cursor: "pointer" }} />
                          </IconButton>
                        )}
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={Open ? "long-menu" : undefined}
                          aria-expanded={Open ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={handleClick}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          style={{ display: "flex", flexDirection: "column" }}
                          id="long-menu"
                          MenuListProps={{
                            "aria-labelledby": "long-button",
                          }}
                          anchorEl={anchorEl}
                          open={Open}
                          onClose={handleClose}
                          PaperProps={{
                            style: {
                              width: "fit-content",
                            },
                          }}
                        >
                          {checkFeaturePermission("lead_listing_delete") && (
                            <MenuItem
                              disabled={assignState < 1}
                              onClick={() => {
                                analytics.triggerEvent(
                                  4625000027,
                                  "delete_leads_button_tap",
                                  "leads_page",
                                  "delete_leads_button",
                                  {}
                                );
                                authStore.updateLastActiveTime();
                                setOpenDeleteLead(true);
                                setAnchorEl(null);
                              }}
                            >
                              Delete Leads
                            </MenuItem>
                          )}
                           {checkFeatureAndSubFeaturePermission(
                            "update_field"
                          ) && (
                            <MenuItem
                              disabled={assignState < 1}
                              onClick={() => {
                                analytics.triggerEvent(
                                  4624999686,
                                  "update_field_button_tap",
                                  "leads_page",
                                  "update_field_button",
                                  {}
                                );
                                authStore.updateLastActiveTime();
                                setOpenUpdateFieldModal(true);
                                handleClose();
                              }}
                            >
                              Update Field
                            </MenuItem>
                          )} 
                          {checkFeatureAndSubFeaturePermission(
                            "update_source"
                          ) && (
                            <MenuItem
                              disabled={assignState < 1}
                              onClick={() => {
                                analytics.triggerEvent(
                                  4625000274,
                                  "update_source_button_tap",
                                  "leads_page",
                                  "update_source_button",
                                  {}
                                );
                                authStore.updateLastActiveTime();
                                setOpenUpdateSourceModal(true);
                                handleClose();
                              }}
                            >
                              Update Source
                            </MenuItem>
                          )}
                          {checkFeatureAndSubFeaturePermission(
                            "update_stage"
                          ) && (
                            <MenuItem
                              disabled={assignState < 1}
                              onClick={() => {
                                analytics.triggerEvent(
                                  4625000263,
                                  "update_stage_button_tap",
                                  "leads_page",
                                  "update_stage_button",
                                  {}
                                );
                                authStore.updateLastActiveTime();
                                setOpenUpdateStageModal(true);
                                handleClose();
                              }}
                            >
                              Update Stage
                            </MenuItem>
                          )}
                          {checkFeatureAndSubFeaturePermission(
                            "update_status"
                          ) && (
                            <MenuItem
                              disabled={assignState < 1}
                              onClick={() => {
                                analytics.triggerEvent(
                                  4625000282,
                                  "update_status_button_tap",
                                  "leads_page",
                                  "update_status_button",
                                  {}
                                );
                                authStore.updateLastActiveTime();
                                setOpenUpdateStatusModal(true);
                                handleClose();
                              }}
                            >
                              Update Status
                            </MenuItem>
                          )}
                          {validateDisabledSubFeature("select_columns") &&
                            !props.isFiltered && (
                              <MenuItem
                                onClick={() => {
                                  analytics.triggerEvent(
                                    4625000026,
                                    "select_columns_button_tap",
                                    "leads_page",
                                    "select_columns_button",
                                    {}
                                  );
                                  authStore.updateLastActiveTime();
                                  handleColumns();
                                }}
                              >
                                Select Columns
                              </MenuItem>
                            )}
                          {/* {checkFeaturePermission("fresh_lead") && (
                        <MenuItem
                          disabled={assignState < 1}
                          onClick={() => {
                            analytics.triggerEvent(
                              4625000264,
                              "mark_fresh_lead_button_tap",
                              "leads_page",
                              "mark_fresh_lead_button",
                              {}
                            );
                            setOpenConfirmMarkLeadDialog(true);
                            setAnchorEl(null);
                          }}
                        >
                          Mark as Fresh Lead
                        </MenuItem>
                      )} */}

                          <MenuItem
                            disabled={assignState < 1}
                            onClick={() => {
                              setOpenPriorityLeadDialog(true);
                              setAnchorEl(null);
                            }}
                          >
                            Mark as Priority Lead
                          </MenuItem>
                          {canShowMoveToProject() && (
                            <MenuItem
                              disabled={assignState < 1}
                              onClick={() => {
                                setOpenMoveLead(true);
                                setAnchorEl(null);
                              }}
                            >
                              Move To Project
                            </MenuItem>
                          )}
                          {checkFeaturePermission("reset_followup") && (
                            <MenuItem
                              disabled={assignState < 1}
                              onClick={() => {
                                setOpenConfirmResetFollowupDialog(true);
                                setAnchorEl(null);
                              }}
                            >
                              Reset Next Follow-up
                            </MenuItem>
                          )}
                          <MenuItem
                            disabled={assignState < 1}
                            onClick={() => {
                              authStore.updateLastActiveTime();
                              setOpenSetLeadColorDialog(true);
                              setAnchorEl(null);
                            }}
                          >
                            Set Lead Color
                          </MenuItem>
                          {validateDisabledSubFeature("lead_scope") &&
                            !props.isFiltered && (
                              <MenuItem
                                onClick={() => {
                                  authStore.updateLastActiveTime();
                                  setOpenLeadScopeDialog(true);
                                  setAnchorEl(null);
                                }}
                              >
                                Lead Scope
                              </MenuItem>
                            )}
                          {checkFeaturePermission("export_leads_listing") && (
                            <MenuItem
                              disabled={assignState < 1}
                              onClick={() => {
                                authStore.updateLastActiveTime();
                                setOpenExportLeadDialog(true);
                                setAnchorEl(null);
                              }}
                            >
                              Export Lead
                            </MenuItem>
                          )}
                        </Menu>{" "}
                      </MenuWrapper>
                    </Grid>
                  </Grid>
                </Wrapper>
              )}
              <Wrapper>
                {(() => {
                  if (!props.isFiltered) {
                    return (
                      <LeadsTab
                        setOpen={setOpen}
                        isClicked={isClicked}
                        setIsClicked={setIsClicked}
                        setIsFiltered={props.setIsFiltered}
                        setAssignState={setAssignState}
                        open={open}
                        openSelectCol={openSelectCol}
                        setOpenSelectCol={setOpenSelectCol}
                        allColumns={allColumns}
                        refresh={refreshLeads}
                        openConfirmMarkLeadDialog={openConfirmMarkLeadDialog}
                        setOpenConfirmMarkLeadDialog={
                          setOpenConfirmMarkLeadDialog
                        }
                        openUpdateStageModal={openUpdateStageModal}
                        setOpenUpdateStageModal={setOpenUpdateStageModal}
                        openUpdateSourceModal={openUpdateSourceModal}
                        setOpenUpdateSourceModal={setOpenUpdateSourceModal}
                        openUpdateStatusModal={openUpdateStatusModal}
                        setOpenUpdateStatusModal={setOpenUpdateStatusModal}
                        openUpdateFieldModal={openUpdateFieldModal}
                        setOpenUpdateFieldModal={setOpenUpdateFieldModal}
                        setOpenMoveLead={setOpenMoveLead}
                        openMoveLead={openMoveLead}
                        openDeleteLead={openDeleteLead}
                        setOpenDeleteLead={setOpenDeleteLead}
                        setOpenPriorityLeadDialog={
                          setOpenPriorityLeadDialog
                        }
                        openPriorityLeadDialog={
                          openPriorityLeadDialog
                        }
                        openConfirmResetFollowupDialog={
                          openConfirmResetFollowupDialog
                        }
                        setOpenConfirmResetFollowupDialog={
                          setOpenConfirmResetFollowupDialog
                        }
                        openSetLeadColorDialog={openSetLeadColorDialog}
                        setOpenSetLeadColorDialog={setOpenSetLeadColorDialog}
                        openLeadScopeDialog={openLeadScopeDialog}
                        setOpenLeadScopeDialog={setOpenLeadScopeDialog}
                        staleDataIDList={staleDataIDList}
                        setStaleDataIDList={setStaleDataIDList}
                        refreshTabLeadListing={refreshTabLeadListing}
                        setRefreshTabLeadListing={setRefreshTabLeadListing}
                        openExportLeadDialog={openExportLeadDialog}
                        setOpenExportLeadDialog={setOpenExportLeadDialog}
                        setIsTabViewDisabled={setIsTabViewDisabled}
                      />
                    );
                  } else if (props.isFiltered) {
                    return (
                      <FilteredTab
                        row={props.row}
                        loading={props.loading}
                        setLoading={props.setLoading}
                        page={props.page}
                        setPage={props.setPage}
                        setRow={props.setRow}
                        count={props.count}
                        setCount={props.setCount}
                        setIsClicked={setIsClicked}
                        setAssignState={setAssignState}
                        open={open}
                        setOpen={setOpen}
                        openSelectCol={openSelectCol}
                        setOpenSelectCol={setOpenSelectCol}
                        allColumns={allColumns}
                        openConfirmMarkLeadDialog={openConfirmMarkLeadDialog}
                        setOpenConfirmMarkLeadDialog={
                          setOpenConfirmMarkLeadDialog
                        }
                        openUpdateStageModal={openUpdateStageModal}
                        setOpenUpdateStageModal={setOpenUpdateStageModal}
                        openUpdateSourceModal={openUpdateSourceModal}
                        setOpenUpdateSourceModal={setOpenUpdateSourceModal}
                        openUpdateStatusModal={openUpdateStatusModal}
                        setOpenUpdateStatusModal={setOpenUpdateStatusModal}
                        openUpdateFieldModal={openUpdateFieldModal}
                        setOpenUpdateFieldModal={setOpenUpdateFieldModal}
                        openDeleteLead={openDeleteLead}
                        setOpenDeleteLead={setOpenDeleteLead}
                        setOpenPriorityLeadDialog={
                          setOpenPriorityLeadDialog
                        }
                        openPriorityLeadDialog={
                          openPriorityLeadDialog
                        }
                        setOpenMoveLead={setOpenMoveLead}
                        openMoveLead={openMoveLead}
                        openConfirmResetFollowupDialog={
                          openConfirmResetFollowupDialog
                        }
                        setOpenConfirmResetFollowupDialog={
                          setOpenConfirmResetFollowupDialog
                        }
                        openSetLeadColorDialog={openSetLeadColorDialog}
                        setOpenSetLeadColorDialog={setOpenSetLeadColorDialog}
                        openExportLeadDialog={openExportLeadDialog}
                        setOpenExportLeadDialog={setOpenExportLeadDialog}
                      />
                    );
                  }
                })()}
              </Wrapper>
            </Container>
            <Footer />
          </>
        ) : (
          <NoAccessPage />
        )
      ) : (
        <NoAccessPermissionPage />
      )}
      {openFilter && <FilterModal open={openFilter} setOpen={setOpenFilter} />}
      <Drawer
        anchor={"right"}
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
      >
        {
          <CreateLeadComponent
            open={openDrawer}
            setOpen={setOpenDrawer}
            refresh={refreshLeads}
            setRefresh={setRefreshLeads}
          />
        }
      </Drawer>

      {isError && <AlertDialogue open={isError} setOpen={setIsError} />}
    </Box>
  ) : authStore.projectId ? (
    <SpinnerWrapper>
      <LoadingAnimationComponent size={"medium"} />
    </SpinnerWrapper>
  ) : (
    <SpinnerWrapper>
      <EmptyDiv>
        Something went wrong! Please contact your administrator.
      </EmptyDiv>
    </SpinnerWrapper>
  );
};

export default observer(LeadsPage);
