import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { Box, IconButton, Tooltip } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import { useMemo } from "react";
import { filesTabColumnHeaders } from "../../Db";
import { CircularProgress } from "@mui/material";
import PaginationComponent from "../pagination/PaginationComponent";
import { pageSize } from "../../Config";
import { AddBoxOutlined, Visibility } from "@material-ui/icons";
import AddFileModal from "../contact_forms_sidepanl/AddFileModal";
import {
  getContactFilesListApi,
  getContactLeadsListApi,
  updateContactFileApi,
} from "../../Api";
import MessageWithLoadingStateCustomDialog from "../alert_dialogue/MessageWithLoadingStateCustomDialog";
import FileUploadComponent from "../file_upload_component/FileUploadComponent";
import { convertBytesToMB, getFilePattern } from "../../Functions";
import { Key } from "@mui/icons-material";
import ViewPasswordModal from "../file_log_modal/ViewPasswordModa";
import CustomAlertMessageDialog from "../alert_dialogue/CustomAlertMessageDialog";

const Container = styled(Box)`
  width: 100%;
  position: relative;
  ${`min-height: calc(100vh - 246px);`};
`;
const Wrapper = styled(Box)`
  display: flex;
`;

const DetailsWrapper = styled(Box)`
  height: fit-content;
  width: 100%;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;

const TableWrapper = styled(Box)`
  width: 100%;
  ${`min-height: calc(100vh - 282px);`};
`;
const PaginationWrapper = styled(Box)`
  padding: 0px 20px;
  background-color: white;
`;
const AddBtnWrapper = styled(Box)`
  position: absolute;
  right: 6px;
  top: 6px;
  color: #185dd2;
  z-index: 2;
  cursor: pointer;
`;

const FilesTab = (props) => {
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [openAddFile, setOpenAddFile] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [openSubmitResponseDialog, setOpenSubmitResponseDialog] =
    useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [showFileSizeAlert, setShowFileSizeAlert] = useState(false);

  const getContactFiles = async () => {
    let response = await getContactFilesListApi({
      contactID: props.id,
      page: 0,
      page_size: 50,
    });
    if (Object.keys(response).length > 0) {
      setRow(response.items);
    } else {
      setRow([]);
    }
  };

  const init = async () => {
    setLoading(true);
    await getContactFiles();
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);
  useEffect(() => {
    if (refresh) {
      init();
      setRefresh(false);
    }
  }, [refresh]);

  const handleFileUpload = async (event, selectedFileData) => {
    let file = event.target.files[0];

    if (file !== null && file !== undefined) {
      const fileSize = convertBytesToMB(file.size ? file.size : 0);
      if (fileSize > 10) {
        //file size limit - 10 MB
        event.preventDefault();
        event.stopPropagation();
        setShowFileSizeAlert(true);
        event.target.value = "";
      } else {
        uploadFile(file, selectedFileData);
      }
    }
  };

  const uploadFile = async (file, selectedFileData) => {
    setUploadingFile(true);
    setOpenSubmitResponseDialog(true);
    let formData = new FormData();
    formData.append("title", selectedFileData.title);
    formData.append("desc", selectedFileData.desc);
    formData.append("file_type", selectedFileData.file_type);
    formData.append("file", file);
    if (selectedFileData.file_password !== null) {
      formData.append("file_password", selectedFileData.file_password);
    }
    let response = await updateContactFileApi({
      contactID: props.id,
      fileID: selectedFileData["id"],
      payload: formData,
    });
    if (!response.hasError()) {
      setIsSubmitFail(false);
      setRefresh(true);
      setSuccessMessage("File uploaded successfully!");
      setUploadingFile(false);
    } else {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
      setUploadingFile(false);
    }
  };

  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "ACTIONS",
      flex: 1,
      width: 100,
      cellRenderer: (params) => {
        return (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              columnGap: "10px",
            }}
          >
            <FileUploadComponent
              getFilePattern={() => getFilePattern(params.data.file_type)}
              handleFileUpload={(event) => {
                handleFileUpload(event, params.data);
              }}
            />
            <IconButton
              onClick={(e) => {
                window.open(params.data.file, "_blank");
              }}
              style={{ padding: "0px" }}
            >
              <Visibility
                style={{
                  cursor: "pointer",
                  width: "20px",
                  height: "20px",
                }}
                color="primary"
              />
            </IconButton>
            <IconButton
              onClick={() => {
                setSelectedRowData(params.data);
                setOpenPasswordDialog(true);
              }}
              style={{ padding: "0px" }}
              disabled={
                params.data.file_password === null ||
                params.data.file_password === ""
              }
            >
              <Tooltip title={"View Password"}>
                <Key
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              </Tooltip>
            </IconButton>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <Container>
        <Wrapper>
          <DetailsWrapper>
            <AddBtnWrapper>
              <AddBoxOutlined
                onClick={() => {
                  setOpenAddFile(true);
                }}
                style={{
                  width: "40px",
                  height: "40px",
                }}
              />
            </AddBtnWrapper>
            {!loading ? (
              <TableWrapper>
                <div className="ag-theme-alpine" style={{ width: "100%" }}>
                  <AgGridReact
                    domLayout="autoHeight"
                    gridOptions={gridOptions}
                    rowData={row}
                    defaultColDef={defaultColDef}
                    columnDefs={filesTabColumnHeaders.concat(actionColumn)}
                    animateRows={true}
                    suppressCellFocus
                    className="paginated-ag-grid"
                    onRowClicked={(row) => {
                      if (row.event.defaultPrevented) {
                        return null;
                      }
                    }}
                  />
                </div>
              </TableWrapper>
            ) : (
              <TableWrapper
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </TableWrapper>
            )}
          </DetailsWrapper>
        </Wrapper>
      </Container>
      {openAddFile && (
        <AddFileModal
          open={openAddFile}
          setOpen={setOpenAddFile}
          contactID={props.id}
          setRefresh={setRefresh}
          setShowFileSizeAlert={setShowFileSizeAlert}
        />
      )}
      {openSubmitResponseDialog && (
        <MessageWithLoadingStateCustomDialog
          open={openSubmitResponseDialog}
          setOpen={setOpenSubmitResponseDialog}
          successMessage={successMessage}
          errorMessage={errorMessage}
          loading={uploadingFile}
          isError={isSubmitFail}
        />
      )}
      {openPasswordDialog && (
        <ViewPasswordModal
          open={openPasswordDialog}
          setOpen={setOpenPasswordDialog}
          data={selectedRowData}
        />
      )}
      {showFileSizeAlert && (
        <CustomAlertMessageDialog
          title={"Alert"}
          open={showFileSizeAlert}
          handleClose={() => setShowFileSizeAlert(false)}
          handleCloseOutside={() => setShowFileSizeAlert(false)}
          message={`Action Failed: The selected file exceeds the allowed limit of 10 MB.`}
        />
      )}
    </>
  );
};

export default observer(FilesTab);
