import { AppBar, Box, Divider, Link, Modal, Toolbar } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  Button,
} from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { dataRenderer } from "../../../Functions";
import { Close, Settings } from "@material-ui/icons";
import {
  createFbPageConfig,
  getFacebookAllPagesData,
  getFacebookConfiguredPagesData,
} from "../../../Api";
import LinkIcon from "@mui/icons-material/Link";
import ViewPageConfigDialog from "./fb_config_dialogs/ViewPageConfigDialog";
const dialogWidth = 600;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
`;

const ViewFacebookPagesConfigDialog = ({ open, setOpen, isConfigCreator }) => {
  const [selectedPageID, setSelectedPageID] = useState({});
  const [openPageConfigDialog, setOpenPageConfigDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [finalList, setFinalList] = useState([]);
  const [addConfig, setAddConfig] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const columns = [
    {
      field: "sl_no",
      headerName: "S.NO",
      width: 70,

      valueGetter: "node.rowIndex + 1",
    },
    {
      field: "name",
      headerName: "PAGE NAME",
      flex: 1,

      cellRenderer: (params) => {
        let value = dataRenderer(params.data.name);
        return (
          <Tooltip title={value} placement="bottom-start">
            <span style={{ whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
              {value}
            </span>
          </Tooltip>
        );
      },
    },

    {
      field: "actions",
      headerName: "ACTIONS",
      width: 100,

      cellRenderer: (params) => {
        if (params.data.status === "connected") {
          return (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                gap: "8px",
              }}
            >
              <Tooltip title="Configure">
                <IconButton
                  style={{ padding: "0px" }}
                  color="primary"
                  onClick={() => {
                    setSelectedPageID(params.data.id);
                    setOpenPageConfigDialog(true);
                  }}
                >
                  <Settings />
                </IconButton>
              </Tooltip>
            </Box>
          );
        } else {
          return (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Tooltip title={"Link page"}>
                <IconButton
                  onClick={() => {
                    handleLinkPage(params.data);
                  }}
                  variant="outlined"
                  color="primary"
                  sx={{
                    textTransform: "none",
                    fontSize: "10px",
                  }}
                >
                  <LinkIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        }
      },
    },
  ];

  const handleLinkPage = async (pageData) => {
    let payload = {
      fb_page_name: pageData.name,
      fb_page_id: pageData.id,
      fb_page_token: pageData.access_token,
    };
    const response = await createFbPageConfig({ payload: payload });
    if (!response.hasError()) {
      setRefresh(true);
    }
  };

  const init = async () => {
    let configuredResponse = await getFacebookConfiguredPagesData();

    let finalArray = configuredResponse.map((item) => {
      return {
        ...item,
        status: "connected",
      };
    });
    if (isConfigCreator) {
      let response = await getFacebookAllPagesData();
      for (let i = 0; i < response.length; i++) {
        let isNotConfigured = !configuredResponse.some(
          (item) => item.id === response[i].id
        );

        if (isNotConfigured) {
          finalArray.push({
            ...response[i],
            status: "not connected",
          });
        }
      }
    }
    setFinalList(finalArray);
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (refresh) {
      setLoading(true);
      init();
      setRefresh(false);
    }
  }, [refresh]);

  const renderLinkPagesComponent = () => {
    return (
      <Link
        onClick={() => {
          setAddConfig(true);
        }}
      >
        {" "}
        <Typography
          style={{
            width: "fit-content",
            fontSize: "16px",
            fontWeight: "600",
            textAlign: "left",
          }}
        >
          Link FB Pages
        </Typography>
      </Link>
    );
  };

  return (
    <>
      <Modal
        disableEnforceFocus
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <AppBar
              component={"nav"}
              elevation={0}
              position="sticky"
              color="inherit"
              sx={{
                top: 0,
                bottom: "auto",
                width: "100%",
                right: 0,
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                }}
              >
                <HeaderWrapper>
                  <Header>FB Pages Configuration</Header>

                  <IconButton
                    style={{ padding: "0px" }}
                    color="primary"
                    onClick={handleClose}
                  >
                    <Close />
                  </IconButton>
                </HeaderWrapper>
              </Toolbar>
            </AppBar>
            <Divider style={{ margin: "0px 16px" }} />
            <Container>
              {!loading ? (
                isConfigCreator ? (
                  finalList.length > 0 || addConfig ? (
                    <Box
                      style={{
                        height: "300px",
                        overflowY: "auto",
                      }}
                    >
                      <div
                        className="ag-theme-alpine"
                        style={{ width: "100%" }}
                      >
                        <AgGridReact
                          domLayout="autoHeight"
                          gridOptions={gridOptions}
                          rowData={finalList}
                          defaultColDef={defaultColDef}
                          columnDefs={columns}
                          animateRows={true}
                          suppressCellFocus
                          overlayNoRowsTemplate={"No Pages Found"}
                        />
                      </div>
                    </Box>
                  ) : (
                    <>
                      <Box
                        sx={{
                          height: "300px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {renderLinkPagesComponent()}
                      </Box>
                    </>
                  )
                ) : (
                  <>
                    <Box
                      style={{
                        height: "300px",
                        overflowY: "auto",
                      }}
                    >
                      <div
                        className="ag-theme-alpine"
                        style={{ width: "100%" }}
                      >
                        <AgGridReact
                          domLayout="autoHeight"
                          gridOptions={gridOptions}
                          rowData={finalList}
                          defaultColDef={defaultColDef}
                          columnDefs={columns}
                          animateRows={true}
                          suppressCellFocus
                          overlayNoRowsTemplate={"No Pages Found"}
                        />
                      </div>
                    </Box>
                  </>
                )
              ) : (
                <Box
                  style={{
                    height: "300px",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </Container>
          </Box>
        </ModelContainer>
      </Modal>

      {openPageConfigDialog && (
        <ViewPageConfigDialog
          open={openPageConfigDialog}
          setOpen={setOpenPageConfigDialog}
          pageID={selectedPageID}
        />
      )}
    </>
  );
};

export default ViewFacebookPagesConfigDialog;
