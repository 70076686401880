import React, { useState, useCallback, useRef, useEffect } from "react";
import Footer from "../../components/landing_page_components/footer/Footer";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { ChevronRight } from "@material-ui/icons";
import { Divider, Grid } from "@material-ui/core";
import { Box, Typography, Button, Tooltip, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import UploadDropContainer from "../../components/branding_page_components/UploadDropContainer";
import ImageComponent from "../../components/branding_page_components/ImageComponent";
import { useDebounceEffect } from "../../components/branding_page_components/useDebounceEffect";
import { canvasPreview } from "../../components/branding_page_components/CanvasPreview";
import {
  sendBrandingTextLogoApi,
  sendBrandingCircularLogoApi,
  sendBrandingIconApi,
  sendBrandingSquareLogoApi,
} from "../../Api";
import rootStore from "../../stores/RootStore";
import DeleteIcon from "@mui/icons-material/Delete";

const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 84px);`};
  height: 1500px;
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;
const SectionHeadingText = styled(Typography)`
  margin: 0px;
  font: normal normal 600 16px Open Sans;
  color: #4d4e4f;
  margin-bottom: 10px;
`;
const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
`;
const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  row-gap: 25px;
`;
const UploadContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  width: "100%",
  height: "600px",
  backgroundColor: "white",
  border: "1px solid #eceef2",
});

const BrandingPage = () => {
  const [imgSrcLogo, setImgSrcLogo] = useState(null);
  const [cropLogo, setCropLogo] = useState({ aspect: 625 / 72 });
  const [imgSrcImage, setImgSrcImage] = useState(null);
  const [cropImage, setCropImage] = useState({ aspect: 1 });
  const [imgSrcIcon, setImgSrcIcon] = useState(null);
  const [cropIcon, setCropIcon] = useState({ aspect: 1 });
  const [imgSrcSplash, setImgSrcSplash] = useState(null);
  const [cropSplash, setCropSplash] = useState({ aspect: 1 });

  const [completedCropLogo, setCompletedCropLogo] = useState(null);
  const [completedCropImage, setCompletedCropImage] = useState(null);
  const [completedCropIcon, setCompletedCropIcon] = useState(null);
  const [completedCropSplash, setCompletedCropSplash] = useState(null);

  const [aspectLogo, setAspectLogo] = useState(625 / 72);
  const [aspectIcon, setAspectIcon] = useState(1);
  const [aspectSplash, setAspectSplash] = useState(1);
  const [aspectImage, setAspectImage] = useState(1);

  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);

  const [scaleLoading, setScaleLoading] = useState(1);
  const [rotateLoading, setRotateLoading] = useState(0);

  const [scaleIcon, setScaleIcon] = useState(1);
  const [rotateIcon, setRotateIcon] = useState(0);

  const [scaleSplash, setScaleSplash] = useState(1);
  const [rotateSplash, setRotateSplash] = useState(0);

  //saved image urls
  const [resultImage, setResultImage] = useState({
    logo: null,
    icon: null,
    loading: null,
    splash: null,
  });

  const imageDimensions = {
    logo: { width: 625, height: 72 },
    loading: { width: 512, height: 512 },
    icon: { width: 256, height: 256 },
    splash: { width: 512, height: 512 },
  };
  const tooltips = {
    logo: "Image size should be 625x71 pixels",
    loading: "Image size should be 512x512 pixels",
    icon: "Image size should be 256x256 pixels",
    splash: "Image size should be 512x512 pixels",
  };

  const blobUrlRef = useRef("");
  const logoImgRef = useRef(null);
  const imageImgRef = useRef(null);
  const iconImageRef = useRef(null);
  const splashImageRef = useRef(null);
  const previewCanvasRefLogo = useRef(null);
  const previewCanvasRefImage = useRef(null);
  const previewCanvasRefIcon = useRef(null);
  const previewCanvasRefSplash = useRef(null);

  const setup = () => {
    const textLogo =
      rootStore.userStore.UserData.account.cobranding_configuration?.text_logo;
    if (textLogo) {
      setResultImage((prev) => ({ ...prev, logo: textLogo }));
      setImgSrcLogo(textLogo);
    }

    const circularLogo =
      rootStore.userStore.UserData.account.cobranding_configuration
        ?.circular_logo;
    if (circularLogo) {
      setResultImage((prev) => ({ ...prev, loading: circularLogo }));
      setImgSrcImage(circularLogo);
    }

    const icon =
      rootStore.userStore.UserData.account.cobranding_configuration?.icon;
    if (icon) {
      setResultImage((prev) => ({ ...prev, icon: icon }));
      setImgSrcIcon(icon);
    }

    const squareLogo =
      rootStore.userStore.UserData.account.cobranding_configuration
        ?.square_logo;
    if (squareLogo) {
      setResultImage((prev) => ({ ...prev, splash: squareLogo }));
      setImgSrcSplash(squareLogo);
    }
  };

  useEffect(() => {
    setup(); //init setup - loading existing images
  }, []);

  // Helper functions
  const onImageLoad = (e, setCropFunc, aspect, type) => {
    const renderedWidth = e.currentTarget.width;
    const renderedHeight = e.currentTarget.height;
    const naturalWidth = e.currentTarget.naturalWidth;
    const naturalHeight = e.currentTarget.naturalHeight;

    // Calculate the scale factor based on height
    const scaleFactor = renderedHeight / naturalHeight;

    // Use predefined crop dimensions
    const originalCropWidth = imageDimensions[type].width;
    const originalCropHeight = imageDimensions[type].height;

    const scaledCropWidth = originalCropWidth * scaleFactor;
    const scaledCropHeight = originalCropHeight * scaleFactor;

    const finalCropWidth = Math.min(scaledCropWidth, renderedWidth);
    const finalCropHeight = Math.min(scaledCropHeight, renderedHeight);

    setCropFunc({
      unit: "px",
      width: finalCropWidth,
      height: finalCropHeight,
      aspect,
    });
  };

  const handleFile = (type, file) => {
    if (file && file.length > 0) {
      setCropLogo(undefined);
      setCropIcon(undefined);
      setCropImage(undefined);
      setCropSplash(undefined);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        if (type === "logo") {
          setImgSrcLogo(reader.result?.toString() || "");
        } else if (type === "loading") {
          setImgSrcImage(reader.result?.toString() || "");
        } else if (type === "icon") {
          setImgSrcIcon(reader.result?.toString() || "");
        } else if (type === "splash") {
          setImgSrcSplash(reader.result?.toString() || "");
        }
      });
      reader.readAsDataURL(file[0]);
    }
  };

  const resetCurrentImage = (type) => {
    setResultImage((prev) => ({ ...prev, [type]: null }));

    switch (type) {
      case "logo":
        setImgSrcLogo(null);
        setCompletedCropLogo(false);
        setScale(1);
        setRotate(0);
        break;
      case "loading":
        setImgSrcImage(null);
        setCompletedCropImage(false);
        setScaleLoading(1);
        setRotateLoading(0);
        break;
      case "icon":
        setImgSrcIcon(null);
        setCompletedCropIcon(false);
        setScaleIcon(1);
        setRotateIcon(0);
        break;
      case "splash":
        setImgSrcSplash(null);
        setCompletedCropSplash(false);
        setScaleSplash(1);
        setRotateSplash(0);
        break;
      default:
    }
  };

  const handleRemove = async (type) => {
    let response = {};
    if (type === "logo") {
      const formData = new FormData();
      formData.append("text_logo", "");
      response = await sendBrandingTextLogoApi({ payload: formData });
    } else if (type === "icon") {
      const formData = new FormData();
      formData.append("icon", "");
      response = await sendBrandingIconApi({ payload: formData });
    } else if (type === "splash") {
      const formData = new FormData();
      formData.append("square_logo", "");
      response = await sendBrandingSquareLogoApi({ payload: formData });
    } else if (type === "loading") {
      const formData = new FormData();
      formData.append("circular_logo", "");
      response = await sendBrandingCircularLogoApi({ payload: formData });
    }

    //reset image if remove was successful
    if(!response.hasError()){
      resetCurrentImage(type);
    }
  };

  //common image save click
  async function onSaveCropClick(type, canvasRef, imgRef, completedCrop) {
    const image = imgRef.current;
    const previewCanvas = canvasRef.current;
    if (!image || !previewCanvas || !completedCrop) {
      return;
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY
    );

    const ctx = offscreen.getContext("2d");
    if (!ctx) {
      return;
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    );

    const blob = await offscreen.convertToBlob({
      type: "image/png",
    });

    //send api
    if (type === "logo") {
      const formData = new FormData();
      formData.append("text_logo", blob, "text_logo.png");
      let response = await sendBrandingTextLogoApi({ payload: formData });
    } else if (type === "icon") {
      const formData = new FormData();
      formData.append("icon", blob, "icon.png");
      let response = await sendBrandingIconApi({ payload: formData });
    } else if (type === "splash") {
      const formData = new FormData();
      formData.append("square_logo", blob, "square_logo.png");
      let response = await sendBrandingSquareLogoApi({ payload: formData });
    }

    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current);
    }
    const newBlobUrl = URL.createObjectURL(blob);
    blobUrlRef.current = newBlobUrl;
    setResultImage((prev) => ({ ...prev, [type]: newBlobUrl }));
  }

  //loading circular crop save
  async function onSaveClickLoadingImage() {
    const image = imageImgRef.current;
    const previewCanvas = previewCanvasRefImage.current;
    if (!image || !previewCanvas || !completedCropImage) {
      throw new Error("Crop canvas does not exist");
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(512, 512); // Fixed size 512x512
    const ctx = offscreen.getContext("2d");
    if (!ctx) {
      throw new Error("No 2d context");
    }

    // Clip the image to a circular shape
    ctx.beginPath();
    ctx.arc(256, 256, 256, 0, 2 * Math.PI); // Center the circle and set radius
    ctx.closePath();
    ctx.clip(); // Clip the drawing to the circle shape

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    );

    const blob = await offscreen.convertToBlob({
      type: "image/png",
    });

    //circular logo save api
    const formData = new FormData();
    formData.append("circular_logo", blob, "circular_logo.png");
    let response = await sendBrandingCircularLogoApi({ payload: formData });

    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current);
    }
    const newBlobUrl = URL.createObjectURL(blob);
    blobUrlRef.current = newBlobUrl;
    setResultImage({ ...resultImage, loading: newBlobUrl });
  }

  const onCropWheel = useCallback((event, type) => {
    const cropArea = document.getElementById("crop-area-" + type);

    const handleWheel = (event) => {
      event.preventDefault();
    };

    if (cropArea) {
      cropArea.addEventListener("wheel", handleWheel, { passive: false });
    }

    if (type === "logo") {
      setScale((prevScale) => {
        const newScale = prevScale + event.deltaY * -0.001;
        return Math.min(Math.max(newScale, 0.3), 3);
      });
    } else if (type === "loading") {
      setScaleLoading((prevScale) => {
        const newScale = prevScale + event.deltaY * -0.001;
        return Math.min(Math.max(newScale, 0.3), 3);
      });
    } else if (type === "icon") {
      setScaleIcon((prevScale) => {
        const newScale = prevScale + event.deltaY * -0.001;
        return Math.min(Math.max(newScale, 0.3), 3);
      });
    } else if (type === "splash") {
      setScaleSplash((prevScale) => {
        const newScale = prevScale + event.deltaY * -0.001;
        return Math.min(Math.max(newScale, 0.3), 3);
      });
    }
  }, []);

  //logo preview
  useDebounceEffect(
    async () => {
      if (
        completedCropLogo?.width &&
        completedCropLogo?.height &&
        logoImgRef.current &&
        previewCanvasRefLogo.current
      ) {
        canvasPreview(
          logoImgRef.current,
          previewCanvasRefLogo.current,
          completedCropLogo,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCropLogo, scale, rotate]
  );

  //icon preview
  useDebounceEffect(
    async () => {
      if (
        completedCropIcon?.width &&
        completedCropIcon?.height &&
        iconImageRef.current &&
        previewCanvasRefIcon.current
      ) {
        canvasPreview(
          iconImageRef.current,
          previewCanvasRefIcon.current,
          completedCropIcon,
          scaleIcon,
          rotateIcon
        );
      }
    },
    100,
    [completedCropIcon, scaleIcon, rotateIcon]
  );

  //splash preview
  useDebounceEffect(
    async () => {
      if (
        completedCropSplash?.width &&
        completedCropSplash?.height &&
        splashImageRef.current &&
        previewCanvasRefSplash.current
      ) {
        canvasPreview(
          splashImageRef.current,
          previewCanvasRefSplash.current,
          completedCropSplash,
          scaleSplash,
          rotateSplash
        );
      }
    },
    100,
    [completedCropSplash, scaleSplash, rotateSplash]
  );

  //loading preview
  useDebounceEffect(
    async () => {
      if (
        completedCropImage?.width &&
        completedCropImage?.height &&
        imageImgRef.current &&
        previewCanvasRefImage.current
      ) {
        const previewCanvas = previewCanvasRefImage.current;
        const ctx = previewCanvas.getContext("2d");

        if (ctx) {
          ctx.clearRect(0, 0, previewCanvas.width, previewCanvas.height);

          // Clip to a circle
          ctx.beginPath();
          ctx.arc(256, 256, 256, 0, 2 * Math.PI);
          ctx.closePath();
          ctx.clip();

          canvasPreview(
            imageImgRef.current,
            previewCanvas,
            completedCropImage,
            scaleLoading,
            rotateLoading
          );
        }
      }
    },
    100,
    [completedCropImage, scaleLoading, rotateLoading]
  );

  const renderResultImage = (src) => {
    return (
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <ImageComponent src={src} />
      </Box>
    );
  };

  return (
    <>
      <ParentContainer>
        <Container>
          <TopWrapper>
            <Header>Admin</Header>
            <ChevronRight style={{ width: "16px", height: "16px" }} />
            <Header>Branding</Header>
          </TopWrapper>
          <Wrapper>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {/* Text Logo Section */}
                <UploadContainer>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "12px 20px",
                        height: "10%",
                        backgroundColor: "#F8F8F8",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <SectionHeadingText>Text logo</SectionHeadingText>
                      <Tooltip title={tooltips["logo"]}>
                        <InfoIcon color={"primary"} />
                      </Tooltip>
                    </Box>

                    <Divider sx={{ backgroundColor: "#eceef2" }} />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: "90%",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "8px",
                          width: "100%",
                          height: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {resultImage["logo"] !== null ? (
                          renderResultImage(resultImage["logo"])
                        ) : imgSrcLogo === null ? (
                          <UploadDropContainer
                            setFile={(file) => {
                              handleFile("logo", file);
                            }}
                          />
                        ) : (
                          <Box
                            sx={{
                              overflow: "hidden",
                              padding: "12px 12px",
                            }}
                          >
                            <div
                              onWheel={(e) => onCropWheel(e, "logo")}
                              id="crop-area-logo"
                            >
                              <ReactCrop
                                src={imgSrcLogo}
                                crop={cropLogo}
                                onChange={(_, percentCrop) =>
                                  setCropLogo(percentCrop)
                                }
                                onComplete={(c) => setCompletedCropLogo(c)}
                                aspect={undefined}
                              >
                                <img
                                  ref={logoImgRef}
                                  alt="Crop me"
                                  src={imgSrcLogo}
                                  style={{
                                    transform: `scale(${scale}) rotate(${rotate}deg)`,
                                    maxHeight: "400px",
                                    width: "100%",
                                  }}
                                  onLoad={(e) =>
                                    onImageLoad(
                                      e,
                                      setCropLogo,
                                      aspectLogo,
                                      "logo"
                                    )
                                  }
                                />
                              </ReactCrop>
                            </div>
                          </Box>
                        )}

                        {!!completedCropLogo && (
                          <>
                            <div style={{ display: "none" }}>
                              <canvas
                                ref={previewCanvasRefLogo}
                                style={{
                                  border: "1px solid black",
                                  objectFit: "cover",
                                  width: completedCropLogo.width,
                                  height: completedCropLogo.height,
                                }}
                              />
                            </div>
                          </>
                        )}
                      </Box>
                    </Box>
                    <Divider sx={{ backgroundColor: "#eceef2" }} />

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        padding: "12px 20px",
                        height: "10%",
                      }}
                    >
                      {" "}
                      {imgSrcLogo !== null &&
                        (resultImage["logo"] === null ? (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              onSaveCropClick(
                                "logo",
                                previewCanvasRefLogo,
                                logoImgRef,
                                completedCropLogo
                              )
                            }
                            style={{
                              font: "normal normal 600 12px Open Sans",
                              textTransform: "none",
                            }}
                          >
                            Save
                          </Button>
                        ) : (
                          <Box>
                            <IconButton
                              onClick={() => {
                                handleRemove("logo");
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => resetCurrentImage("logo")}
                              style={{
                                font: "normal normal 600 12px Open Sans",
                                textTransform: "none",
                              }}
                            >
                              Update
                            </Button>
                          </Box>
                        ))}
                    </Box>
                  </Box>
                </UploadContainer>
              </Grid>
              <Grid item xs={6}>
                {/* Icon Section */}
                <UploadContainer>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "12px 20px",
                        height: "10%",
                        backgroundColor: "#F8F8F8",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <SectionHeadingText>Icon image</SectionHeadingText>
                      <Tooltip title={tooltips["icon"]}>
                        <InfoIcon color={"primary"} />
                      </Tooltip>
                    </Box>

                    <Divider sx={{ backgroundColor: "#eceef2" }} />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: "90%",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "8px",
                          width: "100%",
                          height: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {resultImage["icon"] !== null ? (
                          renderResultImage(resultImage["icon"])
                        ) : imgSrcIcon === null ? (
                          <UploadDropContainer
                            setFile={(file) => {
                              handleFile("icon", file);
                            }}
                          />
                        ) : (
                          <Box
                            sx={{
                              overflow: "hidden",
                              padding: "12px 12px",
                            }}
                          >
                            <div
                              onWheel={(e) => onCropWheel(e, "icon")}
                              id="crop-area-icon"
                            >
                              <ReactCrop
                                src={imgSrcIcon}
                                crop={cropIcon}
                                onChange={(_, percentCrop) =>
                                  setCropIcon(percentCrop)
                                }
                                onComplete={(c) => setCompletedCropIcon(c)}
                                aspect={1}
                                minWidth={256}
                                minHeight={256}
                                locked={true}
                              >
                                <img
                                  ref={iconImageRef}
                                  alt="Crop me"
                                  src={imgSrcIcon}
                                  style={{
                                    transform: `scale(${scaleIcon}) rotate(${rotateIcon}deg)`,
                                    maxHeight: "400px",
                                  }}
                                  onLoad={(e) =>
                                    onImageLoad(
                                      e,
                                      setCropIcon,
                                      aspectIcon,
                                      "icon"
                                    )
                                  }
                                />
                              </ReactCrop>
                            </div>
                          </Box>
                        )}
                        {!!completedCropIcon && (
                          <>
                            <div style={{ display: "none" }}>
                              <canvas
                                ref={previewCanvasRefIcon}
                                style={{
                                  border: "1px solid black",
                                  objectFit: "cover",
                                  width: completedCropIcon.width,
                                  height: completedCropIcon.height,
                                }}
                              />
                            </div>
                          </>
                        )}
                      </Box>
                    </Box>
                    <Divider sx={{ backgroundColor: "#eceef2" }} />

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        padding: "12px 20px",
                        height: "10%",
                      }}
                    >
                      {" "}
                      {imgSrcIcon !== null &&
                        (resultImage["icon"] === null ? (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              onSaveCropClick(
                                "icon",
                                previewCanvasRefIcon,
                                iconImageRef,
                                completedCropIcon
                              )
                            }
                            style={{
                              font: "normal normal 600 12px Open Sans",
                              textTransform: "none",
                            }}
                          >
                            Save
                          </Button>
                        ) : (
                          <Box>
                            <IconButton
                              onClick={() => {
                                handleRemove("icon");
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => resetCurrentImage("icon")}
                              style={{
                                font: "normal normal 600 12px Open Sans",
                                textTransform: "none",
                              }}
                            >
                              Update
                            </Button>
                          </Box>
                        ))}
                    </Box>
                  </Box>
                </UploadContainer>
              </Grid>
              <Grid item xs={6}>
                {/* Loading Image Section */}
                <UploadContainer>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "12px 20px",
                        backgroundColor: "#F8F8F8",
                        height: "10%",
                      }}
                    >
                      {" "}
                      <SectionHeadingText>Loading image</SectionHeadingText>
                      <Tooltip title={tooltips["loading"]}>
                        <InfoIcon color={"primary"} />
                      </Tooltip>
                    </Box>

                    <Divider sx={{ backgroundColor: "#eceef2" }} />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: "90%",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "8px",
                          width: "100%",
                          height: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {resultImage["loading"] !== null ? (
                          renderResultImage(resultImage["loading"])
                        ) : imgSrcImage === null ? (
                          <UploadDropContainer
                            setFile={(file) => {
                              handleFile("loading", file);
                            }}
                          />
                        ) : (
                          <Box
                            sx={{
                              overflow: "hidden",
                              padding: "12px 12px",
                            }}
                          >
                            <div
                              onWheel={(e) => onCropWheel(e, "loading")}
                              id="crop-area-loading"
                            >
                              <ReactCrop
                                src={imgSrcImage}
                                crop={cropImage}
                                onChange={(_, percentCrop) =>
                                  setCropImage(percentCrop)
                                }
                                onComplete={(c) => setCompletedCropImage(c)}
                                aspect={1}
                                keepSelection={true}
                                circularCrop={true}
                              >
                                <img
                                  ref={imageImgRef}
                                  alt="Crop me"
                                  src={imgSrcImage}
                                  style={{
                                    transform: `scale(${scaleLoading}) rotate(${rotateLoading}deg)`,
                                    maxHeight: "400px",
                                  }}
                                  onLoad={(e) =>
                                    onImageLoad(
                                      e,
                                      setCropImage,
                                      aspectImage,
                                      "loading"
                                    )
                                  }
                                />
                              </ReactCrop>
                            </div>
                          </Box>
                        )}
                        {!!completedCropImage && (
                          <>
                            <div style={{ display: "none" }}>
                              <canvas
                                ref={previewCanvasRefImage}
                                style={{
                                  borderRadius: "50%",
                                  border: "1px solid black",
                                  objectFit: "cover",
                                  width: 256,
                                  height: 256,
                                }}
                              />
                            </div>
                          </>
                        )}
                      </Box>
                    </Box>
                    <Divider sx={{ backgroundColor: "#eceef2" }} />

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        padding: "12px 20px",
                        height: "10%",
                      }}
                    >
                      {imgSrcImage !== null &&
                        (resultImage["loading"] === null ? (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              onSaveClickLoadingImage(
                                previewCanvasRefImage,
                                imageImgRef,
                                completedCropImage
                              )
                            }
                            style={{
                              font: "normal normal 600 12px Open Sans",
                              textTransform: "none",
                            }}
                          >
                            Save
                          </Button>
                        ) : (
                          <Box>
                            <IconButton
                              onClick={() => {
                                handleRemove("loading");
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => resetCurrentImage("loading")}
                              style={{
                                font: "normal normal 600 12px Open Sans",
                                textTransform: "none",
                              }}
                            >
                              Update
                            </Button>
                          </Box>
                        ))}
                    </Box>
                  </Box>
                </UploadContainer>
              </Grid>

              <Grid item xs={6}>
                {/* Splash image Section */}
                <UploadContainer>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "12px 20px",
                        height: "10%",
                        backgroundColor: "#F8F8F8",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <SectionHeadingText>Splash image</SectionHeadingText>
                      <Tooltip title={tooltips["splash"]}>
                        <InfoIcon color={"primary"} />
                      </Tooltip>
                    </Box>

                    <Divider sx={{ backgroundColor: "#eceef2" }} />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        height: "90%",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "8px",
                          width: "100%",
                          height: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {resultImage["splash"] !== null ? (
                          renderResultImage(resultImage["splash"])
                        ) : imgSrcSplash === null ? (
                          <UploadDropContainer
                            setFile={(file) => {
                              handleFile("splash", file);
                            }}
                          />
                        ) : (
                          <Box
                            sx={{
                              overflow: "hidden",
                              padding: "12px 12px",
                            }}
                          >
                            {" "}
                            <div
                              onWheel={(e) => onCropWheel(e, "splash")}
                              id="crop-area-splash"
                            >
                              <ReactCrop
                                src={imgSrcSplash}
                                crop={cropSplash}
                                onChange={(_, percentCrop) =>
                                  setCropSplash(percentCrop)
                                }
                                onComplete={(c) => setCompletedCropSplash(c)}
                                aspect={1}
                                minWidth={512}
                                minHeight={512}
                                locked={true}
                              >
                                <img
                                  ref={splashImageRef}
                                  alt="Crop me"
                                  src={imgSrcSplash}
                                  style={{
                                    transform: `scale(${scaleSplash}) rotate(${rotateSplash}deg)`,
                                    maxHeight: "400px",
                                  }}
                                  onLoad={(e) =>
                                    onImageLoad(
                                      e,
                                      setCropSplash,
                                      aspectSplash,
                                      "splash"
                                    )
                                  }
                                />
                              </ReactCrop>
                            </div>
                          </Box>
                        )}
                        {!!completedCropSplash && (
                          <>
                            <div style={{ display: "none" }}>
                              <canvas
                                ref={previewCanvasRefSplash}
                                style={{
                                  borderRadius: "50%",
                                  border: "1px solid black",
                                  objectFit: "cover",
                                  width: 256,
                                  height: 256,
                                }}
                              />
                            </div>
                          </>
                        )}
                      </Box>
                    </Box>
                    <Divider sx={{ backgroundColor: "#eceef2" }} />

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        padding: "12px 20px",
                        height: "10%",
                      }}
                    >
                      {imgSrcSplash !== null &&
                        (resultImage["splash"] === null ? (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              onSaveCropClick(
                                "splash",
                                previewCanvasRefSplash,
                                splashImageRef,
                                completedCropSplash
                              )
                            }
                            style={{
                              font: "normal normal 600 12px Open Sans",
                              textTransform: "none",
                            }}
                          >
                            Save
                          </Button>
                        ) : (
                          <Box>
                            <IconButton
                              onClick={() => {
                                handleRemove("splash");
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => resetCurrentImage("splash")}
                              style={{
                                font: "normal normal 600 12px Open Sans",
                                textTransform: "none",
                              }}
                            >
                              Update
                            </Button>
                          </Box>
                        ))}
                    </Box>
                  </Box>
                </UploadContainer>
              </Grid>
            </Grid>
          </Wrapper>
        </Container>
      </ParentContainer>
      <Footer />
    </>
  );
};

export default observer(BrandingPage);
