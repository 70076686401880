import { AppBar, Box, Divider, Toolbar, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { getLocalizedText } from "../../../../Functions";
import {
  FormControl,
  Typography,
  OutlinedInput,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import { indiamartIntegrationConfigTypes } from "../../../../Db";
import EditIcon from "@mui/icons-material/Edit";

const dialogWidth = 600;

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: `${dialogWidth}px`,
  textAlign: "left",
  margin: "auto",
  backgroundColor: "white",
  height: "fit-content",
  padding: "16px",
  overflowY: "auto",
  rowGap: "10px",
  "&::-webkit-scrollbar": {
    display: "none",
  },
}));

const Header = styled(Typography)(({ theme }) => ({
  font: "normal normal 600 18px Open Sans",
  color: "#4d4e4f",
}));

const SubHeader = styled(Typography)(({ theme }) => ({
  font: "normal normal normal 14px Open Sans",
  color: "#6f6f6f",
  marginTop: "2px",
}));

const Label = styled(Typography)(({ theme }) => ({
  font: "normal normal 500 12px Open Sans",
  color: "#4d4e4f",
}));

const HeaderWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

const InputWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

const IndiamartFirstDialog = ({
  apiKey,
  setApiKey,
  name,
  setName,
  integrationData,
  configType,
  setConfigType,
  isEdit,
}) => {
  const classes = useStyles();
  const [isEditable, setIsEditable] = useState(false);

  const handleEditClick = () => {
    setIsEditable(!isEditable);
  };

  return (
    <>
      <Box>
        <AppBar
          component={"nav"}
          elevation={0}
          position="sticky"
          color="inherit"
          sx={{
            top: 0,
            bottom: "auto",
            width: "100%",
            right: 0,
          }}
        >
          <Toolbar
            style={{
              padding: "16px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <HeaderWrapper>
                <Header>{integrationData.title} Configuration</Header>
                <SubHeader>
                  {" "}
                  {getLocalizedText("fill_in_the_below_details")}
                </SubHeader>
              </HeaderWrapper>
              {integrationData.help_link_url !== "" && (
                <Tooltip title="Help">
                  <IconButton
                    aria-label="help"
                    color="primary"
                    onClick={() => {
                      window.open(integrationData.help_link_url, "_blank");
                    }}
                  >
                    <HelpIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Toolbar>
        </AppBar>
        <Divider style={{ margin: "0px 16px" }} />
        <Container>
          <InputWrapper>
            <Label>Name*</Label>
            <FormControl sx={{ width: "100%" }}>
              <OutlinedInput
                placeholder="Enter value"
                required
                onChange={(e) => {
                  setName(e.target.value);
                }}
                name="api_key"
                style={{ height: "30px" }}
                inputProps={{
                  "aria-label": "weight",
                  style: {
                    fontSize: "12px",
                  },
                }}
                value={name}
              />
            </FormControl>
          </InputWrapper>
          <InputWrapper>
            <Label>Configuration*</Label>
            <FormControl
              sx={{ width: "100%" }}
              classes={{
                root: classes.quantityRoot,
              }}
            >
              <Select
                classes={{
                  icon: classes.icon,
                }}
                disabled={isEdit}
                displayEmpty
                required
                name="config_type"
                onChange={(e) => {
                  setConfigType(e.target.value);
                }}
                value={configType}
                disableUnderline
                variant="outlined"
                style={{
                  width: "100%",
                  height: "30px",
                  fontSize: "12px",
                }}
              >
                {indiamartIntegrationConfigTypes.map((item, i) => {
                  return (
                    <MenuItem value={item} key={i} style={{ fontSize: "12px" }}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </InputWrapper>
          <InputWrapper>
            <Label>API Key*</Label>
            <FormControl sx={{ width: "100%" }}>
              {configType === "push" || !isEdit ? (
                <OutlinedInput
                  placeholder="Enter value"
                  disabled={configType === "push"}
                  required
                  onChange={(e) => {
                    setApiKey(e.target.value);
                  }}
                  name="api_key"
                  style={{ height: "30px" }}
                  inputProps={{
                    "aria-label": "weight",
                    style: {
                      fontSize: "12px",
                    },
                  }}
                  value={apiKey}
                />
              ) : (
                <Box
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <OutlinedInput
                    placeholder="Enter value"
                    disabled={!isEditable}
                    required
                    type={isEditable ? "text" : "password"}
                    onChange={(e) => setApiKey(e.target.value)}
                    name="api_key"
                    style={{ height: "30px", flex: 1 }}
                    inputProps={{
                      "aria-label": "weight",
                      style: {
                        fontSize: "12px",
                      },
                    }}
                    value={apiKey}
                  />
                  <Tooltip title={"Edit API Key"}>
                    <IconButton onClick={handleEditClick}>
                      <EditIcon />
                    </IconButton>{" "}
                  </Tooltip>
                </Box>
              )}
            </FormControl>
          </InputWrapper>
        </Container>
      </Box>
    </>
  );
};

export default IndiamartFirstDialog;
