import React, { useCallback, useState } from "react";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  FormControl,
  Grid,
  makeStyles,
  Modal,
  Select as MuiSelect,
} from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import {
  addStatusToStageApi,
  editStatusToStageApi,
  getAdminMembersApi,
  getAdminStagesApi,
  getAdminStatusesApi,
} from "../../Api";
import { useEffect } from "react";
import "./modal.css";
import { MultiSelect } from "react-multi-select-component";
import { myTheme } from "../../themeUtils";
import analytics from "../../Analytics";
import { getAvailableStatusListByStage, getLocalizedText } from "../../Functions";
import { CircularProgress, Divider, Typography } from "@mui/material";
import rootStore from "../../stores/RootStore";
import { Add, KeyboardArrowDown } from "@material-ui/icons";
import Select from "react-select";

import MuiAlert from "@mui/material/Alert";
import { DndProvider } from "react-dnd";
import DraggableUpdateFieldChipComponent from "./draggable_chip_component/DraggableUpdateFieldChipComponent";
import { HTML5Backend } from "react-dnd-html5-backend";
import AddUpdateFieldDialog from "./AddUpdateFieldDialog";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "10px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  ::-webkit-scrollbar {
    width: 0.2em;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ffffff;
  }
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border-radius: 8px;
  padding: 30px;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
`;
const Form = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 420px;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;

const ButtonWrapper = styled(Box)`
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;
const Message = styled(Typography)`
  font: normal normal normal 16px Open Sans;
  color: #4d4e4f;

  margin: 0px;
  text-align: center;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
`;

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid hsl(0, 0%, 80%)",
      borderRadius: "4px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid hsl(0, 0%, 80%)",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid hsl(0, 0%, 80%)",
      borderRadius: "4px",
    },
  },
  icon: {
    color: "hsl(0, 0%, 80%)",
  },
});
const assignToScopeList = [
  { label: "Team", value: "team" },
  { label: "User", value: "user" },
];
const AddStatusToStageModal = (props) => {
  const classes = useStyles();
  const [user, setUser] = useState({ status: "", stage: "" });
  const [jumpToStage, setJumpToStage] = useState(null);
  const [statusList, setStatusList] = useState([]);
  const [filteredStatusList, setFilteredStatusList] = useState([]);
  const [stageList, setStageList] = useState([]);
  const [selectedColumnList, setSelectedColumnList] = useState({});
  const [columnsList, setColumnsList] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [jumpToStageList, setJumpToStageList] = useState([]);
  const [selectedAssignToScope, setSelectedAssignToScope] = useState(null);
  const [teamList, setTeamList] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [multiSelectTeamList, setMultiSelectTeamList] = useState([]);
  const [multiSelectMemberList, setMultiSelectMemberList] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [openAddUpdateFieldDialog, setOpenAddUpdateFieldDialog] =
    useState(false);

  const handleClick = () => {
    props.setOpen(false);
    props.setIsEdit(false);
  };
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const editStatusToStage = async () => {
      let data = {
        lead_status_id: user.status,
        lead_stage_id: user.stage,
        status_stage_id: props.editableData.id,
      };
      data["jump_to"] = jumpToStage !== null ? jumpToStage["value"] : null;

      let tempList = [];
      Object.keys(selectedColumnList).forEach((key) => {
        let tempObj = {
          id: key,
          required: selectedColumnList[key]["required"],
          overwrite: selectedColumnList[key]["overwrite"],
          append: selectedColumnList[key]["append"],
        };
        tempList.push(tempObj);
      });
      data["update_fields"] = tempList;
      if (selectedAssignToScope !== null) {
        data["submit_enabled"] = true;
        if (selectedAssignToScope["value"] === "team") {
          let newList = [];
          selectedTeams.forEach((item) => {
            let index = teamList.findIndex(
              (team) => team["id"] === item["value"]
            );
            if (index !== -1) {
              let tempObj = {
                id: teamList[index]["id"],
                name: teamList[index]["name"],
              };
              tempObj["type"] = "team";
              newList.push(tempObj);
            }
          });
          data["submit_to"] = newList;
        } else if (selectedAssignToScope["value"] === "user") {
          let newList = [];
          selectedMembers.forEach((item) => {
            let index = memberList.findIndex(
              (member) => member["user_id"] === item["value"]
            );
            if (index !== -1) {
              let tempObj = {
                id: memberList[index]["user_id"],
                name: memberList[index]["name"],
              };
              tempObj["type"] = "user";
              newList.push(tempObj);
            }
          });
          data["submit_to"] = newList;
        }
      } else {
        data["submit_enabled"] = false;
      }
      try {
        let response = await editStatusToStageApi({
          id: props.id,
          data: data,
        });
        setIsSubmitSuccess(true);
        setIsError(false);
        props.setRefresh(!props.refresh);
      } catch (error) {
        setErrorMessage(getLocalizedText("some_error_occured"));
        setIsError(true);
        console.log(error);
      }
    };
    const addStatusToStage = async () => {
      let data = {
        lead_status_id: user.status,
        lead_stage_id: user.stage,
      };
      if (jumpToStage !== null) {
        data["jump_to"] = jumpToStage["value"];
      }
      let tempList = [];
      Object.keys(selectedColumnList).forEach((key) => {
        let tempObj = {
          id: key,
          required: selectedColumnList[key]["required"],
          overwrite: selectedColumnList[key]["overwrite"],
          append: selectedColumnList[key]["append"],
        };
        tempList.push(tempObj);
      });
      data["update_fields"] = tempList;
      if (selectedAssignToScope !== null) {
        data["submit_enabled"] = true;
        if (selectedAssignToScope["value"] === "team") {
          let newList = [];
          selectedTeams.forEach((item) => {
            let index = teamList.findIndex(
              (team) => team["id"] === item["value"]
            );
            if (index !== -1) {
              let tempObj = {
                id: teamList[index]["id"],
                name: teamList[index]["name"],
              };
              tempObj["type"] = "team";
              newList.push(tempObj);
            }
          });
          data["submit_to"] = newList;
        } else if (selectedAssignToScope["value"] === "user") {
          let newList = [];
          selectedMembers.forEach((item) => {
            let index = memberList.findIndex(
              (member) => member["user_id"] === item["value"]
            );
            if (index !== -1) {
              let tempObj = {
                id: memberList[index]["user_id"],
                name: memberList[index]["name"],
              };
              tempObj["type"] = "user";
              newList.push(tempObj);
            }
          });
          data["submit_to"] = newList;
        }
      } else {
        data["submit_enabled"] = false;
      }
      try {
        let response = await addStatusToStageApi({
          id: props.id,
          data: data,
        });
        setIsSubmitSuccess(true);

        setIsError(false);
        props.setRefresh(!props.refresh);
      } catch (error) {
        setErrorMessage(getLocalizedText("some_error_occured"));
        setIsError(true);
        console.log(error);
      }
    };
    if (selectedAssignToScope !== null) {
      if (selectedAssignToScope["value"] === "team") {
        if (selectedTeams.length > 0) {
          if (props.isEdit) {
            editStatusToStage();
          } else {
            addStatusToStage();
          }
        } else {
          setErrorMessage("Please select atleast one team before submitting!");
          setIsError(true);
        }
      } else if (selectedAssignToScope["value"] === "user") {
        if (selectedMembers.length > 0) {
          if (props.isEdit) {
            editStatusToStage();
          } else {
            addStatusToStage();
          }
        } else {
          setErrorMessage("Please select atleast one user before submitting!");
          setIsError(true);
        }
      }
    } else {
      if (props.isEdit) {
        editStatusToStage();
      } else {
        addStatusToStage();
      }
    }
  };
  const handleClose = () => {
    props.setOpen(false);
    props.setIsEdit(false);
  };
  const checkKeyDown = (e) => {
    if (e.code === "Enter") e.preventDefault();
  };

  const getStatus = async () => {
    try {
      let response = await getAdminStatusesApi(props.id);
      const visibleStatuses = response.data.filter(
        (status) => status.is_visible_to_add === true
      );
      setStatusList(visibleStatuses);
      setFilteredStatusList(visibleStatuses);
    } catch (error) {
      console.log(error);
    }
  };
  const getStages = async () => {
    try {
      let response = await getAdminStagesApi(props.id);
      const visibleStages = response.data.filter(
        (stage) => stage.is_visible === true
      );
      initJumpToStageList([...visibleStages]);
      setStageList(visibleStages);
    } catch (error) {
      console.log(error);
    }
  };
  const initJumpToStageList = (list) => {
    let tempList = [];
    list.forEach((stage) => {
      let tempObj = {
        label: stage.stage,
        value: stage.id,
      };
      tempList.push(tempObj);
    });
    setJumpToStageList(tempList);
  };
  const init = async () => {
    await initTeamAndMemberList();
    let filteredColumns = [...rootStore.userStore.AllColumnsList].filter(
      (column) => !column["derived"] && column["editable"]
    );
    filteredColumns.sort((a, b) =>
      a["headerName"].toLowerCase().localeCompare(b["headerName"].toLowerCase())
    );
    setColumnsList(filteredColumns);
    await getStatus();
    await getStages();

    if (props.isEdit === true) {
      analytics.triggerEvent(
        4625000222,
        "edit_statustostage_form_load",
        "project_statustostage_page",
        "",
        {}
      );
      setUser({
        status: props.editableData.lead_status.id,
        stage: props.editableData.lead_stage.id,
      });
      let jumpTo =
        props.editableData.jump_to !== null
          ? {
              label: props.editableData.jump_to.stage,
              value: props.editableData.jump_to.id,
            }
          : null;
      setJumpToStage(jumpTo);
      //********************initializing the assign to values on edit*************** */
      if (props.editableData.hasOwnProperty("submit_to")) {
        if (props.editableData["submit_to"] !== null) {
          let assignToList = props.editableData["submit_to"];
          if (assignToList.length > 0) {
            let type = assignToList[0]["type"];

            if (type === "team") {
              setSelectedAssignToScope({ label: "Team", value: "team" });
              let preselectedTeamList = updateList(assignToList);
              setSelectedTeams(preselectedTeamList);
            } else if (type === "user") {
              setSelectedAssignToScope({ label: "User", value: "user" });
              let preselectedMemberList = updateList(assignToList);
              setSelectedMembers(preselectedMemberList);
            }
          }
        }
      }
      //*********************************END*************************************** */
      if (props.editableData.update_fields.length > 0) {
        let tempList = {};
        props.editableData.update_fields.forEach((item) => {
          let index = filteredColumns.findIndex(
            (column) => column["id"] === item["column_meta"]["id"]
          );
          if (index !== -1) {
            tempList[item["column_meta"]["id"]] = {
              label: filteredColumns[index]["headerName"],
              required: item["required"],
              overwrite: item["overwrite"],
              append: item.hasOwnProperty("append") ? item["append"] : false,
            };
          }
        });
        setSelectedColumnList(tempList);
        console.log(
          "tempList:::::",
          tempList,
          props.editableData.update_fields
        );
      }
    } else {
      analytics.triggerEvent(
        4625000218,
        "add_statustostage_form_load",
        "project_statustostage_page",
        "",
        {}
      );
    }
    setLoading(false);
  };

  const initTeamAndMemberList = async () => {
    await getTeamAndMembers();
  };

  useEffect(() => {
    init();
  }, []);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: "12px", // Font size for options
    }),
    control: (provided, state) => ({
      ...provided,
      fontSize: "12px", // Font size for the entire control container, including the search input
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "12px", // Font size for "no options" message
    }),
    loadingMessage: (provided, state) => ({
      ...provided,
      fontSize: "12px", // Font size for "loading" message
    }),
  };

  const updateList = (list) => {
    let newList = [];
    list.map((e) => {
      let newObj = { label: e.name, value: e.id };
      newList.push(newObj);
    });
    return newList;
  };

  const updateMemberList = (list) => {
    let newList = [];
    list.map((e) => {
      let newObj = { label: e.name, value: e.user_id };
      newList.push(newObj);
    });
    return newList;
  };

  const getMembersList = async () => {
    try {
      let response = await getAdminMembersApi(rootStore.authStore.projectId);
      return response.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const setUpMemberList = (list) => {
    let member_list_role_lead = list.filter(
      (item) =>
        item["role"].toLowerCase() === "lead" ||
        item["role"].toLowerCase() === "manager"
    );
    setMemberList(member_list_role_lead);
    let modifiedList = updateMemberList([...member_list_role_lead]);
    setMultiSelectMemberList(modifiedList);
  };
  const setUpTeamList = (list) => {
    let member_list_role_lead_and_manager = list.filter(
      (item) => item["role"].toLowerCase() === "lead"
    );
    let team_list = [];
    member_list_role_lead_and_manager.forEach((item) => {
      let index = team_list.findIndex(
        (team) => team["id"] === item["team"]["id"]
      );
      if (index === -1) {
        team_list.push(item["team"]);
      }
    });
    setTeamList(team_list);
    let modifiedList = updateList([...team_list]);
    setMultiSelectTeamList(modifiedList);
  };

  const getTeamAndMembers = async () => {
    let member_list = await getMembersList();
    setUpMemberList([...member_list]);
    setUpTeamList([...member_list]);
  };

  const moveChip = useCallback(
    (dragKey, hoverKey) => {
      // Create a shallow copy of the selectedColumnList object
      const updatedChips = { ...selectedColumnList };

      // Convert the object into an array of [key, value] pairs
      const entries = Object.entries(updatedChips);

      // Find the index positions of the drag and hover keys
      const dragIndex = entries.findIndex(([key]) => key === dragKey);
      const hoverIndex = entries.findIndex(([key]) => key === hoverKey);

      // Remove the dragged entry from the array
      const [removed] = entries.splice(dragIndex, 1);

      // Insert the removed entry at the hover index
      entries.splice(hoverIndex, 0, removed);

      // Convert the array of entries back into an object
      const reorderedChips = Object.fromEntries(entries);

      // Update the selectedColumnList state with the reordered object
      setSelectedColumnList(reorderedChips);
    },
    [selectedColumnList]
  );

  const RenderAssignToField = () => {
    if (selectedAssignToScope === null) {
      return (
        <Grid item xs={12}>
          <InputWrapper>
            <Label>Select Team or User</Label>
            <MultiSelect
              disabled
              options={[]}
              value={[]}
              className={"global_filter_multi_select"}
              labelledBy="Select"
            />
          </InputWrapper>
        </Grid>
      );
    } else {
      if (selectedAssignToScope["value"] === "team") {
        return (
          <Grid item xs={12}>
            <InputWrapper>
              <Label>Select Team</Label>
              <MultiSelect
                hasSelectAll={false}
                options={multiSelectTeamList}
                value={selectedTeams}
                className={"global_filter_multi_select"}
                onChange={(e) => {
                  setSelectedTeams(e);
                }}
                labelledBy="Select Team"
              />
            </InputWrapper>
          </Grid>
        );
      } else if (selectedAssignToScope["value"] === "user") {
        return (
          <Grid item xs={12}>
            <InputWrapper>
              <Label>Select User</Label>
              <MultiSelect
                hasSelectAll={false}
                options={multiSelectMemberList}
                value={selectedMembers}
                className={"global_filter_multi_select"}
                onChange={(e) => {
                  setSelectedMembers(e);
                }}
                labelledBy="Select Member"
              />
            </InputWrapper>
          </Grid>
        );
      }
    }
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!loading ? (
          <ModelContainer>
            {!isSubmitSuccess ? (
              <Box>
                <Container>
                  {props.isEdit ? (
                    <Header>{getLocalizedText("edit_details")}</Header>
                  ) : (
                    <Header>{getLocalizedText("add_statustostage")}</Header>
                  )}
                  <SubHeader>
                    {getLocalizedText("fill_in_the_below_details")}
                  </SubHeader>
                  <Divider style={{ margin: "16px 0px" }} />
                  <Form
                    component={"form"}
                    onSubmit={handleSubmit}
                    onKeyDown={(e) => checkKeyDown(e)}
                  >
                    <Grid
                      container
                      spacing={2}
                      style={{ height: "300px", overflowY: "auto" }}
                    >
                      {stageList && (
                        <Grid item xs={12}>
                          <InputWrapper>
                            <Label>Stage*</Label>
                            <FormControl
                              sx={{ width: "100%" }}
                              classes={{
                                root: classes.quantityRoot,
                              }}
                            >
                              <MuiSelect
                                classes={{
                                  icon: classes.icon,
                                }}
                                IconComponent={(props) => (
                                  <KeyboardArrowDown {...props} />
                                )}
                                value={user.stage}
                                name="stage"
                                displayEmpty
                                disableUnderline
                                variant="outlined"
                                required
                                onChange={(e) => {
                                  handleChange(e);

                                  //setting available status based on selected stage
                                  const stageIndex = stageList.findIndex(
                                    (stage) => stage.id === e.target.value
                                  );

                                  if (stageIndex !== -1) {
                                    const stageName =
                                      stageList[stageIndex]["stage"];
                                    const availableStatuses =
                                      getAvailableStatusListByStage({
                                        currentStage: stageName,
                                        stageList: stageList,
                                        statusList: statusList,
                                      });
                                    setFilteredStatusList(availableStatuses);
                                  }
                                }}
                                style={{
                                  width: "100%",
                                  height: "38px",
                                  fontSize: "12px",

                                  borderRadius: "0px",
                                }}
                              >
                                {stageList.map((e) => {
                                  return (
                                    <MenuItem id={e.id} value={e.id}>
                                      {e.stage}
                                    </MenuItem>
                                  );
                                })}
                              </MuiSelect>
                            </FormControl>
                          </InputWrapper>
                        </Grid>
                      )}
                      {statusList && (
                        <Grid item xs={12}>
                          <InputWrapper>
                            <Label>On Status*</Label>
                            <FormControl
                              sx={{ width: "100%" }}
                              classes={{
                                root: classes.quantityRoot,
                              }}
                            >
                              <MuiSelect
                                classes={{
                                  icon: classes.icon,
                                }}
                                IconComponent={(props) => (
                                  <KeyboardArrowDown {...props} />
                                )}
                                name="status"
                                value={user.status}
                                displayEmpty
                                disableUnderline
                                variant="outlined"
                                required
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                style={{
                                  width: "100%",
                                  height: "38px",
                                  fontSize: "12px",

                                  borderRadius: "0px",
                                }}
                              >
                                {filteredStatusList.map((e) => {
                                  return (
                                    <MenuItem id={e.id} value={e.id}>
                                      {e.status}
                                    </MenuItem>
                                  );
                                })}
                              </MuiSelect>
                            </FormControl>
                          </InputWrapper>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <InputWrapper>
                          <Label>Jump To Stage</Label>

                          <Select
                            options={jumpToStageList.filter(
                              (item) => item["value"] !== user.stage
                            )}
                            value={jumpToStage}
                            onChange={(value) => setJumpToStage(value)}
                            closeMenuOnSelect={true}
                            styles={customStyles}
                            isClearable
                          />
                        </InputWrapper>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          component={"fieldset"}
                          sx={{ border: "1px solid #cccccc", padding: "12px" }}
                        >
                          <legend
                            style={{
                              width: "auto",
                              marginBottom: "0px",
                              fontSize: "16px",
                            }}
                          >
                            Fields to be Updated
                          </legend>

                          {Object.keys(selectedColumnList).length > 0 && (
                            <Grid
                              item
                              xs={12}
                              container
                              spacing={2}
                              style={{
                                marginBottom: "8px",
                              }}
                            >
                              <DndProvider backend={HTML5Backend}>
                                {Object.keys(selectedColumnList).map(
                                  (key, index) => {
                                    return (
                                      <Grid item key={key}>
                                        <DraggableUpdateFieldChipComponent
                                          handleDelete={() => {
                                            const filteredColumnList = {
                                              ...selectedColumnList,
                                            };
                                            delete filteredColumnList[key];
                                            setSelectedColumnList(
                                              filteredColumnList
                                            );
                                          }}
                                          chipKey={key}
                                          index={index}
                                          isDisabled={false}
                                          isRequired={
                                            selectedColumnList[key]["required"]
                                          }
                                          label={
                                            selectedColumnList[key]["label"]
                                          }
                                          moveChip={moveChip}
                                        />
                                      </Grid>
                                    );
                                  }
                                )}
                              </DndProvider>
                            </Grid>
                          )}
                          <Button
                            style={{
                              textTransform: "none",
                              fontWeight: "bold",
                              width: "100%",
                            }}
                            color="primary"
                            variant="text"
                            onClick={() => {
                              setOpenAddUpdateFieldDialog(true);
                            }}
                          >
                            <Add /> Add Update Field
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          component={"fieldset"}
                          sx={{ border: "1px solid #cccccc", padding: "12px" }}
                        >
                          <legend
                            style={{
                              width: "auto",
                              marginBottom: "0px",
                              fontSize: "16px",
                            }}
                          >
                            Submit Options
                          </legend>
                          <Grid container item spacing={2}>
                            <Grid item xs={12}>
                              <InputWrapper>
                                <Label>Select Target</Label>
                                <FormControl
                                  sx={{ width: "100%" }}
                                  classes={{
                                    root: classes.quantityRoot,
                                  }}
                                >
                                  <Select
                                    options={assignToScopeList}
                                    value={selectedAssignToScope}
                                    onChange={(value) => {
                                      setSelectedAssignToScope(value);
                                      setSelectedMembers([]);
                                      setSelectedTeams([]);
                                    }}
                                    closeMenuOnSelect={true}
                                    styles={customStyles}
                                    isClearable
                                  />
                                </FormControl>
                              </InputWrapper>
                            </Grid>
                            {RenderAssignToField()}
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                    <ButtonWrapper>
                      {isError && (
                        <ErrorAlert
                          onClose={(e) => {
                            e.stopPropagation();
                            setIsError(false);
                          }}
                          severity="error"
                          sx={{ width: "100%" }}
                        >
                          {errorMessage}
                        </ErrorAlert>
                      )}
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                              width: "100%",
                            }}
                          >
                            <Button
                              onClick={() => {
                                if (props.isEdit) {
                                  analytics.triggerEvent(
                                    4625000223,
                                    "cancel_button_tap",
                                    "edit_statustostage_form",
                                    "cancel_button",
                                    {}
                                  );
                                } else {
                                  analytics.triggerEvent(
                                    4625000219,
                                    "cancel_button_tap",
                                    "add_statustostage_form",
                                    "cancel_button",
                                    {}
                                  );
                                }
                                handleClick();
                              }}
                              type="button"
                              variant="contained"
                              color="default"
                              style={{
                                backgroundColor: "#EFEFF4",
                                textTransform: "none",
                                marginRight: "16px",
                                fontSize: "12px",
                                fontWeight: "bold",
                              }}
                            >
                              {getLocalizedText("cancel")}
                            </Button>

                            <Button
                              onClick={() => {
                                if (props.isEdit) {
                                  analytics.triggerEvent(
                                    4625000224,
                                    "submit_button_tap",
                                    "edit_statustostage_form",
                                    "submit_button",
                                    {}
                                  );
                                } else {
                                  analytics.triggerEvent(
                                    4625000220,
                                    "submit_button_tap",
                                    "add_statustostage_form",
                                    "submit_button",
                                    {}
                                  );
                                }
                              }}
                              type="submit"
                              variant="contained"
                              id="call-log-btn"
                              style={{
                                textTransform: "none",
                                fontSize: "12px",
                                fontWeight: "bold",
                                ...myTheme.Button.btnBlue,
                              }}
                            >
                              {getLocalizedText("submit_details")}
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </ButtonWrapper>
                  </Form>
                </Container>
              </Box>
            ) : (
              <Box>
                <MessageWrapper style={{ marginBottom: "0px" }}>
                  {props.isEdit ? (
                    <Message>Status-Stage updated successfully!</Message>
                  ) : (
                    <Message>Status-Stage created successfully!</Message>
                  )}
                  <Button
                    onClick={handleClose}
                    type="button"
                    variant="contained"
                    color="default"
                    style={{
                      textTransform: "none",
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "100px",
                    }}
                  >
                    {getLocalizedText("close")}
                  </Button>
                </MessageWrapper>
              </Box>
            )}
          </ModelContainer>
        ) : (
          <ModelContainer>
            <Box
              sx={{
                width: "420px",
                height: "300px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </Box>
          </ModelContainer>
        )}
      </Modal>
      {openAddUpdateFieldDialog && (
        <AddUpdateFieldDialog
          open={openAddUpdateFieldDialog}
          canEditField={() => true}
          handlePostAddUpdateField={(updateFieldData) => {
            if (
              !selectedColumnList.hasOwnProperty(updateFieldData["column_id"])
            ) {
              let index = columnsList.findIndex(
                (column) => column["id"] === updateFieldData["column_id"]
              );
              selectedColumnList[updateFieldData["column_id"]] = {
                label: columnsList[index]["headerName"],
                required: updateFieldData["is_required"],
                overwrite: updateFieldData["is_overwrite"],
                append: updateFieldData["is_append"],
              };
            }
          }}
          selectedColumnList={selectedColumnList}
          setOpen={setOpenAddUpdateFieldDialog}
          columnsList={columnsList}
        />
      )}
    </>
  );
};

export default observer(AddStatusToStageModal);
