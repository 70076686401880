import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Button } from "@material-ui/core";
import { AppBar, Box, Toolbar, Typography, CircularProgress } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { createAdminQuotationApi } from "../../Api";
import TemplateInfoScreen from "./screens/TemplateInfoScreen";
import rootStore from "../../stores/RootStore";
import ConfigureShippingAddressModal from "./ConfigureShippingAddressModal";
import AddProductModal from "./dialogs/AddProductModal";
import EditProductModal from "./dialogs/EditProductModal";
import ViewProductModal from "./dialogs/ViewProductModal";
import AdditionalInformationScreen from "./screens/AdditionalInformationScreen";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  ${`height: calc(100vh - 128px);`};
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 24px Open Sans;
  color: green;
  margin: 0px;
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: flex-end;
  column-gap: 10px;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  padding: 24px;
  gap: 20px;
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0px;
  }
`;

const CloneAdminQuoteTemplateSidepanel = ({
  setOpen,
  setRefresh,
  open,
  selectedTemplateData,
}) => {
  const [loading, setLoading] = useState(true);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [showShippingConfig, setShowShippingConfig] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [companyDetails, setCompanyDetails] = useState({
    company_name: "",
    company_address: "",
    company_email: "",
    company_website: "",
    company_state: "",
    company_country: "",
  });
  const [billingDetails, setBillingDetails] = useState({
    show_shipping_address: false,
  });
  const [addressConfig, setAddressConfig] = useState({
    billing_name: "",
    billing_address: "",
    billing_city: "",
    billing_state: "",
    billing_country: "",
    billing_zipcode: "",
    billing_gst: "",
    shipping_name: "",
    shipping_address: "",
    shipping_city: "",
    shipping_state: "",
    shipping_country: "",
    shipping_zipcode: "",
  });
  const [additionalDetails, setAdditionalDetails] = useState({
    show_terms_and_conditions: false,
    show_additional_information: false,
    show_quotation_number: false,
    terms_and_conditions: "",
    additional_information: "",
  });
  const [logoURL, setLogoURL] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [templateDetails, setTemplateDetails] = useState({
    quotation_title: "",
    invoice_title: "",
    template_subject: "",
  });

  const [openAddProductModal, setOpenAddProductModal] = useState(false);
  const [openEditProductModal, setOpenEditProductModal] = useState(false);
  const [openViewProductModal, setOpenViewProductModal] = useState(false);
  const [selectedProductData, setSelectedProductData] = useState({});
  const [productAddedList, setProductAddedList] = useState([]);
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    setup();
  }, []);

  const setup = () => {
    setTemplateName(selectedTemplateData.name);
    let tempDetails = {
      template_subject: selectedTemplateData.schema.subject
        ? selectedTemplateData.schema.subject
        : "",
    };
    if (
      selectedTemplateData.schema.title !== null &&
      selectedTemplateData.schema.title !== undefined
    ) {
      tempDetails["quotation_title"] = selectedTemplateData.schema.title;
    } else {
      tempDetails["quotation_title"] = selectedTemplateData.schema
        .quotation_title
        ? selectedTemplateData.schema.quotation_title
        : "";
      tempDetails["invoice_title"] = selectedTemplateData.schema.invoice_title
        ? selectedTemplateData.schema.invoice_title
        : "";
    }
    setTemplateDetails(tempDetails);
    //company details
    if (selectedTemplateData.schema.company_details !== null) {
      let tempCompanyDetails = {};
      for (let key in selectedTemplateData.schema.company_details) {
        if (key === "logo_url") {
          setLogoURL(selectedTemplateData.schema.company_details[key]);
        } else {
          tempCompanyDetails[key] =
            selectedTemplateData.schema.company_details[key];
        }
      }
      setCompanyDetails(tempCompanyDetails);
    }

    //products list
    if (
      selectedTemplateData.schema.items !== null &&
      selectedTemplateData.schema.items !== undefined
    ) {
      let tempItemList = [];
      //loop through products list
      for (const item of selectedTemplateData.schema.items) {
        let row = {};
        //loop through keys in a row item
        for (let key in item) {
          if (key === "name") {
            row["item"] = {};
            row["item"][key] = item[key];
          } else {
            row[key] = row[key] = item[key];
          }
        }
        tempItemList.push(row);
      }
      setProductAddedList(tempItemList);
    }
    if (
      selectedTemplateData.schema.address_variables !== null &&
      selectedTemplateData.schema.address_variables !== undefined
    ) {
      setAddressConfig(selectedTemplateData.schema.address_variables);
    }
    if (
      selectedTemplateData.schema.additional_details !== null &&
      selectedTemplateData.schema.additional_details !== undefined
    ) {
      setAdditionalDetails(selectedTemplateData.schema.additional_details);
    }
    if (
      selectedTemplateData.schema.billing_details !== null &&
      selectedTemplateData.schema.billing_details !== undefined
    ) {
      setBillingDetails(selectedTemplateData.schema.billing_details);
    }
    setLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getValidIntegerValue = (value) => {
    if (value !== "" && value !== null) {
      return parseInt(value);
    }
    return "";
  };
  const getValidFloatValue = (value) => {
    if (value !== "" && value !== null) {
      const floatValue = parseFloat(value);
      return floatValue.toFixed(2);
    }
    return "";
  };

  const handleSubmit = async () => {
    let formData = {
      name: templateName,
    };
    let schema = {
      quotation_title: templateDetails["quotation_title"],
      invoice_title: templateDetails["invoice_title"],
      subject: templateDetails["template_subject"],
      company_details: { ...companyDetails, logo_url: logoURL },
      billing_details: { ...billingDetails },
      additional_details: { ...additionalDetails },
      address_variables: addressConfig,
      show_gst: false,
    };

    if (companyDetails["company_country"] === "India") {
      schema["show_gst"] = true;
    }

    let itemList = [];
    productAddedList.forEach((productItem) => {
      let tempObj = {
        rate: getValidIntegerValue(productItem["rate"]),
        amount: getValidFloatValue(productItem["amount"]),
        quantity: getValidIntegerValue(productItem["quantity"]),
        discount: getValidIntegerValue(productItem["discount"]),
        desc: productItem["desc"],
      };
      tempObj["name"] = productItem["item"]["name"];
      itemList.push(tempObj);
    });
    schema["items"] = itemList;

    formData["schema"] = schema;
    let response = await createAdminQuotationApi({
      projectID: rootStore.authStore.projectId,
      payload: formData,
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };

  const handleLogo = (event) => {
    const file = event.target.files[0];
    if (file) {
      convertImageToBase64(file);
    }
  };

  const convertImageToBase64 = (imageFile) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64 = reader.result;
      setLogoURL(base64);
    };
    reader.readAsDataURL(imageFile);
  };

  const postClickCallback = () => {
    setShowShippingConfig(true);
  };

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const RenderButton = () => {
    if (activeStep === 0) {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "20px",
            width: "fit-content",
          }}
        >
          <Button
            type="button"
            onClick={handleClose}
            variant="outlined"
            color="primary"
            style={{
              textTransform: "none",
              width: "120px",
              fontWeight: "bold",
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{
              textTransform: "none",
              width: "120px",
              fontWeight: "bold",
            }}
          >
            Next
          </Button>
        </Box>
      );
    } else if (activeStep === 1) {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "20px",
            width: "fit-content",
          }}
        >
          <Button
            onClick={handlePrevious}
            type="button"
            variant="outlined"
            color="primary"
            style={{
              textTransform: "none",
              width: "120px",
              fontWeight: "bold",
            }}
          >
            Prev
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{
              textTransform: "none",
              width: "120px",
              fontWeight: "bold",
            }}
          >
            Submit
          </Button>
        </Box>
      );
    }
  };

  return !loading ? (
    <Box role="presentation">
      <AppBar
        component={"nav"}
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          top: 0,
          bottom: "auto",
          width: 500,
          right: 0,
          bgcolor: "#f9f9fc",
        }}
      >
        <Toolbar>
          <ModelHeader>Clone Template</ModelHeader>
        </Toolbar>
      </AppBar>
      {!isSubmitSuccess ? (
        <ModelWrapper
          component={"form"}
          onSubmit={(e) => {
            e.preventDefault();
            if (activeStep < 1) {
              handleNext();
            } else {
              handleSubmit();
            }
          }}
        >
          <InputContainer>
            {(() => {
              if (activeStep === 0) {
                return (
                  <TemplateInfoScreen
                    companyDetails={companyDetails}
                    handleChange={(name, value) => {
                      setCompanyDetails({ ...companyDetails, [name]: value });
                    }}
                    handleTemplateName={(value) => {
                      setTemplateName(value);
                    }}
                    templateName={templateName}
                    handleTemplateDetail={(name, value) => {
                      setTemplateDetails({ ...templateDetails, [name]: value });
                    }}
                    templateDetails={templateDetails}
                    handleLogo={(event) => {
                      handleLogo(event);
                    }}
                    productAddedList={productAddedList}
                    setOpenAddProductModal={setOpenAddProductModal}
                    setOpenEditProductModal={setOpenEditProductModal}
                    setOpenViewProductModal={setOpenViewProductModal}
                    setSelectedProductData={setSelectedProductData}
                  />
                );
              } else {
                return (
                  <AdditionalInformationScreen
                    additionalDetails={additionalDetails}
                    billingDetails={billingDetails}
                    companyDetails={companyDetails}
                    postClickCallback={postClickCallback}
                    handleAdditionalDetails={(name, value) => {
                      setAdditionalDetails({
                        ...additionalDetails,
                        [name]: value,
                      });
                    }}
                    handleBillingDetails={(name, value) => {
                      setBillingDetails({ ...billingDetails, [name]: value });
                    }}
                    handleChange={(name, value) => {
                      setCompanyDetails({ ...companyDetails, [name]: value });
                    }}
                    handleBillingVariables={(name, value) => {
                      setAddressConfig({ ...addressConfig, [name]: value });
                    }}
                    addressConfig={addressConfig}
                  />
                );
              }
            })()}
          </InputContainer>

          <AppBar
            position="fixed"
            color="inherit"
            elevation={0}
            sx={{ top: "auto", bottom: 0, width: 500, bgcolor: "#f9f9fc" }}
          >
            <Toolbar style={{ width: 500, position: "relative" }}>
              {isSubmitFail && (
                <ErrorAlert
                  onClose={(e) => {
                    e.stopPropagation();
                    setIsSubmitFail(false);
                  }}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  {errorMessage}
                </ErrorAlert>
              )}

              <ButtonWrapper>
                <RenderButton />
              </ButtonWrapper>
            </Toolbar>
          </AppBar>
        </ModelWrapper>
      ) : (
        <MessageWrapper>
          <Message>Quote template created successfully!</Message>
          <Button
            onClick={handleClose}
            type="button"
            variant="contained"
            color="default"
            style={{
              marginTop: "20px",
              textTransform: "none",
              fontSize: "12px",
              fontWeight: "bold",
              width: "100px",
            }}
          >
            Close
          </Button>
        </MessageWrapper>
      )}
      {showShippingConfig && (
        <ConfigureShippingAddressModal
          setOpen={setShowShippingConfig}
          open={showShippingConfig}
          onSubmitCallback={(shippingDetails) => {
            const tempObj = { ...addressConfig, ...shippingDetails };
            setAddressConfig(tempObj);
          }}
          addressConfig={addressConfig}
        />
      )}
      {openAddProductModal && (
        <AddProductModal
          open={openAddProductModal}
          setOpen={setOpenAddProductModal}
          productAddedList={productAddedList}
          setProductAddedList={setProductAddedList}
        />
      )}
      {openEditProductModal && (
        <EditProductModal
          open={openEditProductModal}
          setOpen={setOpenEditProductModal}
          productAddedList={productAddedList}
          setProductAddedList={setProductAddedList}
          editableProduct={selectedProductData}
          setEditableProduct={setSelectedProductData}
        />
      )}
      {openViewProductModal && (
        <ViewProductModal
          open={openViewProductModal}
          setOpen={setOpenViewProductModal}
          data={selectedProductData}
          setData={setSelectedProductData}
        />
      )}
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: 500,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default CloneAdminQuoteTemplateSidepanel;
