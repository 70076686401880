import { action, makeObservable, observable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { getSubscriptionDetailsApi } from "../Api";
import { consoleLogger } from "../Functions";
import rootStore from "../stores/RootStore";

class SubscriptionState {
  subscritionDetails = {};
  constructor() {
    makeObservable(this, {
      subscritionDetails: observable,
      init: action,
      resetStore: action,
      fetchSubscriptionDetails: action,
      isFeatureAvailableWithAddOns: action,
      hasReachedMaxCustomColumnsLimit: action,
      hasReachedMaxLeadsLimitForProject: action,
    });

    makePersistable(this, {
      name: SubscriptionState,
      storage: window.localStorage,
      expireIn: 86400000,
      removeOnExpiration: true,
      stringify: true,
      debugMode: false,
      properties: [""],
    });
  }

  fetchSubscriptionDetails = async () => {
    let response = await getSubscriptionDetailsApi();
    if (response.length > 0) {
      this.subscritionDetails = response[0];
    }
  };

  resetStore = () => {
    this.subscritionDetails = {};
  };

  init = async () => {
    await this.fetchSubscriptionDetails();
  };

  isFeatureAvailableWithAddOns = (featureName) => {
    if (
      this.subscritionDetails.hasOwnProperty("subscription_addons") &&
      this.subscritionDetails["subscription_addons"] !== null
    ) {
      for (
        let i = 0;
        i < this.subscritionDetails["subscription_addons"].length;
        i++
      ) {
        let addOnData = this.subscritionDetails["subscription_addons"][i];
        if (addOnData["active"] === true && addOnData["name"] === featureName) {
          return true;
        }
      }
    }
    return false;
  };

  getMaxUsersWithAddOns = () => {
    let tempMaxUsers =
      this.subscritionDetails["max_users"] !== null &&
      this.subscritionDetails["max_users"] > 0
        ? this.subscritionDetails["max_users"]
        : 0;
    if (
      this.subscritionDetails.hasOwnProperty("subscription_addons") &&
      this.subscritionDetails["subscription_addons"] !== null
    ) {
      this.subscritionDetails["subscription_addons"].forEach((addOnPack) => {
        if (
          addOnPack["name"] === "Additional User Pack" &&
          addOnPack["active"] === true &&
          addOnPack["value"] !== null &&
          addOnPack["value"] > 0
        ) {
          tempMaxUsers += addOnPack["value"];
        }
      });
    }
    return tempMaxUsers;
  };

  getMaxFilesPerLeadAddOns = () => {
    let tempMaxFiles = 0;
    if (
      this.subscritionDetails.hasOwnProperty("subscription_addons") &&
      this.subscritionDetails["subscription_addons"] !== null
    ) {
      for (
        let i = 0;
        i < this.subscritionDetails["subscription_addons"].length;
        i++
      ) {
        let addOnData = this.subscritionDetails["subscription_addons"][i];
        if (
          addOnData["active"] === true &&
          addOnData["name"] === "Files Per Lead"
        ) {
          tempMaxFiles +=
            addOnData["value"] !== null && addOnData["value"] !== undefined
              ? addOnData["value"]
              : 0;
        }
      }
    }
    return tempMaxFiles;
  };

  getMaxCustomFieldsAddons = () => {
    let tempMaxCustomFields = 0;
    if (
      this.subscritionDetails.hasOwnProperty("subscription_addons") &&
      this.subscritionDetails["subscription_addons"] !== null
    ) {
      for (
        let i = 0;
        i < this.subscritionDetails["subscription_addons"].length;
        i++
      ) {
        let addOnData = this.subscritionDetails["subscription_addons"][i];
        if (
          addOnData["active"] === true &&
          addOnData["name"] === "Custom Fields"
        ) {
          tempMaxCustomFields +=
            addOnData["value"] !== null && addOnData["value"] !== undefined
              ? addOnData["value"]
              : 0;
        }
      }
    }
    return tempMaxCustomFields;
  };

  getProjectLeadCountAddons = () => {
    let tempProjectLeadCount = 0;
    if (
      this.subscritionDetails.hasOwnProperty("subscription_addons") &&
      this.subscritionDetails["subscription_addons"] !== null
    ) {
      for (
        let i = 0;
        i < this.subscritionDetails["subscription_addons"].length;
        i++
      ) {
        let addOnData = this.subscritionDetails["subscription_addons"][i];
        if (
          addOnData["active"] === true &&
          addOnData["name"] === "Project Lead Count"
        ) {
          tempProjectLeadCount +=
            addOnData["value"] !== null && addOnData["value"] !== undefined
              ? addOnData["value"]
              : 0;
        }
      }
    }
    return tempProjectLeadCount;
  };

  hasReachedMaxUserLimit = ({ totalUsers }) => {
    if (Object.keys(this.subscritionDetails).length > 0) {
      const maxUsers = this.getMaxUsersWithAddOns();
      consoleLogger("max users ::::", maxUsers);
      if (maxUsers > 0) {
        return totalUsers >= maxUsers;
      }
    }
    return false;
  };

  hasReachedMaxFileLimit = ({ leadFilesCount }) => {
    if (Object.keys(this.subscritionDetails).length > 0) {
      const maxFilePerLead = this.getMaxFilesPerLeadAddOns();
      consoleLogger("max files per lead ::::", maxFilePerLead);
      if (maxFilePerLead > 0) {
        return leadFilesCount >= maxFilePerLead;
      }
    }
    return false;
  };

  hasReachedMaxCustomColumnsLimit = ({ currentTotal }) => {
    if (Object.keys(this.subscritionDetails).length > 0) {
      const addonLimit = this.getMaxCustomFieldsAddons();
      consoleLogger("addon customCols limit ::", addonLimit);
      consoleLogger("currCols", currentTotal);

      if (addonLimit > 0) {
        return currentTotal >= addonLimit;
      }
    }
    return false;
  };

  hasReachedMaxLeadsLimitForProject = async ({ newLeadCount, pid }) => {
    const currentTotalLeadCount = rootStore.authStore.getTotalProjectLeadCount(pid);
    const addonLimit = this.getProjectLeadCountAddons();
    consoleLogger("addon project leads limit ::", addonLimit);
    consoleLogger("new project leads ::", currentTotalLeadCount + newLeadCount);

    if (addonLimit > 0) {
      return currentTotalLeadCount + newLeadCount >= addonLimit;
    }

    return false;
  };
}

export default SubscriptionState;
