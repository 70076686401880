import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Modal,
  Radio,
  RadioGroup,
  Select as MuiSelect,
  OutlinedInput,
} from "@material-ui/core";
import { AppBar, Toolbar } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {
  editProjectMemberApi,
  getProjectAdminWorkTimingTemplateListApi,
  getTeamApi,
} from "../../Api";
import { useEffect } from "react";
import { myTheme } from "../../themeUtils";
import { getLocalizedText } from "../../Functions";
import MuiAlert from "@mui/material/Alert";
import CustomFieldHeader from "../custom_tooltip_headers/CustomFieldHeader";
import rootStore from "../../stores/RootStore";
import { CircularProgress, styled, Typography } from "@mui/material";
import Select from "react-select";
import { AddBoxOutlined } from "@material-ui/icons";
import Chip from "@mui/material/Chip";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={0}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "10px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
`;
const ModalWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: calc(100vh - 128px);
  padding-top: 0px;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const SubHeader = styled(Typography)`
  font: normal normal normal 14px Open Sans;
  color: #6f6f6f;
  margin-top: 2px;
  margin-bottom: 30px;
`;
const Form = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const InputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Label = styled(Typography)`
  font: normal normal 600 10px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const ModalContainer = styled(Box)`
  display: flex;
  width: 100%;
  padding: 24px;
  gap: 20px;
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0px;
  }
`;
const ButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;

  width: 100%;
  justify-content: flex-end;
  column-gap: 10px;
`;

const SelectColumn = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 24px Open Sans;
  color: green;
  margin: 0px;
`;

const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for options
  }),
  control: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for the entire control container, including the search input
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "no options" message
  }),
  loadingMessage: (provided, state) => ({
    ...provided,
    fontSize: "12px", // Font size for "loading" message
  }),
};
const EditProjectMember = (props) => {
  const classes = useStyles();
  const { userStore } = rootStore;
  const [user, setUser] = useState({
    team: "",
    calling_mode: "",
    role: "member",
    unmask_columns: false,
  });
  const [teamList, setTeamList] = useState([]);
  const roleList = ["lead", "member", "manager"];
  const callingModes = ["Manual", "Progressive"];
  const [workTimingTemplates, setWorkTimingTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [propertiesList, setPropertiesList] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const [loading, setLoading] = useState(true);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    const editUser = async () => {
      let data = {
        user_id: props.data.user_id,
        calling_mode: user.calling_mode,
        role: user.role,
        team_id: user.team,
        unmask_columns: user.unmask_columns,
        work_timings_template_id:
          selectedTemplate !== null ? selectedTemplate["value"] : null,
        properties: propertiesList,
      };

      try {
        let response = await editProjectMemberApi({
          id: props.pid,
          data: data,
        });
        props.setRefresh(!props.refresh);
        setIsSubmitSuccess(true);
      } catch (error) {
        setErrorMessage(getLocalizedText("some_error_occurred"));
        setIsSubmitFail(true);
        console.log(error);
      }
    };

    editUser();
  };
  const handleClose = () => {
    props.setOpen(false);
  };

  const getTemplateListOptions = () => {
    let tempList = [...workTimingTemplates];
    return tempList.map((obj) => ({
      label: obj["name"],
      value: obj["id"],
    }));
  };

  const getWorkTimingTemplateList = async () => {
    let response = await getProjectAdminWorkTimingTemplateListApi({
      projectID: rootStore.authStore.projectId,
    });
    setWorkTimingTemplates(response);
  };

  const getTeams = async () => {
    let response = await getTeamApi(props.pid);
    setTeamList(response);
  };
  const init = async () => {
    await getTeams();
    await getWorkTimingTemplateList();
    setUser({
      team: props.data.team["id"],
      calling_mode: props.data.calling_mode,
      role: props.data.role,
      unmask_columns: props.data.unmask_columns,
    });
    if (
      props.data.hasOwnProperty("work_timings") &&
      props.data["work_timings"] !== null
    ) {
      let tempObj = {
        label: props.data["work_timings"]["name"],
        value: props.data["work_timings"]["id"],
      };
      setSelectedTemplate(tempObj);
    }
    let tempList =
      props.data.hasOwnProperty("properties") &&
      props.data["properties"] !== null
        ? props.data["properties"]
        : [];
    setPropertiesList(tempList);
    setLoading(false);
  };

  const handleAddProperty = (e) => {
    const value = inputValue;
    if (!value.trim()) return;
    if (!propertiesList.includes(value)) {
      setPropertiesList([...propertiesList, value]);
    }
    setInputValue("");
  };
  const handleDelete = (input) => {
    let tempList = propertiesList.filter((item) => item !== input);
    setPropertiesList(tempList);
  };
  const checkIfPropertiesDisabled = () => {
    if (propertiesList.length >= 10) {
      return true;
    } else {
      return !inputValue.trim();
    }
  };

  useEffect(() => {
    init();
  }, []);
  return !loading ? (
    <>
      <Box role="presentation">
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            top: 0,
            bottom: "auto",
            width: 500,
            right: 0,
            bgcolor: "#f9f9fc",
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Header>{getLocalizedText("edit_member_details")}</Header>
          </Toolbar>
        </AppBar>
        {!isSubmitSuccess ? (
          <ModalWrapper
            component={"form"}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <ModalContainer
              style={{
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <Box>
                <Container>
                  <InputContainer>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <SelectColumn>
                          <CustomFieldHeader
                            header={getLocalizedText("select_role")}
                            tooltip={"Choose the role assigned to the member."}
                            required={true}
                          />
                          <FormControl
                            sx={{ width: "100%" }}
                            classes={{
                              root: classes.quantityRoot,
                            }}
                          >
                            <MuiSelect
                              classes={{
                                icon: classes.icon,
                              }}
                              value={user.role}
                              name="role"
                              displayEmpty
                              disableUnderline
                              variant="outlined"
                              required
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              style={{
                                width: "100%",
                                height: "30px",
                                fontSize: "12px",
                                fontWeight: "bold",
                                borderRadius: "0px",
                              }}
                            >
                              {roleList.map((e) => {
                                return (
                                  <MenuItem id={e} value={e}>
                                    {e}
                                  </MenuItem>
                                );
                              })}
                            </MuiSelect>
                          </FormControl>
                        </SelectColumn>
                      </Grid>
                      <Grid item xs={6}>
                        <InputWrapper>
                          <CustomFieldHeader
                            header={getLocalizedText("unmask_columns")}
                            tooltip={
                              "Specify whether the column value will be masked for this member."
                            }
                          />
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value={true}
                              control={<Radio size="small" />}
                              label={
                                <Label style={{ fontSize: "12px" }}>Yes</Label>
                              }
                              checked={user.unmask_columns}
                              onChange={() => {
                                setUser({ ...user, unmask_columns: true });
                              }}
                            />
                            <FormControlLabel
                              value={false}
                              checked={!user.unmask_columns}
                              onChange={() => {
                                setUser({ ...user, unmask_columns: false });
                              }}
                              control={<Radio size="small" />}
                              label={
                                <Label style={{ fontSize: "12px" }}>No</Label>
                              }
                            />
                          </RadioGroup>
                        </InputWrapper>
                      </Grid>
                      <Grid item xs={6}>
                        <SelectColumn>
                          <CustomFieldHeader
                            header={getLocalizedText("calling_mode")}
                            tooltip={"Specify the member's calling mode."}
                            required={true}
                          />
                          <FormControl
                            sx={{ width: "100%" }}
                            classes={{
                              root: classes.quantityRoot,
                            }}
                          >
                            <MuiSelect
                              classes={{
                                icon: classes.icon,
                              }}
                              name="calling_mode"
                              displayEmpty
                              disableUnderline
                              variant="outlined"
                              required
                              value={user.calling_mode}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              style={{
                                width: "100%",
                                height: "30px",
                                fontSize: "12px",
                                fontWeight: "bold",
                                borderRadius: "0px",
                              }}
                            >
                              {callingModes.map((e) => {
                                return (
                                  <MenuItem id={e} value={e}>
                                    {e}
                                  </MenuItem>
                                );
                              })}
                            </MuiSelect>
                          </FormControl>
                        </SelectColumn>
                      </Grid>
                      <Grid item xs={6}>
                        <SelectColumn>
                          <CustomFieldHeader
                            header={getLocalizedText("select_team")}
                            tooltip={
                              "Choose the team to which the member belongs."
                            }
                            required={true}
                          />
                          <FormControl
                            sx={{ width: "100%" }}
                            classes={{
                              root: classes.quantityRoot,
                            }}
                          >
                            <MuiSelect
                              classes={{
                                icon: classes.icon,
                              }}
                              value={user.team}
                              name="team"
                              displayEmpty
                              disableUnderline
                              variant="outlined"
                              required
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              style={{
                                width: "100%",
                                height: "30px",
                                fontSize: "12px",
                                fontWeight: "bold",
                                borderRadius: "0px",
                              }}
                            >
                              {teamList.map((e) => {
                                return (
                                  <MenuItem id={e.id} value={e.id}>
                                    {e.name}
                                  </MenuItem>
                                );
                              })}
                            </MuiSelect>
                          </FormControl>
                        </SelectColumn>
                      </Grid>
                      <Grid item xs={12}>
                        <SelectColumn>
                          <CustomFieldHeader
                            header={"Select Work Timing Template"}
                            tooltip={
                              "Choose the work timing assigned to the member."
                            }
                            required={false}
                          />

                          <Select
                            menuPosition="fixed"
                            options={getTemplateListOptions()}
                            value={selectedTemplate}
                            onChange={(selectedOption) => {
                              setSelectedTemplate(selectedOption);
                            }}
                            closeMenuOnSelect={true}
                            styles={customStyles}
                            isClearable
                          />
                        </SelectColumn>
                      </Grid>
                      <Grid item xs={12}>
                        <InputWrapper>
                          <CustomFieldHeader
                            header={"Properties"}
                            tooltip={
                              "Add properties for the member (up to 10)."
                            }
                          />
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <OutlinedInput
                              style={{
                                height: "30px",
                                width: "100%",
                                borderRadius: "0px",
                              }}
                              inputProps={{
                                min: 0,
                                "aria-label": "weight",
                                style: {
                                  fontSize: "12px",
                                },
                              }}
                              type="text"
                              placeholder="Type something"
                              name="properties"
                              value={inputValue}
                              onChange={(e) => {
                                setInputValue(e.target.value);
                              }}
                              disabled={propertiesList.length >= 10}
                            />
                            <AddBoxOutlined
                              color={
                                checkIfPropertiesDisabled()
                                  ? "disabled"
                                  : "primary"
                              }
                              onClick={
                                checkIfPropertiesDisabled()
                                  ? null
                                  : handleAddProperty
                              }
                              style={{
                                marginLeft: "5px",
                                width: "30px",
                                height: "30px",
                                cursor: "pointer",
                                transform: "scale(1.3)",
                              }}
                            />
                          </Box>
                          <Box
                            style={{
                              padding: "12px 0px",
                              display: "flex",
                              alignItems: "flex-start",
                              gap: "8px",
                              flexWrap: "wrap",
                            }}
                          >
                            {propertiesList.map((chip, index) => {
                              return (
                                <Box key={index}>
                                  <Chip
                                    index={index}
                                    label={chip}
                                    onDelete={() => handleDelete(chip)}
                                  />
                                </Box>
                              );
                            })}
                          </Box>
                        </InputWrapper>
                      </Grid>
                    </Grid>
                  </InputContainer>
                </Container>
              </Box>
            </ModalContainer>
            <AppBar
              position="fixed"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: 500, bgcolor: "#f9f9fc" }}
            >
              <Toolbar style={{ width: 500, position: "relative" }}>
                <ButtonWrapper>
                  {isSubmitFail && (
                    <ErrorAlert
                      onClose={(e) => {
                        e.stopPropagation();
                        setIsSubmitFail(false);
                      }}
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      {errorMessage}
                    </ErrorAlert>
                  )}
                  <Button
                    type="button"
                    onClick={handleClose}
                    variant="outlined"
                    color="primary"
                    style={{
                      textTransform: "none",
                      width: "120px",
                      fontWeight: "bold",
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{
                      textTransform: "none",
                      width: "120px",
                      fontWeight: "bold",
                    }}
                  >
                    {getLocalizedText("submit")}
                  </Button>
                </ButtonWrapper>
              </Toolbar>
            </AppBar>
          </ModalWrapper>
        ) : (
          <MessageWrapper>
            <Message>Member edited successfully!</Message>
            <Button
              onClick={handleClose}
              type="button"
              variant="contained"
              color="default"
              style={{
                marginTop: "20px",
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              Close
            </Button>
          </MessageWrapper>
        )}
      </Box>
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: 500,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default observer(EditProjectMember);
