import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import PinToLocationRow from "../../components/security_page_components/PinToLocationRow";
import IPWhitelistingRow from "../../components/security_page_components/IPWhitelistingRow";
import rootStore from "../../stores/RootStore";
import NoAccessPermissionPage from "../no_access_page/NoAccessPermissionPage";
import { advancedSecurityOptions } from "../../Db";

const ParentContainer = styled(Box)`
  width: 100%;
  background-color: #f9f9fc;
  height: 100%;
`;
const Container = styled(Box)`
  ${`min-height: calc(100vh - 64px);`};
  margin: auto;
  padding-top: 70px;
  width: 95%;
`;
const Header = styled(Typography)`
  margin: 0px;
  font: normal normal 600 28px Open Sans;
  /* text-align: center; */
  color: #4d4e4f;
`;
const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  position: relative;
  background-color: white;
  padding: 20px;
  border: 2px solid #f9f9fc;
`;
const TopWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 40px;
`;
const Row = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  min-height: 40px;
  padding: 20px 0px;
`;
const SecurityPage = () => {
  const navigate = useNavigate();
  const [openSettings, setOpenSettings] = useState(false);

  return rootStore.subscriptionState.isFeatureAvailableWithAddOns(
    "Advanced Security"
  ) ? (
    <ParentContainer>
      <Container>
        <Row>
          <TopWrapper>
            <Header>Advanced Security Options</Header>
          </TopWrapper>
        </Row>
        <Wrapper>
          {advancedSecurityOptions.map((row) => {
            return row.title === "pin_to_location" ? (
              <PinToLocationRow
                rowData={row}
                setOpenSettings={(bool) => {
                  setOpenSettings(bool);
                }}
                openSettings={openSettings}
              />
            ) : (
              <IPWhitelistingRow
                rowData={row}
                onSettingsClick={() => {
                  navigate("/admin/ip-config");
                }}
              />
            );
          })}
        </Wrapper>
      </Container>
    </ParentContainer>
  ) : (
    <NoAccessPermissionPage />
  );
};

export default SecurityPage;
