import { AppBar, Box, Divider, Modal, Toolbar } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  IconButton,
  Tooltip,
  Typography,
  Link,
} from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { dataRenderer } from "../../../../Functions";
import { Close, Settings } from "@material-ui/icons";
import IndiaMartConfigSetup from "./IndiaMartConfigSetup";

const dialogWidth = 600;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: fit-content;
  padding: 16px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: fit-content;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  overflow: hidden;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
`;

const ViewIndiamartConfigDialog = ({
  open,
  setOpen,
  formData,
  integrationData,
  postCreate,
  postUpdate,
  setShowPushWebhook,
}) => {
  const [selectedFormData, setSelectedFormData] = useState({});
  const [openCreateConfig, setOpenCreateConfig] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  
  const handleClose = () => {
    setOpen(false);
  };
  const defaultColDef = useMemo(() => ({
    resizable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
  };

  const columns = [
    {
      field: "sl_no",
      headerName: "S.NO",
      width: 70,

      valueGetter: "node.rowIndex + 1",
    },
    {
      field: "name",
      headerName: "NAME",
      flex: 1,

      cellRenderer: (params) => {
        let value = dataRenderer(params.data.name);
        return (
          <Tooltip title={value} placement="bottom-start">
            <span style={{ whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
              {value}
            </span>
          </Tooltip>
        );
      },
    },

    {
      field: "sync_type",
      headerName: "SYNC TYPE",
      flex: 1,

      cellRenderer: (params) => {
        let value = dataRenderer(params.data.sync_type);
        return (
          <Tooltip title={value} placement="bottom-start">
            <span style={{ whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
              {value}
            </span>
          </Tooltip>
        );
      },
    },

    {
      field: "actions",
      headerName: "ACTIONS",
      width: 100,

      cellRenderer: (params) => {
        if (
          params.data.status === "connected" ||
          params.data.status === "active"
        ) {
          return (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                gap: "8px",
              }}
            >
              <Tooltip title="Configure">
                <IconButton
                  style={{ padding: "0px" }}
                  color="primary"
                  onClick={() => {
                    setSelectedFormData(params.data);
                    setIsEdit(true);
                    setOpenCreateConfig(true);
                  }}
                >
                  <Settings />
                </IconButton>
              </Tooltip>
            </Box>
          );
        }
      },
    },
  ];

  const renderLinkPagesComponent = () => {
    return (
      <Link
        onClick={() => {
          setSelectedFormData({}); //reseting formData before create
          setOpenCreateConfig(true);
        }}
      >
        {" "}
        <Typography
          style={{
            width: "fit-content",
            fontSize: "16px",
            fontWeight: "600",
            textAlign: "left",
          }}
        >
          Create Config
        </Typography>
      </Link>
    );
  };

  return (
    <>
      <Modal
        disableEnforceFocus
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModelContainer>
          <Box>
            <AppBar
              component={"nav"}
              elevation={0}
              position="sticky"
              color="inherit"
              sx={{
                top: 0,
                bottom: "auto",
                width: "100%",
                right: 0,
              }}
            >
              <Toolbar
                style={{
                  padding: "16px",
                }}
              >
                <HeaderWrapper>
                  <Header>IndiaMart Configuration</Header>
                  <Box sx={{ display: "flex", gap: "8px" }}>
                    {formData.length > 0 && renderLinkPagesComponent()}
                    <IconButton
                      style={{ padding: "0px" }}
                      color="primary"
                      onClick={handleClose}
                    >
                      <Close />
                    </IconButton>
                  </Box>
                </HeaderWrapper>
              </Toolbar>
            </AppBar>
            <Divider style={{ margin: "0px 16px" }} />
            <Container>
              {formData.length > 0 ? (
                <Box
                  style={{
                    height: "300px",
                    overflowY: "auto",
                  }}
                >
                  <div className="ag-theme-alpine" style={{ width: "100%" }}>
                    <AgGridReact
                      domLayout="autoHeight"
                      gridOptions={gridOptions}
                      rowData={formData}
                      defaultColDef={defaultColDef}
                      columnDefs={columns}
                      animateRows={true}
                      suppressCellFocus
                      overlayNoRowsTemplate={"No Configs Found"}
                    />
                  </div>
                </Box>
              ) : (
                <>
                  <Box
                    sx={{
                      height: "300px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {renderLinkPagesComponent()}
                  </Box>
                </>
              )}
            </Container>
          </Box>
        </ModelContainer>
      </Modal>

      {openCreateConfig && (
        <IndiaMartConfigSetup
          open={openCreateConfig}
          setOpen={setOpenCreateConfig}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          formData={selectedFormData}
          integrationData={integrationData}
          postCreate={postCreate}
          postUpdate={postUpdate}
          setShowPushWebhook={setShowPushWebhook}
        />
      )}
    </>
  );
};

export default ViewIndiamartConfigDialog;
