import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { AddBoxOutlined, Visibility } from "@material-ui/icons";
import { observer } from "mobx-react-lite";
import { leadFileColumns } from "../../Db";
import { DataGrid } from "@mui/x-data-grid";
import AudioModal from "../audio_modal/AudioModal";
import NotesModal from "../notes_modal/NotesModal";
import {
  getLeadContactLinkedFilesApi,
  getLeadFilesApi,
  getLeadPredefinedFilesApi,
  submitFileApi,
  updateContactFileApi,
  uploadLeadFileApi,
} from "../../Api";
import FileLogModal from "../file_log_modal/FileLogModal";
import analytics from "../../Analytics";
import { IconButton, Snackbar, Tooltip } from "@material-ui/core";
import { Chip } from "@mui/material";
import { convertBytesToMB, dataRenderer } from "../../Functions";
import rootStore from "../../stores/RootStore";
import MessageWithLoadingStateCustomDialog from "../alert_dialogue/MessageWithLoadingStateCustomDialog";
import { Key, Upload } from "@mui/icons-material";
import ViewPasswordModal from "../file_log_modal/ViewPasswordModa";
import FileUploadComponent from "../file_upload_component/FileUploadComponent";
import CustomAlertMessageDialog from "../alert_dialogue/CustomAlertMessageDialog";

const Container = styled.div`
  width: 100%;
  position: relative;
  ${`min-height: calc(100vh - 234px);`};
`;
const Wrapper = styled.div`
  display: flex;
`;

const DetailsWrapper = styled.div`
  height: fit-content;
  width: 100%;
  background: white;
  border-radius: 10px;
  /* padding: 12px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;

const AddBtnWrapper = styled.div`
  position: absolute;
  right: 0px;
  top: 9px;
  color: #185dd2;
  z-index: 2;
  cursor: pointer;
  @media (max-width: 700px) {
    position: unset;
    margin-left: 12px;
  }
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TableWrapper = styled.div`
  width: 100%;
  ${`min-height: calc(100vh - 282px);`};
`;
const Text = styled.p`
  margin: 0px;
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;
const UserFileTab = ({ uid, lid, canEditLead, leadData }) => {
  const [open, setOpen] = useState(false);
  const [audioUrl, setAudioUrl] = useState();
  const [openAudio, setOpenAudio] = useState(false);
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [textNote, setTextNote] = useState("");
  const [viewNote, setViewNote] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [predefinedFileList, setPredefinedFileList] = useState([]);
  const columnsList = [...rootStore.userStore.AllColumnsList];
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [openSubmitResponseDialog, setOpenSubmitResponseDialog] =
    useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showLimitAlert, setShowLimitAlert] = useState(false);
  const [userUploadCount, setUserUploadCount] = useState(0);
  const [showFileSizeAlert, setShowFileSizeAlert] = useState(false);

  const getFilePattern = (fileType) => {
    if (fileType.includes("image")) {
      return ".jpg,.png,.jpeg,.svg";
    } else if (fileType.includes("pdf")) {
      return ".pdf";
    }
    return "";
  };

  const uploadLeadFile = async (fileID, formData) => {
    let response = await uploadLeadFileApi({
      leadID: lid,
      fileID: fileID,
      payload: formData,
    });
    if (!response.hasError()) {
      setIsSubmitFail(false);
      setRefresh(true);
      setSuccessMessage("File uploaded successfully!");
    } else {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    }
  };

  const uploadContactFile = async (fileID, formData) => {
    let response = await updateContactFileApi({
      contactID: leadData["contact_id"],
      fileID: fileID,
      payload: formData,
    });
    if (!response.hasError()) {
      setIsSubmitFail(false);
      setRefresh(true);
      setSuccessMessage("File uploaded successfully!");
    } else {
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    }
  };

  const handleFileUpload = async (event, selectedFileData) => {
    let file = event.target.files[0];

    if (file !== null && file !== undefined) {
      const fileSize = convertBytesToMB(file.size ? file.size : 0);
      if (fileSize > 10) {
        //file size limit - 10 MB
        event.preventDefault();
        event.stopPropagation();
        setShowFileSizeAlert(true);
        event.target.value = "";
      } else {
        uploadFile(file, selectedFileData);
      }
    }
  };

  const uploadFile = async (file, selectedFileData) => {
    setUploadingFile(true);
    setOpenSubmitResponseDialog(true);
    let formData = new FormData();
    formData.append("title", selectedFileData.title);
    formData.append("desc", selectedFileData.desc);
    formData.append("file_type", selectedFileData.file_type);
    formData.append("file", file);
    if (selectedFileData.file_password !== null) {
      formData.append("file_password", selectedFileData.file_password);
    }
    if (
      selectedFileData.hasOwnProperty("link_to_contact") &&
      selectedFileData["link_to_contact"] === true
    ) {
      await uploadContactFile(selectedFileData["id"], formData);
    } else {
      await uploadLeadFile(selectedFileData["id"], formData);
    }
    setUploadingFile(false);
  };

  const actionColumn = [
    {
      field: "action",
      headerName: <Text>Actions</Text>,
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return (
          <Row style={{ columnGap: "10px" }}>
            {canEditLead() && (
              <Row>
                {params.row["is_predefined"] === true ? (
                  <IconButton
                    style={{ padding: "0px" }}
                    onClick={(e) => {
                      setSelectedRowData(params.row);
                      setIsEdit(true);
                      setOpen(true);
                    }}
                    color="primary"
                  >
                    <Upload
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </IconButton>
                ) : (
                  <FileUploadComponent
                    getFilePattern={() => getFilePattern(params.row.file_type)}
                    handleFileUpload={(event) => {
                      handleFileUpload(event, params.row);
                    }}
                  />
                )}
              </Row>
            )}
            <IconButton
              style={{ padding: "0px" }}
              disabled={
                !params.row.hasOwnProperty("file") ||
                (params.row.hasOwnProperty("file") &&
                  (params.row.file === null || params.row.file === ""))
              }
              color="primary"
              onClick={(e) => {
                window.open(params.row.file, "_blank");
              }}
            >
              <Tooltip title={"View File"}>
                <Visibility
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              </Tooltip>
            </IconButton>
            <IconButton
              onClick={() => {
                setSelectedRowData(params.row);
                setOpenPasswordDialog(true);
              }}
              style={{ padding: "0px" }}
              disabled={
                !params.row.hasOwnProperty("file_password") ||
                (params.row.hasOwnProperty("file_password") &&
                  (params.row.file_password === null ||
                    params.row.file_password === ""))
              }
            >
              <Tooltip title={"View Password"}>
                <Key
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              </Tooltip>
            </IconButton>
          </Row>
        );
      },
    },
  ];

  const handleCellClick = (params) => {
    if (params.field === "notes") {
      setTextNote(params.value);
      setViewNote(true);
    }
  };
  const handleClick = () => {
    if (
      rootStore.subscriptionState.hasReachedMaxFileLimit({
        leadFilesCount: userUploadCount,
      })
    ) {
      setShowLimitAlert(true);
    } else {
      setIsEdit(false);
      setOpen(true);
    }
  };

  const fetchFileDetails = async () => {
    let predefinedFiles = await getLeadPredefinedFilesApi();
    predefinedFiles = predefinedFiles.map((e) => ({
      ...e,
      is_predefined: true,
    }));

    let details = await getLeadFilesApi({
      id: lid,
      page: 0,
      page_size: 50,
    });

    //setting user uploaded file count for checking addon limit
    if (Object.keys(details).length > 0) {
      setUserUploadCount(details.item_count !== null ? details.item_count : 0);
    }
    let fileDetails = [];

    let titles = predefinedFiles
      .filter((entry) => entry.link_to_contact)
      .map((entry) => entry.title.toString());

    if (titles.length > 0) {
      let linkedFiles = await getLeadContactLinkedFilesApi({
        leadID: lid,
        payload: { file_names: titles },
      });

      linkedFiles.forEach((file) => {
        let index = predefinedFiles.findIndex(
          (doc) => doc.title === file.title
        );
        if (index !== -1) {
          let doc = predefinedFiles.splice(index, 1)[0];
          let tempObj = { ...doc, ...file };
          tempObj.is_predefined = false;
          fileDetails.push(tempObj);
        }
      });
    }

    if (details.hasOwnProperty("items") && details["items"].length > 0) {
      let files = details.items;
      files.forEach((file) => {
        let index = predefinedFiles.findIndex(
          (doc) => doc.id === file.lead_file_list_id
        );
        if (index !== -1) {
          let doc = predefinedFiles.splice(index, 1)[0];
          let tempObj = { ...doc, ...file };
          tempObj.is_predefined = false;
          fileDetails.push(tempObj);
        } else {
          let tempObj = { ...file };
          tempObj.is_predefined = false;
          fileDetails.push(tempObj);
        }
      });
    }

    fileDetails.push(...predefinedFiles);
    fileDetails.sort(
      (a, b) => (b.is_predefined ? 1 : 0) - (a.is_predefined ? 1 : 0)
    );

    return fileDetails;
  };

  const init = async () => {
    setLoading(true);
    setRow([]);
    let tempList = await fetchFileDetails();
    setRow(tempList);
    setLoading(false);
  };

  const refetch = async () => {
    setLoading(true);
    setRow([]);
    let tempList = await fetchFileDetails();
    setRow(tempList);
    setLoading(false);
    setRefresh(false);
  };
  useEffect(() => {
    if (refresh) {
      refetch();
    }
  }, [refresh]);
  useEffect(() => {
    init();
  }, []);

  const isLeadDataExist = (columnID) => {
    let index = columnsList.findIndex((column) => column["id"] === columnID);
    if (index !== -1) {
      let columnMeta = columnsList[index];
      let customData = leadData.hasOwnProperty("custom_params")
        ? JSON.parse(leadData["custom_params"])
        : {};
      if (columnMeta["is_custom_param"] === true) {
        let field_to_check = columnMeta["field"].replace("custom_params_", "");
        if (customData.hasOwnProperty(field_to_check)) {
          return (
            customData[field_to_check] !== null &&
            customData[field_to_check] !== ""
          );
        }
      } else {
        if (leadData.hasOwnProperty(columnMeta["field"])) {
          return (
            leadData[columnMeta["field"]] !== null &&
            leadData[columnMeta["field"]] !== ""
          );
        }
      }
    }
    return false;
  };

  const startingColumns = [
    {
      field: "title",
      headerName: <Text>Title</Text>,
      sortable: false,

      width: 150,
      renderCell: (params) => {
        let value = params.row.hasOwnProperty("title")
          ? dataRenderer(params.row.title)
          : "-";
        return (
          <>
            <Tooltip title={value}>
              <span className="csutable-cell-trucate">{value}</span>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "desc",
      headerName: <Text>Description</Text>,
      sortable: false,

      width: 250,
      renderCell: (params) => {
        let value = params.row.hasOwnProperty("desc")
          ? dataRenderer(params.row.desc)
          : "-";

        return (
          <>
            <Tooltip title={value}>
              <span className="csutable-cell-trucate">{value}</span>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "mandatory",
      headerName: <Text>Mandatory</Text>,
      sortable: false,

      width: 150,
      renderCell: (params) => {
        let isMandatory = null;
        if (
          params.row.hasOwnProperty("mandatory") &&
          params.row["mandatory"] !== null
        ) {
          if (params.row["mandatory"] === false) {
            isMandatory = false;
          } else {
            if (
              params.row.hasOwnProperty("conditions") &&
              params.row["conditions"] !== null
            ) {
              if (params.row["conditions"].length > 0) {
                let conditionData = params.row["conditions"][0];
                if (conditionData["operator"] === "exists") {
                  let isValueExist = isLeadDataExist(conditionData["column"]);
                  isMandatory = isValueExist === conditionData["values"];
                } else {
                  isMandatory = true;
                }
              } else {
                isMandatory = true;
              }
            } else {
              isMandatory = true;
            }
          }
        }
        if (isMandatory !== null) {
          if (isMandatory === true) {
            return <Chip label={"True"} color="success" />;
          } else if (isMandatory === false) {
            return <Chip label={"False"} color="warning" />;
          }
        } else {
          return "-";
        }
      },
    },
  ];

  useEffect(() => {
    analytics.triggerEvent(
      4625000053,
      "files_tab_load",
      "lead_details_page",
      "",
      {}
    );
  }, []);
  return (
    <>
      <Container>
        <AddBtnWrapper>
          <AddBoxOutlined
            color={canEditLead() ? "primary" : "disabled"}
            onClick={canEditLead() ? handleClick : null}
            style={{
              width: "40px",
              height: "40px",
            }}
          />
        </AddBtnWrapper>
        <Wrapper>
          <DetailsWrapper>
            <TableWrapper>
              <DataGrid
                autoHeight={true}
                rows={row}
                columns={startingColumns
                  .concat(leadFileColumns)
                  .concat(actionColumn)}
                checkboxSelection={false}
                style={{ cursor: "pointer" }}
                hideFooter={true}
                loading={loading}
                disableColumnMenu={true}
                disableSelectionOnClick={true}
                onCellClick={handleCellClick}
              />
            </TableWrapper>
          </DetailsWrapper>
        </Wrapper>
      </Container>

      {open && (
        <FileLogModal
          open={open}
          setOpen={setOpen}
          setRefresh={setRefresh}
          refresh={refresh}
          leadID={lid}
          isEdit={isEdit}
          editableData={selectedRowData}
          leadData={leadData}
          setShowFileSizeAlert={setShowFileSizeAlert}
        />
      )}
      {showFileSizeAlert && (
        <CustomAlertMessageDialog
          title={"Alert"}
          open={showFileSizeAlert}
          handleClose={() => setShowFileSizeAlert(false)}
          handleCloseOutside={() => setShowFileSizeAlert(false)}
          message={`Action Failed: The selected file exceeds the allowed limit of 10 MB.`}
        />
      )}
      {openAudio && audioUrl && (
        <AudioModal url={audioUrl} open={openAudio} setOpen={setOpenAudio} />
      )}
      {viewNote && (
        <NotesModal open={viewNote} setOpen={setViewNote} textNote={textNote} />
      )}
      <Snackbar
        open={openSnackBar}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={() => {
          setOpenSnackBar(false);
        }}
        message="Copied to clipboard!"
      />

      {openSubmitResponseDialog && (
        <MessageWithLoadingStateCustomDialog
          open={openSubmitResponseDialog}
          setOpen={setOpenSubmitResponseDialog}
          successMessage={successMessage}
          errorMessage={errorMessage}
          loading={uploadingFile}
          isError={isSubmitFail}
        />
      )}
      {openPasswordDialog && (
        <ViewPasswordModal
          open={openPasswordDialog}
          setOpen={setOpenPasswordDialog}
          data={selectedRowData}
        />
      )}
      {showLimitAlert && (
        <CustomAlertMessageDialog
          title={"Alert"}
          open={showLimitAlert}
          handleClose={() => setShowLimitAlert(false)}
          handleCloseOutside={() => setShowLimitAlert(false)}
          message={
            "Action Failed: You've reached the maximum file limit allowed per lead for your account. Please get in touch with support for assistance or to upgrade your plan."
          }
        />
      )}
    </>
  );
};

export default observer(UserFileTab);
