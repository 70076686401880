import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, Grid, makeStyles } from "@material-ui/core";
import {
  AppBar,
  Backdrop,
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Toolbar,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import {
  createIntegrationsExternalWebFormApi,
  getAdminMembersApi,
  getAdminSourcesApi,
  getAdminStagesApi,
  getAdminStatusesApi,
  getAdminSystemUsersApi,
  getColumnsForMapping,
  updateIntegrationsExternalWebFormApi,
} from "../../Api";
import rootStore from "../../stores/RootStore";
import DefaultParamsAndMappingScreen from "./dialogs/external_webform_column_mapping_screens/DefaultParamsAndMappingScreen";
import { getLocalizedText } from "../../Functions";
import { defaultParamFields } from "../../Db";

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const ModelWrapper = styled(Box)`
  width: 500px;
  margin: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding-top: 0px;
`;

const ModelHeader = styled(Typography)`
  font: normal normal 600 18px Open Sans;
`;

const MessageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  ${`height: calc(100vh - 64px);`};
`;
const Message = styled(Typography)`
  font: normal normal normal 24px Open Sans;
  color: green;
  margin: 0px;
`;

const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;

const InputContainer = styled(Box)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 24px;
  gap: 20px;
`;

const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const CreateIntegrationExternalWebformSidepanel = ({
  setOpen,
  setRefresh,
  isEdit,
  setIsEdit,
  formData,
}) => {
  const classes = useStyles();
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitFail, setIsSubmitFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const projectList = [...rootStore.authStore.projectList];
  const [details, setDetails] = useState({
    name: "",
    origin_domain: "",
    destination_project_id: "",
  });
  const [formStep, setFormStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // default parameters and mapping related state variables
  const [formAndProjectColumnMapping, setFormAndProjectColumnMapping] =
    useState({});
  const [selectedDefaultParameterMapping, setSelectedDefaultParameterMapping] =
    useState({
      lead_owner_id: "",
      lead_status_id: "",
      lead_source_id: "",
      lead_stage_id: "",
      team_id: "",
    });
  const [projectColumnList, setProjectColumnList] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [sourceList, setSourceList] = useState([]);
  const [stageList, setStageList] = useState([]);
  const [fetchingNecessaryData, setFetchingNecessaryData] = useState(false);

  const handleClose = () => {
    setIsEdit(false);
    setOpen(false);
  };
  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const createForm = async (payload) => {
    let response = await createIntegrationsExternalWebFormApi({
      payload: payload,
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };

  const updateForm = async (payload) => {
    let response = await updateIntegrationsExternalWebFormApi({
      formID: formData["id"],
      payload: payload,
    });
    if (response.hasError()) {
      setIsSubmitSuccess(false);
      setErrorMessage(response.errorMessage);
      setIsSubmitFail(true);
    } else {
      setRefresh(true);
      setIsSubmitSuccess(true);
    }
  };

  const getProjectColumnsForMapping = async ({ projectID }) => {
    let response = await getColumnsForMapping(projectID);
    //filtering default param fields
    response = response.filter((column) => !defaultParamFields.includes(column.field));

    return response;
  };

  const isAtleastOneColumnMapped = () => {
    let flag = false;
    Object.keys(formAndProjectColumnMapping).forEach((item) => {
      if (formAndProjectColumnMapping[item]["mapped"] === true) {
        flag = true;
      }
    });
    return flag;
  };

  //below function is to check if all the required field of the selected project are mapped or not

  const isAlreadySelectedProjectField = (field) => {
    let flag = false;
    Object.keys(formAndProjectColumnMapping).forEach((item) => {
      if (
        formAndProjectColumnMapping[item]["project_lead"].hasOwnProperty(
          "field"
        ) &&
        formAndProjectColumnMapping[item]["project_lead"]["field"] === field
      ) {
        flag = true;
      }
    });
    return flag;
  };

  const initFormToProjectColumns = (
    mappingData,
    formQuestionList,
    projectColumnList
  ) => {
    let initList = {};
    let mapping = { ...mappingData };
    if (Object.keys(mapping).length > 0) {
      formQuestionList.forEach((question) => {
        let projectLead = {};
        let mapped = false;
        if (mapping.hasOwnProperty(question["key"])) {
          let index = projectColumnList.findIndex(
            (column) => column["id"] === mapping[question["key"]]
          );
          if (index !== -1) {
            mapped = true;
            projectLead = {
              id: projectColumnList[index]["id"],
              field: projectColumnList[index]["field"],
              display_name: projectColumnList[index]["headerName"],
              data_type: projectColumnList[index]["data_type"],
              is_custom_param: projectColumnList[index]["is_custom_param"],
              label: projectColumnList[index].headerName,
              value: projectColumnList[index].field,
              isDisabled: isAlreadySelectedProjectField(
                projectColumnList[index].field
              ),
              required: projectColumnList[index].required,
            };
          }
        }

        let tempObj = {
          form_lead: {
            field: question["key"],
            display_name: question["label"],
          },
          project_lead: projectLead,
          mapped: mapped,
        };

        initList[question["key"]] = tempObj;
      });
    }
    return initList;
  };

  const initFormToProjectColumnsOnProjectChange = (projectColumnList) => {
    let initList = {};
    projectColumnList.forEach((column) => {
      if (column["required"] === true) {
        let tempObj = {
          form_lead: {
            field: column["headerName"],
            display_name: column["headerName"],
          },
          project_lead: {
            id: column["id"],
            field: column["field"],
            display_name: column["headerName"],
            data_type: column["data_type"],
            is_custom_param: column["is_custom_param"],
            label: column.headerName,
            value: column.field,
            isDisabled: isAlreadySelectedProjectField(column.field),
            required: column.required,
          },
          mapped: true,
        };
        initList[column["headerName"]] = tempObj;
      }
    });
    return initList;
  };

  const allProjectRequiredColumnsFilled = () => {
    let required_columns_list = projectColumnList.filter(
      (column) => column.required
    );
    const checkedCurrToDestProjectList = Object.keys(
      formAndProjectColumnMapping
    )
      .filter((key) => formAndProjectColumnMapping[key].mapped === true)
      .reduce((result, key) => {
        result[key] = formAndProjectColumnMapping[key];
        return result;
      }, {});

    let flag = required_columns_list.every((item) => {
      return Object.values(checkedCurrToDestProjectList).some(
        (obj) => obj["project_lead"]["id"] === item.id
      );
    });

    return flag;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formStep === 1) {
      if (isAtleastOneColumnMapped()) {
        setIsSubmitFail(false);
        if (allProjectRequiredColumnsFilled()) {
          setIsSubmitSuccess(false);
          await handleFormSubmit();
        } else {
          setErrorMessage("All required fields are not mapped.");
          setIsSubmitFail(true);
        }
      } else {
        setErrorMessage(
          getLocalizedText("please_map_atleast_one_column_to_proceed")
        );
        setIsSubmitFail(true);
      }
    } else {
      setFormStep(formStep + 1);
      setIsSubmitFail(false);
    }
  };

  const handleFormSubmit = async () => {
    setIsSubmitting(true);
    let payload = { ...details };

    const systemUserData = await getAdminSystemUsersApi();
    payload["default_params"] = {
      ...selectedDefaultParameterMapping,
      lead_creator_id: systemUserData.hasOwnProperty("id")
        ? systemUserData["id"]
        : "",
    };
    let tempMappingList = {};
    Object.keys(formAndProjectColumnMapping).forEach((key) => {
      if (formAndProjectColumnMapping[key]["mapped"]) {
        tempMappingList[key] =
          formAndProjectColumnMapping[key]["project_lead"]["id"];
      }
    });
    payload["mapping"] = tempMappingList;
    if (isEdit) {
      await updateForm(payload);
    } else {
      await createForm(payload);
    }
    setIsSubmitting(false);
  };

  const getMemberList = async ({ projectID }) => {
    try {
      let response = await getAdminMembersApi(projectID);
      return response.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  };
  const getSourceList = async ({ projectID }) => {
    try {
      let response = await getAdminSourcesApi(projectID);
      return response.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const getStagesList = async ({ projectID }) => {
    try {
      let response = await getAdminStagesApi(projectID);
      return response.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const getStatusList = async ({ projectID }) => {
    try {
      let response = await getAdminStatusesApi(projectID);
      return response.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const fetchDefaultParameterAndGetProjectColumns = async ({
    projectID,
    defaultParams,
  }) => {
    setFetchingNecessaryData(true);
    let memberList = await getMemberList({ projectID });
    let sourceList = await getSourceList({ projectID });
    let stageList = await getStagesList({ projectID });
    let statusList = await getStatusList({ projectID });
    let projectColumns = await getProjectColumnsForMapping({ projectID });
    setMemberList(memberList);
    setSourceList(sourceList);
    setStageList(stageList);
    setStatusList(statusList);
    initSelectedDefaultParameterMapping({
      sourceList,
      stageList,
      statusList,
      defaultParams,
    });
    setFetchingNecessaryData(false);
    return projectColumns;
  };

  const initSelectedDefaultParameterMapping = ({
    sourceList,
    stageList,
    statusList,
    defaultParams,
  }) => {
    let tempObj = {
      ...defaultParams,
    };
    const hasNoDefaultParametersSet = Object.values(defaultParams).every(
      (value) => value === ""
    );

    console.log(hasNoDefaultParametersSet);
    if (hasNoDefaultParametersSet) {
      let defaultSourceIndex = sourceList.findIndex(
        (source) => source["default"] === true
      );
      if (defaultSourceIndex !== -1) {
        tempObj["lead_source_id"] = sourceList[defaultSourceIndex]["id"];
      }
      let defaultStageIndex = stageList.findIndex(
        (stage) => stage["default"] === true
      );
      if (defaultStageIndex !== -1) {
        tempObj["lead_stage_id"] = stageList[defaultStageIndex]["id"];
      }

      let defaultStatusIndex = statusList.findIndex(
        (status) => status["default"] === true
      );
      if (defaultStatusIndex !== -1) {
        tempObj["lead_status_id"] = statusList[defaultStatusIndex]["id"];
      }
    }
    if (Object.keys(tempObj).length > 0) {
      setSelectedDefaultParameterMapping(tempObj);
    } else {
      let tempObj = {
        lead_owner_id: "",
        lead_status_id: "",
        lead_source_id: "",
        lead_stage_id: "",
        team_id: "",
      };
      setSelectedDefaultParameterMapping(tempObj);
    }
  };

  const init = async () => {
    if (isEdit) {
      let tempObj = {
        name: formData["name"],
        origin_domain: formData["origin_domain"],
        destination_project_id: formData["destination_project"]["id"],
      };
      setDetails(tempObj);
      let projectColumnsForMapping =
        await fetchDefaultParameterAndGetProjectColumns({
          projectID: formData["destination_project"]["id"],
          defaultParams: formData["default_params"],
        });
      setProjectColumnList(projectColumnsForMapping);
      let formQuestionList = Object.keys(formData["mapping"]).map((key) => ({
        key: key,
        label: key,
      }));
      let formAndProjectColumnMapping = initFormToProjectColumns(
        formData["mapping"],
        formQuestionList,
        projectColumnsForMapping
      );
      setFormAndProjectColumnMapping(formAndProjectColumnMapping);
    }
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  const RenderButton = () => {
    if (formStep === 0) {
      return (
        <>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                setOpen(false);
              }}
              variant="outlined"
              color="primary"
              style={{
                fontSize: "12px",
                textTransform: "none",
                fontWeight: "bold",
                width: "100%",
              }}
            >
              {getLocalizedText("cancel")}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                width: "100%",
                textTransform: "none",
              }}
            >
              Next
            </Button>
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Grid item xs={6}>
            <Button
              onClick={() => {
                setFormStep(formStep - 1);
              }}
              variant="outlined"
              color="primary"
              style={{
                fontSize: "12px",
                textTransform: "none",
                fontWeight: "bold",
                width: "100%",
              }}
            >
              Prev
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                width: "100%",
                textTransform: "none",
              }}
            >
              Submit
            </Button>
          </Grid>
        </>
      );
    }
  };

  return !loading ? (
    <>
      <Box role="presentation">
        <AppBar
          component={"nav"}
          position="sticky"
          color="inherit"
          elevation={0}
          sx={{
            top: 0,
            bottom: "auto",
            width: 500,
            right: 0,
            bgcolor: "#f9f9fc",
          }}
        >
          <Toolbar>
            <ModelHeader>Configure Webhook</ModelHeader>
          </Toolbar>
        </AppBar>
        {!isSubmitSuccess ? (
          <ModelWrapper component={"form"} onSubmit={handleSubmit}>
            {formStep === 0 ? (
              <InputContainer>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>Name*</Label>
                      <OutlinedInput
                        style={{ height: "30px" }}
                        inputProps={{
                          min: 0,
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        onChange={handleChange}
                        name="name"
                        required
                        value={details["name"]}
                        placeholder="Enter value"
                      />
                    </InputWrapper>
                  </Grid>

                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>Select Destination Project*</Label>
                      <FormControl
                        sx={{ width: "100%" }}
                        classes={{
                          root: classes.quantityRoot,
                        }}
                      >
                        <Select
                          classes={{
                            icon: classes.icon,
                          }}
                          displayEmpty
                          onChange={async (e) => {
                            let projectColumnsForMapping =
                              await fetchDefaultParameterAndGetProjectColumns({
                                projectID: e.target.value,
                                defaultParams: {},
                              });
                            setProjectColumnList(projectColumnsForMapping);
                            let formAndProjectColumnMapping =
                              initFormToProjectColumnsOnProjectChange(
                                projectColumnsForMapping
                              );
                            setFormAndProjectColumnMapping(
                              formAndProjectColumnMapping
                            );
                            setDetails({
                              ...details,
                              destination_project_id: e.target.value,
                            });
                          }}
                          name="destination_project_id"
                          disableUnderline
                          variant="outlined"
                          value={details["destination_project_id"]}
                          style={{
                            width: "100%",
                            height: "30px",
                            fontSize: "12px",

                            borderRadius: "0px",
                          }}
                          required
                        >
                          {projectList.map((item, i) => {
                            return (
                              <MenuItem
                                value={item["id"]}
                                key={i}
                                style={{ fontSize: "12px" }}
                              >
                                {item["name"]}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </InputWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <InputWrapper>
                      <Label>Origin Domain</Label>
                      <OutlinedInput
                        style={{ height: "30px" }}
                        inputProps={{
                          min: 0,
                          "aria-label": "weight",
                          style: {
                            fontSize: "12px",
                          },
                        }}
                        onChange={handleChange}
                        name="origin_domain"
                        value={details["origin_domain"]}
                        placeholder="Enter value"
                      />
                    </InputWrapper>
                  </Grid>
                </Grid>
              </InputContainer>
            ) : (
              <DefaultParamsAndMappingScreen
                formAndProjectColumnMapping={formAndProjectColumnMapping}
                formData={formData}
                selectedDefaultParameterMapping={
                  selectedDefaultParameterMapping
                }
                selectedProjectID={details["destination_project_id"]}
                setFormAndProjectColumnMapping={setFormAndProjectColumnMapping}
                setSelectedDefaultParameterMapping={
                  setSelectedDefaultParameterMapping
                }
                projectColumnList={projectColumnList}
                memberList={memberList}
                sourceList={sourceList}
                stageList={stageList}
                statusList={statusList}
                isAlreadySelectedProjectField={isAlreadySelectedProjectField}
              />
            )}

            <AppBar
              position="fixed"
              color="inherit"
              elevation={0}
              sx={{ top: "auto", bottom: 0, width: 500 }}
            >
              <Toolbar style={{ width: 500, position: "relative" }}>
                {isSubmitFail && (
                  <ErrorAlert
                    onClose={(e) => {
                      e.stopPropagation();
                      setIsSubmitFail(false);
                    }}
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    {errorMessage}
                  </ErrorAlert>
                )}
                <Grid container spacing={2}>
                  <RenderButton />
                </Grid>
              </Toolbar>
            </AppBar>
          </ModelWrapper>
        ) : (
          <MessageWrapper>
            {isEdit ? (
              <Message>Webhook updated successfully!</Message>
            ) : (
              <Message>Webhook created successfully!</Message>
            )}
            <Button
              onClick={handleClose}
              type="button"
              variant="contained"
              color="default"
              style={{
                marginTop: "20px",
                textTransform: "none",
                fontSize: "12px",
                fontWeight: "bold",
                width: "100px",
              }}
            >
              Close
            </Button>
          </MessageWrapper>
        )}
      </Box>
      <Backdrop
        open={fetchingNecessaryData}
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: "absolute",
          top: 0,
          left: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress
            style={{
              color: "white",
              width: "30px",
              height: "30px",
              marginRight: "16px",
            }}
          />
          <Typography>Configuring Webhooks...</Typography>
        </Box>
      </Backdrop>
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "500px",
        height: "100%",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default CreateIntegrationExternalWebformSidepanel;
