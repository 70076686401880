import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Toolbar,
  Typography,
  OutlinedInput,
} from "@material-ui/core";
import { styled } from "@mui/material/styles";
import MuiAlert from "@mui/material/Alert";
import { getLocalizedText } from "../../Functions";
import LocationMarker from "./LocationMarker";
import { GpsFixed } from "@material-ui/icons";
import {
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const dialogWidth = 640;
const center = [28.6139, 77.209];

const ErrorAlert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      {...props}
      color="error"
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        fontSize: "14px",
        zIndex: "9999",
        display: "flex",
        alignItems: "center",
        borderRadius: "0px",
      }}
    />
  );
});
const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${dialogWidth}px;
  text-align: left;
  margin: auto;
  background-color: white;
  height: 100%;
  padding: 0 16px 16px 16px;
`;
const ModelContainer = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  height: 660px;
  width: fit-content;
  border: none;
  outline: none;
  background-color: white;
  box-shadow: 0px 3px 6px #0000000d;
  border: 1px solid #c5d7f1;
  box-shadow: 0px 3px 20px #185dd21f;
  border-radius: 8px;
  box-sizing: border-box;
`;
const Header = styled(Typography)`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
`;
const Label = styled(Typography)`
  font: normal normal normal 10px Open Sans;
  color: #4d4e4f;
`;
const HeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const MapLocationDialog = ({ open, setOpen, handleSave, locationData }) => {
  const [position, setPosition] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState("google_url");
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [googleURL, setGoogleURL] = useState("");
  const [latLong, setLatLong] = useState({
    latitude: "",
    longitude: "",
  });
  const [panToLocation, setPanToLocation] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleMapSearch = () => {
    const coordinates = getValidCoords();

    //set new position if latlong is there
    if (
      coordinates.hasOwnProperty("latitude") &&
      coordinates.hasOwnProperty("longitude")
    ) {
      setPosition([coordinates.latitude, coordinates.longitude]);
      setShowError(false);
      setPanToLocation(true);
    } else {
      showErrorMessage();
    }
  };

  const handleSaveClick = () => {
    const coordinates = getValidCoords();

    if (
      coordinates.hasOwnProperty("latitude") &&
      coordinates.hasOwnProperty("longitude")
    ) {
      handleSave(position);
    } else {
      showErrorMessage();
    }
  };

  const showErrorMessage = () => {
    if (selectedGroup === "google_url") {
      setErrorMessage("URL invalid.");
    } else if (selectedGroup === "lat_long") {
      setErrorMessage("Latitude or longitude invalid.");
    }
    setShowError(true);
  };

  const getValidCoords = () => {
    let coordinates = {};

    if (selectedGroup === "google_url") {
      coordinates = extractLatLongFromGoogleURL(googleURL); //extract lat, long from url
    } else if (selectedGroup === "lat_long") {
      coordinates = validateLatLong(latLong);
    }

    return coordinates;
  };

  const extractLatLongFromGoogleURL = (googleMapURL) => {
    const regex = /@(-?\d+\.\d+),(-?\d+\.\d+),/;
    const match = googleMapURL.match(regex);

    if (match) {
      const latitude = parseFloat(match[1]);
      const longitude = parseFloat(match[2]);
      return { latitude, longitude };
    } else {
      console.log("Latitude and longitude not found in the URL.");
      return {};
    }
  };

  const validateLatLong = (value) => {
    if (value !== null) {
      //return empty if either lat or long is empty
      if (value.latitude === "" || value.longitude === "") {
        return {};
      }

      let lat = value["latitude"];
      let long = value["longitude"];
      if (
        isNaN(lat) ||
        lat < -90 ||
        lat > 90 ||
        isNaN(long) ||
        long < -180 ||
        long > 180
      ) {
        return {}; // invalid lat or long values
      }
      return { latitude: lat, longitude: long };
    } else {
      return {};
    }
  };

  useEffect(() => {
    if (locationData !== null) {
      setPosition(locationData);
    }
  }, []);

  const handleLatLongChange = (field) => (e) => {
    setLatLong((prevState) => ({
      ...prevState,
      [field]: e.target.value,
    }));
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus
    >
      <ModelContainer>
        <Box>
          <AppBar
            component={"nav"}
            elevation={0}
            position="sticky"
            color="inherit"
            sx={{
              top: 0,
              bottom: "auto",
              width: "100%",
              right: 0,
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            <Toolbar
              style={{
                padding: "16px",
              }}
            >
              <HeaderWrapper>
                <Header>Mark your location on map</Header>
              </HeaderWrapper>
            </Toolbar>
          </AppBar>
          <Divider style={{ margin: "0px 16px" }} />
          <Container>
            <Box
              sx={{
                position: "relative",
                "& .leaflet-container .leaflet-control-attribution": {
                  display: "none",
                },
                display: "flex",
                flexDirection: "column",
                paddingTop: "4px",
                gap: "8px",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  marginTop: "8px",
                  marginBottom: "8px",
                }}
              >
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={selectedGroup}
                    onChange={(e) => {
                      setShowError(false);
                      setSelectedGroup(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      style={{ fontSize: "12px" }}
                      value="google_url"
                      control={<Radio />}
                      label={
                        <Label style={{ fontSize: "12px" }}>
                          From Google URL
                        </Label>
                      }
                    />
                    <FormControlLabel
                      value="map"
                      control={<Radio />}
                      label={
                        <Label style={{ fontSize: "12px" }}>From Map</Label>
                      }
                    />
                    <FormControlLabel
                      value="lat_long"
                      control={<Radio />}
                      label={
                        <Label style={{ fontSize: "12px" }}>
                          Enter Coordinates
                        </Label>
                      }
                    />
                  </RadioGroup>
                </FormControl>
                {selectedGroup === "google_url" ? (
                  <OutlinedInput
                    style={{ height: "40px", width: "100%" }}
                    inputProps={{
                      "aria-label": "weight",
                      style: {
                        fontSize: "12px",
                      },
                    }}
                    onChange={(e) => {
                      setGoogleURL(e.target.value);
                    }}
                    onSubmit={(e) => handleMapSearch()}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleMapSearch();
                      }
                    }}
                    endAdornment={
                      <IconButton
                        onClick={handleMapSearch}
                        style={{
                          padding: 1,
                          backgroundColor: "transparent",
                        }}
                      >
                        <SearchIcon fontSize="small" />
                      </IconButton>
                    }
                    name="location_url"
                    placeholder="Enter Google Maps URL"
                  />
                ) : selectedGroup === "lat_long" ? (
                  <Box
                    sx={{ display: "flex", gap: "8px", alignItems: "flex-end" }}
                  >
                    <OutlinedInput
                      style={{
                        height: "40px",
                        width: "50%",
                        marginRight: "5px",
                      }}
                      inputProps={{
                        "aria-label": "latitude",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      value={latLong.latitude}
                      onChange={handleLatLongChange("latitude")}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleMapSearch();
                        }
                      }}
                      name="latitude"
                      placeholder="Enter latitude"
                    />
                    <OutlinedInput
                      style={{ height: "40px", width: "50%" }}
                      inputProps={{
                        "aria-label": "longitude",
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      value={latLong.longitude}
                      onChange={handleLatLongChange("longitude")}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleMapSearch();
                        }
                      }}
                      name="longitude"
                      placeholder="Enter longitude"
                    />
                    <IconButton
                      onClick={handleMapSearch}
                      style={{
                        padding: 10,
                        backgroundColor: "transparent",
                      }}
                    >
                      <SearchIcon fontSize="small" />
                    </IconButton>
                  </Box>
                ) : null}
              </Box>
              <MapContainer
                key={selectedGroup}
                center={center}
                zoom={13}
                style={{
                  height: selectedGroup === "map" ? "440px" : "400px",
                  width: "100%",
                }}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                <LocationMarker
                  setPosition={setPosition}
                  position={position}
                  isDisabled={selectedGroup !== "map"}
                  panToLocation={panToLocation}
                  setPanToLocation={setPanToLocation}
                />
                {/* <IconButton
                  style={{
                    position: "absolute",
                    bottom: "16px",
                    right: "16px",
                    backgroundColor: "#fff",
                    zIndex: 1000,
                  }}
                >
                  <GpsFixed color="primary" />
                </IconButton> */}
              </MapContainer>
            </Box>
          </Container>
          <AppBar
            position="static"
            color="inherit"
            elevation={0}
            sx={{ top: "auto", bottom: 0, width: 500, bgcolor: "#f9f9fc" }}
          >
            <Toolbar
              style={{
                padding: "16px",
                width: dialogWidth,
                position: "relative",
              }}
            >
              {showError && (
                <ErrorAlert
                  onClose={(e) => {
                    e.stopPropagation();
                    setShowError(false);
                  }}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  {errorMessage}
                </ErrorAlert>
              )}
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    color="default"
                    style={{
                      fontSize: "12px",
                      textTransform: "none",
                      fontWeight: "bold",
                    }}
                  >
                    {getLocalizedText("cancel")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disabled={position === null}
                    type="button"
                    onClick={() => {
                      //directly call save if map picker. else check if a valid input is there or not
                      if (selectedGroup !== "map") {
                        handleSaveClick();
                      } else {
                        handleSave();
                      }
                    }}
                    variant="contained"
                    color="primary"
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                      width: "fit-content",
                      textTransform: "none",
                    }}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </Box>
      </ModelContainer>
    </Modal>
  );
};

export default MapLocationDialog;
