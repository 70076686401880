import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import rootStore from "../../stores/RootStore";
import LeadsAssignModal from "../lead_assign_modal/LeadsAssignModal";
import { observer } from "mobx-react-lite";
import ColumnSelectionModal from "../column_selection_modal/ColumnSelectionModal";
import {
  changeLeadstatusApi,
  deleteMultipleLeadsApi,
  getColumnsForTabApi,
  markUnmarkMultipleLeadAsFreshLeadApi,
  resetNextFollowupForLeadsApi,
} from "../../Api";
import { toJS } from "mobx";
import { FormattedColumns, leadListingIconsColumn } from "../../ColumnUtils";
import {
  Backdrop,
  Box,
  CircularProgress,
  Tooltip,
  styled,
} from "@mui/material";
import SuccessMessageDialog from "../alert_dialogue/SuccessMessageDialog";
import ErrorMessageDialog from "../alert_dialogue/ErrorMessageDialog";
import analytics from "../../Analytics";
import {
  getAvailableStatusListByStage,
  hasAccessToLeadsPaginationV2,
  isAdditionalStatusDetailsRequired,
  isLastContactedToday,
  isOpenCallLogContextMenuDisabled,
  isSetLeadColorContextMenuDisabled,
  validateDisabledSubFeature,
} from "../../Functions";
import CustomConfirmationDialog from "../alert_dialogue/CustomConfirmationDialog";
import MultiLeadUpdateStageModal from "../update_stage_modal/MultiLeadUpdateStageModal";
import MultiLeadUpdateSourceModal from "../update_source_modal/MultiLeadUpdateSourceModal";
import MultiLeadUpdateStatusModal from "../update_status_modal/MultiLeadUpdateStatusModal";
import CustomMessageDialog from "../alert_dialogue/CustomMessageDialog";
import DeleteLeadConfirmationDialog from "../alert_dialogue/DeleteLeadConfirmationDialog";
import PriorityIndividualLeadDialog from "../priority_lead_dialog/PriorityIndividualLeadDialog";
import MoveProjectLeadModal from "../move_leads_to_project/MoveProjectLeadModal";
import DialogWithCommentField from "../alert_dialogue/DialogWithCommentField";
import SetMultiLeadColorDialog from "../update_lead_color_dialog/SetMultiLeadColorDialog";
import LeadContextMenu from "../lead_context_menu/LeadContextMenu";
import SetLeadColorDialog from "../update_lead_color_dialog/SetLeadColorDialog";
import CallLogModal from "../call_log_modal/CallLogModal";
import TableSkeleton from "./components/TableSkeleton";
import LeadScopeDialog from "../lead_scope_dialog/LeadScopeDialog";
import DropdownColumnWidget from "./components/DropdownColumnWidget";
import UpdateStatusModal from "../update_status_modal/UpdateStatusModal";
import ExportMultiLeadDialog from "../export_multi_lead_dialog/ExportMultiLeadDialog";
import AddLeadRemarksDialog from "../add_lead_remarks_dialog/AddLeadRemarksDialog";
import { AgGridReact } from "ag-grid-react";
import LeadsListingPaginationComponentV2 from "../pagination/LeadsListingPaginationComponentV2";
import LeadListingPaginationComponentV1 from "../pagination/LeadListingPaginationComponentV1";
import { leadListingRowBuffer } from "../../Config";
import MultiLeadUpdateFieldModal from "../update_field_modal/MultiLeadUpdateFieldModal";
const Container = styled(Box)`
  height: fit-content;
  background-color: white;
  width: 100%;
  @media (max-width: 700px) {
    padding: 0px;
  }
`;
const Wrapper = styled(Box)`
  width: 100%;
  display: flex;
  min-height: 400px;
  flex-direction: column;
  margin: auto;
`;
const PaginationWrapper = styled(Box)`
  padding: 0px 20px;
`;
const disableTabViewDelay = 3000; //milliseconds
const LeadsTab = (props) => {
  const { authStore, userStore } = rootStore;
  let navigate = useNavigate();
  const [selectedLeadIDs, setSelectedLeadIDs] = useState([]);
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [refreshColumn, setRefreshColumn] = useState(false);
  const [columns, setColumns] = useState([]);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [refreshLeads, setRefreshLeads] = useState(false);
  const columnsList = [...userStore.AllColumnsList];
  const stagesList = [...rootStore.authStore.stageList];
  const statusList = [...rootStore.authStore.statusList];
  const [showDeleteProtectedAlert, setShowDeleteProtectedAlert] =
    useState(false);
  const [openCustomCommentDialog, setOpenCustomCommentDialog] = useState(false);

  //********************state variable related to lead context menu******** */
  const [leadContextAnchor, setLeadContextAnchor] = useState(null);
  const [selectedLeadData, setSelectedLeadData] = useState({});
  const [
    openIndividualSetLeadColorDialog,
    setOpenIndividualSetLeadColorDialog,
  ] = useState(false);
  const [openCallLogDialog, setOpenCallLogDialog] = useState(false);
  //***************************END******************************* */
  const [openUpdateStatusDialog, setOpenUpdateStatusDialog] = useState(false);
  const [selectedLeadStatus, setSelectedLeadStatus] = useState("");
  const [hoveredRowID, setHoveredRowID] = useState("");

  const [openAddRemarksDialog, setOpenAddRemarksDialog] = useState(false);

  let uid = localStorage.getItem("uid");
  let pid = authStore.projectId;
  let tid = authStore.teamId;

  const sorting = toJS(rootStore.leadStore.tabColumnSortingList).hasOwnProperty(
    rootStore.leadStore.tabID
  )
    ? toJS(rootStore.leadStore.tabColumnSortingList)[rootStore.leadStore.tabID]
    : null;
  const handlePageSize = async (e) => {
    rootStore.leadStore.updateCurrentTabPageSize({
      tabID: rootStore.leadStore.tabID,
      size: e.target.value,
    });
    rootStore.leadStore.updateCurrentTabPage({
      tabID: rootStore.leadStore.tabID,
      page: 0,
    });
    await getLeadsList();
  };

  const getColumnsForTab = async () => {
    let storedTabColumns =
      rootStore.leadStore.tabInfo[rootStore.leadStore.tabID]["columns"];
    if (storedTabColumns.length > 0) {
      let updatedList = FormattedColumns(storedTabColumns);
      setColumns(updatedList);
    } else {
      try {
        let response = await getColumnsForTabApi(rootStore.leadStore.tabID);

        let newColumnsList = [];
        response.data.forEach((columnField) => {
          let index = columnsList.findIndex(
            (column) => column["field"] === columnField
          );
          if (index !== -1) {
            newColumnsList.push(columnsList[index]);
          }
        });
        rootStore.leadStore.updateCurrentTabColumns({
          tabID: rootStore.leadStore.tabID,
          columnsList: newColumnsList,
        });

        let modifiedList = FormattedColumns([
          ...rootStore.leadStore.tabInfo[rootStore.leadStore.tabID]["columns"],
        ]);

        setColumns(modifiedList);
      } catch (error) {
        if (error.response.request.status === 404) {
          rootStore.leadStore.updateTabColumnsWithDefaultColumns();

          let modifiedList = FormattedColumns([
            ...rootStore.leadStore.tabInfo[rootStore.leadStore.tabID][
              "columns"
            ],
          ]);
          setColumns(modifiedList);
        }
      }
    }
  };

  const getLeadsList = async () => {
    props.setAssignState(0);
    setSelectedLeadIDs([]);
    setRow([]);
    props.setIsTabViewDisabled(true);
    setLoading(true);

    // Set a timeout to reset tab view state if API takes longer than a threshold
    const timeoutId = setTimeout(() => {
      props.setIsTabViewDisabled(false);
    }, disableTabViewDelay);

    await rootStore.leadStore.fetchLeadsForTab();
    setRow(rootStore.leadStore.leadsForCurrentTab);
    props.setStaleDataIDList([]);
    setLoading(false);
    clearTimeout(timeoutId); // Clear the timeout once the API call is finished
    props.setIsTabViewDisabled(false);
  };

  const init = async () => {
    await getColumnsForTab();
    if (sorting !== null) {
      delete sorting["flag"];
    }

    await getLeadsList();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    init();
  }, [
    tid,
    refresh,
    rootStore.leadStore.tabID,
    props.refresh,
    rootStore.leadStore.tabColumnSortingList[rootStore.leadStore.tabID],
    rootStore.leadStore.leadScopeFilter,
    rootStore.leadStore._selectedGroupName,
  ]);

  const refetchTabColumns = async () => {
    setLoading(true);
    try {
      let response = await getColumnsForTabApi(rootStore.leadStore.tabID);

      let newColumnsList = [];
      response.data.forEach((columnField) => {
        let index = columnsList.findIndex(
          (column) => column["field"] === columnField
        );
        if (index !== -1) {
          newColumnsList.push(columnsList[index]);
        }
      });
      rootStore.leadStore.updateCurrentTabColumns({
        tabID: rootStore.leadStore.tabID,
        columnsList: newColumnsList,
      });

      let modifiedList = FormattedColumns([
        ...rootStore.leadStore.tabInfo[rootStore.leadStore.tabID]["columns"],
      ]);

      setColumns(modifiedList);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const refetchOnSelectColumnChange = async () => {
    await refetchTabColumns();
    await getLeadsList();
    setRefreshColumn(false);
  };

  useEffect(() => {
    if (refreshColumn) {
      refetchOnSelectColumnChange();
    }
  }, [refreshColumn]);

  const handlePage = async (e, page) => {
    rootStore.leadStore.updateCurrentTabPage({
      tabID: rootStore.leadStore.tabID,
      page: page - 1,
    });
    await getLeadsList();
  };

  const handleMarkLeadAsFreshLead = async () => {
    props.setOpenConfirmMarkLeadDialog(false);
    setShowBackDrop(true);
    let payload = { lead_ids: selectedLeadIDs, status: true };
    let response = await markUnmarkMultipleLeadAsFreshLeadApi({
      payload: payload,
    });
    if (response.hasError()) {
      setShowBackDrop(false);
      setOpenErrorDialog(true);
    } else {
      setShowBackDrop(false);
      setSuccessMessage("Leads successfully marked as fresh!");
      setOpenSuccessDialog(true);
      await getLeadsList();
    }
  };

  const handleResetFollowupForLeads = async (comments) => {
    props.setOpenConfirmResetFollowupDialog(false);
    setShowBackDrop(true);
    let payload = { lead_ids: selectedLeadIDs, comments: comments };
    let response = await resetNextFollowupForLeadsApi({
      payload: payload,
    });
    if (response.hasError()) {
      setShowBackDrop(false);
      setOpenErrorDialog(true);
    } else {
      setShowBackDrop(false);
      setSuccessMessage("Next Follow-up reset successfully!");
      setOpenSuccessDialog(true);
      await getLeadsList();
    }
  };

  const refetchLeads = async () => {
    await getLeadsList();
    setRefreshLeads(false);
  };
  useEffect(() => {
    if (refreshLeads) {
      refetchLeads();
    }
  }, [refreshLeads]);

  const refetchLeadsOnTabRefresh = async () => {
    await getLeadsList();
    props.setRefreshTabLeadListing(false);
  };

  useEffect(() => {
    if (props.refreshTabLeadListing) {
      refetchLeadsOnTabRefresh();
    }
  }, [props.refreshTabLeadListing]);

  //*******************delete leads related functions************* */

  const isStageDeleteProtected = ({ leadData }) => {
    let index = stagesList.findIndex(
      (stage) => stage["stage"] === leadData["lead_stage"]
    );
    if (index !== -1) {
      return stagesList[index]["delete_protected"];
    }
    return false;
  };
  const isStatusDeleteProtected = ({ leadData }) => {
    let index = statusList.findIndex(
      (status) => status["status"] === leadData["lead_status"]
    );
    if (index !== -1) {
      return statusList[index]["delete_protected"];
    }
    return false;
  };

  const isDeleteProtected = ({ leadData }) => {
    return (
      isStageDeleteProtected({ leadData: leadData }) ||
      isStatusDeleteProtected({ leadData: leadData })
    );
  };

  const isAnyLeadDeleteProtected = () => {
    for (let i = 0; i < selectedLeadIDs.length; i++) {
      let index = row.findIndex((lead) => lead["id"] === selectedLeadIDs[i]);
      let leadData = row[index];
      if (isDeleteProtected({ leadData: leadData })) {
        return true;
      }
    }
    return false;
  };

  const handleDeleteLead = async () => {
    props.setOpenDeleteLead(false);
    setShowBackDrop(true);

    if (isAnyLeadDeleteProtected()) {
      setShowBackDrop(false);
      setShowDeleteProtectedAlert(true);
    } else {
      let filteredLeadIds = [];

      for (let i = 0; i < selectedLeadIDs.length; i++) {
        let index = row.findIndex((lead) => lead["id"] === selectedLeadIDs[i]);
        let leadData = row[index];
        if (leadData["is_customer"] !== null) {
          if (leadData["is_customer"] === false) {
            filteredLeadIds.push(selectedLeadIDs[i]);
          }
        } else {
          // including leads with null is_customer value
          filteredLeadIds.push(selectedLeadIDs[i]);
        }
      }

      let response = await deleteMultipleLeadsApi({
        idList: filteredLeadIds,
      });
      if (response.hasError()) {
        setShowBackDrop(false);
        setOpenErrorDialog(true);
      } else {
        setShowBackDrop(false);
        setSuccessMessage("Leads deleted successfully!");
        setOpenSuccessDialog(true);
        await getLeadsList();
      }
    }
  };
  const sortIdsBasedOnListingOrder = () => {
    // Create a map to store the index of each object's ID in the list of objects
    const idIndexMap = new Map(row.map((obj, index) => [obj.id, index]));
    let ids = [...selectedLeadIDs];
    // Sort the list of IDs based on their index in the list of objects
    return ids.sort((a, b) => idIndexMap.get(a) - idIndexMap.get(b));
  };
  //****************************END********************************** */

  const handleQuickUpdate = async ({ rowID, status }) => {
    let payload = { status: status, sub_status: "None" };
    let response = await changeLeadstatusApi({
      data: payload,
      id: rowID,
    });
    if (!response.hasError()) {
      reFreshLeadListingLocally({ leadData: response.data });
    }
  };

  const reFreshLeadListingLocally = ({ leadData }) => {
    let tempList = [...row];
    let index = tempList.findIndex((entry) => entry["id"] === leadData["id"]);
    if (index !== -1) {
      tempList[index] = leadData;
    }
    setRow(tempList);
    let tempIDList = [...props.staleDataIDList];
    if (!props.staleDataIDList.includes(leadData["id"])) {
      tempIDList.push(leadData["id"]);
    }
    props.setStaleDataIDList(tempIDList);
  };

  const StatusFieldRenderer = ({ rowData }) => {
    if (hoveredRowID === rowData.id) {

      const availableStatuses = getAvailableStatusListByStage({
        currentStage: rowData["lead_stage"],
        stageList: stagesList,
        statusList: statusList,
      });

      let index = availableStatuses.findIndex(
        (status) => status["status"] === rowData["lead_status"]
      );
      if (index === -1) {
        let statusIndexInAllStatus = statusList.findIndex(
          (status) => status["status"] === rowData["lead_status"]
        );
        if (statusIndexInAllStatus !== -1) {
          availableStatuses.unshift(statusList[statusIndexInAllStatus]);
        }
      }
      return (
        <DropdownColumnWidget
          scope={"status"}
          onChange={(value) => {
            let additionalDetailsRequired = isAdditionalStatusDetailsRequired({
              status: value,
              leadData: rowData,
            });

            if (additionalDetailsRequired === true) {
              setSelectedLeadStatus(value);
              setSelectedLeadData(rowData);
              setOpenUpdateStatusDialog(true);
            } else {
              handleQuickUpdate({ rowID: rowData.id, status: value });
            }
          }}
          options={availableStatuses}
          value={rowData["lead_status"]}
          labelKey={"status"}
          valueKey={"status"}
        />
      );
    } else {
      let value = rowData["lead_status"];
      let color_value = "";
      let index = statusList.findIndex((status) => status.status === value);
      if (index !== -1) {
        color_value = statusList[index]["color"];
      }
      return (
        <Tooltip title={value}>
          <span
            className="csutable-cell-trucate"
            style={{
              color: color_value !== null ? color_value : "",
            }}
          >
            {value}
          </span>
        </Tooltip>
      );
    }
  };

  const getColumnListForDatatable = () => {
    if (validateDisabledSubFeature("quick_lead_update_in_listing")) {
      let index = columns.findIndex(
        (column) => column["field"] === "lead_status"
      );
      if (index !== -1) {
        let tempObj = {
          ...columns[index],
          cellRenderer: (params) => {
            return (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
                onMouseEnter={() => {
                  setHoveredRowID(params.data.id);
                }}
                onMouseLeave={() => {
                  setHoveredRowID("");
                }}
              >
                <StatusFieldRenderer rowData={params.data} />
              </Box>
            );
          },
        };
        columns[index] = tempObj;
      }
    }
    let len = columns.length;
    if (len > 0) {
      columns[len - 1]["minWidth"] = columns[len - 1]["width"];
      columns[len - 1]["flex"] = 1;
    }

    //checking if column width is persisted for a column
    const tempColumnWidthMapping =
      rootStore.leadStore.tabInfo[rootStore.leadStore.tabID][
        "columns_width_mapping"
      ];
    columns.forEach((column) => {
      if (tempColumnWidthMapping.hasOwnProperty(column["colId"])) {
        column["width"] = tempColumnWidthMapping[column["colId"]];
      }
    });

    //********end******** */

    if (selectedLeadIDs.length > 0) {
      leadListingIconsColumn[0][
        "headerName"
      ] = `${selectedLeadIDs.length} Selected`;
    } else {
      leadListingIconsColumn[0]["headerName"] = "";
    }
    return leadListingIconsColumn.concat(columns);
  };
  const defaultColDef = useMemo(() => ({
    resizable: true,
    suppressMovable: true,
  }));
  const gridOptions = {
    suppressRowClickSelection: true,
    preventDefaultOnContextMenu: validateDisabledSubFeature(
      "context_menu_in_lead_listing"
    ),
  };

  const handleCellContextMenu = (params) => {
    if (validateDisabledSubFeature("context_menu_in_lead_listing")) {
      params.event.preventDefault();
      setSelectedLeadData(params.data);
      setLeadContextAnchor({
        mouseX: params.event.clientX - 2,
        mouseY: params.event.clientY - 4,
      });
    }
  };
  const handleSelectionChange = (event) => {
    const selectedRows = event.api.getSelectedRows();
    const selectedIds = selectedRows.map((row) => row.id);
    props.setAssignState(selectedIds.length);
    setSelectedLeadIDs(selectedIds);
  };

  const getRowStyle = (params) => {
    return {
      cursor: "pointer",
      backgroundColor: isLastContactedToday(params.data["last_contacted_on"])
        ? "#E6EDF7"
        : "unset",
      opacity: props.staleDataIDList.includes(params.data.id) ? "0.2" : "1",
    };
  };

  const onCellClicked = (params) => {
    if (params.colDef.field === "icons") {
      params.event.stopPropagation();
      params.event.preventDefault();
    }
  };
  const onColumnResized = (params) => {
    if (params.finished) {
      // Get the resized column's ID and new width
      const colId = params.column.getColId();
      const newWidth = params.column.getActualWidth();

      rootStore.leadStore.updateColumnsWidthMapping({
        columnID: colId,
        width: newWidth,
      });
    }
  };

  const memoizedColumns = useMemo(
    () => getColumnListForDatatable(),
    [columns, selectedLeadIDs, hoveredRowID]
  );
  return (
    <>
      <Container>
        <Wrapper>
          {!loading ? (
            <>
              <Box
                sx={{
                  "& .ag-root-wrapper-body": {
                    height:
                      rootStore.leadStore._tabGroups.length > 1
                        ? "calc(100vh - 225px)"
                        : "calc(100vh - 209px)",
                  },
                  "& .ag-checkbox-input-wrapper input": {
                    cursor: "pointer",
                  },
                }}
              >
                <div className="ag-theme-alpine" style={{ width: "100%" }}>
                  <AgGridReact
                    getRowStyle={getRowStyle}
                    gridOptions={gridOptions}
                    rowData={row}
                    defaultColDef={defaultColDef}
                    columnDefs={memoizedColumns}
                    animateRows={true}
                    suppressCellFocus
                    pagination={false}
                    paginationPageSize={
                      rootStore.leadStore.tabInfo[rootStore.leadStore.tabID][
                        "page_size"
                      ]
                    }
                    onRowClicked={(row) => {
                      if (row.event.defaultPrevented) {
                        return null;
                      }
                      analytics.triggerEvent(
                        4625000035,
                        "lead_row_tap",
                        "leads_page",
                        "",
                        {}
                      );
                      authStore.updateLastActiveTime();
                      navigate(`/users/${uid}/leads/${row.data.id}`, {
                        state: {
                          lead_status: row.data["lead_status"],
                          scope: "leads_page",
                        },
                      });
                    }}
                    onCellContextMenu={handleCellContextMenu}
                    rowSelection="multiple"
                    onSelectionChanged={handleSelectionChange}
                    rowBuffer={leadListingRowBuffer}
                    onCellClicked={onCellClicked}
                    suppressColumnVirtualisation={true}
                    onColumnResized={onColumnResized}
                  />
                </div>
              </Box>
              <PaginationWrapper>
                {hasAccessToLeadsPaginationV2() ? (
                  <LeadsListingPaginationComponentV2
                    page_no={
                      rootStore.leadStore.tabInfo[rootStore.leadStore.tabID][
                        "page"
                      ]
                    }
                    page_size={
                      rootStore.leadStore.tabInfo[rootStore.leadStore.tabID][
                        "page_size"
                      ]
                    }
                    count={
                      rootStore.leadStore.tabInfo[rootStore.leadStore.tabID][
                        "count"
                      ]
                    }
                    paginationData={rootStore.leadStore.getLeadPaginationCounterData()}
                    handlePageSize={handlePageSize}
                    handlePage={handlePage}
                    pageSizeOptionList={
                      rootStore.userStore.leadListingPageSizeOptions
                    }
                  />
                ) : (
                  <LeadListingPaginationComponentV1
                    pageNumber={
                      rootStore.leadStore.tabInfo[rootStore.leadStore.tabID][
                        "page"
                      ]
                    }
                    row={row}
                    pageSize={
                      rootStore.leadStore.tabInfo[rootStore.leadStore.tabID][
                        "page_size"
                      ]
                    }
                    count={
                      rootStore.leadStore.tabInfo[rootStore.leadStore.tabID][
                        "count"
                      ]
                    }
                    handlePageSize={handlePageSize}
                    handlePage={handlePage}
                    pageSizeOptionList={
                      rootStore.userStore.leadListingPageSizeOptions
                    }
                  />
                )}
              </PaginationWrapper>
            </>
          ) : (
            <TableSkeleton height={"calc(100vh - 251px)"} />
          )}

          {props.open && (
            <LeadsAssignModal
              open={props.open}
              setOpen={props.setOpen}
              leads={sortIdsBasedOnListingOrder()}
              pid={pid}
              setRefresh={setRefresh}
              refresh={refresh}
              totalLeadCount={
                rootStore.leadStore.tabInfo[rootStore.leadStore.tabID]["count"]
              }
              pageSize={
                rootStore.leadStore.tabInfo[rootStore.leadStore.tabID][
                  "page_size"
                ]
              }
              getAllLeadIDsToAssign={() => {}}
              enableAssignAllLead={false}
            />
          )}
          {props.openSelectCol && (
            <ColumnSelectionModal
              open={props.openSelectCol}
              setOpen={props.setOpenSelectCol}
              setRefresh={setRefreshColumn}
            />
          )}
        </Wrapper>
      </Container>

      {props.openUpdateStageModal && (
        <MultiLeadUpdateStageModal
          open={props.openUpdateStageModal}
          setOpen={props.setOpenUpdateStageModal}
          selectedLeadIdsData={selectedLeadIDs}
          setRefresh={setRefreshLeads}
          scope={"leads_page"}
        />
      )}
      {props.openUpdateSourceModal && (
        <MultiLeadUpdateSourceModal
          open={props.openUpdateSourceModal}
          setOpen={props.setOpenUpdateSourceModal}
          selectedLeadIdsData={selectedLeadIDs}
          setRefresh={setRefreshLeads}
          scope={"leads_page"}
        />
      )}
      {props.openUpdateFieldModal && (
        <MultiLeadUpdateFieldModal
          open={props.openUpdateFieldModal}
          setOpen={props.setOpenUpdateFieldModal}
          selectedLeadIdsData={selectedLeadIDs}
          setRefresh={setRefreshLeads}
          scope={"leads_page"}
        />
      )}
      {props.openUpdateStatusModal && (
        <MultiLeadUpdateStatusModal
          open={props.openUpdateStatusModal}
          setOpen={props.setOpenUpdateStatusModal}
          selectedLeadIdsData={selectedLeadIDs}
          setRefresh={setRefreshLeads}
          scope={"leads_page"}
        />
      )}
      {props.openDeleteLead && (
        <DeleteLeadConfirmationDialog
          open={props.openDeleteLead}
          setOpen={props.setOpenDeleteLead}
          handleConfirm={() => {
            handleDeleteLead();
          }}
        />
      )}
      {showDeleteProtectedAlert && (
        <CustomMessageDialog
          open={showDeleteProtectedAlert}
          setOpen={setShowDeleteProtectedAlert}
          message={
            "Leads are delete-protected. Unprotect or remove them to proceed with deletion."
          }
        />
      )}
      {openSuccessDialog && (
        <SuccessMessageDialog
          open={openSuccessDialog}
          setOpen={setOpenSuccessDialog}
          message={successMessage}
        />
      )}
      {openErrorDialog && (
        <ErrorMessageDialog
          open={openErrorDialog}
          setOpen={setOpenErrorDialog}
        />
      )}

      {props.openConfirmMarkLeadDialog && (
        <CustomConfirmationDialog
          message={"Are you sure you want to mark the leads as fresh?"}
          open={props.openConfirmMarkLeadDialog}
          setOpen={props.setOpenConfirmMarkLeadDialog}
          handleConfirm={() => {
            handleMarkLeadAsFreshLead();
          }}
        />
      )}
     
      {props.openConfirmResetFollowupDialog && (
        <CustomConfirmationDialog
          message={
            "Are you sure you want to reset Next Follow-up for the leads?"
          }
          open={props.openConfirmResetFollowupDialog}
          setOpen={props.setOpenConfirmResetFollowupDialog}
          handleConfirm={() => {
            props.setOpenConfirmResetFollowupDialog(false);
            setOpenCustomCommentDialog(true);
          }}
        />
      )}
      {props.openPriorityLeadDialog && (
        <PriorityIndividualLeadDialog
          open={props.openPriorityLeadDialog}
          setOpen={props.setOpenPriorityLeadDialog}
          selectedLeadIdsData={selectedLeadIDs}
          setRefresh={setRefreshLeads}
        />
      )}
      {props.openMoveLead && (
        <MoveProjectLeadModal
          setOpen={props.setOpenMoveLead}
          open={props.openMoveLead}
          leads={selectedLeadIDs}
          pid={pid}
        />
      )}
      <Backdrop
        open={showBackDrop}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress style={{ color: "white" }} />
      </Backdrop>
      {openCustomCommentDialog && (
        <DialogWithCommentField
          open={openCustomCommentDialog}
          setOpen={setOpenCustomCommentDialog}
          handleSubmitButton={(value) => {
            handleResetFollowupForLeads(value);
            setOpenCustomCommentDialog(false);
          }}
        />
      )}

      {props.openSetLeadColorDialog && (
        <SetMultiLeadColorDialog
          leadIDs={selectedLeadIDs}
          open={props.openSetLeadColorDialog}
          setOpen={props.setOpenSetLeadColorDialog}
          setRefresh={setRefreshLeads}
        />
      )}

      {/* lead context menu related components */}
      {leadContextAnchor !== null && (
        <LeadContextMenu
          anchor={leadContextAnchor}
          setAnchor={setLeadContextAnchor}
          handleClose={() => {
            setLeadContextAnchor(null);
          }}
          handleMenuClick={(value) => {
            if (value === "set_lead_color") {
              setOpenIndividualSetLeadColorDialog(true);
              setLeadContextAnchor(null);
            } else if (value === "add_call_log") {
              setOpenCallLogDialog(true);
              setLeadContextAnchor(null);
            } else if (value === "add_remarks") {
              setOpenAddRemarksDialog(true);
              setLeadContextAnchor(null);
            }
          }}
          isOpenCallLogDisabled={() =>
            isOpenCallLogContextMenuDisabled({ leadData: selectedLeadData })
          }
          isSetLeadColorContextMenuDisabled={() =>
            isSetLeadColorContextMenuDisabled({ leadData: selectedLeadData })
          }
        />
      )}
      {/* end */}

      {openIndividualSetLeadColorDialog && (
        <SetLeadColorDialog
          open={openIndividualSetLeadColorDialog}
          setOpen={setOpenIndividualSetLeadColorDialog}
          setRefresh={setRefreshLeads}
          leadID={selectedLeadData["id"]}
        />
      )}

      {openCallLogDialog && (
        <CallLogModal
          scope={"lead_listing"}
          open={openCallLogDialog}
          setOpen={setOpenCallLogDialog}
          lid={selectedLeadData["id"]}
          uid={localStorage.getItem("uid")}
          setRefreshCallLogAfterAdd={() => {}}
          setRefreshLeadDetails={setRefreshLeads}
          leadData={selectedLeadData}
        />
      )}
      {props.openLeadScopeDialog && (
        <LeadScopeDialog
          open={props.openLeadScopeDialog}
          setOpen={props.setOpenLeadScopeDialog}
        />
      )}
      {openUpdateStatusDialog && (
        <UpdateStatusModal
          selectedLeadStatus={selectedLeadStatus}
          scope={"lead_listing_page"}
          id={selectedLeadData["id"]}
          open={openUpdateStatusDialog}
          setOpen={setOpenUpdateStatusDialog}
          data={selectedLeadData}
          setRefreshLeadDetails={() => {}}
          handleRefreshLeadListing={(leadData) => {
            reFreshLeadListingLocally({ leadData: leadData });
          }}
        />
      )}
      {props.openExportLeadDialog && (
        <ExportMultiLeadDialog
          leadIDs={selectedLeadIDs}
          open={props.openExportLeadDialog}
          setOpen={props.setOpenExportLeadDialog}
        />
      )}

      {openAddRemarksDialog && (
        <AddLeadRemarksDialog
          open={openAddRemarksDialog}
          setOpen={setOpenAddRemarksDialog}
          leadData={selectedLeadData}
          handleRefreshLeadListing={(leadData) => {
            reFreshLeadListingLocally({ leadData: leadData });
          }}
        />
      )}
    </>
  );
};

export default observer(LeadsTab);
