import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Divider, FormControl, makeStyles, Select } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import { observer } from "mobx-react-lite";
import { getTemplateListApi } from "../../Api";
import { Grid } from "@mui/material";
import { getLocalizedText } from "../../Functions";

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-height: 300px;
  width: 428px;
  gap: 20px;
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Header = styled.span`
  font: normal normal 600 18px Open Sans;
  color: #4d4e4f;
  margin-bottom: 10px;
`;
const RequiredFieldWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const Input = styled.input`
  width: 100%;
  height: 30px;
  padding: 4px 12px;
  outline: none;
  color: #4d4e4f;
  border: 1px solid #bfbfbf;
  font: normal normal 600 12px Open Sans;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  [type="number"] {
    -moz-appearance: textfield;
  }
  ::placeholder {
    font: normal normal 500 12px Open Sans;
    color: #e1e1e1;
  }
`;
const Label = styled.span`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Info = styled.span`
  font: normal normal 500 12px Open Sans;
  color: #4d4e4f;
  margin-bottom: 6px;
`;
const Detail = styled.span`
  font: normal normal 600 12px Open Sans;
  color: #4d4e4f;
`;
const SelectColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  @media (max-width: 700px) {
    width: 280px;
  }
`;

function ampm(time) {
  if (time.value !== "") {
    var hours = time.split(":")[0];
    var minutes = time.split(":")[1];
    var suffix = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12;
    var displayTime = hours + ":" + minutes + " " + suffix;
    return displayTime;
  }
  return;
}
const useStyles = makeStyles({
  quantityRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #bfbfbf",
      borderRadius: "0px",
    },
  },
  icon: {
    color: "#4D4E4F",
  },
});

const EmailCampaign = (props) => {
  const [templateList, setTemplateList] = useState([]);
  const [fromEmailAddressList, setFromEmailAddressList] = useState([]);
  const [variableList, setVariableList] = useState([]);

  const classes = useStyles();

  const getTemplates = async () => {
    let payload = {
      provider_id: props.selectedProviderData["provider"]["id"],
      provider_type: props.selectedProviderData["campaign_type"]["name"],
    };
    setFromEmailAddressList(props.selectedProviderData["provider"]["from_addresses"]);
    let response = await getTemplateListApi(payload);
    setTemplateList(response);

    let index = response.findIndex(
      (template) => template["id"] === props.emailTemplateID
    );
    if (index !== -1) {
      setVariableList(response[index]["variables"]);
    }
  };
  useEffect(() => {
    // props.setFromAddress("");
    // props.setEmailTemplateID("");
    // props.setEmailTemplateVariableList({});
    getTemplates();
  }, []);
  function snakeCaseToTitleCase(snakeCaseStr) {
    return snakeCaseStr
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  const handleVariable = (e) => {
    props.setEmailTemplateVariableList({
      ...props.emailTemplateVariableList,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <>
      <InputContainer>
        <InputWrapper>
          <Label>{getLocalizedText("from_address")}*</Label>
          <FormControl
            sx={{ width: "100%" }}
            classes={{
              root: classes.quantityRoot,
            }}
          >
            <Select
              classes={{
                icon: classes.icon,
              }}
              name="from_address"
              displayEmpty
              disableUnderline
              variant="outlined"
              required
              value={props.fromAddress || ""}
              onChange={(e) => {
                props.setFromAddress(e.target.value);
              }}
              style={{
                width: "100%",
                height: "30px",
                fontSize: "12px",
                fontWeight: "bold",
                borderRadius: "0px",
              }}
            >
              {fromEmailAddressList.map((e) => {
                return (
                  <MenuItem id={e.email} value={e.email}>
                    {`${e.name} <${e.email}>`}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </InputWrapper>
        <InputWrapper>
          <Label>{getLocalizedText("select_email_template")}*</Label>
          <FormControl
            sx={{ width: "100%" }}
            classes={{
              root: classes.quantityRoot,
            }}
          >
            <Select
              classes={{
                icon: classes.icon,
              }}
              name="email_template_id"
              displayEmpty
              disableUnderline
              variant="outlined"
              required
              value={props.emailTemplateID || ""}
              onChange={(e) => {
                props.setEmailTemplateID(e.target.value);
                let index = templateList.findIndex(
                  (template) => template["id"] === e.target.value
                );
                if (index !== -1) {
                  setVariableList(templateList[index]["variables"]);
                }
              }}
              style={{
                width: "100%",
                height: "30px",
                fontSize: "12px",
                fontWeight: "bold",
                borderRadius: "0px",
              }}
            >
              {templateList.map((e) => {
                return (
                  <MenuItem id={e} value={e.id}>
                    {e.template_title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </InputWrapper>
        {variableList.length > 0 && (
          <RequiredFieldWrapper>
            <Header>{getLocalizedText("variables")}</Header>
            <Grid
              container
              style={{ maxHeight: "160px", overflowY: "auto", rowGap: "20px" }}
            >
              {variableList.map((variable) => {
                return (
                  <>
                    <Grid container rowSpacing={3} columnSpacing={3}>
                      <Grid item xs={4}>
                        <Label>{`${snakeCaseToTitleCase(variable)}*`}</Label>
                      </Grid>
                      <Grid item xs={8}>
                        <Input
                          placeholder="Enter value"
                          required
                          name={variable}
                          value={props.emailTemplateVariableList[variable] || null}
                          onChange={handleVariable}
                        />
                      </Grid>
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </RequiredFieldWrapper>
        )}
      </InputContainer>
    </>
  );
};

export default observer(EmailCampaign);
