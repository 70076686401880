import React, { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { Print } from "@material-ui/icons";
import { PDFViewer, usePDF } from "@react-pdf/renderer";
import PrintQuoteLayout from "../../components/add_quote_sidepanel/print_quote_dialog/components/PrintQuoteLayout";
import PrintInvoiceLayout from "../../components/invoice_sidepanel/print_invoice_dialog/components/PrintInvoiceLayout";
import { Download } from "@mui/icons-material";
import {
  getLeadQuotationDataApi,
  getLeadInvoiceDataApi,
  getQuoteTemplateListApi,
} from "../../Api";

const PreviewContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
}));
const Navbar = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  height: "40px",
  margin: "auto",
}));
const Container = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
}));

const NavbarInnerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  height: "100%",
  padding: `0px ${theme.spacing(2)}`,
}));

const PreviewQuotePage = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [quoteData, setQuoteData] = useState({});
  const [quotationTemplateConfig, setQuotationTemplateConfig] = useState({});
  const [pdfRequestType, setPdfRequestType] = useState("");
  const [instance, updateInstance] = usePDF({});
  const [loading, setLoading] = useState(true);
  const [isInvoice, setIsInvoice] = useState(false);
  const [version, setVersion] = useState(1);

  const getPDFRenderComponent = (data, isInvoice) => {
    if (isInvoice) {
      return (
        <PrintInvoiceLayout
          data={data}
          templateConfig={quotationTemplateConfig}
          version={version}
        />
      );
    } else {
      return (
        <PrintQuoteLayout
          data={data}
          templateConfig={quotationTemplateConfig}
          version={version}
        />
      );
    }
  };

  const getInvoiceFileName = (invoiceData) => {
    const invoiceNumber = invoiceData["invoice_number"];
    if(invoiceNumber !== "-" && invoiceNumber !== ""){
      return invoiceNumber;
    }else {
      return invoiceData["id"];
    }
  }

  useEffect(() => {
    const handlePDFRequest = async () => {
      if (pdfRequestType === "print") {
        const { default: printJS } = await import("print-js");
        printJS(instance.url);
      } else if (pdfRequestType === "download") {
        const link = document.createElement("a");
        link.href = instance.url;
        link.download = isInvoice
          ? `${getInvoiceFileName(quoteData)}.pdf`
          : `${quoteData.id}.pdf`;
        link.click();
      }
      setPdfRequestType("");
    };

    if (!instance.loading && instance.url) {
      handlePDFRequest();
    }
    if (instance.error) {
      console.log(instance.error);
    }
  }, [instance, pdfRequestType]);

  const RenderPdfViewer = useCallback(() => {
    return (
      <PDFViewer
        showToolbar={false}
        style={{
          width: "100%",
          height: `calc(100vh - 47px)`,
        }}
      >
        {getPDFRenderComponent(quoteData, isInvoice)}
      </PDFViewer>
    );
  }, [quoteData, quotationTemplateConfig]);

  const init = async () => {
    let leadID = queryParams.get("lead_id");
    let isInvoice = queryParams.get("is_invoice") === "yes" ? true : false;
    setIsInvoice(isInvoice);

    let response = {};
    if (isInvoice) {
      let invoiceID = queryParams.get("invoice_id");
      response = await getLeadInvoiceDataApi({
        leadID: leadID,
        invoiceID: invoiceID,
      });
    } else {
      let quoteID = queryParams.get("quote_id");
      response = await getLeadQuotationDataApi({
        leadID: leadID,
        quoteID: quoteID,
      });
    }
    setQuoteData(response);

    //v1 if invoice/template data doesn't have copied schema from template; v2 - uses template schema
    const schema = isInvoice ? response["schema"] : response["quote_schema"];
    let versionVal = 1;
    if (schema?.hasOwnProperty("version")) {
      versionVal = schema["version"];
      setVersion(versionVal);
    }

    if (Object.keys(response).length > 0 && versionVal === 1) {
      //getting template schema for the selected quote template - only for older- version 1
      let templateListResponse = await getQuoteTemplateListApi();
      const templateIndex = templateListResponse.findIndex(
        (quote) => quote["id"] === response.quotation_template_id
      );
      if(templateIndex !== -1){
        const template = templateListResponse[templateIndex];
        setQuotationTemplateConfig(template);
      }
    }

    if (Object.keys(response).length > 0) {
      updateInstance(getPDFRenderComponent(response, isInvoice));
    }
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  return !loading ? (
    Object.keys(quoteData).length > 0 ? (
      <Container>
        <Box
          component="main"
          sx={{ flexGrow: 1, width: "80%", height: "100%" }}
        >
          <PreviewContainer>
            <Navbar>
              <NavbarInnerContainer>
                <IconButton
                  color="primary"
                  onClick={async () => {
                    setPdfRequestType("print");
                    updateInstance(getPDFRenderComponent(quoteData, isInvoice));
                  }}
                >
                  <Print />
                </IconButton>
                <IconButton
                  color="primary"
                  onClick={async () => {
                    setPdfRequestType("download");
                    updateInstance(getPDFRenderComponent(quoteData, isInvoice));
                  }}
                >
                  <Download />
                </IconButton>
              </NavbarInnerContainer>
            </Navbar>
            <RenderPdfViewer />
          </PreviewContainer>
        </Box>
      </Container>
    ) : (
      <Container
        style={{
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography>No data found.</Typography>
      </Container>
    )
  ) : (
    <Container
      style={{
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Container>
  );
};

export default PreviewQuotePage;
