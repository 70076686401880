import React, { useEffect } from "react";
import { useState } from "react";
import { Box } from "@material-ui/core";
import SettingsIcon from "@mui/icons-material/Settings";
import { styled, Typography, Switch, IconButton } from "@mui/material";
import LoadingAnimationComponent from "../loading_component/LoadingAnimationComponent";
import { getLocalizedText } from "../../Functions";

const Container = styled(Box)`
  width: 100%;
  position: relative;
`;
const Wrapper = styled(Box)`
  display: flex;
`;
const SectionWrapper = styled(Box)`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  background-color: #f9f9fc;
  padding: 20px;
`;
const Row = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: "12px";
`;
const SectionsContainer = styled(Box)`
  width: 100%;
  background-color: white;
  border-radius: 10px;
  display: flex;
  height: 100%;
  column-gap: 20px;
  row-gap: 20px;
`;
const DetailsHeader = styled(Typography)`
  font: normal normal 600 14px Open Sans;
  color: #979797;
  text-transform: uppercase;
`;
const Title = styled(Typography)`
  font: normal normal 600 22px Open Sans;
  color: #4d4e4f;
  text-align: left;
`;
const HeaderWrapper = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  column-gap: 20px;
`;
const Description = styled(Typography)`
  font: normal normal 500 14px Open Sans;
  color: #4d4e4f;
  text-align: left;
  word-wrap: break-word;
`;
const IPWhitelistingRow = (props) => {
  const [data, setData] = useState({
    enabled: false,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return !loading ? (
    <>
      <Container>
        <Wrapper>
          <SectionsContainer>
            <SectionWrapper>
              <HeaderWrapper>
                <DetailsHeader>
                  {getLocalizedText(props.rowData.type)}
                </DetailsHeader>
              </HeaderWrapper>
              <Row>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Title>{getLocalizedText(props.rowData.title)}</Title>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Switch
                      onClick={(e) => {
                        setData({
                          ...data,
                          enabled: e.target.checked,
                        });
                      }}
                      checked={data.enabled}
                      disabled={true} //disabled temporarily
                    />
                    <IconButton
                      color="primary"
                      disabled={!data.enabled}
                      onClick={() => {
                        props.onSettingsClick();
                      }}
                    >
                      <SettingsIcon
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </IconButton>
                  </Box>
                </Box>
                <Description>
                  {getLocalizedText(props.rowData.desc)}
                </Description>
              </Row>
            </SectionWrapper>
          </SectionsContainer>
        </Wrapper>
      </Container>
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "118px",
      }}
    >
      <LoadingAnimationComponent size={"small"} />
    </Box>
  );
};

export default IPWhitelistingRow;
